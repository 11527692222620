import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamationTriangle} from '@fortawesome/pro-regular-svg-icons';
import css from '@emotion/css/macro';
import {notify, useSubscription} from '../hooks/use-subscription';
import {useCurrentUser} from '../hooks/use-current-user';
export const TwoFactorWarning: React.FC<{}> = () => {
  const currentUser = useCurrentUser();
  useSubscription('phone-number-changed', () => {
    currentUser.retry();
  });
  return (
    <>
      {currentUser.value && !currentUser.value.phoneNumberConfirmed && (
        <div css={styles}>
          <div className="environment-warning">
            <FontAwesomeIcon icon={faExclamationTriangle} />
            Two Factor Authentication must be enabled for this account before
            February 1, 2024.{' '}
            <a
              className="link-button"
              onClick={() => {
                notify('change-phone-number-requested', undefined);
              }}
            >
              Click here
            </a>{' '}
            to add a cell phone number.
          </div>
        </div>
      )}
    </>
  );
};

const styles = css`
  .environment-warning {
    text-align: center;
    padding: 3px 0 2px;
    background-color: darkred;
    color: #fff;
  }

  .link-button {
    cursor: pointer;
    color: darkblue;
    font-weight: bold;
  }
`;
