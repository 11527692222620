import {css} from '@emotion/core';
import React from 'react';
import {Link, useHistory} from 'react-router-dom';
import {useAsyncFn} from 'react-use';
import {QuotesService} from '../../api/generated';
import {DeleteButton} from '../../components/confirm-button';
import {Form} from '../../forms';
import {useNotification} from '../../hooks/use-notifications';
import {routes} from '../../routes';

export const QuoteStageFormActions: React.FC<{
  quoteId: number;
  action?: React.ReactElement;
}> = ({quoteId, action}) => {
  const notifications = useNotification();
  const history = useHistory();
  const hasFilters = history.location.state
    ? history.location.state.hasFilters
    : false;

  const [deleteQuoteState, deleteQuote] = useAsyncFn(async () => {
    const response = await QuotesService.deleteById({id: quoteId});
    if (response.hasErrors) {
      notifications.errors(response.errors);
    } else {
      notifications.success('Quote successfully deleted');
      history.push(routes.quotes.index);
    }
  });

  return (
    <div
      className="form-actions"
      css={css`
        display: flex;
        justify-content: space-between;
      `}
    >
      <div>
        {action && <>{action}</>}
        <Form.Button
          secondary
          as={Link}
          onClick={
            hasFilters
              ? () => history.goBack()
              : () => history.push(routes.quotes.index)
          }
        >
          Close
        </Form.Button>
      </div>
      <DeleteButton
        onConfirm={deleteQuote}
        loading={deleteQuoteState.loading}
        text="Delete Quote"
      />
    </div>
  );
};
