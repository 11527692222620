import {Form} from '../forms';
import {fieldConfig, input, masked} from '../forms/schema-utils';
import {
  SetPhoneNumberRequest,
  UsersService,
  VerifyPhoneNumberRequest,
} from '../api/generated';
import React, {useState} from 'react';
import {notifications} from '../utils/notification-service';
import {List} from 'semantic-ui-react';

export const UpdatePhoneNumberModal: React.FC<{onComplete: () => void}> = ({
  onComplete,
}) => {
  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);
  return (
    <>
      {!phoneNumber ? (
        <UpdatePhoneNumber
          onPhoneNumberSubmitted={(number) => setPhoneNumber(number)}
          onComplete={onComplete}
        />
      ) : (
        <VerifyPhoneNumber phoneNumber={phoneNumber} onComplete={onComplete} />
      )}
    </>
  );
};

const UpdatePhoneNumber: React.FC<{
  onPhoneNumberSubmitted: (phoneNumber: string) => void;
  onComplete: () => void;
}> = ({onPhoneNumberSubmitted, onComplete}) => {
  return (
    <Form
      onSubmit={async (values) => {
        const response = await UsersService.setPhoneNumber({
          body: {phoneNumber: values.phoneNumber},
        });
        if (response.hasErrors) {
          return response;
        }
        onPhoneNumberSubmitted(response.data?.phoneNumber ?? '');
      }}
      render={() => (
        <>
          <List>
            <List.Item>
              Enter your cell phone number. A 6 digit code will be sent to
              confirm ownership of this number.
            </List.Item>
          </List>
          <Form.InputMasked fieldConfig={fields.phoneNumber} />
          <Form.Button type="submit" primary>
            Send Code
          </Form.Button>
          <Form.Button type="button" onClick={() => onComplete()}>
            Cancel
          </Form.Button>
        </>
      )}
    />
  );
};

export const VerifyPhoneNumber: React.FC<{
  phoneNumber: string;
  onComplete: () => void;
}> = ({phoneNumber, onComplete}) => {
  return (
    <Form
      onSubmit={async (values) => {
        const response = await UsersService.verifyPhoneNumber({
          body: {code: values.code},
        });
        if (response.hasErrors) {
          return response;
        }
        notifications.success('Phone Number Updated');
        onComplete();
      }}
      render={() => (
        <>
          <List>
            <List.Item>
              A 6 digit verification code has been sent to
              <strong> {formatPhoneNumber(phoneNumber)}</strong>. Enter the code
              below to continue.
            </List.Item>
          </List>
          <Form.Input fieldConfig={fields.code} />
          <Form.Button type="submit" primary>
            Submit
          </Form.Button>
        </>
      )}
    />
  );
};

const fields = fieldConfig<SetPhoneNumberRequest & VerifyPhoneNumberRequest>({
  phoneNumber: masked({
    fieldLabel: 'Phone Number',
    inputProps: {
      options: {
        blocks: [0, 3, 0, 3, 4],
        delimiters: ['(', ')', ' ', '-'],
      },
    },
  }),
  code: input({
    fieldLabel: 'Code',
  }),
});

function formatPhoneNumber(phoneNumber) {
  phoneNumber = phoneNumber.replace(/\D/g, '');
  return phoneNumber.replace(/\d(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
}
