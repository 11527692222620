import {css} from '@emotion/core';
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {Segment} from 'semantic-ui-react';
import {
  AssetMaintenanceVisit,
  LeaseCompletePmServicesService,
} from '../../api/generated';
import {Flex} from '../../components/flex';
import {LineItem, LineItemList} from '../../dashboard/dashboard-components';
import {usePagination} from '../../hooks/use-pagination';
import {MobileaseTheme} from '../../styles/branding';
import {AssetMaintenanceHistoryModal} from '../asset-maintenance-history/asset-maintenance-history-modal';
import {
  currencyFormatter,
  dateFormatter,
  numberFormatter,
} from '../asset-utils';

const INTIAL_PAGE_SIZE = 3;
export const AssetMaintenanceHistory: React.FC<{assetId: string}> = ({
  assetId,
}) => {
  const pagination = usePagination({
    initialPage: 1,
    initialPageSize: INTIAL_PAGE_SIZE,
  });

  const services = useAsync(() => {
    return LeaseCompletePmServicesService.getMaintenanceHistory({
      assetId: assetId,
      pageSize: pagination.pageSize,
      page: pagination.pageNumber,
    });
  }, [assetId, pagination.pageNumber, pagination.pageSize]);
  const [selectedVisit, setSelectedVisit] =
    useState<AssetMaintenanceVisit | null>(null);

  return (
    <Segment css={styles}>
      <Flex.Col justifyContent="space-between" className="container">
        {(services.value?.data?.totalItemCount ?? 0) === 0 ? (
          <div className="no-items-message">No items to display.</div>
        ) : (
          <>
            <LineItemList borderLast>
              {services.value?.data?.items.map((item) => (
                <LineItem
                  key={item.invoiveNbr}
                  onClick={() => setSelectedVisit(item)}
                >
                  <Flex.Row
                    justifyContent="space-between"
                    align="center"
                    className="maintenance-history"
                  >
                    <Flex.Col>
                      <p>
                        <span className="date">
                          <strong>{dateFormatter(item.visitDate)}</strong>
                        </span>{' '}
                        - {item.provider}
                      </p>
                      <p className="cost">
                        {item.showTotal && (
                          <>
                            <strong>Total Cost</strong>:
                            {currencyFormatter(item.totalCost)}
                          </>
                        )}
                      </p>
                      <p className="mileage">
                        <strong>Mileage</strong>:{' '}
                        {item.mileage ? numberFormatter(item.mileage) : 'N/A'}
                      </p>
                    </Flex.Col>
                    <FontAwesomeIcon icon={faChevronRight} className="icon" />
                  </Flex.Row>
                </LineItem>
              ))}
            </LineItemList>
            <div
              css={css`
                margin-top: 1rem;
              `}
            >
              {services.value?.data &&
                pagination.render(
                  services.value.data.pageCount,
                  services.value.data.totalItemCount /
                    (services.value.data.pageSize ?? 1)
                )}
            </div>
          </>
        )}
      </Flex.Col>
      {selectedVisit && (
        <AssetMaintenanceHistoryModal
          size="small"
          open
          visit={selectedVisit}
          onClose={() => setSelectedVisit(null)}
        />
      )}
    </Segment>
  );
};

const styles = css`
  height: 100%;

  .container {
    height: 100%;
  }

  .box {
    display: none;
  }
  .fill {
    display: flex;
    justify-content: center;
  }

  .header {
    .mileage,
    .service {
      display: inline-block;
      color: ${MobileaseTheme.colors.grey700};
      margin: 0;
    }
  }

  .no-items-message {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .date {
    color: ${MobileaseTheme.colors.grey700};
  }

  .maintenance-history {
    padding: 1rem 0rem;
    cursor: pointer;

    p {
      margin: 0;
    }

    & :hover {
      .icon {
        transform: translateX(5px);
        transition: transform 100ms ease-in;
      }
    }
  }

  .provider,
  .cost,
  .mileage {
    display: block;
    font-size: 12px;
    color: ${MobileaseTheme.colors.grey500};
  }
`;
