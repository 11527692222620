import React, {useContext} from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import {AssetsRoutes} from '../assets/routes';
import {AuthContext} from '../auth/use-auth';
import {CustomersRoutes} from '../customers/routes';
import {RequestTemplateRoutes} from '../request-templates/routes';
import {DashboardRoutes} from '../dashboard/routes';
import {FinancialRoutes} from '../financial/routes';
import {LogRoutes} from '../logs/routes';
import {RoutePatternRoutes} from '../route-patterns/routes';
import {SettingRoutes} from '../settings/routes';
import {UserRoutes} from '../users/routes';
import {InvoiceRoutes} from '../invoices/routes';
import {routes} from './index';
import {ErrorRoutes} from '../error/routes';
import {RequireAuthorization} from '../auth/require-authorization';
import {QuoteRoutes} from '../quotes/routes';
import {QuoteTermTemplatesRoutes} from '../quote-term-templates/routes';

export const Routes = () => {
  return (
    <Switch>
      <Route path={routes.error.index}>
        <ErrorRoutes />
      </Route>
      <Route path={routes.dashboard.index}>
        <DashboardRoutes />
      </Route>
      <Route path={routes.assets.index}>
        <RequireAuthorization permission="Assets: View">
          <AssetsRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.financials.index}>
        <RequireAuthorization permission="Financial: View">
          <FinancialRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.users.index}>
        <RequireAuthorization permission="Users: View & Manage">
          <UserRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.customers.index}>
        <RequireAuthorization permission="Customers: View & Manage">
          <CustomersRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.invoices.index}>
        <RequireAuthorization permission="Invoices: View & Manage">
          <InvoiceRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.requestTemplates.index}>
        <RequireAuthorization permission="Request Templates: View & Manage">
          <RequestTemplateRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.quotes.index}>
        <RequireAuthorization
          permission={['Quotes: Administrator', 'Quotes: User']}
        >
          <QuoteRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.quoteTermTemplates.index}>
        <RequireAuthorization
          permission={['Quotes: Administrator', 'Quotes: User']}
        >
          <QuoteTermTemplatesRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.settings.index}>
        <RequireAuthorization permission="Settings: View & Manage">
          <SettingRoutes />
        </RequireAuthorization>
      </Route>
      <Route path={routes.admin.logs.index}>
        <LogRoutes />
      </Route>
      <Route path={routes.admin.routePatterns.index}>
        <RoutePatternRoutes />
      </Route>
      <Route path={routes.callback}>
        <Callback />
      </Route>
      <Route>
        <Redirect to={routes.dashboard.index} />
      </Route>
    </Switch>
  );
};

const Callback = () => {
  const context = useContext(AuthContext);
  const redirect = context.redirectUrl ?? routes.dashboard.index;

  return <Redirect to={redirect} />;
};
