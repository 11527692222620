import {HubConnectionState} from '@microsoft/signalr';
import mitt from 'mitt';
import {useEffect} from 'react';

let emitter = mitt();

type SubscriptionEventMap = {
  'session-expired': undefined;
  'signalr-state-changed': HubConnectionState;
  'change-phone-number-requested': undefined;
  'phone-number-changed': undefined;
};

export type SubscriptionEvents = keyof SubscriptionEventMap;

export function useSubscription(
  eventName: SubscriptionEvents,
  cb: (event: SubscriptionEventMap[SubscriptionEvents]) => void
): void {
  useEffect(() => {
    emitter.on(eventName as any, cb as any);
    return () => emitter.off(eventName as any, cb as any);
  });
}

export function notify(
  eventName: SubscriptionEvents,
  event: SubscriptionEventMap[SubscriptionEvents]
) {
  emitter.emit(eventName, event);
}
