import {values} from 'lodash';
import qs from 'qs';
import {List, OptionItemDto_String} from '.';
import {Env} from '../../config/env-vars';
import {AnyObject} from '../../types';
import {logger} from '../../utils/logger';

const log = logger('code-gen utils');

export type DropdownOption = {
  text: string;
  value: any;
};

export const getEnumDropdownOptions = (() => {
  const enumMapCache = new WeakMap();
  return (enumObject: AnyObject) => {
    if (!enumObject) {
      throw new Error('invalid enum cache object');
    }
    if (enumMapCache.has(enumObject)) {
      return enumMapCache.get(enumObject) as DropdownOption[];
    }

    log.info('enum cache miss', enumObject);
    const enumOptions = values(enumObject).map((x) => ({
      text: x,
      value: x,
      key: x,
    }));

    enumMapCache.set(enumObject, enumOptions);
    return enumOptions as DropdownOption[];
  };
})();

export const getOptionDtoDropdownOptions = (
  optionDtos: List<OptionItemDto_String> | null
) => {
  if (!optionDtos) {
    return [];
  }

  return optionDtos.map<DropdownOption>((x) => {
    return {value: x.value, text: x.text};
  });
};

export function buildApiUrl<T>(url: string, params?: T | AnyObject) {
  const baseUrl = Env.apiBaseUrl;
  const queryString = params
    ? '?' + qs.stringify(params, {allowDots: true})
    : '';
  return `${baseUrl}${url}${queryString}`;
}
