import {css} from '@emotion/core';
import {useAsync} from 'react-use';
import {Modal, ModalProps} from 'semantic-ui-react';
import {
  AssetMaintenanceVisit,
  LeaseCompletePmServicesService,
} from '../../api/generated';
import {PmPlanTypes} from '../../api/generated/enums';
import {Flex} from '../../components/flex';
import {LineItem, LineItemList} from '../../dashboard/dashboard-components';
import {usePagination} from '../../hooks/use-pagination';
import {currencyFormatter} from '../asset-utils';

export const AssetMaintenanceHistoryModal: React.FC<
  ModalProps & {
    visit: AssetMaintenanceVisit;
  }
> = ({visit, ...modalProps}) => {
  const pagination = usePagination(
    {initialPage: 1, initialPageSize: 10},
    {boundaryRange: 1, siblingRange: 0}
  );
  const historyItems = useAsync(async () => {
    const response =
      await LeaseCompletePmServicesService.getMaintenanceVisitItems({
        invoiceNumber: visit.invoiveNbr,
        page: pagination.pageNumber,
        pageSize: pagination.pageSize,
      });
    return response;
  }, [pagination.pageNumber, pagination.pageSize, visit.invoiveNbr]);

  return (
    <Modal {...modalProps} css={styles}>
      <Modal.Header>{visit.provider}</Modal.Header>
      <Modal.Content>
        <Flex.Row
          align="center"
          justifyContent="space-between"
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <Flex.Col>
            <strong>Service</strong>
          </Flex.Col>
          <Flex.Col>
            <strong>Cost</strong>
          </Flex.Col>
        </Flex.Row>
        <LineItemList>
          {historyItems.value?.data?.items?.map((item) => (
            <LineItem>
              <Flex.Row justifyContent="space-between">
                <Flex.Col>{item.description}</Flex.Col>
                <Flex.Col>
                  {item.planType === PmPlanTypes.PassThru
                    ? currencyFormatter(item.cost)
                    : 'N/A'}
                </Flex.Col>
              </Flex.Row>
            </LineItem>
          ))}
          {historyItems.value && (
            <LineItem>
              <Flex.Row justifyContent="flex-end">
                {visit.showTotal && (
                  <strong>Total: {currencyFormatter(visit.totalCost)}</strong>
                )}
              </Flex.Row>
            </LineItem>
          )}
        </LineItemList>
        <div className="pagination">
          {historyItems.value?.data &&
            pagination.render(
              historyItems.value?.data?.pageCount,
              historyItems.value?.data?.totalItemCount
            )}
        </div>
      </Modal.Content>
    </Modal>
  );
};

const styles = css`
  .lines-per-page {
    display: none;
  }

  .fill {
    display: flex;
    justify-content: center;
  }

  .pagination {
    margin-top: 1rem;
  }
`;
