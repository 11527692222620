import {css} from '@emotion/core';
import moment from 'moment';
import React, {useState} from 'react';
import {Button, Dimmer, Divider, Label, Loader} from 'semantic-ui-react';
import {
  DashboardService,
  InvoicesService,
  LatestInvoiceDto,
  PaystarIntegrationService,
} from '../api/generated';
import Currency from '../components/currency';
import {useCustomerContext} from '../customer-context';
import {useNotification} from '../hooks/use-notifications';
import {MobileaseTheme} from '../styles/branding';
import {faInfoCircle} from '@fortawesome/pro-regular-svg-icons';
import {Tooltip} from '../components/tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useAsync, useAsyncFn} from 'react-use';
import {Flex} from '../components/flex';
import {AsyncStateContainer} from '../components/async-state-container';

export const FinancialSnapShot = () => {
  const [{currentCustomerId}] = useCustomerContext();

  const fetchFinancialInformation = useAsync(async () => {
    return await DashboardService.getFinancialInformation({
      customerId: currentCustomerId,
    });
  }, [currentCustomerId]);

  const financialInformation = fetchFinancialInformation.value?.data;

  return (
    <Flex.Col css={styles}>
      <AsyncStateContainer loading={fetchFinancialInformation.loading}>
        {financialInformation && (
          <>
            <CurrentBalance amount={financialInformation.currentBalance} />
            <Divider hidden />
            {financialInformation.latestInvoice ? (
              <LatestInvoice
                id={financialInformation.latestInvoice.id}
                amount={financialInformation.latestInvoice.amount}
                dueDate={financialInformation.latestInvoice.dueDate}
              />
            ) : (
              <EmptyInvoice />
            )}
          </>
        )}
      </AsyncStateContainer>
    </Flex.Col>
  );
};

export const CurrentBalance = ({amount}) => {
  const [context] = useCustomerContext();
  const [loadingPayment, setLoadingPayment] = useState(false);
  const notifications = useNotification();
  const handlePayNow = async () => {
    setLoadingPayment(true);
    const response =
      await PaystarIntegrationService.initiatePaystarPaymentSession({
        body: {amount: amount * 100, customerId: context.currentCustomerId},
      });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      setLoadingPayment(false);
      return;
    }
    if (response.data && response.data.paymentLogInLink) {
      window.open(response.data.paymentLogInLink, '_blank');
    }
    setLoadingPayment(false);
  };

  return (
    <>
      <Flex.Row justifyContent="space-between" align="center">
        <Flex.Col>
          <Flex.Row>
            <div className="header-text bold">
              Current Balance
              <Tooltip label="Balance may take as many as 3 business days to update.">
                <FontAwesomeIcon
                  className="header-text-icon"
                  icon={faInfoCircle}
                />
              </Tooltip>
            </div>
          </Flex.Row>
          <Flex.Row>
            <div className="current-balance bold">
              <Currency amount={amount} />
            </div>
          </Flex.Row>
        </Flex.Col>
        <Flex.Col>
          <Button
            className="snapshot-button"
            loading={loadingPayment}
            content={'Pay Now'}
            onClick={() => handlePayNow()}
          />
        </Flex.Col>
      </Flex.Row>
      {loadingPayment && (
        <Dimmer active page>
          <Loader active inverted>
            You are being redirected to Paystar...
          </Loader>
        </Dimmer>
      )}
    </>
  );
};

export const LatestInvoice: React.FC<
  Omit<LatestInvoiceDto, 'invoiceNumber'>
> = ({id, amount, dueDate}) => {
  const notifications = useNotification();

  const [getLinkState, sendGetLink] = useAsyncFn(async () => {
    const response = await InvoicesService.getInvoiceLink({
      id,
    });
    if (response.data) {
      window.open(response.data.toString(), '_blank');
    } else {
      notifications.error(response.errors);
    }
  });

  return (
    <>
      <Flex.Row justifyContent="space-between" align="center">
        <Flex.Col>
          <div className="header-text bold">Latest Invoice Amount</div>
        </Flex.Col>
        <Flex.Col>
          <Button
            size="small"
            secondary
            compact
            type="button"
            content="View Invoice"
            loading={getLinkState.loading}
            onClick={sendGetLink}
          />
        </Flex.Col>
      </Flex.Row>
      <Flex.Row justifyContent="space-between" align="top">
        <Flex.Col>
          <div className="invoice-charge bold">
            <Currency amount={amount} />
          </div>
        </Flex.Col>
        <Flex.Col>
          <div className="date">
            Due Date: {moment.utc(dueDate).format('MM/DD/YYYY')}
          </div>
        </Flex.Col>
      </Flex.Row>
    </>
  );
};

export const EmptyInvoice = () => {
  return (
    <>
      <Flex.Row justifyContent="space-between" align="center">
        <Flex.Col>
          <div className="header-text bold">Latest Invoice Amount</div>
        </Flex.Col>
      </Flex.Row>
      <Flex.Row justifyContent="space-between" align="top">
        <Flex.Col>
          <div className="invoice-charge bold">N/A</div>
        </Flex.Col>
        <Flex.Col>
          <div className="date">Due Date: N/A</div>
        </Flex.Col>
      </Flex.Row>
    </>
  );
};

export const OverDueTag = () => {
  return (
    <div css={overDueTagStyles}>
      <div className="tag-container">
        <Label content="Overdue" />
      </div>
    </div>
  );
};

const overDueTagStyles = css`
  .tag-container {
    display: flex;
    justify-content: flex-end;
  }

  .ui.label {
    background: #${MobileaseTheme.colors.orange50};
    color: #a04f11;
  }
`;

const styles = css`
  .bold {
    font-weight: bold;
  }

  .snapshot-button {
    height: 45px;
    width: 124px;
    margin-right: 0;
    background-color: ${MobileaseTheme.colors.blue400};
    color: ${MobileaseTheme.colors.white};
    align-self: flex-end;
  }

  .date,
  .invoiceNumber {
    font-size: 12px;
    color: ${MobileaseTheme.colors.grey500};
    align-self: flex-end;
  }

  .header-text {
    font-size: 14px;
  }

  .header-text-icon {
    font-size: 14px;
    margin-left: 0.5rem;
  }

  .invoice-charge {
    font-size: 16px;
  }

  .current-balance {
    font-size: 22px;
    color: ${MobileaseTheme.colors.blue500};
  }

  .total-amount {
    font-size: 20px;
  }
`;
