import css from '@emotion/css/macro';
import moment, {Moment} from 'moment';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {
  DateRangePicker,
  SingleDatePicker,
  SingleDatePickerShape,
} from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import {useForm, useFormState} from 'react-final-form';
import {Header, HtmlInputrops, StrictInputProps} from 'semantic-ui-react';
import nameof from 'ts-nameof.macro';
import {DateFormat} from '../../components/date';
import {themeColors} from '../../styles';
import {Button} from '../button';

type OmittedDatePickerProps =
  | 'id'
  | 'focused'
  | 'onFocusChange'
  | 'date'
  | 'onDateChange';

type DatePickerShape = Omit<SingleDatePickerShape, OmittedDatePickerProps> & {
  years?: number[];
};

export type DatePickerAdapterProps = StrictInputProps &
  HtmlInputrops &
  DatePickerShape;

export const DatePickerAdapter = ({input, meta, ...rest}) => {
  const [focused, setFocused] = useState<boolean | null>(false);
  const last = useRef<Moment | null>(null);

  let {years, ...additionalProps} = rest;

  const getDate = (date) => {
    if (!date) return;
    const possibleDate = moment(date).format('MM/DD/YYYY');
    const strings = possibleDate.toString().split('/');
    if (
      possibleDate.toString() !== 'Invalid date' &&
      strings[2] &&
      strings[2].length === 4 &&
      last.current !== date
    ) {
      last.current = date ? moment(date) : null;
    }
    return last.current;
  };

  return (
    <div css={datepickerStyles} className="date-picker-container">
      <SingleDatePicker
        id={input.id}
        inputIconPosition="after"
        focused={!!focused}
        onFocusChange={({focused}) => setFocused(focused)}
        noBorder
        enableOutsideDays
        isOutsideRange={() => false}
        numberOfMonths={1}
        date={getDate(input.value) as any}
        onDateChange={(date) => {
          input.onChange(date ? date.toDate().toISOString() : date);
        }}
        renderMonthElement={(props) =>
          years ? (
            <YearMonthSelector {...props} years={years} />
          ) : (
            <YearMonthSelector {...props} />
          )
        }
        {...additionalProps}
      />
    </div>
  );
};

export type DateRangeAdapterProps = StrictInputProps &
  HtmlInputrops & {
    startDateProperty: string;
    endDateProperty: string;
  };
type FocusedInputStates = 'startDate' | 'endDate' | null;
const getDate = (date) => (date ? moment(date) : null);

export const DateRangeCustomField: React.FC<DateRangeAdapterProps> = (
  props
) => {
  const [focusedInput, setFocusedInput] = useState<FocusedInputStates>(null);

  const state = useFormState();
  const startDate = getDate(state.values[props.startDateProperty]);
  const endDate = getDate(state.values[props.endDateProperty]);

  const form = useForm(nameof(DateRangeCustomField));

  useEffect(() => {
    form.registerField(props.startDateProperty, () => {}, {dirty: true});
    form.registerField(props.endDateProperty, () => {}, {dirty: true});
  }, [form, props.endDateProperty, props.startDateProperty]);

  const onDatesChanged = ({startDate, endDate}) => {
    form.batch(() => {
      form.change(
        props.startDateProperty,
        startDate ? startDate.toDate().toISOString() : startDate
      );
      form.change(
        props.endDateProperty,
        endDate ? endDate.toDate().toISOString() : endDate
      );
      form.blur(props.startDateProperty);
      form.blur(props.endDateProperty);
    });

    setFocusedInput(null);
  };

  return (
    <div css={datepickerStyles}>
      <DateRangePicker
        startDate={startDate as any}
        startDateId={props.startDateProperty}
        endDate={endDate as any}
        endDateId={props.endDateProperty}
        onDatesChange={onDatesChanged}
        focusedInput={focusedInput}
        onFocusChange={(focusedInput) => setFocusedInput(focusedInput)}
        noBorder
        enableOutsideDays
        numberOfMonths={1}
        minimumNights={0}
        renderCalendarInfo={() => (
          <CalendarQuickSelection onDatesChanged={onDatesChanged} />
        )}
        calendarInfoPosition="after"
      />
    </div>
  );
};

type CalendarQuickSelection = {
  onDatesChanged: (dates: {startDate: Moment; endDate: Moment}) => void;
};

const CalendarQuickSelection: React.FC<CalendarQuickSelection> = ({
  onDatesChanged,
}) => {
  const {
    today,
    yesterday,
    startOfWeek,
    startOfLastWeek,
    endOfLastWeek,
    startOfMonth,
  } = useMemo(() => {
    const today = moment().startOf('date').toDate();

    const yesterday = moment().startOf('date').subtract(1, 'day').toDate();

    const startOfWeek = moment().startOf('week').toDate();

    const startOfLastWeek = moment()
      .startOf('week')
      .subtract(1, 'week')
      .toDate();

    const endOfLastWeek = moment(startOfLastWeek)
      .add(1, 'week')
      .subtract(1, 'day')
      .toDate();

    const startOfMonth = moment().startOf('month').toDate();

    return {
      today,
      yesterday,
      startOfWeek,
      startOfLastWeek,
      endOfLastWeek,
      startOfMonth,
    };
  }, []);

  return (
    <div className="DateRangePicker__actions">
      <Header>Quick Selection</Header>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(today),
            endDate: moment(today),
          });
        }}
      >
        <strong>{'Today: '}</strong>
        <DateFormat date={today} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(yesterday),
            endDate: moment(yesterday),
          });
        }}
      >
        <strong>{'Yesterday: '}</strong>
        <DateFormat date={yesterday} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfWeek),
            endDate: moment(today),
          });
        }}
      >
        <strong>{'This Week: '}</strong>
        <DateFormat date={startOfWeek} />
        {' - '}
        <DateFormat date={today} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfLastWeek),
            endDate: moment(endOfLastWeek),
          });
        }}
      >
        <strong>{'Last Week: '}</strong>
        <DateFormat date={startOfLastWeek} />
        {' - '}
        <DateFormat date={endOfLastWeek} />
      </Button>
      <Button
        type="button"
        className="clear"
        basic
        size="small"
        fluid
        onClick={() => {
          onDatesChanged({
            startDate: moment(startOfMonth),
            endDate: moment(today),
          });
        }}
      >
        <strong>{'MTD: '}</strong>
        <DateFormat date={startOfMonth} />
        {' - '}
        <DateFormat date={today} />
      </Button>
    </div>
  );
};

const datepickerStyles = css`
  .SingleDatePicker,
  .SingleDatePicker > div,
  .SingleDatePickerInput,
  .SingleDatePickerInput > div {
    display: flex;
    flex: 1 1 0%;
  }

  .SingleDatePickerInput__showClearDate {
    padding-right: 0px;
  }

  .SingleDatePickerInput_clearDate {
    margin: 1px 5px 0 0;
    padding: 5px 7px 7px;
  }

  .SingleDatePickerInput_clearDate__default:hover {
    color: #000;
    border-radius: 4px;
    background: rgba(219, 219, 219, 0.65);
  }

  .SingleDatePicker .DateInput_input {
    background-color: #fff;
    font-weight: 400 !important;
  }

  .DateInput_fang {
    margin-top: 1px;
  }

  .DayPicker__withBorder {
    box-shadow: 0px 1px 11px 1px rgba(0, 0, 0, 0.4),
      0 0 0 1px rgba(0, 0, 0, 0.07) !important;
  }

  .DateRangePicker {
    border: solid 2px #cbcfd1;
    border-radius: 4px;

    .DateInput_input {
      border: none !important;
      background: #fff !important;
      font-weight: normal;
    }

    .DateRangePickerInput_arrow {
      margin: 0 5px;
    }

    .DateInput {
      width: 128px;
    }

    .DateInput:last-child .DateInput_input {
      text-align: right;
    }

    .DateInput_input__focused {
      outline: solid #85b7d9 2px !important;
    }
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected:hover,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover {
    background: ${themeColors.black1};
    border: 1px double ${themeColors.black1};
    color: #fff;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span,
  .CalendarDay__hovered_span:active {
    background: ${themeColors.black1};
    border: 1px double ${themeColors.black1};
    color: #fff;
  }

  .DayPicker {
    background: #f1f1f1;
  }

  .DayPicker_wrapper__horizontal {
    background: #fff;
  }

  .DayPicker_transitionContainer {
    border-right: solid 1px #d2d2d2;
    background: #fff;
  }

  .DateRangePicker__actions {
    padding: 20px;

    .ui.button {
      text-align: left;

      strong {
        width: 85px;
        display: inline-block;
      }
    }
  }
`;

const YEARS = Array.from({length: 120})
  .map((_, idx) => moment().year() - idx)
  .reverse();

export const YearMonthSelector = ({
  month,
  onMonthSelect,
  onYearSelect,
  years = YEARS,
}) => (
  <div css={yearStyles}>
    <div>
      <select
        value={month.month()}
        onChange={(e) => {
          onMonthSelect(month, e.target.value);
        }}
      >
        {moment.months().map((label, value) => (
          <option value={value} key={value}>
            {label}
          </option>
        ))}
      </select>
    </div>
    <div>
      <select
        value={month.year()}
        onChange={(e) => {
          onYearSelect(month, e.target.value);
        }}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </div>
  </div>
);

const yearStyles = css`
  display: flex;
  justify-content: center;

  > div:first-of-type {
    margin-right: 3px;
  }

  select {
    font-size: 0.8rem;
    margin-top: -4px;
  }
`;
