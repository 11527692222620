import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import '@fontsource/manrope';
import 'rsuite/styles/index.less';
import 'semantic-ui-less/semantic.less';
import '@reach/skip-nav/styles.css';
import '@reach/tooltip/styles.css';

import './dev-tools';
import './analytics/sentry';
import './api/generated/config';

import reportWebVitals from './reportWebVitals';

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from 'react-router-dom';
import {App} from './app';
import {AuthProvider} from './auth/use-auth';
import {Env} from './config/env-vars';
import {GlobalStyles} from './styles';

ReactDOM.render(
  <Router basename={Env.subdirectory}>
    <AuthProvider>
      <GlobalStyles />
      <App />
    </AuthProvider>
  </Router>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
