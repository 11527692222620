import {css} from '@emotion/core';
import React from 'react';
import {Label} from 'semantic-ui-react';
import {AssetDto, AssetListingsDto} from '../api/generated';
import {DateFormat} from '../components/date';
import {MobileaseTheme} from '../styles/branding';
import {endOfMonth} from './asset-utils';

export const LeaseLabel: React.FC<{
  terminationDate: Date;
  isLeaseExpiring: boolean;
  isLeaseExpired: boolean;
}> = ({terminationDate, isLeaseExpiring, isLeaseExpired}) => {
  const displayDate = terminationDate ? endOfMonth(terminationDate) : 'N/A';

  return isLeaseExpiring || isLeaseExpired ? (
    <div css={styles}>
      <Label className={isLeaseExpiring ? 'warning' : 'danger'}>
        {displayDate}
      </Label>
    </div>
  ) : (
    <>{displayDate}</>
  );
};

export const RegistrationLabel: React.FC<{
  assetDto: AssetDto | AssetListingsDto;
}> = ({assetDto}) => {
  return assetDto.isAssetRegistrationExpiring ||
    assetDto.isAssetRegistrationExpired ? (
    <div css={styles}>
      <Label
        className={assetDto.isAssetRegistrationExpiring ? 'warning' : 'danger'}
      >
        <DateFormat date={assetDto.assetLicenseExpDate} />
      </Label>
    </div>
  ) : (
    <>
      {assetDto.assetLicenseExpDate ? (
        <DateFormat date={assetDto.assetLicenseExpDate} />
      ) : (
        'N/A'
      )}
    </>
  );
};

const styles = css`
  .ui.label.warning {
    background: ${MobileaseTheme.colors.yellow50};
    color: ${MobileaseTheme.colors.yellow800};
  }

  .ui.label.danger {
    background: ${MobileaseTheme.colors.orange50};
    color: ${MobileaseTheme.colors.orange700};
  }
`;
