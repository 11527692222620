import css from '@emotion/css';
import React from 'react';
import {Loader} from 'semantic-ui-react';
import {MobileaseTheme} from '../styles/branding';
import {EnumDictionary} from '../types';

type AttentionCardStylingProps = {
  cardBackgroundColor: string;
  labelColor: string;
  valueBackgroundColor: string;
  cardHoverBackgroundColor?: string;
  valueHoverBackgroundColor?: string;
  labelHoverColor?: string;
};

type BaseAttentionCardProps = {
  loading?: boolean;
  value: number | React.ReactNode;
  label: string | React.ReactNode;
  onClick?: () => void;
};

type AttentionCardPropsWithStatus = BaseAttentionCardProps &
  Partial<AttentionCardStylingProps> & {
    type: AttentionCardType;
  };

type AttentionCardPropsWithoutStatus = BaseAttentionCardProps &
  AttentionCardStylingProps & {
    type?: undefined;
  };

export const AttentionCard: React.FC<
  AttentionCardPropsWithStatus | AttentionCardPropsWithoutStatus
> = ({
  value,
  label,
  onClick,
  children,
  loading = false,
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  type = AttentionCardType.CallToAction,
  ...styleProps
}) => {
  return (
    <div css={styles(styleProps, type)}>
      <div className="container" onClick={onClick}>
        <div className="value">
          {loading ? <Loader inline inverted active /> : value}
        </div>
        <div className="label">{label}</div>
      </div>
    </div>
  );
};
const styles = (
  {
    cardBackgroundColor,
    labelColor,
    valueBackgroundColor,
    cardHoverBackgroundColor,
    labelHoverColor,
    valueHoverBackgroundColor,
  }: Partial<AttentionCardStylingProps>,
  cardType: AttentionCardType
) => {
  const styleForCardType = builtInStyles[cardType];
  const cardBackgroundChoosen = cardBackgroundColor
    ? cardBackgroundColor
    : styleForCardType.cardBackgroundColor;
  return css`
    display: flex;
    width: 100%;

    .container {
      display: flex;
      flex-wrap: wrap;
      flex: 1;
      align-items: center;
      cursor: pointer;
      padding: 0.75rem;
      padding-left: 2rem;
      border-radius: 15px;
      justify-content: center;
      background-color: ${cardBackgroundChoosen};
      transition: background-color 200ms ease-in-out;
      transition: transform 200ms ease-in-out;
      user-select: none;

      &:hover {
        transform: translateY(-2px);
        background-color: ${cardHoverBackgroundColor ??
        styleForCardType.cardHoverBackgroundColor ??
        styleForCardType.cardBackgroundColor};

        & .label {
          color: ${labelHoverColor ??
          styleForCardType.labelHoverColor ??
          styleForCardType.labelColor};
        }

        .value {
          background-color: ${valueHoverBackgroundColor ??
          styleForCardType.valueHoverBackgroundColor ??
          styleForCardType.valueBackgroundColor};
        }
      }
    }

    .value {
      display: grid;
      place-items: center;
      background-color: ${valueBackgroundColor ??
      styleForCardType.valueBackgroundColor};
      color: white;
      height: 45px;
      min-width: 45px;
      padding: 0rem 0.5rem;
      border-radius: 1.5rem;
      font-size: 1.5rem;
      font-weight: 700;
    }

    .label {
      flex-basis: 5rem;
      flex-grow: 1;
      color: ${labelColor ?? styleForCardType.labelColor};
      font-size: 0.875rem;
      font-weight: 600;
      margin: 1rem;
    }
  `;
};

export enum AttentionCardType {
  Success = 0,
  Warning = 1,
  Danger = 2,
  CallToAction = 3,
  Information = 4,
}

const builtInStyles: EnumDictionary<
  AttentionCardType,
  AttentionCardStylingProps
> = {
  [AttentionCardType.Success]: {
    cardBackgroundColor: MobileaseTheme.colors.white,
    cardHoverBackgroundColor: MobileaseTheme.colors.blue50,
    labelColor: MobileaseTheme.colors.green800,
    labelHoverColor: MobileaseTheme.colors.blue800,
    valueBackgroundColor: MobileaseTheme.colors.green500,
  },
  [AttentionCardType.Danger]: {
    cardBackgroundColor: MobileaseTheme.colors.orange50,
    cardHoverBackgroundColor: MobileaseTheme.colors.orange300,
    labelColor: MobileaseTheme.colors.orange700,
    labelHoverColor: MobileaseTheme.colors.orange900,
    valueBackgroundColor: MobileaseTheme.colors.orange300,
    valueHoverBackgroundColor: MobileaseTheme.colors.orange500,
  },
  [AttentionCardType.CallToAction]: {
    cardBackgroundColor: MobileaseTheme.colors.purple50,
    cardHoverBackgroundColor: MobileaseTheme.colors.purple300,
    labelColor: MobileaseTheme.colors.purple700,
    labelHoverColor: MobileaseTheme.colors.purple900,
    valueBackgroundColor: MobileaseTheme.colors.purple300,
    valueHoverBackgroundColor: MobileaseTheme.colors.purple500,
  },
  [AttentionCardType.Warning]: {
    cardBackgroundColor: MobileaseTheme.colors.yellow50,
    cardHoverBackgroundColor: MobileaseTheme.colors.yellow300,
    labelColor: MobileaseTheme.colors.yellow800,
    valueBackgroundColor: MobileaseTheme.colors.yellow500,
    valueHoverBackgroundColor: MobileaseTheme.colors.yellow600,
  },
  [AttentionCardType.Information]: {
    cardBackgroundColor: MobileaseTheme.colors.blue50,
    cardHoverBackgroundColor: MobileaseTheme.colors.blue300,
    valueBackgroundColor: MobileaseTheme.colors.blue300,
    valueHoverBackgroundColor: MobileaseTheme.colors.blue500,
    labelColor: MobileaseTheme.colors.blue600,
    labelHoverColor: MobileaseTheme.colors.blue900,
  },
};
