import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga';
import {AnalyticsEvents} from './events';
import {Env} from '../config/env-vars';
import {logger} from '../utils/logger';

type Analytics = {
  identify: Mixpanel['identify'];
  set: Mixpanel['people']['set'];
  trackEvent: (
    eventName: AnalyticsEvents,
    properties?: {
      [index: string]: any;
    }
  ) => void;
  timeEvent: (eventName: AnalyticsEvents) => void;
  trackPage: (url: string) => void;
};

const log = logger('analytics');

const {isProductionBuild, mixPanelToken, googleAnalyticsId} = Env;

const initializeMixpanel = isProductionBuild && !!mixPanelToken;
const initializeGoogleAnalytics = isProductionBuild && !!googleAnalyticsId;

let analtyics: Analytics;
let identified = false;

analtyics = {
  identify: mockInDevelopment('identify', (uniqueId) => {
    identified = true;
    mixpanel.identify(uniqueId);
    ReactGA.set({userId: uniqueId});
  }),
  trackEvent: mockInDevelopment('trackEvent', (eventName, properties) => {
    if (identified) {
      mixpanel.track(eventName, properties);
    }
    ReactGA.event({
      category: 'Tracked Event',
      action: eventName,
    });
  }),
  set: mockInDevelopment('set', (properties) => {
    if (identified) {
      mixpanel.people.set(properties);
    }
  }),
  timeEvent: mockInDevelopment('timeEvent', (eventName) => {
    if (identified) {
      mixpanel.time_event(eventName);
    }
  }),
  trackPage: mockInDevelopment('trackPage', (url) => {
    ReactGA.pageview(url);

    if (identified) {
      const pageview: AnalyticsEvents = 'routes.pageview';
      mixpanel.track(pageview, {url});
    }
  }),
};

if (initializeMixpanel) {
  mixpanel.init(mixPanelToken as string);
}

if (initializeGoogleAnalytics) {
  ReactGA.initialize(googleAnalyticsId as string);
}

function mockInDevelopment(method, action) {
  return isProductionBuild
    ? (...args) => {
        try {
          action(...args);
        } catch (error) {
          log.error(`analytics.${method}`, error);
        }
      }
    : (...args) => log.info(`analytics.${method}`, ...args);
}

export {analtyics};
