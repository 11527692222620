import css from '@emotion/css';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Button} from 'semantic-ui-react';
import {AssetDto} from '../../api/generated';
import {Flex} from '../../components/flex';
import {routes} from '../../routes';
import {MobileaseTheme} from '../../styles/branding';
import {MaintenanceStatusIcon} from '../icons';
import {ContractProgress} from './asset-progress';

export const AssetNavigator: React.FC<{asset: AssetDto}> = ({asset}) => {
  const history = useHistory();
  const hasFilters = history.location.state
    ? history.location.state.hasFilters
    : false;
  const {assetDescription} = asset;
  return (
    <>
      <Flex.Row css={navStyles} justifyContent="space-between">
        <Flex.Col>
          <Flex.Row align="center">
            <Flex.Col justifyContent="justify-center">
              <Button
                className="back-button"
                circular
                icon="arrow left"
                onClick={
                  hasFilters
                    ? () => history.goBack()
                    : () => history.push(routes.assets.index)
                }
                size="small"
              />
            </Flex.Col>
            <Flex.Col className="text-align-center">
              <Flex.Row align="center">
                <h3>
                  {assetDescription !== ''
                    ? assetDescription
                    : `VIN: ${asset.assetSerialNbr}`}
                </h3>
              </Flex.Row>
              <Flex.Row>
                {asset.assetDescription !== '' && (
                  <>VIN: {asset.assetSerialNbr}</>
                )}
              </Flex.Row>
            </Flex.Col>
            <Flex.Col
              justifyContent="space-around"
              className="text-align-center"
            >
              <div className="status-icons">
                {asset.isUnderMaintenancePlan && (
                  <MaintenanceStatusIcon size="lg" />
                )}
              </div>
            </Flex.Col>
          </Flex.Row>
        </Flex.Col>
        <Flex.Col className="min-width-25">
          {asset.assetLease && (
            <ContractProgress
              startDate={asset.assetLease.leaseFirstPmtDate}
              endDate={asset.assetLease.leaseLastPmtDate}
              status={asset.assetLease.leaseStatus}
            />
          )}
        </Flex.Col>
      </Flex.Row>
    </>
  );
};

const navStyles = css`
  margin: 0.5rem 0 0.5rem 0;

  .ui.button {
    color: ${MobileaseTheme.colors.blue600};
    background: ${MobileaseTheme.colors.blue50};

    & :hover {
      background: ${MobileaseTheme.colors.blue100};
    }
  }

  .back-button {
    margin: 0 1rem 0 1rem;
  }

  .status-icons {
    display: inline;
    margin-left: 1rem;

    & > * {
      margin-right: 1rem;
    }
  }

  .text-align-center {
    text-align: center;
  }

  .min-width-25 {
    min-width: 25%;
  }
`;
