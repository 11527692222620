import {css} from '@emotion/core';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import _ from 'lodash';
import React from 'react';
import {useAsync} from 'react-use';
import {Segment} from 'semantic-ui-react';
import {FleetOverviewDto, Response} from '../../api/generated';
import {useRequireAuthorization} from '../../auth/require-authorization';
import {useCustomerContext} from '../../customer-context';
import {useNotification} from '../../hooks/use-notifications';
import {
  fleetOverviewChartColors,
  containerProps,
  dataLabelStyles,
  legendItemStyles,
} from './chart-styles';

const getOptions = (
  fleetData: FleetOverviewDto | null,
  onClick?: (seriesName: string) => void
): Highcharts.Options => {
  const total = _.sum(fleetData?.fleetCounts.map((x) => x.count) ?? []);

  var series: Highcharts.SeriesOptionsType[] =
    fleetData?.fleetCounts.map((x) => {
      return {
        type: 'bar',
        name: x.status,
        pointWidth: 50,
        data: [x.count],
        dataLabels: dataLabelStyles,
        custom: {
          assetStatus: x.status,
        },
      };
    }) ?? [];

  return {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      type: 'bar',
    },
    colors: fleetOverviewChartColors,
    series: series,
    xAxis: {
      categories: [''],
    },
    yAxis: {
      min: 0,
      max: total,
      labels: {
        enabled: false,
      },
      tickInterval: total / 4,
      title: {
        text: null,
      },
    },
    legend: {
      reversed: true,
      itemStyle: legendItemStyles,
    },
    plotOptions: {
      series: {
        stacking: 'normal',
        cursor: 'pointer',
        dataLabels: {
          formatter: function (
            this: Highcharts.PointLabelObject,
            options: any
          ) {
            return this.y ? this.y : null;
          },
        },
        point: {
          events: {
            click: function () {
              onClick &&
                onClick(this.series.options['custom'].assetStatus ?? '');
            },
          },
        },
      },
    },
  };
};

export const FleetOverviewChart: React.FC<{
  fetchFleetOverView: () => Promise<Response<FleetOverviewDto>>;
  onChartClick?: (assetStatusSelected: string) => void;
}> = ({fetchFleetOverView, onChartClick}) => {
  const [{currentCustomerId}] = useCustomerContext();

  const notifications = useNotification();
  const canViewAssets = useRequireAuthorization({permission: 'Assets: View'});
  const fleetData = useAsync(async () => {
    const results = await fetchFleetOverView();
    if (results.hasErrors) {
      notifications.errors(results.errors);
      return;
    }

    return getOptions(results.data, onChartClick);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewAssets, currentCustomerId]);

  return (
    <Segment css={styles}>
      <HighchartsReact
        containerProps={containerProps}
        highcharts={Highcharts}
        options={fleetData.value}
      />
    </Segment>
  );
};

const styles = css`
  height: 100%;
  padding-bottom: 0 !important;
`;
