import {useCallback, useMemo} from 'react';
import {DropzoneOptions, useDropzone} from 'react-dropzone';
import {Message} from 'semantic-ui-react';
import React from 'react';

export type FileDropzoneProps = DropzoneOptions & {
  files: File[];
  onFilesChanged: (acceptedFiles: File[]) => void;
  header?: string;
  subHeader?: string;
  fileUploadedMessage?: string;
};

export const FileDropzone = ({
  files,
  onFilesChanged,
  header = 'Upload files',
  subHeader = 'Drag file here, or click to select file',
  fileUploadedMessage = 'File selected',
  ...dropZoneOptions
}: FileDropzoneProps) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onFilesChanged(acceptedFiles);
    },
    [onFilesChanged]
  );

  const {getRootProps, getInputProps} = useDropzone({
    accept: dropZoneOptions.accept,
    maxFiles: dropZoneOptions.maxFiles,
    onDrop,
  });

  const uploader = useMemo(
    () => (
      <div {...getRootProps()}>
        <Message
          icon="upload"
          header={header}
          content={
            <>
              <input {...getInputProps()} />
              <p>{subHeader}</p>
            </>
          }
        />
      </div>
    ),
    [getInputProps, getRootProps, header, subHeader]
  );

  const thumbs = useMemo(
    () => (
      <div>
        {files.length && (
          <Message
            icon="check"
            header={fileUploadedMessage}
            content={
              <>
                <p>Uploading {files.map((f) => f.name).join(', ')}</p>
              </>
            }
          />
        )}
      </div>
    ),
    [fileUploadedMessage, files]
  );

  return <div className="ui field">{files?.length ? thumbs : uploader}</div>;
};
