/* eslint-disable react-perf/jsx-no-new-object-as-prop */
import React from 'react';
import copy from 'copy-to-clipboard';
import css from '@emotion/css/macro';
import Highlight from 'react-highlight';
import {BasicPage} from '../basic-page';
import {Chip} from '../components/chip';
import {DateFormat} from '../components/date';
import {ellipsis} from 'polished';
import {faCheck, faTimes} from '@fortawesome/pro-regular-svg-icons';
import {Flex} from '../components/flex';
import {getPooledMoment} from '../utils/get-pooled-moment';
import {getStatusColor} from './logs-listing';
import {LogHandleStatuses} from '../api/generated/enums';
import {
  LogGroupNotesService,
  LogSummariesService,
  LogSummaryDetailDto,
  LogSummaryListDto,
} from '../api/generated';
import {notifications} from '../utils/notification-service';
import {routes} from '../routes';
import {Tooltip} from '../components/tooltip';
import {useAsyncFn, useAsyncRetry, useList} from 'react-use';
import {useRouteMatch} from 'react-router-dom';
import '../../node_modules/highlight.js/styles/an-old-hope.css';
import {
  Button,
  Grid,
  Header,
  List,
  Modal,
  Segment,
  Tab,
  Table,
} from 'semantic-ui-react';
import {useProduce} from '../hooks/use-produce';
import {ConfirmButton} from '../components/confirm-button';
import moment from 'moment';
import {Form} from '../forms';

type LogGroupParams = {
  status: string;
  errorMessages: string;
  matchedPattern: string;
};

type LogGroupDetailState = {
  currentPage: number;
  selectedLogId: number;
  openNotes: boolean;
};

const DefaultState: LogGroupDetailState = {
  currentPage: 1,
  selectedLogId: 0,
  openNotes: false,
};

const breadcrumbs = [
  {title: 'Logs', url: routes.admin.logs.index},
  {title: 'Log Details'},
];

export const LogGroupDetail = () => {
  const [list, {push, clear}] = useList<LogSummaryListDto>();
  const [state, setState] = useProduce<LogGroupDetailState>(DefaultState);
  const match = useRouteMatch<{parameters: string}>();
  const parameters = match.params.parameters;

  const fetchLogs = useAsyncRetry(async () => {
    const response = await LogSummariesService.getByGroup({
      parameters,
      pageSize: list.length !== 0 ? 7 : 7 * state.currentPage,
      page: list.length !== 0 ? state.currentPage : 1,
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
    } else {
      if (response.data) {
        push(...response.data.items);
        !state.selectedLogId &&
          setState(
            (draft) =>
              (draft.selectedLogId = Number(response.data?.items[0].id))
          );
      }
      return response.data;
    }
  });

  const fetchSelectedLog = useAsyncRetry(async () => {
    if (state.selectedLogId) {
      const {data} = await LogSummariesService.getById({
        id: state.selectedLogId,
      });
      return data;
    }
  }, [state.selectedLogId]);

  const logGroupParams = JSON.parse(atob(parameters)) as LogGroupParams;
  const logs = fetchLogs.value;
  const selectedLog = fetchSelectedLog.value;

  const fetchNotes = useAsyncRetry(async () => {
    const {data} = await LogGroupNotesService.getByParameters({parameters});
    return data;
  });

  const notes = fetchNotes.value;

  const [resolveAllState, resolveAll] = useAsyncFn(async () => {
    const response = await LogSummariesService.resolveGroup({parameters});
    if (response.hasErrors) {
      return response;
    } else {
      notifications.success('Resolved all occurrences');
    }
  });

  const [updateHandleStatusState, updateHandleStatus] = useAsyncFn(
    async (log: LogSummaryDetailDto) => {
      var status = log.handledStatus === 'Resolved' ? 'Unresolved' : 'Resolved';
      const response = await LogSummariesService.updateHandledStatus({
        id: log.id,
        status,
      });
      if (response.hasErrors) {
        return response;
      } else {
        notifications.success(`Occurrence marked as ${status.toLowerCase()}`);
      }
    }
  );

  const onSaveNotes = async (values) => {
    const response = await LogGroupNotesService.update({
      notes: values.notes,
      parameters,
    });
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Notes updated');
    setState((draft) => (draft.openNotes = false));
    fetchNotes.retry();
  };

  const handleResolveAll = async () => {
    await resolveAll();
    refreshList();
  };

  const handleUpdateHandleStatus = async (log: LogSummaryDetailDto) => {
    await updateHandleStatus(log);
    refreshList();
  };

  const refreshList = () => {
    clear();
    fetchLogs.retry();
    fetchSelectedLog.retry();
  };

  const handleCopy = (value: string) => {
    copy(value);
    notifications.info(`"${value}" copied to clipboard`);
  };

  const getStatusLevel = (status: string) => {
    const typedStatus = status as LogHandleStatuses;
    switch (typedStatus) {
      case 'Resolved':
        return 'success';
      case 'Unresolved':
        return 'error';
      default:
        return 'neutral';
    }
  };

  const isResolved = (status: string) => {
    const typedStatus = status as LogHandleStatuses;
    return typedStatus === 'Resolved';
  };

  return (
    <BasicPage title={breadcrumbs} css={styles}>
      {list && logs && (
        <>
          <Segment>
            <Grid>
              <Grid.Column textAlign="center" width={5}>
                <Header
                  color={getStatusColor(logGroupParams.status)}
                  className="status"
                >
                  {logGroupParams.status}
                </Header>
                <Header sub size="huge" className="occurrences">
                  {logs.totalItemCount}{' '}
                  {logs.totalItemCount > 1 ? 'occurrences' : 'occurrence'}
                </Header>
                {list[0]?.timestamp && (
                  <time title={moment(list[0]?.timestamp).format('LLLL')}>
                    Last occurred{' '}
                    {getPooledMoment(list[0]?.timestamp).fromNow()}
                  </time>
                )}
              </Grid.Column>
              <Grid.Column verticalAlign="middle" width={7}>
                <Header>{logGroupParams.matchedPattern}</Header>
                {logGroupParams.errorMessages}
              </Grid.Column>
              <Grid.Column width={4} textAlign="right">
                <Flex.Row css={{float: 'right'}}>
                  <Button
                    basic
                    className="clear"
                    onClick={() =>
                      setState((draft) => (draft.openNotes = true))
                    }
                  >
                    View Notes
                  </Button>
                  <Modal
                    open={state.openNotes}
                    size="small"
                    onClose={() =>
                      setState((draft) => (draft.openNotes = false))
                    }
                  >
                    <Modal.Header>Notes</Modal.Header>
                    <Modal.Content>
                      <Form
                        initialValues={notes || {}}
                        onSubmit={onSaveNotes}
                        render={() => (
                          <>
                            <Form.TextArea
                              fieldName="notes"
                              fieldLabel="Notes"
                              rows={12}
                            />
                            <Modal.Actions className="modal-actions">
                              <Form.Button type="submit" primary>
                                Save
                              </Form.Button>
                            </Modal.Actions>
                          </>
                        )}
                      />
                    </Modal.Content>
                  </Modal>
                  <ConfirmButton
                    onConfirm={handleResolveAll}
                    loading={resolveAllState.loading}
                    text="Resolve All"
                    loadingText="Resolving all..."
                    icon={faCheck}
                  />
                </Flex.Row>
              </Grid.Column>
            </Grid>
          </Segment>
          <Grid>
            {selectedLog && (
              <Grid.Column width={list.length > 1 ? 12 : 16}>
                <Segment className="occurrence-detail">
                  <div className="detail-header">
                    <span>
                      {selectedLog.handledStatus && (
                        <Chip
                          level={getStatusLevel(selectedLog.handledStatus)}
                          text={selectedLog.handledStatus}
                        />
                      )}
                    </span>
                    {isResolved(selectedLog.handledStatus) ? (
                      <ConfirmButton
                        onConfirm={() => handleUpdateHandleStatus(selectedLog)}
                        loading={updateHandleStatusState.loading}
                        text="Mark as Unresolved"
                        loadingText="Unresolving..."
                        icon={faTimes}
                      />
                    ) : (
                      <ConfirmButton
                        onConfirm={() => handleUpdateHandleStatus(selectedLog)}
                        loading={updateHandleStatusState.loading}
                        text="Mark as Resolved"
                        loadingText="Resolving..."
                        icon={faCheck}
                      />
                    )}
                  </div>
                  <Tab
                    menu={{secondary: true, pointing: true}}
                    panes={[
                      {
                        menuItem: 'Details',
                        render: () => (
                          <Table basic celled compact selectable>
                            <Table.Body>
                              <Table.Row
                                onClick={() =>
                                  handleCopy(String(selectedLog.timestamp))
                                }
                              >
                                <Table.Cell>Timestamp</Table.Cell>
                                <Table.Cell>
                                  <DateFormat
                                    date={selectedLog?.timestamp}
                                    format="MM/DD/YYYY hh:mm:SS A"
                                  />
                                </Table.Cell>
                              </Table.Row>
                              <Table.Row
                                onClick={() => handleCopy(selectedLog.route)}
                              >
                                <Table.Cell>Route</Table.Cell>
                                <Table.Cell>{selectedLog.route}</Table.Cell>
                              </Table.Row>
                              <Table.Row
                                onClick={() => handleCopy(selectedLog.duration)}
                              >
                                <Table.Cell>Duration</Table.Cell>
                                <Table.Cell>{selectedLog.duration}</Table.Cell>
                              </Table.Row>
                              <Table.Row
                                onClick={() => handleCopy(selectedLog.userId)}
                              >
                                <Table.Cell>User ID</Table.Cell>
                                <Table.Cell>{selectedLog.userId}</Table.Cell>
                              </Table.Row>
                              <Table.Row
                                onClick={() =>
                                  handleCopy(selectedLog.ipAddress)
                                }
                              >
                                <Table.Cell>IP Address</Table.Cell>
                                <Table.Cell>{selectedLog.ipAddress}</Table.Cell>
                              </Table.Row>
                              <Table.Row
                                onClick={() => handleCopy(selectedLog.trace)}
                              >
                                <Table.Cell>Trace</Table.Cell>
                                <Table.Cell>{selectedLog.trace}</Table.Cell>
                              </Table.Row>
                            </Table.Body>
                          </Table>
                        ),
                      },
                      {
                        menuItem: 'Stack',
                        render: () => (
                          <Highlight className="accesslog">
                            {selectedLog.stack}
                          </Highlight>
                        ),
                      },
                    ]}
                  />
                </Segment>
              </Grid.Column>
            )}
            {list.length > 1 && (
              <Grid.Column width={4}>
                <Segment className="occurrence-list">
                  <List divided relaxed selection>
                    {list.map((x) => (
                      <List.Item
                        key={x.id}
                        onClick={() => {
                          setState((draft) => (draft.selectedLogId = x.id));
                        }}
                        active={state.selectedLogId === x.id}
                      >
                        <Grid>
                          <Grid.Column width={13}>
                            <List.Content>
                              <List.Header>
                                <DateFormat
                                  date={x.timestamp}
                                  format="MM/DD/YYYY hh:mm:SS A"
                                />
                              </List.Header>
                              <List.Description>{x.duration}</List.Description>
                              <List.Description
                                className="route"
                                title={x.route}
                              >
                                {x.route}
                              </List.Description>
                            </List.Content>
                          </Grid.Column>
                          <Grid.Column
                            width={3}
                            textAlign="center"
                            verticalAlign="middle"
                          >
                            <Tooltip label={x.handledStatus}>
                              <Chip
                                level={getStatusLevel(x.handledStatus)}
                                icon={
                                  isResolved(x.handledStatus)
                                    ? faCheck
                                    : faTimes
                                }
                                iconOnly
                                text=""
                              />
                            </Tooltip>
                          </Grid.Column>
                        </Grid>
                      </List.Item>
                    ))}
                  </List>
                  {list.length < logs.totalItemCount && (
                    <Flex.Row>
                      <Button
                        className="load-button"
                        loading={fetchLogs.loading}
                        onClick={() => {
                          setState((draft) => draft.currentPage++);
                          fetchLogs.retry();
                        }}
                      >
                        Load More
                      </Button>
                    </Flex.Row>
                  )}
                </Segment>
              </Grid.Column>
            )}
          </Grid>
        </>
      )}
    </BasicPage>
  );
};

const styles = css`
  .occurrence-list {
    max-height: 550px;
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;

    .ui.grid {
      margin: 0;
      .thirteen.wide.column {
        padding: 0;
      }
    }
  }
  .detail-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .hljs {
    height: 414px;
    font-size: 13px;
    overflow-x: scroll;

    ::-webkit-scrollbar-thumb {
      background: rgb(217 217 217 / 86%);
    }
  }
  .load-button {
    margin: 0 auto 10px auto;
  }
  .status {
    font-size: 65px !important;
    margin-bottom: 0;
  }
  .occurrences {
    margin-top: 0;
  }
  .modal-actions {
    text-align: right !important;
  }
  .route {
    ${ellipsis(300)}
  }
`;
