import css from '@emotion/css';
import React from 'react';
import {Flex} from '../components/flex';
import {MobileaseTheme} from '../styles/branding';

export const DashboardPanel: React.FC<{
  headerText: string;
  headerActionText?: string;
  headerAction?: () => void;
}> = ({headerText, headerActionText, headerAction, children}) => {
  return (
    <div css={style}>
      <Flex.Row
        className="dashboard-panel-header"
        justifyContent="space-between"
      >
        <div>{headerText ? headerText : ''}</div>
        <a onClick={headerAction} className="header-action-text">
          {headerActionText ? headerActionText : ''}
        </a>
      </Flex.Row>
      <Flex.Col fill justifyContent="space-between" className="height-100">
        {children}
      </Flex.Col>
    </div>
  );
};

const style = css`
  margin: 0.5rem;
  height: 100%;

  .height-100 {
    height: 100%;
  }

  .dashboard-panel-header {
    display: flex;
    font-size: 0.75rem;
    font-weight: bold;
    color: ${MobileaseTheme.colors.grey500};
    margin: 0 0.5rem 0.5rem 0.5rem;
    text-transform: uppercase;
    letter-spacing: 0.05rem;
  }

  .header-action-text {
    cursor: pointer;
    color: ${MobileaseTheme.colors.purple500};
  }
`;
