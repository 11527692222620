import {css} from '@emotion/core';
import React, {useEffect, useMemo, useState} from 'react';
import {startOfMonth, format} from 'date-fns';
import {useForm, useFormState} from 'react-final-form';
import {Input, Segment} from 'semantic-ui-react';
import {InvoicesService} from '../api/generated';
import {currencyFormatter, dateFormatter} from '../assets/asset-utils';
import {Flex} from '../components/flex';
import {useCustomerContext} from '../customer-context';
import {Form} from '../forms';
import {datepicker, fieldConfig, getDefaults} from '../forms/schema-utils';
import {BlueFilterFieldStyles} from '../forms/styles';
import {
  AdditionalParams,
  PagedDataTableConfig,
  PagedDataTableStyle,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {ViewInvoiceButton} from '../invoices/monthly-invoice-listing';
import {MobileaseTheme} from '../styles/branding';
import {ExportButton} from '../export/export';
import {ExportTypes} from '../api/generated/enums';
import {buildApiUrl} from '../api/generated/utils';
import {FormFiltersAppliedCount} from '../forms/form-filters-applied-count';

type FilterState = AdditionalParams<
  typeof InvoicesService.getInvoicesForCustomer
>;

type UserConfigurableFilters = Omit<FilterState, 'customerId'>;

export const InvoiceListing = () => {
  const [{currentCustomerId}] = useCustomerContext();
  const [filterState, setFilterState] = useProduce<UserConfigurableFilters>({});
  const [searchValue, setSearchValue] = useState<string>('');

  const pagedDataTableConfig = useMemo(() => {
    return PagedDataTableConfig(InvoicesService.getInvoicesForCustomer, {
      columns: [
        {
          header: 'Invoice #',
          column: 'invoiceNumber',
          sortable: 'invoiceNumber',
        },
        {
          header: 'Billing Cycle',
          render: (item) => (
            <p>{format(startOfMonth(new Date(item.dueDate)), 'LLLL yyyy')}</p>
          ),
          sortable: 'invoiceDate',
        },
        {
          header: 'Invoice Date',
          render: (item) => <p>{dateFormatter(item.invoiceDate)}</p>,
          sortable: 'invoiceDate',
        },
        {
          header: 'Due Date',
          render: (item) => <p>{dateFormatter(item.dueDate)}</p>,
          sortable: 'dueDate',
        },
        {
          header: 'Amount',
          render: (item) => <p>{currencyFormatter(item.amount)}</p>,
          sortable: 'amount',
        },
        {
          header: 'Type',
          render: (item) => (
            <p>{item.isSupplemental ? 'Supplemental' : 'Monthly'}</p>
          ),
          sortable: 'isSupplemental',
        },
        {
          header: '',
          cellProps: {textAlign: 'center'},
          render: (item) => <ViewInvoiceButton id={item.id} />,
        },
      ],
      searchFieldNames: ['invoiceNumber'],
      hideSearch: true,
      defaultSort: {column: 'invoiceDate', direction: 'DESC'},
    });
  }, []);

  const exportUrl = useMemo(
    () =>
      buildApiUrl<FilterState>('/api/invoices/export', {
        filterParams: {
          ...filterState,
          customerId: currentCustomerId,
        },
      }),
    [currentCustomerId, filterState]
  );

  const dataTable = usePagedDataTable(
    InvoicesService.getInvoicesForCustomer,
    pagedDataTableConfig,
    {
      additionalParams: {
        customerId: currentCustomerId,
        ...filterState,
        searchSearchText: searchValue,
      },
      actions: (
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <ExportButton
            exportType={ExportTypes.Invoices}
            title="Export Invoice Report"
            exportUrl={exportUrl}
          />
        </div>
      ),
    }
  );
  return (
    <div css={style}>
      <Flex.Row className="listing-wrapper">
        <Flex.Col flex={1} className="filter-wrapper">
          <TableFilters
            onSearchChanged={(value) => setSearchValue(value)}
            initialValues={filterState}
            onApply={(values) => {
              setFilterState((draft) => {
                draft.dueDateStart = values.dueDateStart;
                draft.dueDateEnd = values.dueDateEnd;
                draft.invoiceDateStart = values.invoiceDateStart;
                draft.invoiceDateEnd = values.invoiceDateEnd;
              });
            }}
          />
        </Flex.Col>
        <Flex.Col flex={4} css={PagedDataTableStyle}>
          <div className="table-wrapper">{dataTable}</div>
        </Flex.Col>
      </Flex.Row>
    </div>
  );
};

const fields = fieldConfig<Required<UserConfigurableFilters>>({
  invoiceDateStart: datepicker({
    fieldLabel: 'Invoice Date Start',
    inputProps: {showClearDate: true, placeholder: ''},
  }),
  invoiceDateEnd: datepicker({
    fieldLabel: 'Invoice Date End',
    inputProps: {showClearDate: true, placeholder: ''},
  }),
  dueDateStart: datepicker({
    fieldLabel: 'Due Date Start',
    inputProps: {showClearDate: true, placeholder: ''},
  }),
  dueDateEnd: datepicker({
    fieldLabel: 'Due Date End',
    inputProps: {showClearDate: true, placeholder: ''},
  }),
});

const TableFilters: React.FC<{
  onApply: (values: UserConfigurableFilters) => void;
  initialValues: any;
  onSearchChanged: (value) => void;
}> = ({onApply, initialValues, onSearchChanged}) => {
  return (
    <>
      <Segment className="search-container">
        <Input
          onChange={(e, data) => {
            onSearchChanged(data.value);
          }}
          iconPosition="left"
          icon="search"
          placeholder="Search Invoices"
        />
      </Segment>
      <Segment className="filter-list">
        <Form.Container basic>
          <Form
            initialValues={initialValues || getDefaults(fields)}
            disableLoading
            onSubmit={(values) => {
              onApply(values);
            }}
            render={() => (
              <div css={BlueFilterFieldStyles}>
                <FormFiltersAppliedCount />
                <FormFields />
              </div>
            )}
          />
        </Form.Container>
      </Segment>
    </>
  );
};

const FormFields: React.FC<{}> = () => {
  const {submit} = useForm();
  const {values} = useFormState();

  useEffect(() => {
    submit();
  }, [values, submit]);
  return (
    <>
      <Form.DatePicker fieldConfig={fields.invoiceDateStart}></Form.DatePicker>
      <Form.DatePicker fieldConfig={fields.invoiceDateEnd}></Form.DatePicker>
      <Form.DatePicker fieldConfig={fields.dueDateStart}></Form.DatePicker>
      <Form.DatePicker fieldConfig={fields.dueDateEnd}></Form.DatePicker>
    </>
  );
};

const style = css`
  .listing-wrapper {
    padding: 1rem;
  }

  .filter-wrapper {
    padding: 0rem 1rem;
    .filter-list {
      padding: 0;
    }
  }

  .table-wrapper {
    padding: 0rem 1rem;
  }

  .search-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.input {
      width: 100%;
    }

    .ui.input > input {
      background: ${MobileaseTheme.colors.blue50};
      border: none;
      width: 100%;

      ::placeholder {
        color: ${MobileaseTheme.colors.grey500} !important;
      }
    }

    i.icon {
      color: ${MobileaseTheme.colors.blue500};
    }
  }
`;
