import React from 'react';
import {RequestTemplateService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {DateTimeFormat} from '../components/date';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {routes} from '../routes';

export const RequestTemplateListing = () => {
  const dataTable = usePagedDataTable(
    RequestTemplateService.getAll,
    TableConfig
  );

  return (
    <>
      <BasicPage title="Request Templates">{dataTable}</BasicPage>
    </>
  );
};

const TableConfig = PagedDataTableConfig(RequestTemplateService.getAll, {
  columns: [
    {
      header: '',
      render: item =>
        renderEditButton({
          item,
          descriptor: 'request template',
          route: routes.requestTemplates.detail,
        }),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Name',
      column: 'name',
      sortable: 'name',
    },
    {
      header: 'Last Updated On',
      render: item => (
        <>
          <DateTimeFormat datetime={item.modifiedDate} />
        </>
      ),
    },
    {
      header: 'Last Updated By',
      column: 'modifiedByUserName',
    },
  ],
  defaultSort: {
    column: 'name',
    direction: 'ASC',
  },
  searchFieldNames: ['name'],
});
