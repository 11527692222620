import css from '@emotion/css';
import React from 'react';
import {Header, Icon, Segment} from 'semantic-ui-react';
import {MobileaseTheme} from '../styles/branding';

export const DashboardPlaceholder: React.FC<{
  text: string;
}> = ({text}) => {
  return (
    <div css={styles}>
      <Segment placeholder>
        <Header icon>
          <Icon name="search" size="big" />
          {text}
        </Header>
      </Segment>
    </div>
  );
};

const styles = css`
  .search.big.icon {
    font-size: 2.5em;
  }

  .ui.placeholder.segment {
    background: ${MobileaseTheme.colors.grey50};
  }
`;
