import css from '@emotion/css';
import React from 'react';
import {Progress} from 'semantic-ui-react';
import {LeaseCompleteLeaseStatuses} from '../../api/generated/enums';
import {Flex} from '../../components/flex';
import {MobileaseTheme} from '../../styles/branding';
import {getAllFormattedDates, percentageDateCalculator} from '../asset-utils';

export const ContractProgress: React.FC<{
  startDate: Date;
  endDate: Date;
  status: string;
}> = ({startDate, endDate, status}) => {
  const {formattedStartDate, today, formattedEndDate} = getAllFormattedDates(
    startDate,
    endDate
  );

  const percentage = percentageDateCalculator(startDate, endDate);

  const isLeased = status === LeaseCompleteLeaseStatuses['Active'];

  return (
    <>
      {isLeased ? (
        <div css={progressStyles}>
          <Flex.Row justifyContent="space-between" className="dates-container">
            <DateLabel formattedDate={formattedStartDate} text="Contract" />
            <DateLabel formattedDate={today} text="Today" className="center" />
            <DateLabel
              formattedDate={formattedEndDate}
              text="Term"
              className="end"
            />
          </Flex.Row>
          <Progress percent={percentage} size="small" active />
        </div>
      ) : null}
    </>
  );
};

const DateLabel: React.FC<{
  formattedDate: string;
  text: string;
  className?: string;
}> = ({formattedDate, text, className}) => (
  <Flex.Col className={className}>
    <Flex.Row className="bold">{formattedDate}</Flex.Row>
    <Flex.Row>{text}</Flex.Row>
  </Flex.Col>
);

const progressStyles = css`
  .bold {
    font-weight: 600;
  }

  .ui.progress {
    background: ${MobileaseTheme.colors.purple50};
    border-radius: 3.6rem;
  }

  .ui.progress .bar {
    background: ${MobileaseTheme.colors.purple500};
    border-radius: 3.6rem;
  }

  .dates-container {
    display: flex;
  }

  .date {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .center {
    align-items: center;
  }

  .end {
    align-items: flex-end;
  }
`;
