import React from 'react';
import css from '@emotion/css/macro';
import {Env} from '../config/env-vars';

export const Json = ({data}) => {
  if (Env.isProductionBuild) {
    return null;
  }

  return (
    <pre css={styles} className="debug-container">
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
};

const styles = css`
  background-color: #eaeaea;
  padding: 1rem;
  border-radius: 0.5rem;
  border: solid 1px #757575;
  max-height: 400px;
  overflow-y: auto;
`;
