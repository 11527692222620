// Enums

export type ClientIds = keyof typeof ClientIds;
export const ClientIds = {web: 'web'} as const;

export type CustomClaims = keyof typeof CustomClaims;
export const CustomClaims = {permissions: 'permissions', role: 'role'} as const;

export type CurrencyCodes = keyof typeof CurrencyCodes;
export const CurrencyCodes = {USD: 'USD'} as const;

export type LogHandleStatuses = keyof typeof LogHandleStatuses;
export const LogHandleStatuses = {
  Resolved: 'Resolved',
  Unresolved: 'Unresolved',
} as const;

export type Timezones = keyof typeof Timezones;
export const Timezones = {
  'US/Pacific': 'US/Pacific',
  'US/Mountain': 'US/Mountain',
  'US/Central': 'US/Central',
  'US/Eastern': 'US/Eastern',
} as const;

export type UriProtocols = keyof typeof UriProtocols;
export const UriProtocols = {
  'http://': 'http://',
  'https://': 'https://',
} as const;

export type RequestTemplateKeys = keyof typeof RequestTemplateKeys;
export const RequestTemplateKeys = {
  NewOrder: 'NewOrder',
  ContactCsr: 'ContactCsr',
  ChangeGarageAddress: 'ChangeGarageAddress',
  RequestExtension: 'RequestExtension',
  OrderSimilar: 'OrderSimilar',
  PayoffInfo: 'PayoffInfo',
  EnrollInMaintenance: 'EnrollInMaintenance',
  SendInformation: 'SendInformation',
} as const;

export type RequestTemplateTokenCharacters =
  keyof typeof RequestTemplateTokenCharacters;
export const RequestTemplateTokenCharacters = {'[$': '[$', '$]': '$]'} as const;

export type RequestTemplateTokens = keyof typeof RequestTemplateTokens;
export const RequestTemplateTokens = {
  UserName: 'UserName',
  UserEmail: 'UserEmail',
  CustomerName: 'CustomerName',
  AssetDescription: 'AssetDescription',
  AssetId: 'AssetId',
  AssetVIN: 'AssetVIN',
  AssetGarageAddress: 'AssetGarageAddress',
} as const;

export type RequestTemplateConstants = keyof typeof RequestTemplateConstants;
export const RequestTemplateConstants = {'[Portal]': '[Portal]'} as const;

export type ExportTypes = keyof typeof ExportTypes;
export const ExportTypes = {
  Asset: 'Asset',
  Charges: 'Charges',
  Invoices: 'Invoices',
  AssetRegistration: 'AssetRegistration',
} as const;

export type LeaseCompleteCustomerLinkStatuses =
  keyof typeof LeaseCompleteCustomerLinkStatuses;
export const LeaseCompleteCustomerLinkStatuses = {
  Unlinked: 'Unlinked',
  Linked: 'Linked',
} as const;

export type LeaseCompleteLeaseStatuses =
  keyof typeof LeaseCompleteLeaseStatuses;
export const LeaseCompleteLeaseStatuses = {
  Active: 'Active',
  Closed: 'Closed',
  'Pending Booking': 'Pending Booking',
  Terminated: 'Terminated',
} as const;

export type TransactionErrorParams = keyof typeof TransactionErrorParams;
export const TransactionErrorParams = {
  PaymentReferenceNumber: 'PaymentReferenceNumber',
} as const;

export type AssetStatuses = keyof typeof AssetStatuses;
export const AssetStatuses = {
  'On Consignment': 'On Consignment',
  'Maintenance Only': 'Maintenance Only',
  'Courtesy Titling Only': 'Courtesy Titling Only',
  Sold: 'Sold',
  Leased: 'Leased',
} as const;

export type PmPlanTypes = keyof typeof PmPlanTypes;
export const PmPlanTypes = {Deposit: 'Deposit', PassThru: 'PassThru'} as const;

export type BillTypes = keyof typeof BillTypes;
export const BillTypes = {
  'Taxes and Registration': 'Taxes and Registration',
  'Monthly Payment': 'Monthly Payment',
  Maintenance: 'Maintenance',
  Telematics: 'Telematics',
  Insurance: 'Insurance',
  Other: 'Other',
} as const;

export type FormErrorMessage = keyof typeof FormErrorMessage;
export const FormErrorMessage = {ErrorFieldName: 'ErrorFieldName'} as const;

export type AssetFilters = keyof typeof AssetFilters;
export const AssetFilters = {Expiring: 'Expiring', Expired: 'Expired'} as const;

export type AssetMaintenanceFilters = keyof typeof AssetMaintenanceFilters;
export const AssetMaintenanceFilters = {
  'Under Maintenance': 'Under Maintenance',
  'Not Under Maintenance': 'Not Under Maintenance',
} as const;

export type AssetTypes = keyof typeof AssetTypes;
export const AssetTypes = {
  Equipment: 'Equipment',
  Trailer: 'Trailer',
  Truck: 'Truck',
  Vehicle: 'Vehicle',
  Van: 'Van',
  SUV: 'SUV',
  Passenger: 'Passenger',
  Other: 'Other',
} as const;

export type AssetRegistrationStatus = keyof typeof AssetRegistrationStatus;
export const AssetRegistrationStatus = {
  Complete: 'Complete',
  'In Process': 'In Process',
  'Under Review': 'Under Review',
} as const;

export type RegistrationRequiredMessages =
  keyof typeof RegistrationRequiredMessages;
export const RegistrationRequiredMessages = {
  'The current mileage on your vehicle is needed to renew your registration.':
    'The current mileage on your vehicle is needed to renew your registration.',
  'A current passing Emissions Inspection is needed to renew your registration':
    'A current passing Emissions Inspection is needed to renew your registration',
  'A current passing Smog Inspection is needed to renew your registration.':
    'A current passing Smog Inspection is needed to renew your registration.',
  'A current passing Safety Inspection is needed to renew your registration.':
    'A current passing Safety Inspection is needed to renew your registration.',
  'A current VIN Verification/Certification is needed to renew your registration.':
    'A current VIN Verification/Certification is needed to renew your registration.',
  'A current Certified Weight Ticket is needed to renew your registration.':
    'A current Certified Weight Ticket is needed to renew your registration.',
} as const;

export type SignalRTopics = keyof typeof SignalRTopics;
export const SignalRTopics = {TestSignal: 'TestSignal'} as const;

export type SignalRConfig = keyof typeof SignalRConfig;
export const SignalRConfig = {'/signalr-hub': '/signalr-hub'} as const;

export type MobileasePermissions = keyof typeof MobileasePermissions;
export const MobileasePermissions = {
  'Customers: View & Manage': 'Customers: View & Manage',
  'Invoices: View & Manage': 'Invoices: View & Manage',
  'Request Templates: View & Manage': 'Request Templates: View & Manage',
  'Quotes: Administrator': 'Quotes: Administrator',
  'Quotes: User': 'Quotes: User',
  'Settings: View & Manage': 'Settings: View & Manage',
} as const;

export type UserPermissions = keyof typeof UserPermissions;
export const UserPermissions = {
  'Users: View & Manage': 'Users: View & Manage',
  'Assets: View': 'Assets: View',
  'Assets: Manage': 'Assets: Manage',
  'Financial: View': 'Financial: View',
} as const;

export type Roles = keyof typeof Roles;
export const Roles = {
  'Mobilease Admin': 'Mobilease Admin',
  'Mobilease Employee': 'Mobilease Employee',
  'Mobilease Quotes User': 'Mobilease Quotes User',
  'Customer Admin': 'Customer Admin',
  'Customer User': 'Customer User',
} as const;

export type ElevatedRoles = keyof typeof ElevatedRoles;
export const ElevatedRoles = {'System User': 'System User'} as const;

export type QuoteAssetStatuses = keyof typeof QuoteAssetStatuses;
export const QuoteAssetStatuses = {
  'Awaiting Customer': 'Awaiting Customer',
  'Awaiting Dealership': 'Awaiting Dealership',
  Located: 'Located',
} as const;

export type ManagerApprovalStatuses = keyof typeof ManagerApprovalStatuses;
export const ManagerApprovalStatuses = {
  'Manager Approved': 'Manager Approved',
  'Awaiting Manager Approval': 'Awaiting Manager Approval',
} as const;

export type QuoteStages = keyof typeof QuoteStages;
export const QuoteStages = {
  Details: 'Details',
  LocateAsset: 'LocateAsset',
  ConfigureTerms: 'ConfigureTerms',
  InReview: 'InReview',
  Completed: 'Completed',
} as const;

export type ConfigureTermsQuoteStatuses =
  keyof typeof ConfigureTermsQuoteStatuses;
export const ConfigureTermsQuoteStatuses = {
  'In Progress': 'In Progress',
  Consultation: 'Consultation',
} as const;

export type CustomerApprovalQuoteStatuses =
  keyof typeof CustomerApprovalQuoteStatuses;
export const CustomerApprovalQuoteStatuses = {
  'Awaiting Customer': 'Awaiting Customer',
} as const;

export type CompletedQuoteStatuses = keyof typeof CompletedQuoteStatuses;
export const CompletedQuoteStatuses = {
  'Quote Approved': 'Quote Approved',
  'Sent to Lease Complete': 'Sent to Lease Complete',
} as const;

export type UseTypes = keyof typeof UseTypes;
export const UseTypes = {New: 'New', Used: 'Used'} as const;

export type DriveTypes = keyof typeof DriveTypes;
export const DriveTypes = {'2wd': '2wd', '4wd': '4wd'} as const;

export type BedTypes = keyof typeof BedTypes;
export const BedTypes = {
  'Short Bed': 'Short Bed',
  'Long Bed': 'Long Bed',
} as const;

export type GasTypes = keyof typeof GasTypes;
export const GasTypes = {Gas: 'Gas', Diesel: 'Diesel'} as const;

export type TermsSalesTaxTypes = keyof typeof TermsSalesTaxTypes;
export const TermsSalesTaxTypes = {
  'Rolled In': 'Rolled In',
  Upfront: 'Upfront',
  Monthly: 'Monthly',
} as const;

export type TermsRegistrationTypes = keyof typeof TermsRegistrationTypes;
export const TermsRegistrationTypes = {
  'Rolled In': 'Rolled In',
  Upfront: 'Upfront',
} as const;

export type TermsPercentageOrDollar = keyof typeof TermsPercentageOrDollar;
export const TermsPercentageOrDollar = {
  Percentage: 'Percentage',
  'Dollar Amount': 'Dollar Amount',
} as const;

export type QuoteContractTypes = keyof typeof QuoteContractTypes;
export const QuoteContractTypes = {
  Open: 'Open',
  Closed: 'Closed',
  Equipment: 'Equipment',
} as const;

export type SalesTaxTypes = keyof typeof SalesTaxTypes;
export const SalesTaxTypes = {
  STATE: 'STATE',
  CITY: 'CITY',
  COUNTY: 'COUNTY',
} as const;

export type InvoiceTypes = keyof typeof InvoiceTypes;
export const InvoiceTypes = {
  Monthly: 'Monthly',
  Supplemental: 'Supplemental',
} as const;

export type InvoiceGenerationRecordStatus =
  keyof typeof InvoiceGenerationRecordStatus;
export const InvoiceGenerationRecordStatus = {
  Generated: 'Generated',
  Failed: 'Failed',
} as const;

// Statics

export const RoleTemplates = {
  'Mobilease Admin': [
    'Customers: View & Manage',
    'Invoices: View & Manage',
    'Request Templates: View & Manage',
    'Quotes: Administrator',
    'Quotes: User',
    'Settings: View & Manage',
    'Users: View & Manage',
    'Assets: View',
    'Assets: Manage',
    'Financial: View',
  ],
  'Mobilease Employee': [
    'Users: View & Manage',
    'Assets: View',
    'Assets: Manage',
    'Financial: View',
  ],
  'Mobilease Quotes User': [
    'Users: View & Manage',
    'Assets: View',
    'Assets: Manage',
    'Financial: View',
    'Quotes: User',
  ],
  'Customer Admin': [
    'Users: View & Manage',
    'Assets: View',
    'Assets: Manage',
    'Financial: View',
  ],
  'Customer User': ['Assets: View'],
} as const;

export const AllPermissions = [
  'Customers: View & Manage',
  'Invoices: View & Manage',
  'Request Templates: View & Manage',
  'Quotes: Administrator',
  'Quotes: User',
  'Settings: View & Manage',
  'Users: View & Manage',
  'Assets: View',
  'Assets: Manage',
  'Financial: View',
] as const;
