import {css} from '@emotion/core';
import React, {useEffect, useMemo, useRef} from 'react';
import {useField} from 'react-final-form';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {FormField, Header, Label} from 'semantic-ui-react';
import {RequestTemplateTokenCharacters} from '../api/generated/enums';
import {FieldConfig} from '../forms/schema-utils';
import {SubmitError} from '../forms/submit-error';
import {themeColors} from '../styles';

type HtmlInput = {
  fieldConfig: FieldConfig;
  defaultValue?: string;
  readOnly?: boolean;
  onChange: any;
  tokenOptions?: string[];
};

export const HtmlTextInput: React.FC<HtmlInput> = ({
  fieldConfig,
  defaultValue,
  readOnly = false,
  onChange,
  tokenOptions,
}) => {
  const quillRef = useRef<ReactQuill>(null);
  const {input, meta} = useField(fieldConfig.fieldName);
  const {value} = input;
  const toolbarVisibility = useMemo(
    () => ({
      toolbar: !readOnly,
    }),
    [readOnly]
  );

  const insertToken = (
    content: string | undefined,
    quillRef: React.MutableRefObject<ReactQuill | null>
  ) => {
    if (content && quillRef && quillRef.current) {
      const editor = quillRef!.current.getEditor();
      const caretPosition = editor.getSelection(true);

      editor.insertText(
        caretPosition?.index ?? 0,
        `${RequestTemplateTokenCharacters['[$']}${content}${RequestTemplateTokenCharacters['$]']}`
      );
    }
  };

  useEffect(() => {
    const toolbar = quillRef?.current?.getEditor()?.getModule('toolbar');
    if (toolbar && !readOnly) {
      toolbar.addHandler('insertToken', insertToken);
    }
  }, [quillRef, readOnly]);

  return (
    <div className="form-field" css={styles}>
      <FormField error={!!meta.submitError}>
        {fieldConfig.fieldLabel && (
          <span className="css-1oiibla-indicatorStyles">
            <label className="field-label" htmlFor={input.name}>
              {fieldConfig.fieldLabel}
            </label>
          </span>
        )}

        <ReactQuill
          readOnly={readOnly}
          defaultValue={defaultValue}
          className={`html-editor`}
          value={value}
          modules={toolbarVisibility}
          onChange={onChange}
          ref={quillRef}
        />
        {tokenOptions && !readOnly && (
          <div className="token-dropdown-container">
            <Header as="h4">Insert Token: </Header>
            <div className="tokens">
              {tokenOptions?.map((token) => {
                var tokenLabel = token
                  .replaceAll('{', '')
                  .replaceAll('}', '')
                  .split('_')
                  .map((x) => x[0].toUpperCase() + x.substring(1))
                  .join(' ');
                return (
                  <>
                    <Label
                      css={css``}
                      basic
                      onClick={() => insertToken(token, quillRef)}
                    >
                      {tokenLabel}
                    </Label>
                  </>
                );
              })}
            </div>
          </div>
        )}
      </FormField>
      <SubmitError name={input.name} />
    </div>
  );
};

const styles = css`
  .html-editor {
    margin-top: 4px;
    min-height: 10rem;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .ql-toolbar {
      height: 42.125px;
    }

    .ql-container {
      height: 100%;
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    select {
      padding: 0.2rem 1.5rem 0.2rem 0.4rem;
      border-radius: 0;
      box-shadow: none;
      background: inherit;
    }
  }
  .public-DraftEditor-content {
    min-height: 100px;
  }

  .field-label {
    color: ${themeColors.textLabel};
    font-weight: bold;
    font-size: 0.93333333em;
  }

  .token-dropdown-container {
    display: flex;
    align-items: center;
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: 1px solid #cccccc;
    border-top: none;

    .header {
      margin-bottom: 0;
      margin-right: 0.5rem;
      text-align: center;
    }

    .tokens {
      display: flex;
      flex-wrap: wrap;

      .label {
        margin-top: 5px;
      }
    }

    div {
      cursor: pointer;
    }
  }
`;
