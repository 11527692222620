import React, {useContext} from 'react';
import {AssetDto} from '../api/generated';

export type AssetContextState = {
  asset?: AssetDto;
};

const AssetContext = React.createContext<AssetContextState>({
  asset: undefined,
});

export const AssetContextProvider: React.FC<{
  children: React.ReactElement;
  value: AssetContextState;
}> = ({children, value}) => {
  return (
    <>
      <AssetContext.Provider value={value}>{children}</AssetContext.Provider>
    </>
  );
};

export const useAssetContext = () => useContext(AssetContext);
