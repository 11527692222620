import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {
  RoutePatternCreate,
  RoutePatternUpdate,
} from './route-pattern-create-update';
import {RoutePatternListing} from './route-pattern-listing';

export const RoutePatternRoutes = () => {
  return (
    <Switch>
      <Route path={routes.admin.routePatterns.index} exact>
        <RoutePatternListing />
      </Route>
      <Route path={routes.admin.routePatterns.create} exact>
        <RoutePatternCreate />
      </Route>
      <Route path={routes.admin.routePatterns.detail}>
        <RoutePatternUpdate />
      </Route>
    </Switch>
  );
};
