import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {QuoteCreate, QuoteDetails} from './quote-create-update';
import {QuoteDashboard} from './quote-dashboard';

export const QuoteRoutes = () => {
  return (
    <Switch>
      <Route path={routes.quotes.index} exact>
        <QuoteDashboard />
      </Route>
      <Route path={routes.quotes.create} exact>
        <QuoteCreate />
      </Route>
      <Route path={routes.quotes.detail}>
        <QuoteDetails />
      </Route>
    </Switch>
  );
};
