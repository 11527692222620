import React, {useMemo} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {
  CustDetailDto,
  CustomerSettingsDto,
  CustomerSettingsService,
  LeaseCompleteCustomerService,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {AsyncStateContainer} from '../components/async-state-container';
import {Form} from '../forms';
import {
  checkbox,
  fieldConfig,
  input,
  RawFieldConfig,
} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

const customerFields = fieldConfig<CustDetailDto>({
  id: input({
    fieldLabel: 'Customer #',
    inputProps: {
      readOnly: true,
    },
  }),
  name: input({
    fieldLabel: 'Business Name',
    inputProps: {
      readOnly: true,
    },
  }),
  type: input({
    fieldLabel: 'Business Type',
    inputProps: {
      readOnly: true,
    },
  }),
  officerName: input({
    fieldLabel: 'Account Executive',
    inputProps: {
      readOnly: true,
    },
  }),
  relationOfficerName: input({
    fieldLabel: 'Customer Service Representative',
    inputProps: {
      readOnly: true,
    },
  }),
});
type CustomerSettingsOmitKey = 'id' | 'customerId';

type FieldConfigDto = Omit<CustomerSettingsDto, CustomerSettingsOmitKey>;

const useFields = () => {
  const fetchOptions = useAsync(async () => {
    const settingFields = fieldConfig<FieldConfigDto>({
      displayUnitNumber: checkbox({
        fieldLabel: 'Display Unit #',
        inputProps: {
          toggle: true,
        },
      }),
      displayDriver: checkbox({
        fieldLabel: 'Display Driver',
        inputProps: {
          toggle: true,
        },
      }),
      displayBranchDivision: checkbox({
        fieldLabel: 'Display Branch/Division',
        inputProps: {
          toggle: true,
        },
      }),
      geotabId: input({
        fieldLabel: 'Geotab Database ID',
      }),
    });
    return settingFields;
  });
  return fetchOptions;
};
export const CustomerDetails = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = String(match.params.id);
  const notifications = useNotification();
  const fetchSettingsFields = useFields();

  const fetchCustomer = useAsync(async () => {
    const customer = await LeaseCompleteCustomerService.getById({
      id,
    });
    return customer;
  }, [id]);

  const fetchCustomerSettings = useAsync(async () => {
    const customerSettings = await CustomerSettingsService.getForCustomer({
      customerId: id,
    });

    return customerSettings;
  }, [id]);

  const customer = fetchCustomer.value?.data || undefined;
  const customerName = customer?.name ?? '';

  const customerSettings = fetchCustomerSettings.value?.data || undefined;

  const breadcrumbs = useMemo(
    () => [
      {title: 'Customers', url: routes.customers.index},
      {title: customerName},
    ],
    [customerName]
  );

  const onSubmit = async (values) => {
    const response = await CustomerSettingsService.update({id, body: values});
    if (response.hasErrors) {
      return response;
    }
    notifications.success('Customer Updated');
    history.push(routes.customers.index);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <AsyncStateContainer {...fetchCustomer}>
          <Form
            initialValues={customer}
            onSubmit={() => {}}
            render={() => <CustomerFormFields />}
          />
        </AsyncStateContainer>
        <Divider hidden={true} />
        <AsyncStateContainer {...fetchCustomerSettings}>
          <Form
            initialValues={customerSettings}
            onSubmit={onSubmit}
            render={() => (
              <>
                <AsyncStateContainer {...fetchSettingsFields}>
                  {fetchSettingsFields.value && (
                    <SettingFormFields
                      settingFields={fetchSettingsFields.value}
                    />
                  )}
                </AsyncStateContainer>
                <Divider />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Update Customer
                  </Form.Button>
                  <Form.Button secondary as={Link} to={routes.customers.index}>
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </AsyncStateContainer>
      </Form.Container>
    </BasicPage>
  );
};

const CustomerFormFields = () => {
  return (
    <>
      <Form.Section title="Customer Details">
        <Form.Row proportions={[1, 3]}>
          <Form.Input fieldConfig={customerFields.id} />
        </Form.Row>
        <Form.Row proportions={[1, 1]}>
          <Form.Input fieldConfig={customerFields.name} />
          <Form.Input fieldConfig={customerFields.type} />
        </Form.Row>
        <Form.Row>
          <Form.Input fieldConfig={customerFields.officerName} />
          <Form.Input fieldConfig={customerFields.relationOfficerName} />
        </Form.Row>
      </Form.Section>
    </>
  );
};

const SettingFormFields: React.FC<{
  settingFields: RawFieldConfig<FieldConfigDto>;
}> = ({settingFields: settingsFields}) => {
  return (
    <>
      <Form.Section title="Customer Settings">
        <Form.Row proportions={[1, 1, 1]}>
          <Form.Input fieldConfig={settingsFields.geotabId} />
        </Form.Row>
        <Form.Row>
          <Form.Checkbox fieldConfig={settingsFields.displayUnitNumber} />
        </Form.Row>
        <Form.Row>
          <Form.Checkbox fieldConfig={settingsFields.displayDriver} />
        </Form.Row>
        <Form.Row>
          <Form.Checkbox fieldConfig={settingsFields.displayBranchDivision} />
        </Form.Row>
      </Form.Section>
    </>
  );
};
