import {css} from '@emotion/core';
import React from 'react';
import {useAsyncFn} from 'react-use';
import {Button, Divider, Segment} from 'semantic-ui-react';
import {AssetDto, LeaseCompleteAssetsService} from '../../api/generated';
import {Flex} from '../../components/flex';
import {differenceInCalendarMonths, isFuture} from 'date-fns';
import {
  AssetDetailRow,
  NO_VALUE_PLACEHOLDER,
} from '../../dashboard/dashboard-components';
import {LeaseLabel} from '../asset-labels';
import {currencyFormatter, dateFormatter} from '../asset-utils';

export const AssetContractDetailCard: React.FC<{
  asset: AssetDto;
}> = ({asset}) => {
  const [, downloadContract] = useAsyncFn(async () => {
    const response =
      await LeaseCompleteAssetsService.getAssetExecutedContractLink({
        id: asset.assetId,
      });
    window.open(response?.data?.toString(), '_blank');
  }, [asset.assetId]);

  return (
    <Segment css={styles}>
      <Flex.Col>
        <AssetDetailRow
          title="Contract Date"
          value={
            asset.assetLease?.leaseFirstPmtDate
              ? dateFormatter(asset.assetLease.leaseFirstPmtDate)
              : NO_VALUE_PLACEHOLDER
          }
        />
        <AssetDetailRow
          title={asset.terminationDate ? 'Term Date' : 'Scheduled Term Date'}
          value={
            <LeaseLabel
              terminationDate={
                asset.terminationDate ?? asset.scheduledTerminationDate
              }
              isLeaseExpiring={asset.isLeaseExpiring}
              isLeaseExpired={asset.isLeaseExpired}
            />
          }
        />
        {asset.assetLease && (
          <AssetDetailRow
            title="Months in Service"
            value={asset.monthsInService}
          />
        )}
        {asset.monthsRemainingInLease > 0 && (
          <AssetDetailRow
            title="Months Remaining"
            value={asset.monthsRemainingInLease}
          />
        )}
        <AssetDetailRow
          title="Payment Amount"
          value={
            asset.assetLease?.leaseBasePmtAmt
              ? currencyFormatter(asset.assetLease?.leaseBasePmtAmt)
              : NO_VALUE_PLACEHOLDER
          }
        />
        <AssetDetailRow
          title="Residual Value"
          value={
            asset.assetLease?.leaseResidualAmt
              ? currencyFormatter(asset.assetLease?.leaseResidualAmt)
              : NO_VALUE_PLACEHOLDER
          }
        />
        {asset.hasExecutedContract && (
          <>
            <Divider />
            <AssetDetailRow
              title="Executed Contract"
              value={
                <Button
                  content="Download"
                  size="tiny"
                  onClick={downloadContract}
                />
              }
            />
          </>
        )}
      </Flex.Col>
    </Segment>
  );
};

const styles = css`
  height: 100%;

  .width-100 {
    width: 100%;
  }
`;
