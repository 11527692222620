import {css} from '@emotion/core';
import {
  endOfDay,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
} from 'date-fns';
import * as Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {DateRangePicker} from 'rsuite';
import {ValueType} from 'rsuite/esm/DateRangePicker/types';
import Currency from '../../components/currency';
import {useCustomerContext} from '../../customer-context';
import {routes} from '../../routes';
import {formatCurrencyWithSymbols} from '../../utils/currency-helpers';
import {
  containerProps,
  dataLabelStyles,
  legendItemStyles,
} from './chart-styles';
import {DashboardService, FleetExpensesDto} from '../../api/generated';
import {useAsync} from 'react-use';
import {BillTypes} from '../../api/generated/enums';
import {MobileaseTheme} from '../../styles/branding';
import _ from 'lodash';
import {ChargesListingQueryParams} from '../../financial/charges-listing';
import {Divider, Segment} from 'semantic-ui-react';
import {Flex} from '../../components/flex';
import {useRequireAuthorization} from '../../auth/require-authorization';

const getExpenseTotal = (expensesData: FleetExpensesDto): number =>
  _.sum(expensesData.expenses.map((e) => e.amount));

const getOptions = (
  expensesData: FleetExpensesDto,
  onClick: (params: ChargesListingQueryParams) => void,
  dateRange: ValueType
): Highcharts.Options => {
  const data: Highcharts.PointOptionsObject[] = Object.values(BillTypes).map(
    (billType) => {
      return {
        name: billType,
        y:
          expensesData?.expenses?.find((e) => e.type === billType)?.amount ?? 0,
        color: billTypeColorsMap[billType],
        custom: {
          billType,
        },
      };
    }
  );

  return {
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    chart: {
      backgroundColor: 'transparent',
      type: 'pie',
      spacingLeft: 0,
      spacingTop: 0,
    },
    series: [
      {
        type: 'pie',
        data: [...data],
        size: '100%',
        innerSize: '60%',
        showInLegend: true,
        dataLabels: {
          ...dataLabelStyles,
          distance: -15,
          alignTo: 'connectors',
          formatter: function (this: any) {
            return this.y ? formatCurrencyWithSymbols(this.y) : null;
          },
        },
        tooltip: {
          pointFormatter: function (this: any) {
            return `<span style="color:${
              this.color
            }">\u25CF</span> <b>${formatCurrencyWithSymbols(this.y)}</b>`;
          },
        },
      },
    ],
    xAxis: {
      categories: [''],
    },
    legend: {
      itemStyle: legendItemStyles,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'top',
      itemMarginTop: 5,
      itemMarginBottom: 5,
      className: 'expenses-legend',
    },
    plotOptions: {
      pie: {
        center: ['50%', '50%'],
      },
      series: {
        cursor: 'pointer',
        point: {
          events: {
            click: function () {
              onClick({
                billType: this.options.custom?.billType,
                dueDateStart: dateRange[0] as Date,
                dueDateEnd: dateRange[1] as Date,
              });
            },
          },
        },
      },
    },
  };
};

export const FleetExpensesChart = () => {
  const [{currentCustomerId}] = useCustomerContext();
  const canViewFinancials = useRequireAuthorization({
    permission: 'Financial: View',
  });

  const history = useHistory();
  const defaultDateRange: ValueType = [
    startOfMonth(new Date()),
    endOfDay(new Date()),
  ];
  const [selectedDateRange, setSelectedDateRange] =
    useState<ValueType>(defaultDateRange);

  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);

  const options = useAsync(async () => {
    let startDate = startOfMonth(new Date());
    let endDate = endOfDay(new Date());

    if (selectedDateRange[0] && selectedDateRange[1]) {
      startDate = selectedDateRange[0];
      endDate = selectedDateRange[1];
    }
    setLoading(true);
    const result = await DashboardService.getFleetExpenses({
      customerId: currentCustomerId,
      expensesStartDate: startDate.toDateString(),
      expensesEndDate: endDate.toDateString(),
    });
    setTotal(getExpenseTotal(result.data ?? ({} as FleetExpensesDto)));
    setLoading(false);

    const navigateToFinancials = ({
      billType,
      dueDateStart,
      dueDateEnd,
    }: ChargesListingQueryParams) => {
      if (canViewFinancials) {
        history.push({
          pathname: routes.financials.charges,
          state: {billType, dueDateStart, dueDateEnd},
        });
      }
    };
    return getOptions(
      result.data ?? ({} as FleetExpensesDto),
      navigateToFinancials,
      selectedDateRange
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canViewFinancials, currentCustomerId, selectedDateRange]);

  return (
    <>
      <Segment css={styles}>
        <Flex.Col>
          <Flex.Row justifyContent="space-between">
            <Flex.Col>
              <DateRangePicker
                format="MM/dd/yyyy"
                character=" - "
                cleanable={false}
                placement="autoVerticalEnd"
                defaultValue={defaultDateRange}
                onChange={setSelectedDateRange}
                ranges={[
                  {
                    label: 'YTD',
                    value: [startOfYear(new Date()), endOfDay(new Date())],
                  },
                  {
                    label: 'Last Month',
                    value: [
                      subMonths(startOfMonth(new Date()), 1),

                      startOfDay(subDays(startOfMonth(new Date()), 1)),
                    ],
                  },
                  {
                    label: 'Current Month',
                    value: [
                      startOfDay(startOfMonth(new Date())),
                      endOfDay(new Date()),
                    ],
                  },
                ]}
              />
            </Flex.Col>
            <Flex.Col>
              {!loading && (
                <div className="total-label">
                  Total
                  <Currency amount={total} />
                </div>
              )}
            </Flex.Col>
          </Flex.Row>
          <Divider fitted hidden />
          <Flex.Row>
            <div className="chart-wrapper">
              {!loading ? (
                <HighchartsReact
                  highcharts={Highcharts}
                  options={options.value}
                  containerProps={containerProps}
                />
              ) : (
                <span className="loading-indicator">Loading...</span>
              )}
            </div>
          </Flex.Row>
        </Flex.Col>
      </Segment>
    </>
  );
};

const styles = css`
  height: 100%;
  padding-top: 0.75rem !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;

  .total-label {
    font-weight: bold;
  }

  .donut-chart {
    height: 15rem;
  }

  .chart-wrapper {
    height: ${containerProps.style.height};
    width: 100%;
    display: grid;
  }

  .loading-indicator {
    place-self: center;
  }
`;

const billTypeColorsMap: {[k in BillTypes]: string} = {
  [BillTypes.Insurance]: MobileaseTheme.colors.orange400,
  [BillTypes.Maintenance]: MobileaseTheme.colors.blue300,
  [BillTypes['Monthly Payment']]: MobileaseTheme.colors.green300,
  [BillTypes.Other]: MobileaseTheme.colors.red400,
  [BillTypes['Taxes and Registration']]: MobileaseTheme.colors.yellow400,
  [BillTypes.Telematics]: MobileaseTheme.colors.purple400,
};
