import React from 'react';
import {useHistory} from 'react-router';
import {useRouteMatch} from 'react-router-dom';
import {Icon, SemanticICONS, Step} from 'semantic-ui-react';
import {QuoteStages} from '../api/generated/enums';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';

type QuoteStepsProps = {
  quoteStage: QuoteStages;
};

type QuoteStep = {
  quoteStage: QuoteStages;
  title: string;
  icon: SemanticICONS;
  completed: boolean;
  active: boolean;
  disabled: boolean;
  url: string;
};

export const QuoteSteps: React.FC<QuoteStepsProps> = ({quoteStage}) => {
  const match = useRouteMatch<{id: string; stage: string}>();
  const steps: QuoteStep[] = [...QuoteStageInfo];

  const history = useHistory();

  const quoteStageIndex = steps.findIndex((x) => x.quoteStage === quoteStage);
  const requestedStageIndex = steps.findIndex(
    (x) =>
      x.quoteStage ===
      (steps.find((x) => x.url === match.params.stage)?.quoteStage ??
        quoteStage)
  );

  return (
    <Step.Group fluid stackable="tablet">
      {steps.map((x, index) => {
        return (
          <Step
            key={index}
            active={
              requestedStageIndex < quoteStageIndex
                ? index === requestedStageIndex
                : index === quoteStageIndex
            }
            completed={index < quoteStageIndex}
            disabled={index > quoteStageIndex}
            link
            onClick={() => {
              history.push(
                buildPath(routes.quotes.detail, {
                  id: match.params.id,
                  stage: x.url,
                })
              );
            }}
          >
            <Icon name={x.icon} />
            <Step.Content>
              <Step.Title>{x.title}</Step.Title>
            </Step.Content>
          </Step>
        );
      })}
    </Step.Group>
  );
};

export const QuoteStageInfo: QuoteStep[] = [
  {
    quoteStage: QuoteStages.Details,
    title: 'Details',
    icon: 'info circle',
    completed: false,
    active: false,
    disabled: false,
    url: 'details',
  },
  {
    quoteStage: QuoteStages.LocateAsset,
    title: 'Locate Asset',
    icon: 'truck',
    completed: false,
    active: false,
    disabled: false,
    url: 'assets',
  },
  {
    quoteStage: QuoteStages.ConfigureTerms,
    title: 'Configure Terms',
    icon: 'clipboard',
    completed: false,
    active: false,
    disabled: false,
    url: 'terms',
  },
  {
    quoteStage: QuoteStages.InReview,
    title: 'Review',
    icon: 'clipboard',
    completed: false,
    active: false,
    disabled: false,
    url: 'review',
  },
  {
    quoteStage: QuoteStages.Completed,
    title: 'Final Documents',
    icon: 'folder',
    completed: false,
    active: false,
    disabled: false,
    url: 'complete',
  },
];

export const getUrlForQuoteStage = (stage: QuoteStages) =>
  QuoteStageInfo.find((x) => x.quoteStage === stage)?.url ??
  QuoteStages.Details;
