import css from '@emotion/css/macro';
import {isArray, last, take} from 'lodash';
import React, {Fragment} from 'react';
import {Link} from 'react-router-dom';
import {Breadcrumb, Header} from 'semantic-ui-react';
import {Flex} from './flex';
import {themeColors} from '../styles';
import {Media} from '../styles/breakpoints';

type BreadcrumbPart = {
  title: string;
  url?: string;
};

export const PageTitle = ({title}) => {
  const parts = (isArray(title) ? title : [{title}]) as BreadcrumbPart[];
  const currentPage = last(parts);
  const breadcrumbs = take(parts, parts.length - 1);
  return (
    <Flex.Row align="center" css={styles} className="page-title">
      <Flex.Box>
        <Header className="page-header">{currentPage?.title}</Header>
      </Flex.Box>
      {breadcrumbs.length > 0 && (
        <>
          <div className="breadcrumbs-separator"></div>
          <Flex.Box className="breadcrumbs">
            <Breadcrumb>
              {breadcrumbs.map((crumb, index) => (
                <Fragment key={crumb.url || crumb.title}>
                  {index < breadcrumbs.length - 1 ? (
                    <>
                      <Breadcrumb.Section link as={Link} to={crumb.url}>
                        {crumb.title}
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider>/</Breadcrumb.Divider>
                    </>
                  ) : (
                    <Breadcrumb.Section link as={Link} to={crumb.url}>
                      {crumb.title}
                    </Breadcrumb.Section>
                  )}
                </Fragment>
              ))}
            </Breadcrumb>
          </Flex.Box>
        </>
      )}
      <Flex.Fill></Flex.Fill>
    </Flex.Row>
  );
};

const styles = css`
  .page-header {
    margin-top: 0;
    font-weight: normal;
  }

  .ui.breadcrumb a {
    color: ${themeColors.grey1};
  }

  .breadcrumbs-separator {
    height: 22px;
    width: 1px;
    margin: 0 20px;
    background-color: #dbdce7;

    ${Media('MobileMax')} {
      display: none;
    }
  }

  margin-top: 0px;
  margin-bottom: 20px;

  &.page-title {
    ${Media('MobileMax')} {
      display: flex !important;
      flex-direction: column-reverse !important;
      align-items: flex-start !important;

      > .box {
        margin-bottom: 0;
      }

      .breadcrumbs {
        margin-bottom: 0;
        margin-top: -1rem;
      }
    }
  }
`;
