import {css} from '@emotion/core';
import {faTrash} from '@fortawesome/pro-solid-svg-icons';
import {useAsync, useAsyncRetry} from 'react-use';
import {Modal, ModalContent, ModalHeader, ModalProps} from 'semantic-ui-react';
import {AssetUsageDetailDto, AssetUsageService} from '../../api/generated';
import {DeleteButton} from '../../components/confirm-button';
import {Flex} from '../../components/flex';
import {LineItem, LineItemList} from '../../dashboard/dashboard-components';
import {MobileaseTheme} from '../../styles/branding';
import {notifications} from '../../utils/notification-service';
import {dateFormatter, numberFormatter} from '../asset-utils';
import React from 'react';
import {usePagination} from '../../hooks/use-pagination';
import {RequireAuthorization} from '../../auth/require-authorization';
import {AssetTypes} from '../../api/generated/enums';

const INITIAL_PAGE_SIZE = 5;
export const AssetUsageHistoryModal: React.FC<
  ModalProps & {
    assetId: string;
    usageHistoryUpdated: () => void;
    assetType: AssetTypes;
  }
> = ({assetId, usageHistoryUpdated, assetType, ...modalProps}) => {
  const pagination = usePagination(
    {initialPage: 0, initialPageSize: INITIAL_PAGE_SIZE},
    {boundaryRange: 1, siblingRange: 0}
  );

  const usageHistory = useAsyncRetry(async () => {
    if (modalProps.open) {
      const response = await AssetUsageService.getAssetUsageHistory({
        id: assetId,
        sorted: [{fieldName: 'readingDate', descending: true}],
        page: pagination.pageNumber,
        pageSize: pagination.pageSize,
      });
      return response;
    }
  }, [modalProps.open, assetId, pagination.pageNumber, pagination.pageSize]);

  return (
    <Modal {...modalProps} css={usageItemStyles}>
      <ModalHeader>
        {assetType === AssetTypes.Equipment ? 'Usage' : 'Mileage'} History
      </ModalHeader>
      <ModalContent>
        <LineItemList>
          {usageHistory.value?.data?.totalItemCount ? (
            <>
              <Flex.Row justifyContent="space-between">
                <Flex.Col fill>
                  <strong>Reading Date</strong>
                </Flex.Col>
                <Flex.Col>
                  <strong className="usage-heading">
                    {assetType === AssetTypes.Equipment ? 'Hours' : 'Mileage'}
                  </strong>
                </Flex.Col>
                <RequireAuthorization permission="Assets: Manage">
                  <Flex.Col className="delete-column"></Flex.Col>
                </RequireAuthorization>
              </Flex.Row>
              {usageHistory.value?.data?.items.map((historyItem) => {
                return (
                  <UsageHistoryItem
                    usageItem={historyItem}
                    onDelete={() => {
                      usageHistory.retry();
                      usageHistoryUpdated();
                    }}
                  />
                );
              })}
              <div
                css={css`
                  margin-top: 1rem;

                  .lines-per-page {
                    display: none;
                  }

                  .fill {
                    display: flex;
                    justify-content: center;
                  }
                `}
              >
                {usageHistory.value?.data &&
                  pagination.render(
                    usageHistory.value.data.pageCount,
                    usageHistory.value.data?.totalItemCount
                  )}
              </div>
            </>
          ) : (
            <>No items to display.</>
          )}
        </LineItemList>
      </ModalContent>
    </Modal>
  );
};

export const UsageHistoryItem: React.FC<{
  usageItem: AssetUsageDetailDto;
  onDelete: () => void;
}> = ({usageItem, onDelete}) => (
  <LineItem>
    <Flex.Row align="center">
      <Flex.Col fill>
        <span className="reading-date">
          {dateFormatter(usageItem.readingDate)}
        </span>
      </Flex.Col>
      <Flex.Col>
        <span className="usage">{numberFormatter(usageItem.usageUnits)}</span>
      </Flex.Col>
      <RequireAuthorization permission="Assets: Manage">
        <Flex.Col className="delete-column">
          <DeleteButton
            icon={faTrash}
            onConfirm={async () => {
              const response = await AssetUsageService.delete({
                id: usageItem.id,
              });
              if (response.hasErrors) {
                notifications.errors(response.errors);
                return;
              }
              notifications.success('Entry deleted.');
              onDelete();
            }}
            loading={false}
          />
        </Flex.Col>
      </RequireAuthorization>
    </Flex.Row>
    <Flex.Row className="sub-row">
      <span>
        <strong>Logged By: </strong>
        {usageItem.createdByUserName}
      </span>
    </Flex.Row>
    <Flex.Row className="notes-row sub-row">
      <Flex.Col>
        {usageItem.notes && (
          <span>
            <strong>Notes: </strong> {usageItem.notes}{' '}
          </span>
        )}
      </Flex.Col>
    </Flex.Row>
  </LineItem>
);

const usageItemStyles = css`
  .reading-date,
  .usage {
    color: ${MobileaseTheme.colors.grey800};
    margin-right: 0.5rem;
  }

  .notes-row {
    width: 70%;
  }
  .sub-row {
    font-size: 0.9em;
  }
  .usage-heading {
    text-align: end;
  }

  .delete-column {
    flex-basis: 40px;
  }
`;
