import {css} from '@emotion/core';
import {faDownload} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import _ from 'lodash';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useAsync} from 'react-use';
import {
  Button,
  Card,
  Dropdown,
  DropdownItemProps,
  Header,
  List,
  Modal,
  Segment,
  SegmentGroup,
} from 'semantic-ui-react';
import {
  ApprovedQuoteTermDto,
  QuoteApprovalService,
  QuotesService,
} from '../../api/generated';
import {
  CustomerApprovalQuoteStatuses,
  ManagerApprovalStatuses,
  QuoteStages,
} from '../../api/generated/enums';
import {buildApiUrl} from '../../api/generated/utils';
import {ConfirmButton} from '../../components/confirm-button';
import Currency from '../../components/currency';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {useNotification} from '../../hooks/use-notifications';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {MobileaseTheme} from '../../styles/branding';
import {CloneQuoteButton} from '../clone-quote';
import {QuoteStageProps} from '../quote-create-update';
import {getUrlForQuoteStage, QuoteStageInfo} from '../quote-steps';
import {QuoteStageFormActions} from './quote-stage-form-actions';

export const QuoteReview: React.FC<QuoteStageProps> = ({
  quote,
  refetchQuote,
  readOnly,
}) => {
  const notifications = useNotification();
  const history = useHistory();
  const leaseWorksheetUrl = buildApiUrl<
    typeof QuotesService.downloadLeaseWorksheet
  >('/api/quotes/lease-worksheet', {quoteId: quote.id});

  const customerQuoteUrl = buildApiUrl<
    typeof QuotesService.downloadCustomerQuote
  >('/api/quotes/customer-quote', {quoteId: quote.id});

  const [selectingApprovedTerm, setSelectingApprovedTerm] = useState(false);

  const handleTermSubmitted = async (term: ApprovedQuoteTermDto) => {
    const response = await QuoteApprovalService.customerApprove({
      body: {quoteTermId: term.quoteTermId},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    notifications.success('Quote updated.');
    setSelectingApprovedTerm(false);
    refetchQuote();
    history.push(
      buildPath(routes.quotes.detail, {
        id: quote.id,
        stage: getUrlForQuoteStage(QuoteStages.InReview),
      })
    );
  };

  const handleMoveOutOfReview = async () => {
    var response = await QuotesService.moveQuoteOutOfReview({
      body: {quoteId: quote.id},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    notifications.success('Quote updated.');
    refetchQuote();

    history.push(
      buildPath(routes.quotes.detail, {
        id: quote.id,
        stage: getUrlForQuoteStage(QuoteStages.ConfigureTerms),
      })
    );
  };

  return (
    <SegmentGroup>
      <Segment>
        <Header>1 - Review Terms and Documents</Header>
        <List bulleted>
          <List.Item>
            Please review the following documents to ensure everything is
            correct.
          </List.Item>
        </List>
        <Button
          icon
          type="button"
          onClick={() => {
            window.open(leaseWorksheetUrl, '_blank');
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Lease Worksheet
        </Button>
        <Button
          icon
          type="button"
          onClick={() => {
            window.open(customerQuoteUrl, '_blank');
          }}
        >
          <FontAwesomeIcon icon={faDownload} /> Customer Quote
        </Button>
      </Segment>
      <Segment>
        <Header>2 - Optional: Get Manager Approval</Header>
        <List bulleted>
          <List.Item>
            If this quote needs manager approval, please select the "
            {ManagerApprovalStatuses['Awaiting Manager Approval']}" status below
            and contact your manager.
          </List.Item>
          <List.Item>
            If changes are needed, please select the "Send Back for Changes"
            button below.
          </List.Item>
          <List.Item>
            Once the quote has been approved by the manager, please select the "
            {ManagerApprovalStatuses['Manager Approved']}" status below.
          </List.Item>
        </List>
        <Flex.Row align="center">
          <Flex.Col>
            <Dropdown
              selection
              placeholder="Select Manager Status..."
              disabled={readOnly}
              selectOnBlur={false}
              selectOnNavigation={false}
              defaultValue={quote.quoteStatus}
              onChange={async (event, data) => {
                const response =
                  await QuoteApprovalService.updateQuoteManagerApprovalStatus({
                    body: {
                      quoteId: quote.id,
                      managerApprovalStatus: data.value as string,
                    },
                  });
                if (response.hasErrors) {
                  notifications.errors(response.errors);
                  return false;
                }
                notifications.success('Status Updated');
                refetchQuote();
                history.push(
                  buildPath(routes.quotes.detail, {
                    id: quote.id,
                    stage: getUrlForQuoteStage(QuoteStages.InReview),
                  })
                );

                return true;
              }}
              options={[
                ManagerApprovalStatuses['Awaiting Manager Approval'],
                ManagerApprovalStatuses['Manager Approved'],
              ].map<DropdownItemProps>((status, index) => ({
                key: index,
                value: status,
                text: status,
              }))}
            />
          </Flex.Col>
          <Flex.Col>
            <Button
              css={css`
                margin-left: 1rem !important;
              `}
              type="button"
              secondary
              onClick={handleMoveOutOfReview}
              content="Send Back for Changes"
              disabled={readOnly}
            />
          </Flex.Col>
        </Flex.Row>
      </Segment>
      <Segment>
        <Header>3 - Get Customer Approval</Header>
        <List bulleted>
          <List.Item>
            After sending the quote to the customer, select whether the quote
            has been approved or denied.
          </List.Item>
        </List>
        <Button
          content="Approved"
          color="green"
          type="button"
          disabled={readOnly}
          onClick={() => {
            setSelectingApprovedTerm(true);
          }}
        />
        <ConfirmButton
          text="Denied"
          css={css`
            border: 1px solid #d01919;
          `}
          negative
          basic={false}
          primary={false}
          confirmProps={{negative: true, primary: false}}
          type="button"
          disabled={readOnly}
          onConfirm={async () => {
            const response = await QuoteApprovalService.customerDeny({
              body: {quoteId: quote.id},
            });
            if (response.hasErrors) {
              notifications.errors(response.errors);
              return;
            }
            notifications.success('Quote updated.');
            refetchQuote();
            history.push(
              buildPath(routes.quotes.detail, {
                id: quote.id,
                stage: getUrlForQuoteStage(QuoteStages.ConfigureTerms),
              })
            );
          }}
        />
      </Segment>
      {quote.approvedQuoteTerm && (
        <Segment>
          <Header color="green">Completed - Customer Approved Terms</Header>
          <ApprovableTermCard
            term={quote.approvedQuoteTerm}
            selected={false}
            onTermSelected={() => {}}
          />
        </Segment>
      )}
      <Segment>
        <Header>Optional: Create a New Quote</Header>
        <List bulleted>
          <List.Item>
            This quote can be cloned, and a new quote will be created with the
            same asset and term information.
          </List.Item>
          <List.Item>
            You can then modify the new quote as needed before submitting for
            approval.
          </List.Item>
        </List>
        <CloneQuoteButton quoteId={quote.id} />
      </Segment>
      <Segment>
        <QuoteStageFormActions
          quoteId={quote.id}
          action={
            <>
              {!readOnly && (
                <ConfirmButton
                  css={css`
                    margin-bottom: 1rem !important;
                  `}
                  disabled={
                    !quote.approvedQuoteTermId ||
                    quote.quoteStatus ===
                      ManagerApprovalStatuses['Awaiting Manager Approval']
                  }
                  type="button"
                  primary
                  basic={false}
                  onConfirm={async () => {
                    const response = await QuotesService.finalize({
                      body: {quoteId: quote.id},
                    });
                    if (response.hasErrors) {
                      notifications.errors(response.errors);
                      return;
                    }
                    notifications.success('Quote finalized.');
                    refetchQuote();
                    history.push(
                      buildPath(routes.quotes.detail, {
                        id: quote.id,
                        stage: getUrlForQuoteStage(QuoteStages.Completed),
                      })
                    );
                  }}
                  text="Finalize Quote"
                />
              )}
            </>
          }
        />
        {selectingApprovedTerm && (
          <CustomerTermsSelector
            open
            onClose={() => {
              setSelectingApprovedTerm(false);
            }}
            onTermsSubmitted={handleTermSubmitted}
            initiallySelectedTerms={quote.approvedQuoteTerm}
            quoteId={quote.id}
          />
        )}
      </Segment>
    </SegmentGroup>
  );
};

const CustomerTermsSelector: React.FC<{
  quoteId: number;
  onTermsSubmitted: (term: ApprovedQuoteTermDto) => void;
  open: boolean;
  onClose: () => void;
  initiallySelectedTerms: ApprovedQuoteTermDto;
}> = ({quoteId, onTermsSubmitted, open, onClose, initiallySelectedTerms}) => {
  const quoteTerms = useAsync(async () => {
    const terms = await QuoteApprovalService.getOptions({quoteId: quoteId});
    return terms.data;
  });

  const [selectedTerm, setSelectedTerm] = useState<ApprovedQuoteTermDto | null>(
    initiallySelectedTerms
  );

  return (
    <>
      <Modal open={open} onClose={onClose} css={cardStyles} size={'large'}>
        <Modal.Header>Select Approved Terms</Modal.Header>
        <Modal.Content>
          {quoteTerms.value && (
            <TermsSelectorList
              quoteTerms={quoteTerms.value}
              onTermsSelected={(term) => {
                setSelectedTerm(term);
              }}
              selectedTerm={selectedTerm}
            />
          )}
        </Modal.Content>
        <Modal.Actions>
          <Button
            primary
            disabled={selectedTerm === null}
            onClick={() => {
              selectedTerm && onTermsSubmitted(selectedTerm);
            }}
          >
            Submit
          </Button>
          <Button
            onClick={() => {
              onClose();
            }}
          >
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const TermsSelectorList: React.FC<{
  quoteTerms: ApprovedQuoteTermDto[];
  onTermsSelected: (term: ApprovedQuoteTermDto) => void;
  selectedTerm: ApprovedQuoteTermDto | null;
}> = ({quoteTerms, onTermsSelected, selectedTerm}) => {
  const termsGroupedByAssetId = Object.entries(
    _.groupBy(quoteTerms, (x) => x.assetId)
  );

  return (
    <>
      {termsGroupedByAssetId.map((termGroup) => {
        const terms = termGroup[1];
        return (
          <Form.Row
            css={css`
              margin-bottom: 1rem;
            `}
          >
            <Card.Group itemsPerRow={3}>
              {terms.map((term) => {
                return (
                  <ApprovableTermCard
                    term={term}
                    onTermSelected={onTermsSelected}
                    selected={selectedTerm?.quoteTermId === term.quoteTermId}
                  />
                );
              })}
            </Card.Group>
          </Form.Row>
        );
      })}
    </>
  );
};

const ApprovableTermCard: React.FC<{
  term: ApprovedQuoteTermDto;
  onTermSelected: (term: ApprovedQuoteTermDto) => void;
  selected?: boolean;
}> = ({term, onTermSelected, selected}) => {
  return (
    <>
      <Card
        className={cx({['selected']: selected})}
        as="div"
        onClick={() => {
          onTermSelected(term);
        }}
      >
        <Card.Content header>{term.assetName}</Card.Content>
        <Card.Content>
          <CardValue
            label="Capitalized Cost:"
            value={<Currency amount={term.capitalizedCost / 100} />}
          />
          <CardValue label="Term Length :" value={`${term.term} months`} />
          <CardValue
            label="Monthly Payment:"
            value={<Currency amount={term.monthlyPayment / 100} />}
          />
          <CardValue
            label="Due on Delivery:"
            value={<Currency amount={term.dueOnDelivery / 100} />}
          />
        </Card.Content>
      </Card>
    </>
  );
};

const CardValue: React.FC<{value: any; label: string}> = ({value, label}) => {
  return (
    <Flex.Row>
      <Flex.Col fill className="card-label">
        {label}
      </Flex.Col>
      <Flex.Col fill justifyContent="flex-end" className="card-value">
        <strong>{value}</strong>
      </Flex.Col>
    </Flex.Row>
  );
};

const cardStyles = css`
  .card {
    cursor: pointer;
    user-select: none;
    &:hover {
      transform: translateY(-3px);
    }
    &.selected {
      border: 2px solid ${MobileaseTheme.colors.blue500};
    }
  }

  .card-label {
    text-align: end;
  }

  .card-value {
    padding-left: 5px;
  }
`;
