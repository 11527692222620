import {
  QuoteTermsTemplatesService,
  QuoteTermsTemplateListingDto,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import React from 'react';
import {routes} from '../routes';
import {DateTimeFormat} from '../components/date';
import {Button} from 'semantic-ui-react';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'react-router-dom';
import {useProduce} from '../hooks/use-produce';
import {useAsync} from 'react-use';
import {dropdown, fieldConfig} from '../forms/schema-utils';
import {Form} from '../forms';

const pagedDataTableConfig: PagedDataTableConfig<QuoteTermsTemplateListingDto> =
  {
    columns: [
      {
        header: '',
        render: (item) =>
          renderEditButton({
            item: item,
            descriptor: 'Terms Template',
            route: routes.quoteTermTemplates.detail,
          }),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: 'Name',
        column: 'name',
        sortable: 'name',
      },
      {
        header: 'Customer',
        column: 'customerName',
      },
      {
        header: 'Updated By',
        column: 'lastUpdatedBy',
        sortable: 'lastUpdatedOn',
      },
      {
        header: 'Updated On',
        render: (item) => <DateTimeFormat datetime={item.lastUpdatedOn} />,
        sortable: 'lastUpdatedOn',
      },
    ],
    searchFieldNames: ['name'],
    defaultSort: {column: 'name', direction: 'ASC'},
  };

export const QuoteTermTemplatesListing: React.FC<{}> = () => {
  const [filterState, setFilterState] = useProduce<{
    customerId: string;
  }>({customerId: ''});

  const dataTable = usePagedDataTable(
    QuoteTermsTemplatesService.getAll,
    pagedDataTableConfig,
    {
      actions: (
        <Button as={Link} primary to={routes.quoteTermTemplates.create}>
          <FontAwesomeIcon icon={faPlus} /> New Terms Template
        </Button>
      ),
      renderFilter: (dismiss) => {
        return (
          <>
            <TableFilters
              initialValues={filterState}
              onApply={(customerId) => {
                setFilterState((draft) => {
                  draft.customerId = customerId;
                });
                dismiss();
              }}
            />
          </>
        );
      },
      filterBadge: filterState.customerId !== '' ? 1 : null,
      additionalParams: {customerId: filterState.customerId},
    }
  );
  return (
    <>
      <BasicPage title="Quote Term Templates">{dataTable}</BasicPage>
    </>
  );
};

const useFields = (customerOptions) => {
  return fieldConfig<{customerId: string}>({
    customerId: dropdown({
      fieldLabel: 'Customer',
      inputProps: {
        options: customerOptions,
        clearable: true,
        selection: true,
        search: true,
      },
    }),
  });
};

const TableFilters: React.FC<{
  onApply: (customerId: string) => void;
  initialValues: {customerId: string};
}> = ({onApply, initialValues}) => {
  const customerOptions = useAsync(async () => {
    const response =
      await QuoteTermsTemplatesService.getQuoteTermsTemplateOptions();
    return response.data?.customers;
  }, []);

  const fields = useFields(customerOptions.value);

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={(values) => {
          onApply(values.customerId);
        }}
        render={() => (
          <>
            {fields && (
              <>
                <Form.Dropdown fieldConfig={fields.customerId} />
                <Form.Button type="submit" primary>
                  Apply Filter
                </Form.Button>
              </>
            )}
          </>
        )}
      />
    </>
  );
};
