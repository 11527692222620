import {css} from '@emotion/core';
import React from 'react';
import {useState, useEffect} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {Button} from 'semantic-ui-react';
import {Flex} from '../components/flex';
import {MobileaseTheme} from '../styles/branding';

export const FormFiltersAppliedCount: React.FC<{
  excludeFilters?: string[];
}> = ({excludeFilters}) => {
  const form = useForm();
  const {values} = useFormState();

  const [filterCount, setFilterCount] = useState<number>(0);
  const clearForm = () => {
    setFilterCount(0);
    form.reset({});
  };

  useEffect(() => {
    if (values) {
      setFilterCount(
        Object.entries(values)
          .filter(
            (e) =>
              (excludeFilters?.length ?? 0) > 0 &&
              !excludeFilters?.includes(e[0])
          )
          .map((x) => x[1])
          .filter(
            (x) => x !== '' && x !== undefined && x !== false && x !== null
          ).length
      );
    } else {
      setFilterCount(0);
    }
  }, [form, values]);
  return (
    <>
      <Flex.Row
        justifyContent="space-between"
        align="center"
        css={css`
          margin-bottom: 1rem;
        `}
      >
        <Flex.Col>
          <strong>
            {filterCount > 0 && <>{filterCount} Filter(s) Applied</>}
          </strong>
        </Flex.Col>
        <Flex.Col>
          <Button
            content="Clear All"
            floated="right"
            onClick={clearForm}
            css={css`
              background: none !important;
              border: none;
              color: ${MobileaseTheme.colors.blue500} !important;
              padding: 0 !important;
              cursor: pointer;
            `}
          />
        </Flex.Col>
      </Flex.Row>
    </>
  );
};
