import React, {useMemo} from 'react';
import {useForm} from 'react-final-form';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {
  RequestTemplateService,
  UpdateRequestTemplateRequest,
  UsersService,
} from '../api/generated';
import {FormErrorMessage, RequestTemplateTokens} from '../api/generated/enums';
import {DropdownOption} from '../api/generated/utils';
import {BasicPage} from '../basic-page';
import {HtmlTextInput} from '../components/html-input';
import {Form} from '../forms';
import {
  checkbox,
  dropdown,
  fieldConfig,
  input,
  textarea,
  RawFieldConfig,
} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

type RequestTemplateOmitKey = 'id';

type FieldConfigDto = Omit<
  UpdateRequestTemplateRequest,
  RequestTemplateOmitKey
>;

const useFields = () => {
  const fetchFields = useAsync(async () => {
    const {data} = await UsersService.getAll();
    return fieldConfig<FieldConfigDto>({
      name: input({
        fieldLabel: 'Name',
      }),
      subject: input({
        fieldLabel: 'Subject',
      }),
      body: textarea({
        fieldLabel: 'Body',
      }),
      notifyAccountExecutive: checkbox({
        fieldLabel: 'Notify Account Executive?',
        defaultValue: false,
        inputProps: {
          toggle: true,
        },
      }),
      notifyCustomerServiceRepresentative: checkbox({
        fieldLabel: 'Notify Customer Service Representative?',
        defaultValue: false,
        inputProps: {
          toggle: true,
        },
      }),
      additionalRecipients: dropdown({
        fieldLabel: 'Additional Recipients',
        inputProps: {
          options: data?.items.map<DropdownOption>((item) => {
            return {
              text: `${item.firstName} ${item.lastName} - ${item.emailAddress}`,
              value: item.id,
            };
          }),
          selection: true,
          multiple: true,
          search: true,
        },
      }),
    });
  });
  return fetchFields;
};

export const RequestTemplateEdit = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();
  const fields = useFields();
  const fetchRequestTemplate = useAsync(async () => {
    const {data} = await RequestTemplateService.getById({
      id,
    });
    return data;
  }, [id]);

  const requestTemplate = fetchRequestTemplate.value || undefined;
  const requestTemplateName = requestTemplate?.name ?? '';

  const breadcrumbs = useMemo(
    () => [
      {title: 'Request Templates', url: routes.requestTemplates.index},
      {title: requestTemplateName},
    ],
    [requestTemplateName]
  );

  const onSubmit = async (value) => {
    const response = await RequestTemplateService.update({id, body: value});
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Request Template Updated');
    history.push(routes.requestTemplates.index);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          initialValues={fetchRequestTemplate.value ?? undefined}
          onSubmit={onSubmit}
          render={() => {
            return (
              <>
                {fields.value && <FormFields fields={fields.value} />}
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Save
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.requestTemplates.index}
                  >
                    Cancel
                  </Form.Button>
                </div>
              </>
            );
          }}
        />
      </Form.Container>
    </BasicPage>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<FieldConfigDto>;
}> = ({fields}) => {
  const {change} = useForm();
  return (
    <>
      <Form.Row>
        <Form.ErrorMessage name={FormErrorMessage.ErrorFieldName} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.name} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.subject} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}></Form.Row>
      <Form.Row proportions={[1, 1]}>
        <HtmlTextInput
          fieldConfig={fields.body}
          onChange={(content: string) => {
            change('body', content);
          }}
          tokenOptions={Object.keys(RequestTemplateTokens)}
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Checkbox fieldConfig={fields.notifyAccountExecutive} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Checkbox
          fieldConfig={fields.notifyCustomerServiceRepresentative}
        />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Dropdown fieldConfig={fields.additionalRecipients} />
      </Form.Row>
    </>
  );
};
