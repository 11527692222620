import {createStateContext} from 'react-use';
import {CustomerSettingsDto} from './api/generated';
import {sessionCustomerIdKey} from './utils/constants';

export const getSessionCustomerId = () => {
  return localStorage.getItem(sessionCustomerIdKey) ?? 'empty_id';
};

export type InitialContextState = {
  currentCustomerId: string;
  settings: Omit<CustomerSettingsDto, 'id' | 'customerId'>;
};

const initialContextState: InitialContextState = {
  currentCustomerId: getSessionCustomerId(),
  settings: {
    displayUnitNumber: false,
    displayDriver: false,
    displayBranchDivision: false,
    geotabId: '',
  },
};

export const [useCustomerContext, CustomerContextProvider] =
  createStateContext<InitialContextState>(initialContextState);
