import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {AssetDashboard} from './dashboard/asset-dashboard';
import {AssetsListing} from './assets-listing';

export const AssetsRoutes = () => {
  return (
    <Switch>
      <Route path={routes.assets.index} exact>
        <AssetsListing />
      </Route>
      <Route path={routes.assets.detail}>
        <AssetDashboard />
      </Route>
    </Switch>
  );
};
