import {reduce} from 'lodash';

const DEFAULT_CRUD = {
  create: `/create`,
  detail: `/:id`,
  index: ``,
};

export const routes = {
  assets: route('/assets', {
    detail: `/:id`,
    index: ``,
  }),
  financials: route('/financials', {
    index: '',
    invoices: '/invoices',
    charges: '/charges',
  }),
  users: route('/users', DEFAULT_CRUD),
  customers: route('/customers', {
    detail: `/:id`,
    index: ``,
  }),
  invoices: route('/invoices', {
    index: ``,
    monthly: `/monthly`,
    supplemental: `/supplemental`,
    upload: `/upload`,
  }),
  requestTemplates: route('/request-templates', {
    detail: `/:id`,
    index: ``,
  }),
  quotes: route('/quotes', {
    ...DEFAULT_CRUD,
    asset: `/:id/assets/:assetId`,
    newAsset: `/:id/assets/new`,
    detail: `/:id/:stage?`,
    terms: '/:id/terms/:assetId?',
  }),
  quoteTermTemplates: route('/quote-term-templates', DEFAULT_CRUD),
  settings: route('/settings', {
    detail: `/:id`,
    index: ``,
  }),
  callback: '/callback',
  error: route('/error', {
    transaction: '/transaction-error',
    index: '',
  }),
  reporting: route('/reporting', DEFAULT_CRUD),
  admin: {
    logs: route('/admin/logs', {
      groupedDetail: `/:parameters`,
      errorDetail: `/:id`,
      index: ``,
    }),
    routePatterns: route('/admin/route-patterns', DEFAULT_CRUD),
  },
  dashboard: route('/dashboard', {
    admin: '/admin',
    index: '',
  }),
};

function route<T>(base: string, definition: T) {
  return reduce(
    definition as any,
    (acc, value, key) => {
      acc[key] = `${base}${value}`;
      return acc;
    },
    {...definition}
  ) as T;
}
