import React, {useMemo} from 'react';
import {
  faClock,
  faCommentDollar,
  faTruckPickup,
} from '@fortawesome/pro-solid-svg-icons';
import {useRouteMatch} from 'react-router';
import {useAsyncRetry} from 'react-use';
import {Button, Divider, Icon, Message} from 'semantic-ui-react';
import {LeaseCompleteAssetsService} from '../../api/generated';
import {
  AssetRegistrationStatus,
  RegistrationRequiredMessages,
  RequestTemplateKeys,
} from '../../api/generated/enums';
import {Flex} from '../../components/flex';
import {DashboardPanel} from '../../dashboard/dashboard-panel';
import {DashboardPlaceholder} from '../../dashboard/dashboard-placeholder';
import {useRequestTemplateModal} from '../../request-templates/request-template-context';
import {notifications} from '../../utils/notification-service';
import {AssetContextProvider} from '../asset-context';
import {AssetDetailCard} from './asset-detail-card';
import {AssetMaintenanceHistory} from './asset-maintenance-history';
import {SecondaryNavigation} from '../../secondary-navigation';
import {css} from '@emotion/core';
import {AssetNavigator} from './asset-nav';
import {AssetContractDetailCard} from './asset-contract-detail-card';
import {DateFormat} from '../../components/date';

export const AssetDashboard = () => {
  const matchedRoute = useRouteMatch();
  const assetId = matchedRoute.params['id'];
  const {openRequestTemplateModal} = useRequestTemplateModal();
  const response = useAsyncRetry(async () => {
    const response = await LeaseCompleteAssetsService.getById({
      assetId: assetId,
    });
    if (response.hasErrors) {
      response.errors.forEach((e) => notifications.error(e.errorMessage));
    }
    return response.data;
  }, [assetId]);

  const assetDto = response?.value;

  const showMessage = useMemo(() => {
    switch (assetDto?.registrationStatus) {
      case '':
        return false;
      case AssetRegistrationStatus['Under Review']:
        return true;
      case AssetRegistrationStatus['In Process']:
        return true;
      case AssetRegistrationStatus['Complete']:
        return false;
    }
  }, [assetDto?.registrationStatus]);

  const items = useMemo(() => {
    let list: string[] = [];
    if (
      assetDto?.registrationStatus === AssetRegistrationStatus['In Process']
    ) {
      return list;
    }
    if (assetDto?.uAssetsInspectionReqMileage)
      list.push(
        RegistrationRequiredMessages[
          'The current mileage on your vehicle is needed to renew your registration.'
        ]
      );
    if (assetDto?.uAssetsInspectionReqEmissionsInspection)
      list.push(
        RegistrationRequiredMessages[
          'A current passing Emissions Inspection is needed to renew your registration'
        ]
      );
    if (assetDto?.uAssetsInspectionReqSmogInspection)
      list.push(
        RegistrationRequiredMessages[
          'A current passing Smog Inspection is needed to renew your registration'
        ]
      );
    if (assetDto?.uAssetsInspectionReqSafetyInspection)
      list.push(
        RegistrationRequiredMessages[
          'A current passing Safety Inspection is needed to renew your registration.'
        ]
      );
    if (assetDto?.uAssetsInspectionReqVinverification)
      list.push(
        RegistrationRequiredMessages[
          'A current VIN Verification/Certification is needed to renew your registration.'
        ]
      );
    if (assetDto?.uAssetsCertifiedWeightTicket)
      list.push(
        RegistrationRequiredMessages[
          'A current Certified Weight Ticket is needed to renew your registration.'
        ]
      );
    if (assetDto?.uAssetsComments) list.push(assetDto?.uAssetsComments);

    return list;
  }, [assetDto]);

  const requestButtons = [
    {
      label: 'Request Extension',
      icon: faClock,
      templateKey: RequestTemplateKeys.RequestExtension,
    },
    {
      label: 'Order Similar',
      icon: faTruckPickup,
      templateKey: RequestTemplateKeys.OrderSimilar,
    },
    {
      label: 'Payoff Info',
      icon: faCommentDollar,
      templateKey: RequestTemplateKeys.PayoffInfo,
    },
  ];

  return (
    <>
      {assetDto && !response.loading ? (
        <AssetContextProvider value={{asset: response?.value ?? undefined}}>
          <>
            <SecondaryNavigation
              navigationRequestButtons={requestButtons}
              disabledCustomerSelectorId={assetDto.assetCustomerId}
            />
            <div css={dashboardStyles}>
              {showMessage && (
                <>
                  <Divider hidden fitted />
                  <Message warning icon>
                    <Icon name="warning sign" />
                    <Message.Content>
                      <Message.Header>
                        <Flex.Row justifyContent="space-between">
                          <Flex.Col>
                            <Flex.Row>
                              Registration Status -{' '}
                              {assetDto.registrationStatus}
                            </Flex.Row>
                            {assetDto.uAssetsSentForProcessing &&
                              assetDto.registrationStatus ===
                                AssetRegistrationStatus['In Process'] && (
                                <Flex.Row className="sent-for-processing-message">
                                  Sent for Processing -{' '}
                                  <DateFormat
                                    date={assetDto.uAssetsSentForProcessing}
                                  />
                                </Flex.Row>
                              )}
                            <Flex.Row>
                              {items.length > 0 &&
                                'The following are needed to renew your registration:'}
                            </Flex.Row>
                          </Flex.Col>
                          <Flex.Col>
                            {items.length > 0 && (
                              <Button
                                onClick={() =>
                                  openRequestTemplateModal(
                                    RequestTemplateKeys.SendInformation,
                                    assetId
                                  )
                                }
                                basic
                                compact
                                floated="right"
                                content="Send Information"
                              />
                            )}
                          </Flex.Col>
                        </Flex.Row>
                      </Message.Header>
                      <Message.List items={items} />
                    </Message.Content>
                  </Message>
                </>
              )}
              <AssetNavigator asset={assetDto} />
              <Flex.Row fill>
                <Flex.Col fill className="column-margin">
                  <DashboardPanel headerText="Asset Details">
                    <AssetDetailCard
                      asset={assetDto}
                      onAssetUpdated={() => response.retry()}
                    />
                  </DashboardPanel>
                </Flex.Col>
                <Flex.Col fill className="column-margin">
                  <DashboardPanel headerText="Contract Details">
                    <AssetContractDetailCard asset={assetDto} />
                  </DashboardPanel>
                </Flex.Col>
                <Flex.Col fill className="column-margin">
                  <DashboardPanel headerText="Maintenance History">
                    <AssetMaintenanceHistory assetId={assetDto.assetId} />
                  </DashboardPanel>
                </Flex.Col>
              </Flex.Row>
            </div>
          </>
        </AssetContextProvider>
      ) : (
        <DashboardPlaceholder text="No detail information found" />
      )}
    </>
  );
};

const dashboardStyles = css`
  padding: 0 1rem;

  .width-100 {
    width: 100%;
  }

  .min-width-25 {
    min-width: 25%;
  }

  .column-margin {
    margin: 0 1rem;
  }

  .sent-for-processing-message {
    font-size: 0.8em;
  }
`;
