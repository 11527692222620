import {Route, Switch} from 'react-router';
import {routes} from '../routes';
import {TransactionError} from './transaction-error';

export const ErrorRoutes = () => (
  <Switch>
    <Route path={routes.error.transaction}>
      <TransactionError />
    </Route>
  </Switch>
);
