import {css} from '@emotion/core';
import _ from 'lodash';
import moment from 'moment';
import React, {useReducer} from 'react';
import {useHistory} from 'react-router-dom';
import {
  Divider,
  Header,
  Segment,
  SemanticCOLORS,
  Table,
} from 'semantic-ui-react';
import {QuoteListingDto} from '../api/generated';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';
import {MobileaseTheme} from '../styles/branding';

type QuoteDashboardTableProps = {
  header: string;
  color: SemanticCOLORS;
  quotes: QuoteListingDto[] | undefined;
};

export const QuoteDashboardTable: React.FC<QuoteDashboardTableProps> = ({
  header,
  color,
  quotes,
}) => {
  const history = useHistory();

  function exampleReducer(state, action) {
    if (state.column === action.column) {
      return {
        ...state,
        data: state.data.slice().reverse(),
        direction: state.direction === 'ascending' ? 'descending' : 'ascending',
      };
    }

    return {
      column: action.column,
      data: _.sortBy(state.data, [action.column]),
      direction: 'ascending',
    };
  }

  const [sortState, sortDispatch] = useReducer(exampleReducer, {
    column: null,
    data: quotes,
    direction: null,
  });
  const {column, data, direction} = sortState;

  const formatDate = (date: Date) => {
    return moment.utc(date).format('MM/DD/YYYY');
  };

  const HeaderCellSegment = ({children}) => {
    return (
      <>
        <Segment size="small" color={color} className="header-segment" />
        {children}
      </>
    );
  };

  return (
    <>
      {quotes && quotes.length > 0 ? (
        <Table basic="very" css={styles} selectable sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell
                width={2}
                className="table-header-cell"
                sorted={column === 'quoteId' ? direction : null}
                onClick={() => sortDispatch({column: 'quoteId'})}
              >
                <Divider hidden fitted />
                <Header size="medium">{header}</Header>
                <HeaderCellSegment>Quote ID</HeaderCellSegment>
              </Table.HeaderCell>

              <Table.HeaderCell
                width={4}
                className="table-header-cell"
                sorted={column === 'customerName' ? direction : null}
                onClick={() => sortDispatch({column: 'customerName'})}
              >
                <HeaderCellSegment>Customer</HeaderCellSegment>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={2}
                className="table-header-cell"
                sorted={column === 'facilityStatus' ? direction : null}
                onClick={() => sortDispatch({column: 'facilityStatus'})}
              >
                <HeaderCellSegment>Facility</HeaderCellSegment>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                className="table-header-cell"
                sorted={column === 'quoteStatus' ? direction : null}
                onClick={() => sortDispatch({column: 'quoteStatus'})}
              >
                <HeaderCellSegment>Status</HeaderCellSegment>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={3}
                className="table-header-cell"
                sorted={column === 'assignedUserName' ? direction : null}
                onClick={() => sortDispatch({column: 'assignedUserName'})}
              >
                <HeaderCellSegment>Assignee</HeaderCellSegment>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                className="table-header-cell"
                sorted={column === 'createdDate' ? direction : null}
                onClick={() => sortDispatch({column: 'createdDate'})}
              >
                <HeaderCellSegment>Created</HeaderCellSegment>
              </Table.HeaderCell>
              <Table.HeaderCell
                width={1}
                className="table-header-cell"
                sorted={column === 'modifiedDate' ? direction : null}
                onClick={() => sortDispatch({column: 'modifiedDate'})}
              >
                <HeaderCellSegment>Modified</HeaderCellSegment>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((item: QuoteListingDto, key) => {
              return (
                <Table.Row
                  key={key}
                  warning={item.isArchived}
                  onClick={() => {
                    history.push(
                      buildPath(routes.quotes.detail, {
                        id: item.id,
                        stage: '',
                      }),
                      {hasFilters: true}
                    );
                  }}
                >
                  <Table.Cell>{item.id}</Table.Cell>
                  <Table.Cell>
                    {item.customerId ? (
                      <>
                        {item.customerName}{' '}
                        {item.customerId && <>({item.customerId})</>}
                      </>
                    ) : (
                      <>{item.customerName}</>
                    )}
                  </Table.Cell>
                  <Table.Cell>{item.facilityStatus ?? 'N/A'}</Table.Cell>
                  <Table.Cell>
                    {item.isArchived ? 'Archived' : item.quoteStatus}
                  </Table.Cell>
                  <Table.Cell>
                    {item.assignedUserId ? item.assignedUserName : 'N/A'}
                  </Table.Cell>
                  <Table.Cell>{formatDate(item.createdDate)}</Table.Cell>
                  <Table.Cell>{formatDate(item.modifiedDate)}</Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      ) : (
        <Segment textAlign="center" placeholder>
          <Header>There are currently no quotes in this stage.</Header>
        </Segment>
      )}
    </>
  );
};

const styles = css`
  th {
    background-color: white !important;
    vertical-align: bottom !important;
    position: sticky;
    top: 0;
  }

  .assignee-bubble {
    height: 35px;
    width: 35px;
    display: flex;
    padding: 0;
    border-radius: 30px;
    background: ${MobileaseTheme.colors.blue50};
    color: ${MobileaseTheme.colors.blue500};
    align-items: center;
    justify-content: center;
    line-height: 1.5rem;
  }

  .ui.label {
    background: ${MobileaseTheme.colors.purple50};
    color: ${MobileaseTheme.colors.purple500};
  }

  .ui.sortable.table thead th.sorted:after {
    display: inline !important;
  }

  .header-segment {
    border-radius: 0 !important;
    padding: 1em;
    font-weight: 600;
  }

  .table-header-cell {
    padding: 0em 0em 0.8em 0em !important;
  }
`;
