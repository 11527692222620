import React from 'react';
import {useHistory} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Button, Divider, Modal} from 'semantic-ui-react';
import {QuotesService} from '../api/generated';
import {AsyncStateContainer} from '../components/async-state-container';
import {Form} from '../forms';
import {dropdown, fieldConfig, RawFieldConfig} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

type ChangeAssigneeModalProps = {
  openAssigneeModal: boolean;
  setOpenAssigneeModal: (openAssigneeModal: boolean) => void;
  quoteId: number;
  assignedUserId: number;
};

export const ChangeAssigneeModal: React.FC<ChangeAssigneeModalProps> = ({
  openAssigneeModal,
  setOpenAssigneeModal,
  quoteId,
  assignedUserId,
}) => {
  const history = useHistory();
  const notifications = useNotification();

  const fetchOptions = useAsync(async () => {
    const response = await QuotesService.getAssigneeOptions();
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fields = useFields(options);

  const onSubmit = async (values) => {
    const response = await QuotesService.updateAssignee({
      body: {quoteId: quoteId, assignedUserId: values.assignedUserId},
    });
    if (response.hasErrors) {
      return response;
    }

    notifications.success('Assignee Updated');
    history.push(routes.quotes.index);
    setOpenAssigneeModal(false);
  };

  return (
    <Modal open={openAssigneeModal}>
      <Modal.Header>Change Assignee</Modal.Header>
      <AsyncStateContainer {...fetchOptions}>
        <Modal.Content>
          <Form.Container basic>
            <Form
              onSubmit={onSubmit}
              initialValues={{assignedUserId}}
              render={() => {
                return (
                  <>
                    <FormFields fields={fields} />
                    <Divider />
                    <Button type="submit" primary content="Update" />
                    <Button
                      onClick={() => setOpenAssigneeModal(false)}
                      secondary
                      content="Cancel"
                    />
                  </>
                );
              }}
            />
          </Form.Container>
        </Modal.Content>
      </AsyncStateContainer>
    </Modal>
  );
};

type UpdateAssignedUser = {
  assignedUserId: number;
};

const useFields = (options) => {
  return fieldConfig<UpdateAssignedUser>({
    assignedUserId: dropdown({
      fieldLabel: 'Assignee',
      required: true,
      inputProps: {
        selection: true,
        search: true,
        options: options,
      },
    }),
  });
};

const FormFields: React.FC<{fields: RawFieldConfig<UpdateAssignedUser>}> = ({
  fields,
}) => {
  return (
    <>
      <Form.Row>
        <Form.Dropdown fieldConfig={fields.assignedUserId} />
      </Form.Row>
    </>
  );
};
