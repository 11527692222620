import {MobileaseTheme} from '../../styles/branding';

export const fleetOverviewChartColors = [
  MobileaseTheme.colors.purple400,
  MobileaseTheme.colors.blue300,
  MobileaseTheme.colors.yellow400,
  MobileaseTheme.colors.red400,
  MobileaseTheme.colors.green300,
];
export const legendItemStyles = {
  fontSize: '10pt',
  fontWeight: 'semibold',
  fontFamily: 'Manrope',
};
export const dataLabelStyles = {
  borderRadius: 5,
  backgroundColor: 'rgba(250, 250, 250, 0.6)',
  borderWidth: 1,
  borderColor: '#AAA',
  style: {
    textOutline: '#fff',
    color: '#000',
    textOverflow: 'none',
  },
  enabled: true,
};
export const containerProps = {
  style: {
    height: '15rem',
    width: '100%',
  },
};
