import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {CustomersListing} from './customer-listing';
import {CustomerDetails} from './customer-update';

export const CustomersRoutes = () => {
  return (
    <Switch>
      <Route path={routes.customers.index} exact>
        <CustomersListing />
      </Route>
      <Route path={routes.customers.detail} exact>
        <CustomerDetails />
      </Route>
    </Switch>
  );
};
