import {Options, useToasts} from 'react-toast-notifications';
import {Error} from '../api/generated';

type MessageTypes = 'success' | 'error' | 'info' | 'warning';

const defaultOptions = {
  autoDismiss: true,
  autoDismissTimeout: 5000,
  pauseOnHover: true,
};

export function useNotification() {
  const {addToast} = useToasts();

  type AddToast = Parameters<typeof addToast>;

  const createMessageType =
    (type: MessageTypes) =>
    (
      message: string | React.ReactNode,
      options?: Partial<Options>,
      callback?: AddToast[2]
    ) => {
      addToast(
        message,
        {
          appearance: type,
          ...defaultOptions,
          ...(options || {}),
        },
        callback
      );
    };

  const notifiers = {
    success: createMessageType('success'),
    error: createMessageType('error'),
    info: createMessageType('info'),
    warning: createMessageType('warning'),
  };

  return {
    ...notifiers,
    errors: (errors: Error[]) =>
      notifiers.error(errors.map((x) => x.errorMessage).join(' ')),
  };
}
