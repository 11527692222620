type Colors =
  | 'red'
  | 'yellow'
  | 'grey'
  | 'purple'
  | 'orange'
  | 'green'
  | 'blue';
type Nums =
  | '50'
  | '100'
  | '200'
  | '300'
  | '400'
  | '500'
  | '600'
  | '700'
  | '800'
  | '900';
type ColorName<C extends string, N extends string> = `${C}${N}`;
type ColorMap = {
  [K in ColorName<Colors, Nums>]: string;
} & {white: string};
interface Theme {
  colors: ColorMap;
}

export const MobileaseTheme: Theme = {
  colors: {
    blue50: '#DBE6FB',
    blue100: '#AECBF6',
    blue200: '#80AFF2',
    blue300: '#5394ED',
    blue400: '#3F7CD5',
    blue500: '#2B64BD',
    blue600: '#174CA4',
    blue700: '#03348C',
    blue800: '#032866',
    blue900: '#021B3F',
    red50: '#F4CECE',
    red100: '#EAAAAA',
    red200: '#E08686',
    red300: '#D66262',
    red400: '#CC3E3E',
    red500: '#AE3333',
    red600: '#902828',
    red700: '#711C1C',
    red800: '#531111',
    red900: '#350606',
    yellow50: '#F4F1D9',
    yellow100: '#F0EAB4',
    yellow200: '#EDE48F',
    yellow300: '#E9DD6A',
    yellow400: '#E5D645',
    yellow500: '#C3B638',
    yellow600: '#A1952B',
    yellow700: '#7E751F',
    yellow800: '#5C5412',
    yellow900: '#3A3405',
    green50: '#D2FFE9',
    green100: '#ABF0D0',
    green200: '#84E2B7',
    green300: '#5DD39E',
    green400: '#27B76F',
    green500: '#209E5F',
    green600: '#19844E',
    green700: '#136B3E',
    green800: '#0C512D',
    green900: '#05381D',
    grey50: '#F3F5F8',
    grey100: '#0b0c0c',
    grey200: '#B6BABE',
    grey300: '#989CA0',
    grey400: '#797F83',
    grey500: '#5B6166',
    grey600: '#44494D',
    grey700: '#2E3133',
    grey800: '#17181A',
    grey900: '#000000',
    orange50: '#F5E6D8',
    orange100: '#EBC5AB',
    orange200: '#E2A47E',
    orange300: '#D88352',
    orange400: '#CE6225',
    orange500: '#B1541E',
    orange600: '#944617',
    orange700: '#773911',
    orange800: '#5A2B0A',
    orange900: '#3D1D03',
    purple50: '#DCDCF7',
    purple100: '#BFBEEE',
    purple200: '#A1A1E6',
    purple300: '#8483DD',
    purple400: '#6665D4',
    purple500: '#5352B4',
    purple600: '#403F94',
    purple700: '#2C2C73',
    purple800: '#191953',
    purple900: '#060633',
    white: '#FFFFFF',
  },
};
