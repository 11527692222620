import {css} from '@emotion/core';
import React, {useMemo, useState} from 'react';
import {useForm} from 'react-final-form';
import {Button, Modal} from 'semantic-ui-react';
import {CalculateSalesTaxDto, SalesTaxService} from '../api/generated';
import {Form} from '../forms';
import {fieldConfig, input} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {
  AdditionalParams,
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';

type FilterState = AdditionalParams<typeof SalesTaxService.getSalesTax>;

export const SalesTaxModal = ({setOpenSalesTaxModal, setSalesTax}) => {
  const notifications = useNotification();
  const [filterState, setFilterState] = useProduce<FilterState>({});

  const onSubmit = (values) => {
    setFilterState((x) => {
      x.geoCode = values.geoCode;
      x.postalCode = values.postalCode;
    });
  };

  const TableConfig = useMemo(() => {
    return PagedDataTableConfig(SalesTaxService.getSalesTax, {
      columns: [
        {
          header: 'Location',
          column: 'salestaxName',
          sortable: 'salestaxName',
        },
        {
          header: 'State',
          column: 'salestaxStateName',
          sortable: 'salestaxStateName',
        },
        {
          header: 'County',
          column: 'salestaxCountyName',
          sortable: 'salestaxCountyName',
        },
        {
          header: 'Type',
          column: 'salestaxType',
          sortable: 'salestaxType',
        },
        {
          header: 'Geo Code',
          column: 'salestaxGeoCode',
          sortable: 'salestaxGeoCode',
        },
        {
          header: 'Postal Code',
          render: (x) => {
            return (
              <>
                {x.salestaxPostalCodeBegin === x.salestaxPostalCodeEnd
                  ? x.salestaxPostalCodeBegin
                  : `${x.salestaxPostalCodeBegin} - ${x.salestaxPostalCodeEnd}`}
              </>
            );
          },
          sortable: 'salestaxPostalCodeBegin',
        },
      ],
      defaultSort: {
        column: 'salestaxId',
        direction: 'ASC',
      },
      searchFieldNames: [
        'salestaxId',
        'salestaxName',
        'salestaxStateName',
        'salestaxCountyName',
      ],
      initialPageSize: 10,
      tableProps: {
        selectable: true,
      },
      hideSearch: true,
      noResultsText: 'No results found. Please try the other filter.',
      rowProps: (item) => {
        return {
          onClick: () => {
            calculateSalesTax(item.salestaxId);
            closeSalesTaxModal();
          },
        };
      },
    });
  }, [setOpenSalesTaxModal]);

  const calculateSalesTax = async (salesTaxId) => {
    const response = await SalesTaxService.calculate({
      salesTaxId: salesTaxId,
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return response;
    }
    if (response.data) {
      notifications.success('Sales Tax Calculation Successful');
      setSalesTax(response.data ?? null);
    }
  };

  const pagedDataTable = usePagedDataTable(
    SalesTaxService.getSalesTax,
    TableConfig,
    {
      additionalParams: {
        ...filterState,
      },
    }
  );

  const closeSalesTaxModal = () => {
    setOpenSalesTaxModal(false);
  };

  return (
    <Modal open={true} onClose={closeSalesTaxModal}>
      <Modal.Header>Select Sales Tax</Modal.Header>
      <TableFilters onSubmit={onSubmit} />
      <Modal.Content>
        {pagedDataTable}
        <Button type="button" content="Close" onClick={closeSalesTaxModal} />
      </Modal.Content>
    </Modal>
  );
};

const TableFilters = ({onSubmit}) => {
  return (
    <Form.Container
      basic
      css={css`
        margin: 0 !important;
        padding-bottom: 0 !important;
      `}
    >
      <Form
        onSubmit={(values) => onSubmit(values)}
        render={() => (
          <>
            <Form.Row proportions={[1, 1, 1]}>
              <Form.Input fieldConfig={fields.geoCode} />
              <Form.Input fieldConfig={fields.postalCode} />
            </Form.Row>
            <Form.Button type="submit" primary>
              Search
            </Form.Button>
          </>
        )}
      />
    </Form.Container>
  );
};
const fields = fieldConfig<any>({
  geoCode: input({
    fieldLabel: 'Geo Code',
  }),
  postalCode: input({
    fieldLabel: 'Postal Code',
  }),
});

export const FindSalesTax = ({setSalesTax, disabled}) => {
  const [openSalesTaxModal, setOpenSalesTaxModal] = useState<boolean>(false);
  return (
    <>
      <Button
        type="button"
        content="Find Tax"
        icon="dollar"
        disabled={disabled}
        onClick={() => setOpenSalesTaxModal(true)}
      />
      {openSalesTaxModal && (
        <SalesTaxModal
          setOpenSalesTaxModal={setOpenSalesTaxModal}
          setSalesTax={setSalesTax}
        />
      )}
    </>
  );
};

export const FindSalesTaxFormComponent: React.FC<{disabled?: boolean}> = ({
  disabled,
}) => {
  const {mutators} = useForm();

  const setSalesTax = (salesTaxDto: CalculateSalesTaxDto) => {
    mutators.setTax(salesTaxDto.salesTaxRate);
    mutators.setTaxState(salesTaxDto.stateTaxId);
    mutators.setTaxCounty(salesTaxDto.countyTaxId);
    mutators.setTaxCity(salesTaxDto.cityTaxId);
  };

  return <FindSalesTax setSalesTax={setSalesTax} disabled={disabled} />;
};
