import css from '@emotion/css';
import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {Dropdown, DropdownItemProps} from 'semantic-ui-react';
import {
  CustomerSettingsDto,
  CustomerSettingsService,
  LeaseCompleteCustomerService,
} from '../api/generated';
import {useCustomerContext} from '../customer-context';
import {MobileaseTheme} from '../styles/branding';
import {sessionCustomerIdKey} from '../utils/constants';

export const CustomerContextSelector: React.FC<{
  disabledCustomerSelectorId?: string;
  value?: string;
  onValueChanged?: (value) => void;
}> = ({disabledCustomerSelectorId, onValueChanged, value}) => {
  const [customerContext, setCustomerContext] = useCustomerContext();
  const {currentCustomerId} = customerContext;

  const getCustomers = useAsync(async () => {
    const result = await LeaseCompleteCustomerService.getAllForUser();
    const customers = result?.data;
    const defaultCustomerId = customers?.[0]?.id || '';
    let customerIdToSet = currentCustomerId;
    if (currentCustomerId) {
      const currentCustomerInList = customers?.find(
        (x) => x.id === currentCustomerId
      );
      if (!currentCustomerInList) {
        customerIdToSet = defaultCustomerId;
      }
    } else if (defaultCustomerId) {
      customerIdToSet = defaultCustomerId;
    }

    const settingsResult = await CustomerSettingsService.getForCustomer({
      customerId: currentCustomerId,
    });

    setCustomerContext({
      currentCustomerId: customerIdToSet,
      settings: settingsResult.data as CustomerSettingsDto,
    });

    return result;
  }, [currentCustomerId, setCustomerContext]);

  const linkedCustomers = getCustomers?.value?.data;

  const options =
    linkedCustomers?.map((x) => ({
      key: `${x.id} ${x.name}`,
      text: (
        <div className="text-container">
          <div>{x?.name}</div>
          <div className="customer-number">{x?.id}</div>
        </div>
      ),
      value: x.id,
    })) ?? [];

  const searchCustomers = (options, searchQuery): DropdownItemProps[] => {
    return options?.filter((x) =>
      x.key.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };

  return (
    <div css={styles}>
      {disabledCustomerSelectorId ? (
        <h6>
          {
            linkedCustomers?.find((x) => x.id === disabledCustomerSelectorId)
              ?.name
          }
          {disabledCustomerSelectorId}
        </h6>
      ) : (
        <Dropdown
          className="customer-selector"
          placeholder="Select a Customer..."
          selection
          loading={getCustomers.loading}
          value={value ?? currentCustomerId}
          icon="angle down"
          search={searchCustomers}
          selectOnBlur={false}
          selectOnNavigation={false}
          onChange={(e, data) => {
            setCustomerContext({
              ...customerContext,
              currentCustomerId: data.value as string,
            });
            localStorage.setItem(sessionCustomerIdKey, `${data.value}`);
            onValueChanged && onValueChanged(data.value);
          }}
          options={options ?? []}
        />
      )}
    </div>
  );
};

const styles = css`
  text-align: center;

  .customer-selector {
    .divider.default.text {
      color: ${MobileaseTheme.colors.grey500} !important;
    }

    .divider.text {
      width: 100%;
    }
    .menu.visible {
      width: 550px !important;
    }
    &.ui.selection.dropdown {
      background: ${MobileaseTheme.colors.grey50};
      border: 0;
      font-weight: 500;

      & .selected.item {
        background: rgba(0, 0, 0, 0.1);
      }
    }

    .text-container {
      display: flex;
      font-weight: 600;
      font-size: 1rem;
    }

    .customer-number {
      font-weight: 400;
      color: grey;
      margin-left: 1rem;
    }

    .angle.down.icon {
      color: ${MobileaseTheme.colors.blue400};
      cursor: pointer;
      position: absolute;
      width: auto;
      height: auto;
      line-height: 1.21428571em;
      top: 0.48em;
      right: 0.12em;
      z-index: 3;
      margin: -0.73333333em;
      padding: 0.91666667em;
      opacity: 0.8;
      transition: opacity 0.1s ease;
    }
  }
`;
