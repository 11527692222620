import css from '@emotion/css/macro';
import React from 'react';
import { Header } from 'semantic-ui-react';

type FormSection = {
  title: string | React.ReactNode;
  size?: 'tiny' | 'small' | 'medium' | 'large' | 'huge'
};

export const FormSection: React.FC<FormSection> = ({ title, size = 'medium', children }) => (
  <>
    <Header size={size} className="form-section" css={styles}>
      {title}
    </Header>
    {children}
  </>
);

const styles = css`
  &.ui.header {
    padding-top: 15px;

    &:first-of-type {
      padding-top: 0;
    }
  }
`;
