import css from '@emotion/css';
import React from 'react';
import {faCommentAlt, IconDefinition} from '@fortawesome/pro-solid-svg-icons';
import {CustomerContextSelector} from './dashboard/customer-context-selector';
import {RequestButton} from './dashboard/request-button';
import {RequestTemplateKeys} from './api/generated/enums';
import {useRequestTemplateModal} from './request-templates/request-template-context';
import {useAssetContext} from './assets/asset-context';
import {Dropdown} from 'semantic-ui-react';
import {useMedia} from './styles/breakpoints';
import {Flex} from './components/flex';
import {MobileaseTheme} from './styles/branding';

export type RequestButtonProps = {
  label: string;
  icon: IconDefinition;
  templateKey: RequestTemplateKeys;
};

type SecondaryNavigationProps = {
  disabledCustomerSelectorId?: string;
  navigationRequestButtons?: RequestButtonProps[];
  renderCustomerSelector?: () => React.ReactElement;
  hideRequestButtons?: boolean;
};

export const SecondaryNavigation: React.FC<SecondaryNavigationProps> = ({
  disabledCustomerSelectorId,
  navigationRequestButtons,
  renderCustomerSelector,
  hideRequestButtons = false,
}) => {
  const {openRequestTemplateModal} = useRequestTemplateModal();
  const {asset} = useAssetContext();

  const isDesktopMin = useMedia('DesktopMin'); // FIXME: Place this in a provider

  return (
    <>
      <Flex.Row css={style} justifyContent="space-between">
        <Flex.Col justifyContent="justify-center">
          {renderCustomerSelector ? (
            renderCustomerSelector()
          ) : (
            <CustomerContextSelector
              disabledCustomerSelectorId={disabledCustomerSelectorId}
            />
          )}
        </Flex.Col>
        <Flex.Col justifyContent="justify-center">
          {isDesktopMin ? (
            <div className="button-wrapper">
              {!hideRequestButtons && (
                <>
                  {navigationRequestButtons?.map((button) => {
                    return (
                      <RequestButton
                        as="a"
                        key={button.templateKey}
                        label={button.label}
                        icon={button.icon}
                        templateKey={button.templateKey}
                        onClick={() =>
                          openRequestTemplateModal(
                            button.templateKey,
                            asset?.assetId
                          )
                        }
                      />
                    );
                  })}
                  <RequestButton
                    as="a"
                    label="Contact CSR"
                    icon={faCommentAlt}
                    templateKey={RequestTemplateKeys.ContactCsr}
                    onClick={() =>
                      openRequestTemplateModal(
                        RequestTemplateKeys.ContactCsr,
                        asset?.assetId
                      )
                    }
                  />
                </>
              )}
            </div>
          ) : (
            <Dropdown
              text="Send Request"
              icon="mail"
              button
              labeled
              className="icon mobile-button"
            >
              <Dropdown.Menu>
                {navigationRequestButtons?.map((button) => {
                  return (
                    <Dropdown.Item
                      content={button.label}
                      onClick={() =>
                        openRequestTemplateModal(
                          button.templateKey,
                          asset?.assetId
                        )
                      }
                    />
                  );
                })}
                <Dropdown.Item
                  onClick={() =>
                    openRequestTemplateModal(
                      RequestTemplateKeys.ContactCsr,
                      asset?.assetId
                    )
                  }
                >
                  Contact CSR
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Flex.Col>
      </Flex.Row>
    </>
  );
};

const style = css`
  padding: 0.75rem;
  border-bottom: 1px solid #d8dadc;

  .button-wrapper > * {
    margin-left: 1rem;
  }

  .mobile-button {
    text-align: center;
    font-weight: bold;
    background-color: ${MobileaseTheme.colors.blue50};
    color: ${MobileaseTheme.colors.blue600};
  }
`;
