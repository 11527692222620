import {css} from '@emotion/core';
import moment from 'moment';
import React from 'react';
import {Divider, Segment} from 'semantic-ui-react';
import {CustDashboardDto} from '../api/generated';
import {Flex} from '../components/flex';
import {MobileaseTheme} from '../styles/branding';
import {FinancialSnapShot} from './financial-snapshot';

export const CustomerDetailsCard: React.FC<{
  customer: CustDashboardDto;
  reviewDate;
}> = ({customer, reviewDate}) => {
  return (
    <>
      <Segment css={styles} className="width-100">
        <Flex.Col>
          <Flex.Row>
            <h3>{customer.name}</h3>
          </Flex.Row>
          <Divider />
          <FinancialSnapShot />
          <Divider />
          {/* <ReviewDateAlert reviewDate={reviewDate} /> */}
          <Divider hidden />
          <CustomerDetailRow
            title="Account Executive"
            value={
              <>
                <Flex.Row justifyContent="flex-end">
                  {customer.officerName}
                </Flex.Row>
                <Flex.Row justifyContent="flex-end">
                  {customer.officerEmail}
                </Flex.Row>
                <Flex.Row justifyContent="flex-end">
                  {customer.officerPhone}
                </Flex.Row>
              </>
            }
          />
          <Divider hidden />
          {customer.officerName !== customer.relationOfficerName && (
            <CustomerDetailRow
              title="Customer Service Rep."
              value={
                <>
                  <Flex.Row justifyContent="flex-end">
                    {customer.relationOfficerName}
                  </Flex.Row>
                  <Flex.Row justifyContent="flex-end">
                    {customer.relationOfficerEmail}
                  </Flex.Row>
                  <Flex.Row justifyContent="flex-end">
                    {customer.relationOfficerPhone}
                  </Flex.Row>
                </>
              }
            />
          )}
        </Flex.Col>
      </Segment>
    </>
  );
};

const CustomerDetailRow = ({title, value}) => {
  return (
    <Flex.Row justifyContent="space-between">
      <Flex.Col className="row-title">{title}</Flex.Col>
      <Flex.Col className="row-value">{value ? value : 'N/A'}</Flex.Col>
    </Flex.Row>
  );
};

const ReviewDateAlert: React.FC<{reviewDate?: Date}> = ({reviewDate}) => {
  if (reviewDate == null) {
    return <></>;
  }
  const formattedReviewDate = moment.utc(reviewDate).format('MM/DD/YYYY');
  const now = moment.utc(moment.now()).startOf('day');
  const daysUntilReview = moment
    .duration(moment.utc(reviewDate).diff(now))
    .asDays();

  if (daysUntilReview >= 30) {
    return (
      <Flex.Row css={reviewStatusStyles} justifyContent="space-between">
        <Flex.Col className="row-title">Annual Review Status</Flex.Col>
        <Flex.Col className="row-value completed">
          <Flex.Row justifyContent="flex-end">Review Complete</Flex.Row>
          <Flex.Row justifyContent="flex-end" className="small-text">
            Next Review is {formattedReviewDate}
          </Flex.Row>
        </Flex.Col>
      </Flex.Row>
    );
  } else if (daysUntilReview < 30 && daysUntilReview >= 0) {
    return (
      <Flex.Row css={reviewStatusStyles} justifyContent="space-between">
        <Flex.Col className="row-title">Annual Review Status</Flex.Col>
        <Flex.Col className="row-value warning">
          <Flex.Row justifyContent="flex-end">Annual Review Upcoming</Flex.Row>
          <Flex.Row justifyContent="flex-end">
            Next Review is {formattedReviewDate}
          </Flex.Row>
        </Flex.Col>
      </Flex.Row>
    );
  } else {
    return (
      <Flex.Row css={reviewStatusStyles} justifyContent="space-between">
        <Flex.Col className="row-title">Annual Review Status</Flex.Col>
        <Flex.Col className="row-value danger">
          <Flex.Row justifyContent="flex-end">Annual Review</Flex.Row>
          <Flex.Row justifyContent="flex-end">
            Annual review was {formattedReviewDate}
          </Flex.Row>
        </Flex.Col>
      </Flex.Row>
    );
  }
};

const styles = css`
  padding-bottom: 0 !important;

  .width-100 {
    width: 100%;
  }

  .row-title {
    font-weight: bold;
  }

  .row-value {
    text-align: right;
    font-weight: bold;
    color: ${MobileaseTheme.colors.blue400};
  }
`;

const reviewStatusStyles = css`
  .completed {
    color: ${MobileaseTheme.colors.green500};
  }

  .warning {
    color: ${MobileaseTheme.colors.yellow600};
  }

  .danger {
    color: ${MobileaseTheme.colors.orange500};
  }

  .small-text {
    font-size: 0.85rem;
  }
`;
