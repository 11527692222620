import React from 'react';
import {StrictFormTextAreaProps, TextArea} from 'semantic-ui-react';

export type TextAreaAdapterProps = StrictFormTextAreaProps;

export const TextAreaAdapter = ({input, meta, ...rest}) => {
  return (
    <TextArea
      rows={4}
      {...input}
      {...rest}
      onChange={(event, {value}) => input.onChange(value)}
    />
  );
};
