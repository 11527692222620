import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {UserCreate, UserDetails} from './user-create-update';
import {UserListing} from './user-listing';
import {routes} from '../routes/index';

export const UserRoutes = () => {
  return (
    <Switch>
      <Route path={routes.users.index} exact>
        <UserListing />
      </Route>
      <Route path={routes.users.create} exact>
        <UserCreate />
      </Route>
      <Route path={routes.users.detail}>
        <UserDetails />
      </Route>
    </Switch>
  );
};
