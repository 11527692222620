import css from '@emotion/css';
import {useAsync} from 'react-use';
import {LeaseCompleteBillingHistoryService} from '../api/generated';
import React, {useEffect, useMemo} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {Link, useHistory} from 'react-router-dom';
import {Label, Segment} from 'semantic-ui-react';
import {BillTypes, ExportTypes} from '../api/generated/enums';
import {buildApiUrl, getEnumDropdownOptions} from '../api/generated/utils';
import {currencyFormatter, numberFormatter} from '../assets/asset-utils';
import {DateFormat} from '../components/date';
import {Flex} from '../components/flex';
import {useCustomerContext} from '../customer-context';
import {Form} from '../forms';
import {
  datepicker,
  dropdown,
  fieldConfig,
  getDefaults,
} from '../forms/schema-utils';
import {BlueFilterFieldStyles} from '../forms/styles';
import {
  AdditionalParams,
  PagedDataTableConfig,
  PagedDataTableStyle,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {formatCurrencyWithSymbols} from '../utils/currency-helpers';
import {buildPath} from '../routes/utils';
import {routes} from '../routes';
import {useRequireAuthorization} from '../auth/require-authorization';
import {ExportButton} from '../export/export';
import moment from 'moment';
import {FormFiltersAppliedCount} from '../forms/form-filters-applied-count';

type FilterState = AdditionalParams<
  typeof LeaseCompleteBillingHistoryService.getTable
>;

export type ChargesListingQueryParams = {
  billType: string;
  dueDateStart: Date;
  dueDateEnd: Date;
};

export const ChargesListing = () => {
  const [{currentCustomerId}] = useCustomerContext();
  const history = useHistory<FilterState>();
  const initialFilterState: FilterState = useMemo(() => {
    return history.location.state;
  }, [history.location.state]);
  const [filterState, setFilterState] = useProduce<FilterState>(
    initialFilterState || {
      dueDateStart: moment().startOf('M').format(),
      dueDateEnd: moment().endOf('M').format(),
    }
  );
  const canViewAssets = useRequireAuthorization({permission: 'Assets: View'});

  const summary = useAsync(async () => {
    const response =
      await LeaseCompleteBillingHistoryService.getFinancialSummary({
        ...filterState,
        customerId: currentCustomerId,
      });
    return response.data;
  }, [filterState, currentCustomerId]);

  const tableConfig = useMemo(() => {
    return TableConfig(canViewAssets);
  }, [canViewAssets]);

  const exportUrl = useMemo(
    () =>
      buildApiUrl('/api/billing-history/export', {
        FilterParams: {
          ...filterState,
          customerId: currentCustomerId,
        },
      }),
    [currentCustomerId, filterState]
  );

  const dataTable = usePagedDataTable(
    LeaseCompleteBillingHistoryService.getTable,
    tableConfig,
    {
      additionalParams: {
        ...filterState,
        customerId: currentCustomerId,
      },
      actions: (
        <div
          css={css`
            margin-bottom: 1rem;
          `}
        >
          <ExportButton
            title="Export Charges Report"
            exportUrl={exportUrl}
            exportType={ExportTypes.Charges}
          />
        </div>
      ),
    }
  );

  return (
    <div css={styles}>
      <Flex.Row className="listing-wrapper">
        <Flex.Col flex={1} className="filter-wrapper">
          <ChargesListingsSummary
            loading={summary.loading}
            totalAmount={summary.value?.totalAmount}
            totalCharges={summary.value?.totalCharges}
          />
          <TableFilters
            onApply={(filters) => {
              setFilterState((draft) => {
                draft.billType = filters.billType;
                draft.dueDateStart = filters.dueDateStart;
                draft.dueDateEnd = filters.dueDateEnd;
                draft.satisfiedDateStart = filters.satisfiedDateStart;
                draft.satisfiedDateEnd = filters.satisfiedDateEnd;
              });
            }}
            initialValues={{...filterState}}
          />
        </Flex.Col>
        <Flex.Col flex={4} css={PagedDataTableStyle}>
          <div className="table-wrapper">{dataTable}</div>
        </Flex.Col>
      </Flex.Row>
    </div>
  );
};

const TableFilters: React.FC<{
  onApply: any;
  initialValues: FilterState;
}> = ({onApply, initialValues}) => {
  return (
    <div
      css={css`
        height: 100%;
        .filter-list {
          padding: 0;
        }
      `}
    >
      <Segment className="filter-list">
        <Form.Container basic>
          <Form
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            initialValues={initialValues || getDefaults(fields)}
            disableLoading
            onSubmit={(values) => {
              onApply(values);
            }}
            render={() => (
              <div css={BlueFilterFieldStyles}>
                <FormFields />
              </div>
            )}
          />
        </Form.Container>
      </Segment>
    </div>
  );
};

const FormFields = () => {
  const history = useHistory();
  const form = useForm();
  const {values} = useFormState();

  useEffect(() => {
    if (values) {
      history.push(routes.financials.charges, values);
      form.submit();
    }
  }, [form, history, values]);

  return (
    <>
      <FormFiltersAppliedCount />
      <Form.Dropdown fieldConfig={fields.billType} />
      <Form.DatePicker fieldConfig={fields.dueDateStart} />
      <Form.DatePicker fieldConfig={fields.dueDateEnd} />
      <Form.DatePicker fieldConfig={fields.satisfiedDateStart} />
      <Form.DatePicker fieldConfig={fields.satisfiedDateEnd} />
    </>
  );
};

const ChargesListingsSummary: React.FC<{
  loading: boolean;
  totalCharges?: number;
  totalAmount?: number;
}> = ({loading, totalAmount, totalCharges}) => (
  <Segment loading={loading}>
    <Flex.Row justifyContent="space-between">
      <strong>Number of Charges </strong>
      {totalCharges && numberFormatter(totalCharges)}
    </Flex.Row>
    <Flex.Row justifyContent="space-between">
      <strong>Total </strong>
      {totalAmount && currencyFormatter(totalAmount)}
    </Flex.Row>
  </Segment>
);

const fields = fieldConfig<any>({
  billType: dropdown({
    fieldLabel: 'Bill Type',
    inputProps: {
      options: getEnumDropdownOptions(BillTypes),
      selection: true,
      clearable: true,
    },
  }),
  dueDateStart: datepicker({
    fieldLabel: 'Due Date Start',
    inputProps: {
      showClearDate: true,
      placeholder: '',
    },
  }),
  dueDateEnd: datepicker({
    fieldLabel: 'Due Date End',
    inputProps: {
      showClearDate: true,
      placeholder: '',
    },
  }),
  satisfiedDateStart: datepicker({
    fieldLabel: 'Satisfied Date Start',
    inputProps: {
      showClearDate: true,
      placeholder: '',
    },
  }),
  satisfiedDateEnd: datepicker({
    fieldLabel: 'Satisfied Date End',
    inputProps: {
      showClearDate: true,
      placeholder: '',
    },
  }),
});

const TableConfig = (canViewAssets) =>
  PagedDataTableConfig(LeaseCompleteBillingHistoryService.getTable, {
    columns: [
      {
        header: 'Bill Type',
        render: (item) => <Label content={item.billType} />,
      },
      {
        header: 'Asset',
        render: (item) => (
          <>
            {canViewAssets ? (
              <Link
                to={{
                  pathname: buildPath(routes.assets.detail, {id: item.assetId}),
                  state: {hasFilters: true},
                }}
              >
                {item.assetId}
              </Link>
            ) : (
              <>{item.assetId}</>
            )}
          </>
        ),
        sortable: 'assetId',
      },
      {
        header: 'Description',
        render: (item) => <>{item.description} </>,
        sortable: 'description',
      },
      {
        header: 'Due Date',
        render: (item) => (
          <>
            <DateFormat date={item.dueDate} />
          </>
        ),
        sortable: 'dueDate',
      },
      {
        header: 'Total Billed',
        render: (item) => <>{formatCurrencyWithSymbols(item.amount)}</>,
        sortable: 'amount',
      },
      {
        header: 'Satisfied Date',
        render: (item) => (
          <>
            {item.satisfiedDate ? <DateFormat date={item.satisfiedDate} /> : ''}
          </>
        ),
        sortable: 'satisfiedDate',
      },
    ],
    defaultSort: {
      column: 'dueDate',
      direction: 'DESC',
    },
    hideSearch: true,
    searchFieldNames: [],
  });

const styles = css`
  .listing-wrapper {
    padding: 1rem;
  }

  .filter-wrapper {
    padding: 0rem 1rem;
    .filter-list {
      padding: 0;
    }
  }

  .table-wrapper {
    padding: 0rem 1rem;
  }
`;
