import css from '@emotion/css';
import React, {useState} from 'react';
import {Divider, Label, Segment} from 'semantic-ui-react';
import {AssetDto, AddressDto} from '../../api/generated';
import {AssetTypes, RequestTemplateKeys} from '../../api/generated/enums';
import {Flex} from '../../components/flex';
import {useCustomerContext} from '../../customer-context';
import {
  AssetDetailRow,
  EditableAssetDetailRow,
  NO_VALUE_PLACEHOLDER,
} from '../../dashboard/dashboard-components';
import {useRequestTemplateModal} from '../../request-templates/request-template-context';
import {MobileaseTheme} from '../../styles/branding';
import {RegistrationLabel} from '../asset-labels';
import {AssetUsageHistoryCreateButton} from '../asset-usage-history/asset-usage-history-create-button';
import {AssetUsageHistoryModal} from '../asset-usage-history/asset-usage-history-modal';

import {numberFormatter} from '../asset-utils';

export const AssetDetailCard: React.FC<{
  asset: AssetDto;
  onAssetUpdated: () => void;
}> = ({asset, onAssetUpdated}) => {
  const {openRequestTemplateModal} = useRequestTemplateModal();
  const [customerContext] = useCustomerContext();
  const {settings} = customerContext;

  const [showingUsageHistory, setShowingUsageHistory] = useState(false);

  const {assetUsageBeginUnits, assetAddress} = asset;

  return (
    <Segment css={styles} className="width-100">
      <Flex.Col>
        <AssetDetailRow title="Asset #" value={asset.assetId} />
        <AssetDetailRow
          title="Asset Type"
          value={asset.assetType ? <Label content={asset.assetType} /> : 'N/A'}
        />
        <AssetDetailRow
          title="Plate"
          value={`${asset.assetLicenseState}-${asset.assetLicenseNbr}`}
        />
        <AssetDetailRow
          title="Registration Renewal"
          value={<RegistrationLabel assetDto={asset} />}
        />
        <AssetDetailRow
          title="Insurance Policy"
          value={asset.assetLease?.currentPolicy?.inspolPolicyNumber}
        />
        <Divider />
        {settings.displayUnitNumber && (
          <EditableAssetDetailRow
            title="Unit #"
            value={asset.uAssetsCustInternalCode}
            onAssetUpdated={onAssetUpdated}
            fieldName="unit"
          />
        )}
        {settings.displayBranchDivision && (
          <EditableAssetDetailRow
            title="Branch/Division"
            value={asset.assetAddress.addrContact1Name}
            onAssetUpdated={onAssetUpdated}
            fieldName="division"
          />
        )}
        {settings.displayDriver && (
          <EditableAssetDetailRow
            title="Driver"
            value={asset.uAssetsDriverName1}
            onAssetUpdated={onAssetUpdated}
            fieldName="driverName"
          />
        )}

        <>
          <Divider hidden />
          <AssetDetailRow
            title={
              asset.assetType === AssetTypes.Equipment ? 'Hours' : 'Mileage'
            }
            value={
              <>
                <Flex.Row justifyContent="flex-end">
                  {numberFormatter(assetUsageBeginUnits)}
                  <AssetUsageHistoryCreateButton
                    assetId={asset.assetId}
                    onUsageHistoryUpdated={() => {
                      onAssetUpdated();
                    }}
                    assetType={asset.assetType as AssetTypes}
                  />
                </Flex.Row>
                <Flex.Row>
                  <a
                    className="purple-text-button"
                    onClick={() => setShowingUsageHistory(true)}
                  >
                    View History
                  </a>
                </Flex.Row>
                {showingUsageHistory && (
                  <AssetUsageHistoryModal
                    open={true}
                    assetId={asset.assetId}
                    onClose={() => {
                      setShowingUsageHistory(false);
                    }}
                    size="small"
                    usageHistoryUpdated={() => {
                      onAssetUpdated();
                    }}
                    assetType={asset.assetType as AssetTypes}
                  />
                )}
              </>
            }
          />
        </>

        <Divider hidden />
        <AssetDetailRow
          title="Garage Address"
          value={
            <GarageDetail
              assetAddress={assetAddress}
              onChangeRequested={() => {
                openRequestTemplateModal(
                  RequestTemplateKeys.ChangeGarageAddress,
                  asset.assetId
                );
              }}
            />
          }
        />
      </Flex.Col>
    </Segment>
  );
};

const GarageDetail: React.FC<{
  assetAddress: AddressDto;
  onChangeRequested: () => void;
}> = ({assetAddress, onChangeRequested}) => {
  const {addrAddress1, addrAddress2, addrCity, addrState, addrPostalCode} =
    assetAddress;
  const addressExists =
    addrAddress1 || addrAddress2 || addrCity || addrState || addrPostalCode;

  return (
    <>
      {addressExists ? (
        <>
          <Flex.Row justifyContent="flex-end">
            {addrAddress1 ? addrAddress1 : NO_VALUE_PLACEHOLDER}
          </Flex.Row>
          <Flex.Row justifyContent="flex-end">
            {addrAddress2 && <>{addrAddress2}</>}
          </Flex.Row>
          <Flex.Row justifyContent="flex-end">
            {`${addrCity ? addrCity : NO_VALUE_PLACEHOLDER}, ${
              addrState ? addrState : NO_VALUE_PLACEHOLDER
            } ${addrPostalCode ? addrPostalCode : NO_VALUE_PLACEHOLDER}`}
          </Flex.Row>
        </>
      ) : (
        <Flex.Row justifyContent="flex-end">{NO_VALUE_PLACEHOLDER}</Flex.Row>
      )}
      <Flex.Row justifyContent="flex-end">
        <a className="purple-text-button" onClick={() => onChangeRequested()}>
          Request Change
        </a>
      </Flex.Row>
    </>
  );
};

const styles = css`
  height: 100%;
  padding-bottom: 0 !important;

  .width-100 {
    width: 100%;
  }

  .purple-text-button {
    cursor: pointer;
    color: ${MobileaseTheme.colors.purple500};
  }
`;
