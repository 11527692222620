import css from '@emotion/css';
import React, {useEffect, useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useDebounce} from 'react-use';
import {Button, Icon, Input, Label, Segment} from 'semantic-ui-react';
import {
  AssetListingsDto,
  CustomerSettingsDto,
  LeaseCompleteAssetsService,
} from '../api/generated';
import {
  AssetFilters,
  AssetMaintenanceFilters,
  AssetTypes,
  AssetStatuses,
  ExportTypes,
} from '../api/generated/enums';
import {DateFormat} from '../components/date';
import {useCustomerContext} from '../customer-context';
import {
  AdditionalParams,
  PagedDataTableConfig,
  PagedDataTableStyle,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';
import {SecondaryNavigation} from '../secondary-navigation';
import {ExportButton} from '../export/export';
import {LeaseLabel, RegistrationLabel} from './asset-labels';
import {MaintenanceStatusIcon} from './icons';
import {Form} from '../forms';
import {dropdown, fieldConfig, getDefaults} from '../forms/schema-utils';
import {BlueFilterFieldStyles} from '../forms/styles';
import {buildApiUrl, getEnumDropdownOptions} from '../api/generated/utils';
import {MobileaseTheme} from '../styles/branding';
import {useForm, useFormState} from 'react-final-form';
import {Flex} from '../components/flex';
import {FormFiltersAppliedCount} from '../forms/form-filters-applied-count';
import {CustomerContextSelector} from '../dashboard/customer-context-selector';

export type AssetFilterState = Omit<
  AdditionalParams<
    typeof LeaseCompleteAssetsService.getAllLeaseCompleteAssetsByCustomerId
  >,
  'customerId'
> & {bypassCustomerContext?: boolean};

export const getSettingsDependentSearchableAssetFields = (
  settings: Partial<CustomerSettingsDto>
) => {
  const fields: Array<keyof AssetListingsDto> = [];
  if (settings.displayBranchDivision) {
    fields.push('branchDivision');
  }
  if (settings.displayUnitNumber) {
    fields.push('assetUnitNumber');
  }
  if (settings.displayDriver) {
    fields.push('driverName');
  }
  return fields;
};

export const AssetsListing = () => {
  const history = useHistory<AssetFilterState>();
  const [{currentCustomerId: contextCustomerId, settings}] =
    useCustomerContext();

  const settingsDependentSearchableFields = useMemo(() => {
    return getSettingsDependentSearchableAssetFields(settings);
  }, [settings]);

  const initialFilterState: AssetFilterState = useMemo(() => {
    return history.location.state;
  }, [history.location.state]);
  const [bypassCustomerContext, setBypassCustomerContext] = useState(
    !!initialFilterState?.bypassCustomerContext
  );

  const [filterState, setFilterState] = useProduce<AssetFilterState>(
    initialFilterState || {}
  );

  const [searchText, setSearchText] = useState('');
  const tableConfig = useMemo(() => {
    let config = {
      ...pagedTableConfig(history, settings, settingsDependentSearchableFields),
    };
    return config;
  }, [history, settings, settingsDependentSearchableFields]);

  useDebounce(
    () => {
      setSearchText(searchText);
    },
    500,
    [searchText]
  );

  const exportUrl = useMemo(
    () =>
      buildApiUrl('/api/lease-complete-assets/export', {
        FilterParams: {
          ...filterState,
        },
        customerId: bypassCustomerContext ? '' : contextCustomerId,
        Search: {
          SearchText: searchText,
          ExactMatch: false,
          FieldNames: [
            'assetDescription',
            'leaseNumber',
            'plateNumber',
            'serialNumber',
          ],
        },
      }),
    [contextCustomerId, filterState, searchText, bypassCustomerContext]
  );

  const registrationExportUrl = useMemo(
    () =>
      buildApiUrl('/api/lease-complete-assets/registration-exception-report', {
        customerId: bypassCustomerContext ? '' : contextCustomerId,
      }),
    [contextCustomerId, bypassCustomerContext]
  );

  const dataTable = usePagedDataTable(
    LeaseCompleteAssetsService.getAllLeaseCompleteAssetsByCustomerId,
    tableConfig,
    {
      additionalParams: {
        searchSearchText: searchText,
        ...filterState,
        customerId: bypassCustomerContext ? '' : contextCustomerId,
      } as Parameters<
        typeof LeaseCompleteAssetsService.getAllLeaseCompleteAssetsByCustomerId
      >[0],
      actions: (
        <Flex.Row>
          <Button
            onClick={() => window.open(registrationExportUrl, '_blank')}
            className="export-button"
          >
            Export Registration Report
          </Button>
          <ExportButton
            title="Export Inventory Report"
            exportUrl={exportUrl}
            exportType={ExportTypes.Asset}
            customerId={bypassCustomerContext ? '' : contextCustomerId}
          />
        </Flex.Row>
      ),
    }
  );
  return (
    <>
      <SecondaryNavigation
        renderCustomerSelector={
          bypassCustomerContext
            ? () => (
                <CustomerContextSelector
                  onValueChanged={() => {
                    setBypassCustomerContext(false);
                  }}
                  value={''}
                />
              )
            : undefined
        }
        hideRequestButtons={bypassCustomerContext}
      />
      <div css={styles}>
        <Flex.Row className="listing-wrapper">
          <Flex.Col flex={1} className="filter-wrapper">
            <TableFilters
              onSearchChanged={setSearchText}
              searchText={searchText}
              onApply={(filters) => {
                setFilterState((draft) => {
                  draft.assetType = filters.assetType;
                  draft.assetLeaseExpirationStatus =
                    filters.assetLeaseExpirationStatus;
                  draft.assetRegistrationStatus =
                    filters.assetRegistrationStatus;
                  draft.assetStatus = filters.assetStatus;
                  draft.maintenancePlan = filters.maintenancePlan;
                });
              }}
              initialValues={{...filterState}}
            />
          </Flex.Col>
          <Flex.Col flex={4} css={PagedDataTableStyle}>
            <div className="table-wrapper">{dataTable}</div>
          </Flex.Col>
        </Flex.Row>
      </div>
    </>
  );
};

const pagedTableConfig = (
  history,
  settings,
  settingsDependentSearchableFields
) =>
  PagedDataTableConfig<AssetListingsDto>(
    LeaseCompleteAssetsService.getAllLeaseCompleteAssetsByCustomerId,
    {
      columns: [
        {
          header: 'Asset #',
          render: (item) => (
            <>
              <div>{item.id}</div>
              {settings.displayUnitNumber && item.assetUnitNumber && (
                <p>Unit: {item.assetUnitNumber}</p>
              )}
            </>
          ),
          sortable: 'id',
        },
        {
          header: 'Description',
          render: (item) => <AssetDescription item={item} />,
          sortable: 'description',
        },
        {
          header: 'Type',
          render: (item) => (item.type ? <Label content={item.type} /> : 'N/A'),
          sortable: 'type',
        },
        {
          header: 'Contract Date',
          render: (item) =>
            item.contractDate ? <DateFormat date={item.contractDate} /> : 'N/A',
          sortable: 'contractDate',
          cellProps: {
            textAlign: 'center',
          },
        },
        {
          header: 'Termination Date',
          render: (item) => (
            <LeaseLabel
              terminationDate={
                item.terminationDate ?? item.scheduledTerminationDate
              }
              isLeaseExpiring={item.isLeaseExpiring}
              isLeaseExpired={item.isLeaseExpired}
            />
          ),
          sortable: 'terminationDate',
          cellProps: {
            textAlign: 'center',
          },
        },
        {
          header: 'Registration Renewal',
          render: (item) => <RegistrationLabel assetDto={item} />,
          sortable: 'assetLicenseExpDate',
          cellProps: {
            textAlign: 'center',
          },
        },
        {
          header: 'Status',
          render: (item) => <Label content={item.status} />,
        },
        {
          header: '',
          render: (item) => (
            <>{item.isUnderMaintenancePlan && <MaintenanceStatusIcon />}</>
          ),
          cellProps: {
            collapsing: true,
          },
        },
      ],
      searchFieldNames: [
        'description',
        'id',
        'plateNumber',
        'serialNumber',
        ...settingsDependentSearchableFields,
      ],
      defaultSort: {column: 'id', direction: 'ASC'},
      tableProps: {selectable: true},
      hideSearch: true,
      rowProps: (item) => {
        return {
          onClick: () => {
            history.push(
              buildPath(routes.assets.detail, {
                id: item.id,
              }),
              {hasFilters: true}
            );
          },
          verticalAlign: 'top',
        };
      },
    }
  );

const AssetDescription: React.FC<{
  item: AssetListingsDto;
}> = ({item}) => {
  const {description, plateNumber, serialNumber, assetStateAbbreviation} = item;
  const icon = <Icon name="circle" />;
  return (
    <div>
      <div className="header">{description ? description : 'N/A'}</div>
      <div className="sub-header">
        {assetStateAbbreviation && `${assetStateAbbreviation}-`}
        {plateNumber} {plateNumber && icon} {serialNumber}
      </div>
    </div>
  );
};

const TableFilters: React.FC<{
  onSearchChanged: (newSearchText: string) => void;
  searchText: string;
  onApply: any;
  initialValues: AssetFilterState;
}> = ({onSearchChanged, searchText, onApply, initialValues}) => {
  return (
    <div
      css={css`
        height: 100%;
        .filter-list {
          padding: 0;
        }
      `}
    >
      <Segment className="search-container">
        <Input
          onChange={(e, data) => {
            onSearchChanged(data.value);
          }}
          iconPosition="left"
          icon="search"
          placeholder="Search Assets"
          value={searchText}
        />
      </Segment>
      <Segment className="filter-list">
        <Form.Container basic>
          <Form
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            initialValues={initialValues || getDefaults(fields)}
            disableLoading
            onSubmit={(values) => {
              onApply(values);
            }}
            render={() => (
              <div css={BlueFilterFieldStyles}>
                <FormFields />
              </div>
            )}
          />
        </Form.Container>
      </Segment>
    </div>
  );
};

const FormFields = () => {
  const history = useHistory<AssetFilterState>();
  const form = useForm();
  const {values} = useFormState();

  useEffect(() => {
    if (values) {
      history.push(routes.assets.index, values);
      form.submit();
    }
  }, [form, history, values]);

  return (
    <>
      <FormFiltersAppliedCount excludeFilters={['bypassCustomerContext']} />
      <Form.Dropdown fieldConfig={fields.assetStatus} />
      <Form.Dropdown fieldConfig={fields.assetType} />
      <Form.Dropdown fieldConfig={fields.assetLeaseExpirationStatus} />
      <Form.Dropdown fieldConfig={fields.assetRegistrationStatus} />
      <Form.Dropdown fieldConfig={fields.maintenancePlan} />
    </>
  );
};

const fields = fieldConfig<any>({
  assetStatus: dropdown({
    fieldLabel: 'Status',
    inputProps: {
      options: getEnumDropdownOptions(AssetStatuses),
      selection: true,
      clearable: true,
    },
  }),
  assetType: dropdown({
    fieldLabel: 'Asset Type',
    inputProps: {
      options: getEnumDropdownOptions(AssetTypes),
      selection: true,
      clearable: true,
    },
  }),
  assetLeaseExpirationStatus: dropdown({
    fieldLabel: 'Lease Expiration',
    inputProps: {
      options: getEnumDropdownOptions(AssetFilters),
      selection: true,
      clearable: true,
    },
  }),
  assetRegistrationStatus: dropdown({
    fieldLabel: 'Registration',
    inputProps: {
      options: getEnumDropdownOptions(AssetFilters),
      selection: true,
      clearable: true,
    },
  }),
  maintenancePlan: dropdown({
    fieldLabel: 'Maintenance Status',
    inputProps: {
      options: getEnumDropdownOptions(AssetMaintenanceFilters),
      selection: true,
      clearable: true,
    },
  }),
});

const styles = css`
  .listing-wrapper {
    padding: 1rem;
  }

  .filter-wrapper {
    padding: 0rem 1rem;
    .filter-list {
      padding: 0;
    }
  }

  .table-wrapper {
    padding: 0rem 1rem;
  }

  .export-button {
    margin-bottom: 1rem;
  }

  .search-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .ui.input {
      width: 100%;
    }

    .ui.input > input {
      background: ${MobileaseTheme.colors.blue50};
      border: none;
      width: 100%;

      ::placeholder {
        color: ${MobileaseTheme.colors.grey500} !important;
      }
    }

    i.icon {
      color: ${MobileaseTheme.colors.blue500};
    }
  }
`;
