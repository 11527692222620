import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {CustomerDashboard} from './customer-dashboard';
import {AdminDashboard} from './admin-dashboard';

export const DashboardRoutes = () => {
  return (
    <>
      <Switch>
        <Route path={routes.dashboard.admin} exact>
          <AdminDashboard />
        </Route>
        <Route path={routes.dashboard.index} exact>
          <CustomerDashboard />
        </Route>
      </Switch>
    </>
  );
};
