import css from '@emotion/css/macro';
import {lighten} from 'polished';
import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {Dropdown, Image, Menu, Modal} from 'semantic-ui-react';
import {userManager} from './auth';
import {ChangePassword} from './auth/change-password';
import {
  RequireAuthorization,
  useRequireAuthorization,
} from './auth/require-authorization';
import logo from './resources/MobileasePNG.png';
import {routes} from './routes';
import {NavSearch} from './search/search';
import {themeColors} from './styles';
import {MobileaseTheme} from './styles/branding';
import {Media, useMedia} from './styles/breakpoints';
import {User} from './types';
import {UpdatePhoneNumberModal} from './users/update-phone-number-modal';
import {notify, useSubscription} from './hooks/use-subscription';

type PrimaryNavigation = {
  user: User;
};

export const PrimaryNavigation: React.FC<PrimaryNavigation> = ({user}) => {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const [showUpdatePhoneNumber, setShowUpdatePhoneNumber] = useState(false);
  const isDesktopMin = useMedia('DesktopMin'); // FIXME: Place this in a provider

  useSubscription('change-phone-number-requested', () => {
    setShowUpdatePhoneNumber(true);
  });

  return (
    <>
      <Menu secondary className="top-navigation" css={styles}>
        <Image
          as={Link}
          to={routes.dashboard.index}
          src={logo}
          alt="Mobilease logo"
          className="logo"
        />
        {isDesktopMin && (
          <Menu.Item>
            <NavSearch />
          </Menu.Item>
        )}
        {isDesktopMin && <DesktopNavigation />}
        <Menu.Menu position="right">
          <Dropdown
            item
            className="user-icon"
            trigger={
              <span className="user-icon-initial" title={user.name}>
                {user.name
                  .split(' ')
                  .map((x) => x.substring(0, 1).toUpperCase())
                  .join('')}
              </span>
            }
            icon={null}
          >
            <Dropdown.Menu>
              {!isDesktopMin && <MobileNavigation />}
              <Dropdown.Item onClick={() => setShowChangePassword(true)}>
                Change Password
              </Dropdown.Item>
              <Dropdown.Item onClick={() => setShowUpdatePhoneNumber(true)}>
                Update Phone Number
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  userManager.signoutRedirect();
                }}
              >
                Sign Out
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Menu>
      </Menu>

      <Modal
        size="small"
        open={showChangePassword}
        onClose={() => setShowChangePassword(false)}
      >
        <Modal.Header>Change Password</Modal.Header>
        <Modal.Content>
          <ChangePassword
            onComplete={() => {
              setShowChangePassword(false);
            }}
          />
        </Modal.Content>
      </Modal>
      <Modal
        size="small"
        open={showUpdatePhoneNumber}
        onClose={() => setShowUpdatePhoneNumber(false)}
      >
        <Modal.Header>Update Phone Number</Modal.Header>
        <Modal.Content>
          <UpdatePhoneNumberModal
            onComplete={() => {
              setShowUpdatePhoneNumber(false);
              notify('phone-number-changed', undefined);
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const DesktopNavigation = () => {
  const isMobileaseAdmin = useRequireAuthorization({
    role: ['Mobilease Admin'],
  });
  const isCustomerAdmin = useRequireAuthorization({
    role: ['Customer Admin'],
  });
  return (
    <Menu
      secondary
      role="navigation"
      className="desktop-navigation"
      size="large"
    >
      {isMobileaseAdmin || isCustomerAdmin ? (
        <Dropdown item trigger={<span>Dashboard</span>}>
          <Dropdown.Menu>
            <Menu.Item as={NavLink} to={routes.dashboard.index} exact>
              Customer Dashboard
            </Menu.Item>
            <Menu.Item as={NavLink} exact to={routes.dashboard.admin}>
              {isCustomerAdmin ? 'Admin' : 'Manager'} Dashboard
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Menu.Item as={NavLink} to={routes.dashboard.index}>
          DASHBOARD
        </Menu.Item>
      )}

      <RequireAuthorization permission="Assets: View">
        <Menu.Item as={NavLink} to={routes.assets.index}>
          ASSETS
        </Menu.Item>
      </RequireAuthorization>
      <RequireAuthorization permission="Financial: View">
        <Menu.Item as={NavLink} to={routes.financials.index}>
          FINANCIAL
        </Menu.Item>
      </RequireAuthorization>
      <RequireAuthorization
        permission={['Quotes: Administrator', 'Quotes: User']}
      >
        <Dropdown item trigger={<span>Quoting</span>}>
          <Dropdown.Menu>
            <RequireAuthorization
              permission={['Quotes: Administrator', 'Quotes: User']}
            >
              <Menu.Item as={NavLink} to={routes.quotes.index}>
                Quotes
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization
              permission={['Quotes: Administrator', 'Quotes: User']}
            >
              <Menu.Item as={NavLink} to={routes.quoteTermTemplates.index}>
                Quote Term Templates
              </Menu.Item>
            </RequireAuthorization>
          </Dropdown.Menu>
        </Dropdown>
      </RequireAuthorization>
      <RequireAuthorization
        permission={[
          'Users: View & Manage',
          'Customers: View & Manage',
          'Invoices: View & Manage',
          'Request Templates: View & Manage',
          'Settings: View & Manage',
        ]}
      >
        <Dropdown item trigger={<span>Manage</span>}>
          <Dropdown.Menu>
            <RequireAuthorization permission="Users: View & Manage">
              <Menu.Item as={NavLink} to={routes.users.index}>
                Users
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Customers: View & Manage">
              <Menu.Item as={NavLink} to={routes.customers.index}>
                Customers
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Invoices: View & Manage">
              <Menu.Item as={NavLink} to={routes.invoices.index}>
                Invoices
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Request Templates: View & Manage">
              <Menu.Item as={NavLink} to={routes.requestTemplates.index}>
                Request Templates
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Settings: View & Manage">
              <Menu.Item as={NavLink} to={routes.settings.index}>
                Settings
              </Menu.Item>
            </RequireAuthorization>
          </Dropdown.Menu>
        </Dropdown>
      </RequireAuthorization>
    </Menu>
  );
};

const MobileNavigation = () => {
  const isMobileaseAdmin = useRequireAuthorization({role: 'Mobilease Admin'});
  return (
    <>
      {isMobileaseAdmin ? (
        <Dropdown item trigger={<span>Dashboard</span>}>
          <Dropdown.Menu>
            <Menu.Item as={NavLink} to={routes.dashboard.index}>
              Customer Dashboard
            </Menu.Item>
            <Menu.Item as={NavLink} to={routes.dashboard.admin}>
              Manager Dashboard
            </Menu.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
        <Menu.Item as={NavLink} to={routes.dashboard.index}>
          DASHBOARD
        </Menu.Item>
      )}
      <RequireAuthorization permission="Assets: View">
        <Dropdown.Item as={NavLink} to={routes.assets.index}>
          Assets
        </Dropdown.Item>
      </RequireAuthorization>
      <RequireAuthorization permission="Financial: View">
        <Dropdown.Item as={NavLink} to={routes.financials.index}>
          Financial
        </Dropdown.Item>
      </RequireAuthorization>
      <RequireAuthorization
        permission={['Quotes: Administrator', 'Quotes: User']}
      >
        <Dropdown item trigger={<span>Quoting</span>}>
          <Dropdown.Menu>
            <RequireAuthorization
              permission={['Quotes: Administrator', 'Quotes: User']}
            >
              <Menu.Item as={NavLink} to={routes.quotes.index}>
                Quotes
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization
              permission={['Quotes: Administrator', 'Quotes: User']}
            >
              <Menu.Item as={NavLink} to={routes.quoteTermTemplates.index}>
                Quote Term Templates
              </Menu.Item>
            </RequireAuthorization>
          </Dropdown.Menu>
        </Dropdown>
      </RequireAuthorization>
      <RequireAuthorization
        permission={[
          'Users: View & Manage',
          'Customers: View & Manage',
          'Invoices: View & Manage',
          'Request Templates: View & Manage',
          'Settings: View & Manage',
        ]}
      >
        <Dropdown item trigger={<span>Manage</span>}>
          <Dropdown.Menu>
            <RequireAuthorization permission="Users: View & Manage">
              <Menu.Item as={NavLink} to={routes.users.index}>
                Users
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Customers: View & Manage">
              <Menu.Item as={NavLink} to={routes.customers.index}>
                Customers
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Invoices: View & Manage">
              <Menu.Item as={NavLink} to={routes.invoices.index}>
                Invoices
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Request Templates: View & Manage">
              <Menu.Item as={NavLink} to={routes.requestTemplates.index}>
                Request Templates
              </Menu.Item>
            </RequireAuthorization>
            <RequireAuthorization permission="Settings: View & Manage">
              <Menu.Item as={NavLink} to={routes.settings.index}>
                Settings
              </Menu.Item>
            </RequireAuthorization>
          </Dropdown.Menu>
        </Dropdown>
      </RequireAuthorization>
    </>
  );
};

const styles = css`
  &.top-navigation.ui.secondary.menu {
    box-shadow: rgb(87, 99, 105) 2px 0px 5px -2px;
    z-index: 10;
    margin: 0;
    padding: 0 25px 0 10px;
    height: 60px;

    ${Media('TabletMax')} {
      padding: 0 10px 0 0;
    }

    .ui.header {
      color: ${themeColors.black1};
      font-weight: normal;
    }

    .logo {
      img {
        height: 100%;
        object-fit: contain;
        padding: 0.75rem;
      }
    }

    .ui.dropdown .menu {
      box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.3);
    }

    .ui.secondary.menu.desktop-navigation {
      border-bottom-color: #fff !important;
      display: flex;
      flex: 1;
      justify-content: flex-end;

      .item {
        display: flex;
        transition: background-color 0.5s ease;
        transition: color 0.5s ease;
        color: ${themeColors.textLabel};
        cursor: pointer;
        text-align: left;
        border-bottom-color: #fff;
        padding: 1rem 1.5rem;
        font-weight: 600;
        font-style: normal;
        font-size: 12px;
        border-top: 4px solid transparent;
        line-height: 16px;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        height: 100%;

        &.header {
          margin-top: 10px;
          margin-bottom: 0;
          padding-bottom: 5px;
          cursor: default;
        }

        &:hover:not(.header) {
          color: ${themeColors.black1};
          background: rgba(0, 0, 0, 0.05);
        }

        &.active,
        &.active:hover {
          background-color: ${themeColors.white2};
          color: #000;
          border-radius: 0;
          border-top: 4px solid ${MobileaseTheme.colors.blue300};
          background-color: #fff;

          > .label {
            background-color: #fff;
            color: ${lighten(0.05, themeColors.black1)};
          }
        }

        &.dropdown {
          .item.active,
          .item.active:hover {
            border: none;
            background-color: #fff;
          }
        }

        > i.icon {
          float: none;
          margin-right: 10px;
        }

        > .label {
          background-color: #767676;
        }
      }

      .logo-container {
        padding: 10px;

        .logo {
          width: 50%;
          margin: 10px auto;
          max-height: 40px;
        }

        .terms {
          display: block;
          text-align: center;
          padding: 10px 0;
        }
      }

      svg {
        width: 1.18em;
        margin: 0 10px 0 0;
        opacity: 0.9;
        color: ${themeColors.black1};
      }
    }

    .ui.dropdown.mobile-navigation {
      svg {
        width: 1.18em;
        margin: 0 10px 0 0;
        opacity: 0.9;
        color: ${themeColors.black1};
      }
    }

    .user-icon.ui.item {
      height: 35px;
      width: 35px;
      display: flex;
      padding: 0;
      border-radius: 30px;
      background: ${MobileaseTheme.colors.blue50};
      color: ${MobileaseTheme.colors.blue500};
      align-items: center;
      justify-content: center;
      line-height: 1.5rem;
      margin-left: 2rem;

      &:hover,
      &:focus,
      &:active {
      }
    }

    .user-icon-initial {
      display: block;
      text-align: center;
      font-size: 1rem;
    }
  }
`;
