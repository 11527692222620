import {SizeProp} from '@fortawesome/fontawesome-svg-core';
import {faTools} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from '../components/tooltip';
import {MobileaseTheme} from '../styles/branding';
import React from 'react';

export const MaintenanceStatusIcon: React.FC<{size?: SizeProp}> = ({
  size = '2x',
}) => (
  <Tooltip label="Under Maintenance">
    <FontAwesomeIcon
      icon={faTools}
      size={size}
      color={MobileaseTheme.colors.blue500}
    />
  </Tooltip>
);
