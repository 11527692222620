import css from '@emotion/css';
import React, {useMemo, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {useAsync, useDebounce} from 'react-use';
import {Dropdown, Icon} from 'semantic-ui-react';
import {LeaseCompleteAssetsService} from '../api/generated';
import {getSettingsDependentSearchableAssetFields} from '../assets/assets-listing';
import {useCustomerContext} from '../customer-context';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';
import {MobileaseTheme} from '../styles/branding';

export const NavSearch = () => {
  const [searchValue, setsearchValue] = useState<string>('');
  const [customerQuery, setCustomerQuery] = useState<string>('');
  const [customerContext] = useCustomerContext();

  const {currentCustomerId, settings} = customerContext;
  const settingsDependentSearchableFields = useMemo(() => {
    return getSettingsDependentSearchableAssetFields(settings);
  }, [settings]);
  const history = useHistory();

  useDebounce(
    () => {
      setsearchValue(customerQuery);
    },
    500,
    [customerQuery]
  );

  const fetchAssetOptions = useAsync(async () => {
    const result =
      await LeaseCompleteAssetsService.getAllLeaseCompleteAssetsByCustomerId({
        customerId: currentCustomerId,
        page: 1,
        searchSearchText: searchValue,
        searchFieldNames: [
          'SerialNumber',
          'Description',
          'PlateNumber',
          'Id',
          ...settingsDependentSearchableFields,
        ],
      });
    return result.data;
  }, [currentCustomerId, searchValue]);
  const options =
    fetchAssetOptions.value?.items?.map((x) => ({
      key: x.id,
      items: x,
      text: (
        <>
          <div className="result-header">{x?.description || 'N/A'}</div>
          <div className="result-subheader">
            <div>VIN: {x?.serialNumber || 'N/A'}</div>
            <div>Asset #: {x?.id || 'N/A'}</div>
            <div>
              Plate #:{' '}
              {x?.assetStateAbbreviation && `${x?.assetStateAbbreviation}-`}
              {x?.plateNumber || 'N/A'}
            </div>
          </div>
        </>
      ),
      value: x.id,
    })) ?? [];

  const handleChange = (e, data) => {
    setCustomerQuery('');
    const assetDetailPath = buildPath(routes.assets.detail, {
      id: data.value,
    });
    history.push(assetDetailPath);
  };

  return (
    <div css={styles}>
      <div className="search-addon">
        <Icon name="search" />
      </div>
      <Dropdown
        icon="angle down"
        loading={fetchAssetOptions.loading}
        selectOnNavigation={false}
        value={0}
        text={customerQuery || 'Search...'}
        selectOnBlur={false}
        onChange={handleChange}
        onBlur={() => {
          setCustomerQuery('');
        }}
        options={options ?? []}
        onSearchChange={(e, data) => {
          setCustomerQuery(data.searchQuery);
        }}
        selection
        minCharacters={3}
        search={(options) => options}
      />
    </div>
  );
};

const styles = css`
  margin-left: 2rem;
  display: flex;

  .ui.search.dropdown > .text {
    width: 100% !important;
    height: 100% !important;
    overflow: hidden !important;
  }

  .ui.selection.dropdown {
    border-radius: 0rem 0.6rem 0.6rem 0;
    background: #dbe6fb;
    display: inline;
    height: 2.25rem;
    width: 14.1875rem;
    padding-left: 0.3em;
    border: 0;
  }

  .ui.search.selection.dropdown > input.search {
    padding-left: 0.3em;
  }

  .angle.down.icon {
    color: ${MobileaseTheme.colors.blue400};
    cursor: pointer;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 1.31428571em;
    padding: 0.61666667em 0.91666667em;
    margin: -0.63333333em;
    z-index: 3;
    right: 0.4em;
  }
  .result-subheader {
    display: flex;
    justify-content: space-between;
    font-size: 0.8em;
    flex-wrap: wrap;
    color: ${MobileaseTheme.colors.grey500};
  }

  .result-header {
    color: black !important;
  }

  .ui.search.dropdown > .text {
    line-height: 1.31428571em;
  }

  .search-addon {
    background: #dbe6fb;
    border-radius: 0.6rem 0 0 0.6rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .search.icon {
      color: ${MobileaseTheme.colors.blue500};
      margin-left: 0.5em;
    }
  }
`;
