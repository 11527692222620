import React, {useCallback, useContext, useMemo, useState} from 'react';
import {
  RequestTemplateDetailDto,
  RequestTemplateService,
} from '../api/generated';
import {useCustomerContext} from '../customer-context';
import {notifications} from '../utils/notification-service';
import {RequestTemplateModal} from './request-template-modal';

export type RequestTemplateContextState = {
  isOpen: boolean;
  openRequestTemplateModal: (key: string, assetId?: string) => void;
};

export const RequestTemplateContext =
  React.createContext<RequestTemplateContextState>({
    isOpen: false,
    openRequestTemplateModal: (key) => {},
  });

export const useRequestTemplateModal = () => useContext(RequestTemplateContext);

export const RequestTemplateContextProvider = ({children}) => {
  const [{currentCustomerId}] = useCustomerContext();
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [requestTemplate, setRequestTemplate] =
    useState<RequestTemplateDetailDto | null>(null);
  const [associatedAssetId, setAssociatedAssetId] = useState<
    string | undefined
  >('');

  const openRequestTemplateModal = useCallback(
    async (key: string, assetId?: string) => {
      setRequestModalOpen(true);
      const response = await RequestTemplateService.getByKey({
        key,
        customerId: currentCustomerId,
        assetId,
      });
      if (response.hasErrors) {
        response.errors.forEach((error) => notifications.error(error));
        return response;
      }
      setAssociatedAssetId(assetId);
      setRequestTemplate(response.data);
    },
    [currentCustomerId]
  );

  const initialRequestTemplateValue = useMemo(() => {
    return {
      isOpen: requestModalOpen,
      openRequestTemplateModal,
    };
  }, [requestModalOpen, openRequestTemplateModal]);

  return (
    <RequestTemplateContext.Provider value={initialRequestTemplateValue}>
      {children}
      <RequestTemplateModal
        associatedAssetId={associatedAssetId}
        requestTemplate={requestTemplate}
        open={requestModalOpen}
        onClose={() => {
          setRequestTemplate(null);
          setRequestModalOpen(false);
        }}
      />
    </RequestTemplateContext.Provider>
  );
};
