import {useAsync} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {QuotesService, UpdateQuoteSetupRequest} from '../../api/generated';
import React, {useMemo, useState} from 'react';
import {AsyncStateContainer} from '../../components/async-state-container';
import {Form} from '../../forms';
import {
  checkbox,
  dropdown,
  fieldConfig,
  RawFieldConfig,
  textarea,
} from '../../forms/schema-utils';
import {useNotification} from '../../hooks/use-notifications';
import {
  QuoteStageProps,
  UpdateQuoteSetupRequestWithOptionalCustomerInfo,
} from '../quote-create-update';
import {QuoteStageFormActions} from './quote-stage-form-actions';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {QuoteContractTypes} from '../../api/generated/enums';

export const QuoteInformation: React.FC<QuoteStageProps> = ({
  quote,
  refetchQuote,
  readOnly,
}) => {
  const notifications = useNotification();

  const fetchOptions = useAsync(async () => {
    const response = await QuotesService.getSetupOptions();
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fields = useFieldsForQuoteSetup({
    customers: quote.customerName
      ? options?.customers.concat([
          {
            value: quote.customerName,
            text: quote.customerName,
            meta: null,
          },
        ])
      : options?.customers,
    readOnly: readOnly,
  });

  const onSubmit = async (
    values: UpdateQuoteSetupRequestWithOptionalCustomerInfo
  ) => {
    const valuesToSubmit = {...values};
    if (
      options?.customers.find((o) => o.value === values.customerId) ===
      undefined
    ) {
      valuesToSubmit.customerName = values.customerId;
      valuesToSubmit.customerId = null;
    } else {
      valuesToSubmit.customerName = null;
    }
    const response = await QuotesService.updateSetup({
      body: {...(valuesToSubmit as UpdateQuoteSetupRequest), id: quote.id},
    });
    if (response.hasErrors) {
      return response;
    }

    refetchQuote();
    notifications.success('Quote Updated');
  };

  return (
    <AsyncStateContainer {...fetchOptions}>
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          initialValues={quote}
          render={() => (
            <>
              {fields && <QuoteSetupFormFields fields={fields} />}
              <Divider />
              <QuoteStageFormActions
                quoteId={quote.id}
                action={
                  readOnly ? (
                    <></>
                  ) : (
                    <Form.Button type="submit" primary>
                      Save
                    </Form.Button>
                  )
                }
              />
            </>
          )}
        />
      </Form.Container>
    </AsyncStateContainer>
  );
};

export const QuoteSetupFormFields: React.FC<{
  fields: RawFieldConfig<CreateQuote>;
}> = ({fields}) => {
  return (
    <>
      <Form.Section title="">
        <Form.Row proportions={[2, 2]}>
          <Form.Dropdown fieldConfig={fields.customerId} />
        </Form.Row>
        <Form.Row proportions={[2, 2]}>
          <Form.Dropdown fieldConfig={fields.contractType} />
        </Form.Row>
        <Form.Row proportions={[2, 2]}>
          <Form.Checkbox fieldConfig={fields.isFloatRate} />
        </Form.Row>
        <Form.Row proportions={[2, 2]}>
          <Form.TextArea fieldConfig={fields.description} />
        </Form.Row>
      </Form.Section>
    </>
  );
};

export type CreateQuote = Omit<UpdateQuoteSetupRequest, 'id' | 'customerName'>;

export const useFieldsForQuoteSetup = ({customers, readOnly}) => {
  const [newCustomerName, setNewCustomerName] = useState<string>('');
  const [selectedCustomerId, setSelectedCustomerId] = useState<string>('');

  const customersWithName = useMemo(() => {
    if (customers)
      return [{value: newCustomerName, text: newCustomerName}, ...customers];
    return customers;
  }, [newCustomerName, customers]);

  return fieldConfig<CreateQuote>({
    customerId: dropdown({
      fieldLabel: 'Customer',
      required: true,
      inputProps: {
        disabled: readOnly,
        placeholder: 'Select existing customer or type New Customer name...',
        selection: true,
        allowAdditions: true,
        search: true,
        options: newCustomerName !== '' ? customersWithName : customers,
        value: selectedCustomerId ?? newCustomerName,
        additionLabel: <strong style={{color: 'green'}}>New Customer: </strong>,
        onAddItem: (event, {value}) => {
          setNewCustomerName(value as string);
        },
        onChange: (event, {value}) => {
          if (value !== newCustomerName) {
            setSelectedCustomerId(value as string);
            setNewCustomerName('');
          }
        },
      },
    }),
    isFloatRate: checkbox({
      fieldLabel: 'Use Float Rate',
      inputProps: {disabled: readOnly, toggle: true},
    }),
    contractType: dropdown({
      fieldLabel: 'Contract Type',
      required: true,
      inputProps: {
        disabled: readOnly,
        placeholder: 'Contract Type',
        selection: true,
        options: getEnumDropdownOptions(QuoteContractTypes),
      },
    }),
    description: textarea({
      inputProps: {disabled: readOnly},
      fieldLabel: 'Description',
    }),
  });
};
