import css from '@emotion/css/macro';
import {IconDefinition} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import React from 'react';
import {Label} from 'semantic-ui-react';
import {Tooltip} from './tooltip';

export type ChipLevel = 'success' | 'info' | 'neutral' | 'warning' | 'error';

type Chip = {
  text: string;
  level: ChipLevel;
  icon?: IconDefinition;
  iconOnly?: boolean;
  basic?: boolean;
  className?: string;
};

export const Chip: React.FC<Chip> = (props) => {
  const {text, level, icon, basic, iconOnly, className} = props;
  return (
    <Label
      className={cx(level, className, {
        icon: !!icon,
        iconOnly: !!iconOnly,
        transparent: !!basic,
      })}
      css={styles}
      basic
    >
      {icon && (
        <Tooltip label={text}>
          <FontAwesomeIcon icon={icon} />
        </Tooltip>
      )}
      {!iconOnly && <span>{text}</span>}
    </Label>
  );
};

const styles = css`
  &.ui.basic.label {
    border-radius: 50px;
    border: solid 1px transparent;
    padding: 0.3833em 0.633em;
    font-weight: normal;
    white-space: nowrap;

    &.icon:not(.iconOnly) > span {
      padding-left: 5px;
    }

    &.transparent {
      background-color: transparent !important;
    }

    &.success {
      background-color: #c6f6d5;
      color: #22543d;
    }

    &.info {
      background-color: #c6e9f6;
      color: #223f54;
    }

    &.neutral {
      background-color: #efefef;
      color: #333333;
    }

    &.warning {
      background-color: #feebc8;
      color: #7b341e;
    }

    &.error {
      background-color: #ffe2e2;
      color: #742a2a;
    }
  }
`;
