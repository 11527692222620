import React from 'react';
import {Flex} from '../components/flex';
import {DashboardPanel} from './dashboard-panel';
import {AttentionCard, AttentionCardType} from './attention-card';
import {Divider, Header} from 'semantic-ui-react';
import {FleetOverviewChart} from './charts/fleet-overview-chart';
import {useAsync} from 'react-use';
import {DashboardService} from '../api/generated';
import css from '@emotion/css';
import {useHistory} from 'react-router';
import {routes} from '../routes';
import {
  AssetFilters,
  AssetMaintenanceFilters,
  AssetStatuses,
} from '../api/generated/enums';
import {AssetFilterState} from '../assets/assets-listing';
import {useRequireAuthorization} from '../auth/require-authorization';

export const AdminDashboard: React.FC<{testProp?: number}> = ({testProp}) => {
  const history = useHistory<AssetFilterState>();
  const fetchDashboardMetrics = useAsync(async () => {
    return await DashboardService.getManagerStats();
  });

  const isCustomerAdmin = useRequireAuthorization({
    role: ['Customer Admin'],
  });

  return (
    <>
      <div css={dashboardStyles}>
        <Flex.Row fill className={'header-container'}>
          <Header
            size="tiny"
            content={`${isCustomerAdmin ? 'Admin' : 'Manager'} Dashboard`}
          />
        </Flex.Row>
        <Divider />
        <Flex.Row fill>
          <Flex.Col fill>
            <Flex.Row>
              <Flex.Col fill>
                <DashboardPanel headerText="Leases">
                  <Flex.Row>
                    <AttentionCard
                      loading={fetchDashboardMetrics.loading}
                      label="Upcoming Lease Expirations"
                      value={
                        fetchDashboardMetrics.value?.data?.upcomingPastDueLeases
                      }
                      type={AttentionCardType.Warning}
                      onClick={() => {
                        history.push({
                          pathname: routes.assets.index,
                          state: {
                            assetLeaseExpirationStatus: AssetFilters.Expiring,
                            assetStatus: AssetStatuses.Leased,
                            bypassCustomerContext: true,
                          },
                        });
                      }}
                    />
                  </Flex.Row>
                  <Flex.Row>
                    <AttentionCard
                      loading={fetchDashboardMetrics.loading}
                      label="Past Due Leases"
                      value={fetchDashboardMetrics.value?.data?.pastDueLeases}
                      type={AttentionCardType.Danger}
                      onClick={() => {
                        history.push({
                          pathname: routes.assets.index,
                          state: {
                            assetLeaseExpirationStatus: AssetFilters.Expired,
                            assetStatus: AssetStatuses.Leased,
                            bypassCustomerContext: true,
                          },
                        });
                      }}
                    />
                  </Flex.Row>
                </DashboardPanel>
              </Flex.Col>
              <Flex.Col fill>
                <DashboardPanel headerText="Registration">
                  <Flex.Row>
                    <AttentionCard
                      loading={fetchDashboardMetrics.loading}
                      label="Upcoming Expirations"
                      value={
                        fetchDashboardMetrics.value?.data
                          ?.upcomingRegistrationExpirations
                      }
                      type={AttentionCardType.Warning}
                      onClick={() => {
                        history.push({
                          pathname: routes.assets.index,
                          state: {
                            assetRegistrationStatus: AssetFilters.Expiring,
                            assetStatus: AssetStatuses.Leased,
                            bypassCustomerContext: true,
                          },
                        });
                      }}
                    />
                  </Flex.Row>

                  <Flex.Row>
                    <AttentionCard
                      loading={fetchDashboardMetrics.loading}
                      label="Expired"
                      value={
                        fetchDashboardMetrics.value?.data?.expiredRegistrations
                      }
                      type={AttentionCardType.Danger}
                      onClick={() => {
                        history.push({
                          pathname: routes.assets.index,
                          state: {
                            assetRegistrationStatus: AssetFilters.Expired,
                            assetStatus: AssetStatuses.Leased,
                            bypassCustomerContext: true,
                          },
                        });
                      }}
                    />
                  </Flex.Row>
                </DashboardPanel>
              </Flex.Col>
              <Flex.Col fill>
                <DashboardPanel headerText="Maintenance">
                  <>
                    <Flex.Row>
                      <AttentionCard
                        loading={fetchDashboardMetrics.loading}
                        label="Vehicles With Maintenance"
                        value={
                          fetchDashboardMetrics.value?.data
                            ?.assetsUnderMaintenancePlans
                        }
                        type={AttentionCardType.Information}
                        onClick={() => {
                          history.push({
                            pathname: routes.assets.index,
                            state: {
                              maintenancePlan:
                                AssetMaintenanceFilters['Under Maintenance'],
                              assetStatus: AssetStatuses.Leased,
                              bypassCustomerContext: true,
                            },
                          });
                        }}
                      />
                    </Flex.Row>
                    <Flex.Row>
                      <AttentionCard
                        loading={fetchDashboardMetrics.loading}
                        label="Vehicles Without Maintenance"
                        value={
                          fetchDashboardMetrics.value?.data
                            ?.assetsNotUnderMaintenancePlans
                        }
                        type={AttentionCardType.Information}
                        onClick={() => {
                          history.push({
                            pathname: routes.assets.index,
                            state: {
                              maintenancePlan:
                                AssetMaintenanceFilters[
                                  'Not Under Maintenance'
                                ],
                              assetStatus: AssetStatuses.Leased,
                              bypassCustomerContext: true,
                            },
                          });
                        }}
                      />
                    </Flex.Row>
                  </>
                </DashboardPanel>
              </Flex.Col>
            </Flex.Row>
            <Divider hidden />
            <Flex.Row>
              <Flex.Col fill>
                <DashboardPanel headerText="Fleet Overview">
                  <FleetOverviewChart
                    fetchFleetOverView={() =>
                      DashboardService.getManagerFleetOverview()
                    }
                    onChartClick={(assetStatusSelected: string) => {
                      history.push({
                        pathname: routes.assets.index,
                        state: {
                          assetStatus: assetStatusSelected,
                          bypassCustomerContext: true,
                        },
                      });
                    }}
                  />
                </DashboardPanel>
              </Flex.Col>
            </Flex.Row>
          </Flex.Col>
        </Flex.Row>
      </div>
    </>
  );
};

const dashboardStyles = css`
  .header-container {
    padding: 1.5rem 2rem 0.5rem 2rem;
  }
`;
