import css from '@emotion/css';
import React, {useEffect, useMemo, useState} from 'react';
import {useForm, useFormState} from 'react-final-form';
import {useHistory} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Grid, Segment} from 'semantic-ui-react';
import {
  List,
  QuoteFilterOptions,
  QuoteListingDto,
  QuotesService,
} from '../api/generated';
import {QuoteStages} from '../api/generated/enums';
import {RequireAuthorization} from '../auth/require-authorization';
import {AsyncStateContainer} from '../components/async-state-container';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {FormFiltersAppliedCount} from '../forms/form-filters-applied-count';
import {
  checkbox,
  dropdown,
  fieldConfig,
  getDefaults,
  input,
  RawFieldConfig,
} from '../forms/schema-utils';
import {BlueFilterFieldStyles} from '../forms/styles';
import {AdditionalParams} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {routes} from '../routes';

import {NewQuoteButton} from './new-quote-button';
import {QuoteDashboardTable} from './quote-dashboard-table';

type FilterState = Required<AdditionalParams<typeof QuotesService.getAll>>;

export const QuoteDashboard = () => {
  const history = useHistory<FilterState>();
  const [quotes, setQuotes] = useState<List<QuoteListingDto> | null>([]);

  const initialFilterState = useMemo(() => {
    return history.location.state;
  }, [history.location.state]);

  const [filterState, setFilterState] = useProduce<FilterState>(
    initialFilterState || {}
  );
  const fetchQuotes = useAsync(async () => {
    const response = await QuotesService.getAll({...filterState});
    if (response.hasErrors) {
      return null;
    }
    setQuotes(response.data);
  }, [filterState]);

  const quoteOptions = useAsync(async () => {
    const response = await QuotesService.getDashboardFilterOptions();
    return response.data;
  });

  const fields = useFields(quoteOptions.value || undefined, initialFilterState);

  return (
    <div css={styles}>
      <Grid columns="equal" padded>
        <Grid.Row only="mobile">
          <Grid.Column>
            <Segment clearing>
              <TableFilters
                fields={fields}
                initialValues={initialFilterState}
                onFiltersChanged={(values) => {
                  setFilterState((draft) => {
                    draft.quoteSearchText = values.quoteSearchText;
                    draft.customerSearchText = values.customerSearchText;
                    draft.assigneeUserId = values.assigneeUserId;
                    draft.showArchived = values.showArchived;
                  });
                }}
              />
            </Segment>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column only="tablet computer">
            <div className="sticky-active">
              <Segment>
                <TableFilters
                  fields={fields}
                  initialValues={initialFilterState}
                  onFiltersChanged={(values) => {
                    setFilterState((draft) => {
                      draft.quoteSearchText = values.quoteSearchText;
                      draft.customerSearchText = values.customerSearchText;
                      draft.assigneeUserId = values.assigneeUserId;
                      draft.showArchived = values.showArchived;
                    });
                  }}
                />
              </Segment>
            </div>
          </Grid.Column>

          <Grid.Column
            widescreen={12}
            largeScreen={12}
            computer={11}
            tablet={11}
            mobile={16}
          >
            <Segment className="main-section">
              <Flex.Row justifyContent="flex-end">
                <NewQuoteButton />
              </Flex.Row>
              <AsyncStateContainer {...fetchQuotes}>
                <QuoteDashboardTable
                  header="Locate Assets"
                  color="blue"
                  quotes={quotes?.filter(
                    (x) => x.quoteStage === QuoteStages.LocateAsset
                  )}
                />

                <QuoteDashboardTable
                  header="Configure Terms"
                  color="yellow"
                  quotes={quotes?.filter(
                    (x) => x.quoteStage === QuoteStages.ConfigureTerms
                  )}
                />

                <QuoteDashboardTable
                  header="Review"
                  color="brown"
                  quotes={quotes?.filter(
                    (x) => x.quoteStage === QuoteStages.InReview
                  )}
                />

                <QuoteDashboardTable
                  header="Completed Quotes"
                  color="green"
                  quotes={quotes?.filter(
                    (x) => x.quoteStage === QuoteStages.Completed
                  )}
                />
              </AsyncStateContainer>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  );
};

const TableFilters: React.FC<{
  fields: RawFieldConfig<FilterState>;
  onFiltersChanged: (values: FilterState) => void;
  initialValues: FilterState;
}> = ({fields, onFiltersChanged, initialValues}) => {
  return (
    <>
      <Form
        disableLoading
        onSubmit={(values) => {
          onFiltersChanged(values);
        }}
        initialValues={initialValues || getDefaults(fields)}
        render={() => {
          return (
            <div css={BlueFilterFieldStyles}>
              <FormFields fields={fields} />
            </div>
          );
        }}
      />
    </>
  );
};

const FormFields: React.FC<{fields: RawFieldConfig<FilterState>}> = ({
  fields,
}) => {
  const history = useHistory();
  const form = useForm();
  const {values} = useFormState();

  useEffect(() => {
    if (values) {
      history.push(routes.quotes.index, values);
      form.submit();
    }
  }, [form, history, values]);

  return (
    <>
      <FormFiltersAppliedCount />
      <Form.Input fieldConfig={fields.quoteSearchText} />
      <Form.Dropdown fieldConfig={fields.customerSearchText} />
      <RequireAuthorization permission={'Quotes: Administrator'}>
        <Form.Dropdown fieldConfig={fields.assigneeUserId} />
      </RequireAuthorization>
      <Form.Checkbox fieldConfig={fields.showArchived} />
    </>
  );
};
const useFields = (
  options: QuoteFilterOptions | undefined,
  initialFilterState: FilterState
) => {
  const [customerSearchText, setCustomerSearchText] = useState<string>(
    initialFilterState?.customerSearchText ?? ''
  );

  return fieldConfig<Required<FilterState>>({
    quoteSearchText: input({
      fieldLabel: 'Quote ID',
      inputProps: {
        type: 'number',
      },
    }),
    customerSearchText: dropdown({
      fieldLabel: 'Customer',
      inputProps: {
        additionLabel: 'Search for ',
        allowAdditions: true,
        search: true,
        onAddItem: (event, {value}) => {
          setCustomerSearchText(value as string);
        },
        onChange: (event, {value}) => {
          if (value !== customerSearchText) {
            setCustomerSearchText('');
          }
        },
        value: customerSearchText,
        selection: true,
        clearable: true,
        options:
          customerSearchText !== ''
            ? [
                ...(options?.customers ?? []),
                {text: customerSearchText, value: customerSearchText},
              ]
            : options?.customers,
      },
    }),
    assigneeUserId: dropdown({
      fieldLabel: 'Assigned User',
      inputProps: {
        search: true,
        clearable: true,
        selection: true,
        options: options?.assignedUsers,
      },
    }),
    showArchived: checkbox({
      fieldLabel: 'Show Archived',
      inputProps: {
        toggle: true,
      },
    }),
  });
};

const styles = css`
  padding-top: 1em;

  .sticky-active {
    position: sticky;
    top: 1em;
  }

  .filter-rail {
    width: -webkit-fill-available;
    padding: 0em !important;
    margin: 0em 0em 0em 1em !important;
    right: 0 !important;
  }

  .main-section {
    min-width: fit-content;
    padding: 1em 2em !important;
  }

  .ui.header {
    margin: 0em 0em;
  }

  .ui.grid {
    margin-left: 0em;
    margin-right: 0em;
  }

  .ui.grid > .column:not(.row) {
    padding-top: 0 !important;
  }
`;
