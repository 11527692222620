import {css} from '@emotion/core';
import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {Button, Modal} from 'semantic-ui-react';
import {
  CloneTermsFromExistingTermRequest,
  QuoteTermsService,
} from '../api/generated';
import {currencyFormatter} from '../assets/asset-utils';
import {Form} from '../forms';
import {dropdown, fieldConfig} from '../forms/schema-utils';
import {notifications} from '../utils/notification-service';

type FieldsType = {quoteAssetId};

export const CloneTermsButton: React.FC<{
  quoteTermId: number;
  onTermsCloned?: () => void;
}> = ({quoteTermId, onTermsCloned}) => {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const quoteAssetOptions = useAsync(async () => {
    const response = await QuoteTermsService.getOptions({quoteTermId});
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    return response.data?.quoteAssets.map((x) => {
      return {
        text: (
          <span css={styles}>
            {x.text} -{' '}
            <span className="company-cost">
              {currencyFormatter(x.meta / 100)}
            </span>
          </span>
        ),
        value: x.value,
      };
    });
  });

  const fields = useFields(quoteAssetOptions.value);

  return (
    <>
      <Button
        type="button"
        icon="copy"
        content="Clone Terms"
        onClick={() => setOpenModal(true)}
      />
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>Choose an Asset to Clone to</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={async (values: {quoteAssetId: number}) => {
              const response = await QuoteTermsService.createFromExistingTerm({
                body: {
                  quoteAssetId: values.quoteAssetId,
                  quoteTermId: quoteTermId,
                },
              });
              if (response.hasErrors) {
                notifications.errors(response.errors);
                return;
              }
              notifications.success('Term created.');
              onTermsCloned && onTermsCloned();
              setOpenModal(false);
            }}
            render={() => (
              <>
                <Form.Dropdown fieldConfig={fields.quoteAssetId} />
                <Form.Button type="submit" primary>
                  Clone Terms
                </Form.Button>
                <Form.Button type="button" onClick={() => setOpenModal(false)}>
                  Cancel
                </Form.Button>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const useFields = (quoteAssetOptions) => {
  return fieldConfig<FieldsType>({
    quoteAssetId: dropdown({
      fieldLabel: 'Asset to Clone Into',
      inputProps: {
        placeholder: 'Select a Quote Asset',
        options: quoteAssetOptions,
        selection: true,
        search: true,
      },
      required: true,
    }),
  });
};

const styles = css`
  .company-cost {
    font-size: 0.8em;
  }
`;
