import React from 'react';
import {Container, Message} from 'semantic-ui-react';
import {TransactionErrorParams} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {useQueryParams} from '../hooks/use-query-params';

export const TransactionError = () => {
  const {PaymentReferenceNumber} = useQueryParams<{
    [TransactionErrorParams.PaymentReferenceNumber]: string;
  }>();
  return (
    <BasicPage title="">
      <Container>
        <Message
          negative
          header="A payment error occurred."
          content={
            <>
              An error occured with the following payment. Please contact the
              Mobilease admin for futher assistance.
              <p>
                Payment Number: <strong>{PaymentReferenceNumber}</strong>
              </p>
            </>
          }
        />
      </Container>
    </BasicPage>
  );
};
