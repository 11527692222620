import React, {useState} from 'react';
import {Form} from '../forms';
import {useAsync} from 'react-use';
import {Button, Modal} from 'semantic-ui-react';
import {
  CreateNewQuoteTermsFromTemplateRequest,
  QuoteTermGetDto,
  QuoteTermsTemplateGetDto,
  QuoteTermsTemplatesService,
} from '../api/generated';
import {dropdown, fieldConfig} from '../forms/schema-utils';
import {notifications} from '../utils/notification-service';

type FieldsType = Pick<
  CreateNewQuoteTermsFromTemplateRequest,
  'quoteTermsTemplateId'
>;

const useFields = (templateOptions) => {
  return fieldConfig<FieldsType>({
    quoteTermsTemplateId: dropdown({
      fieldLabel: 'Template',
      inputProps: {
        options: templateOptions,
        selection: true,
        search: true,
      },
      required: true,
    }),
  });
};

export const CreateQuoteTermsFromTemplateButton: React.FC<{
  quoteAssetId: number;
  onTemplateSelected: (quoteTerm: Omit<QuoteTermsTemplateGetDto, 'id'>) => void;
}> = ({quoteAssetId, onTemplateSelected}) => {
  const [addingQuoteTerm, setAddingQuoteTerm] = useState<boolean>(false);

  const templateOptions = useAsync(async () => {
    const response =
      await QuoteTermsTemplatesService.getTemplateOptionsForQuoteAsset({
        quoteAssetId,
      });
    return response.data;
  }, [quoteAssetId]);

  const handleSubmit = async (values: FieldsType) => {
    const templateResponse = await QuoteTermsTemplatesService.getTemplateById({
      id: values.quoteTermsTemplateId,
    });
    if (templateResponse.hasErrors) {
      notifications.errors(templateResponse.errors);
      return;
    }
    let x = templateResponse.data as QuoteTermsTemplateGetDto;

    let {id, ...templateDto} = x;

    onTemplateSelected({
      ...templateDto,
    });
    setAddingQuoteTerm(false);
  };

  const fields = useFields(templateOptions.value);

  return (
    <>
      <Button
        type="button"
        onClick={() => {
          setAddingQuoteTerm(true);
        }}
        icon="upload"
        content="Import Terms"
      />
      <Modal
        open={addingQuoteTerm}
        onClose={() => {
          setAddingQuoteTerm(false);
        }}
      >
        <Modal.Header>Choose a Template</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={handleSubmit}
            render={() => (
              <>
                <Form.Dropdown fieldConfig={fields.quoteTermsTemplateId} />
                <Form.Button type="submit">Apply Template</Form.Button>
              </>
            )}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};
