import css from '@emotion/css/macro';
import {
  faFilter,
  faSort,
  faSortDown,
  faSortUp,
} from '@fortawesome/pro-duotone-svg-icons';
import {
  faPencil,
  faTimes,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {cx} from 'emotion';
import {isEqual, isFunction} from 'lodash';
import {lighten, transparentize} from 'polished';
import React, {ReactNode, useEffect, useRef, useState} from 'react';
import {Link} from 'react-router-dom';
import {useAsyncRetry} from 'react-use';
import {
  Dimmer,
  Header,
  Icon,
  Input,
  Label,
  Loader,
  Segment,
  SemanticCOLORS,
  Table,
  TableCellProps,
  TableProps,
  TableRowProps,
} from 'semantic-ui-react';
import {Optional} from 'utility-types';
import {AdvancedQueryPage, Response} from '../api/generated';
import {Alert} from '../components/alert';
import {CopyButton} from '../components/copy-button';
import {Flex} from '../components/flex';
import {SlideOut} from '../components/slide-out';
import {Tooltip} from '../components/tooltip';
import {useCustomerContext} from '../customer-context';
import {Button} from '../forms/button';
import {usePagination} from '../hooks/use-pagination';
import {buildPath} from '../routes/utils';
import {themeColors} from '../styles';
import {MobileaseTheme} from '../styles/branding';
import {
  AdvancedPagedRequest,
  AdvancedPagedSearch,
  AnyObject,
  KeyOf,
  KeysOfType,
  RequireOnlyOne,
} from '../types';
import {notifications} from '../utils/notification-service';
import {flattenObject} from '../utils/object-helpers';
import {useDebouncedState} from './use-debounced-state';
import {useDelayedExpiration} from './use-delay-expiration';
import {useProduce} from './use-produce';

type ColumnRenderProps<TDto> = RequireOnlyOne<
  {
    column?: KeysOfType<TDto, any>;
    render?: (item: TDto) => ReactNode;
  },
  'column' | 'render'
>;

type ColumnConfig<TDto> = {
  header: string | React.ReactNode;
  cellProps?: TableCellProps;
  copy?: boolean | ((item: TDto) => string);
  sortable?: keyof TDto;
} & ColumnRenderProps<TDto>;

type RenderEditButton = {
  item: {id: number} | {id: string};
  route: string;
  descriptor: string;
};

type ServiceCall = (params: any) => Promise<any>;

export type AdditionalParams<T extends ServiceCall> = Pick<
  NonNullable<Parameters<T>[0]>,
  Exclude<
    keyof NonNullable<Parameters<T>[0]>,
    keyof Optional<AdvancedPagedRequest<any>>
  >
>;

type RuntimeConfig<TDto> = {
  onStateChange?: (state: AdvancedPagedRequest<TDto>) => void;
  additionalParams?: AnyObject;
  actions?: React.ReactNode;

  renderFilter?: (dismiss: () => void) => React.ReactNode;
  filterBadge?: React.ReactNode;
  filterBadgeColor?: SemanticCOLORS;
  filterTitle?: string;
  renderSearch?: (
    onSearchChange: (newValue: string) => void,
    defaultComponent: React.ReactNode
  ) => React.ReactNode;
};

type SortState<TDto> = {
  column: KeyOf<TDto>;
  direction: 'ASC' | 'DESC';
};

export type PagedDataTableConfig<TDto> = {
  columns: ColumnConfig<TDto>[];
  defaultSort?: SortState<TDto>;
  searchFieldNames: KeyOf<TDto>[];
  hideSearch?: boolean;
  rowProps?: (item: TDto) => TableRowProps;
  tableProps?: TableProps;
  initialPageSize?: number;
  noResultsText?: string;
};

type State<TDto> = {
  sort: SortState<TDto> | undefined;
  filter: 'open' | 'closed';
};

export function hashCode(s) {
  var h = 0,
    i = s.length;
  while (i > 0) {
    h = ((h << 5) - h + s.charCodeAt(--i)) | 0;
  }
  return h.toString();
}

export function usePagedDataTable<TDto>(
  pagedFetchAction: (
    request: any
  ) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto>
): ReactNode;
export function usePagedDataTable<TDto>(
  pagedFetchAction: (
    request: any
  ) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>,
  runtimeConfig?: RuntimeConfig<TDto>
): ReactNode {
  const [{currentCustomerId}] = useCustomerContext();
  const pagination = usePagination(
    config.initialPageSize
      ? {
          initialPageSize: config.initialPageSize,
        }
      : undefined
  );
  const [search, setSearch] = useDebouncedState('', 350);

  const [state, setState] = useProduce<State<TDto>>({
    sort: config.defaultSort,
    filter: 'closed',
  });

  const cachedResult = useRef<AdvancedQueryPage<TDto> | null | undefined>();
  const additionalParams = useAdditionalParams(runtimeConfig?.additionalParams);
  const onStateChange = runtimeConfig?.onStateChange;

  useEffect(() => {
    pagination.resetPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomerId]);

  const fetchData = useAsyncRetry(async () => {
    const searchParams: AdvancedPagedSearch<TDto> = search
      ? {
          searchFieldNames: config.searchFieldNames,
          searchSearchText: search,
        }
      : ({
          searchFieldNames: config.searchFieldNames,
        } as AdvancedPagedSearch<TDto>);

    const sortParams: AdvancedPagedRequest<TDto> = state.sort
      ? {
          sorted: [
            {
              fieldName: state.sort.column as string,
              descending: state.sort.direction === 'DESC',
            },
          ],
        }
      : {};

    const params = {
      page: pagination.pageNumber,
      pageSize: pagination.pageSize,
      ...searchParams,
      ...sortParams,
      ...additionalParams,
    };

    if (onStateChange) {
      onStateChange(params);
    }

    try {
      const {data, hasErrors} = await pagedFetchAction(
        params as Parameters<typeof pagedFetchAction>['0']
      );

      if (hasErrors) {
        notifications.error('Failed to fetch data');
      } else {
        cachedResult.current = data;
        return data;
      }
    } catch (error) {
      notifications.error('Failed to fetch data');
    }
  }, [
    additionalParams,
    config.searchFieldNames,
    onStateChange,
    pagedFetchAction,
    pagination.pageNumber,
    pagination.pageSize,
    search,
    state.sort,
  ]);

  const showLoading = useDelayedExpiration({
    isActive: fetchData.loading,
    delayInMs: 350,
  });

  const normalizedData = normalizedAdvancedPagedResult<TDto>(
    cachedResult.current
  );

  const SearchComponent = (
    <>
      <Input
        onChange={(e, {value}) => {
          setSearch(value);
          pagination.resetPage();
        }}
        icon="search"
        placeholder="Search"
        className="table-search"
      />
      {runtimeConfig?.renderFilter && (
        <>
          <FilterButton
            onClick={() =>
              setState((draft) => {
                draft.filter = 'open';
              })
            }
            runtimeConfig={runtimeConfig}
          />
          <SlideOut
            open={state.filter === 'open'}
            scroll
            width={400}
            onRequestClose={() => {
              setState((draft) => {
                draft.filter = 'closed';
              });
            }}
          >
            <SlideOut.Header>
              <Header>{runtimeConfig?.filterTitle || 'Filters'}</Header>
              <Button
                type="button"
                basic
                color="black"
                size="mini"
                onClick={() => {
                  setState((draft) => {
                    draft.filter = 'closed';
                  });
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            </SlideOut.Header>
            <SlideOut.Content>
              {runtimeConfig?.renderFilter(() => {
                setState((draft) => {
                  draft.filter = 'closed';
                });
              })}
            </SlideOut.Content>
          </SlideOut>
        </>
      )}
    </>
  );

  return (
    <>
      <Segment css={styles}>
        <Flex.Row align="center">
          <Flex.Fill>
            {!config.hideSearch && (
              <>
                {runtimeConfig?.renderSearch
                  ? runtimeConfig.renderSearch(setSearch, SearchComponent)
                  : SearchComponent}
              </>
            )}
          </Flex.Fill>

          <Flex.Box>{runtimeConfig?.actions}</Flex.Box>
        </Flex.Row>

        {fetchData.error ? (
          <Alert negative>{fetchData.error.message}</Alert>
        ) : normalizedData.items.length === 0 && !fetchData.loading ? (
          <Segment placeholder className="no-results">
            <Header icon>
              <Icon name="search" size="tiny" />
              {config.noResultsText ?? 'No results found'}
            </Header>
          </Segment>
        ) : normalizedData.items.length > 0 ? (
          <>
            <Dimmer.Dimmable className="table-container">
              <Table
                basic="very"
                compact
                className="paged-table"
                unstackable
                {...config.tableProps}
              >
                <Table.Header>
                  <Table.Row>
                    {config.columns.map((column, columnIndex) => {
                      const key =
                        (column.column as string) || `column_${columnIndex}`;

                      let sortIcon: IconDefinition | undefined;

                      if (column.sortable) {
                        sortIcon =
                          column.sortable &&
                          state.sort?.column === (column.sortable as string)
                            ? state.sort.direction === 'ASC'
                              ? faSortUp
                              : faSortDown
                            : faSort;
                      }

                      return (
                        <Table.HeaderCell
                          {...column.cellProps}
                          key={key}
                          className={cx(
                            column.cellProps?.className,
                            column.sortable && 'sortable'
                          )}
                          onClick={
                            column.sortable &&
                            (() => {
                              const direction =
                                state.sort?.column !==
                                (column.sortable as string)
                                  ? 'ASC'
                                  : state.sort?.direction === 'DESC'
                                  ? 'ASC'
                                  : 'DESC';

                              setState((draft) => {
                                draft.sort = {
                                  column: column.sortable as any,
                                  direction,
                                };
                              });
                            })
                          }
                        >
                          {column.header}
                          {sortIcon && <FontAwesomeIcon icon={sortIcon} />}
                        </Table.HeaderCell>
                      );
                    })}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {normalizedData.items.map((item, itemIndex) => {
                    const rowProps = config.rowProps?.(item) ?? {};
                    const hash = hashCode(JSON.stringify(item));
                    return (
                      <Table.Row key={hash} {...rowProps}>
                        {config.columns.map((column, columnIndex) => {
                          const key =
                            (column.column as string) ||
                            `column_${columnIndex}`;

                          let copyValue: string = '';

                          if (column.copy) {
                            copyValue = isFunction(column.copy)
                              ? column.copy(item)
                              : !column.render
                              ? (item[column.column] as unknown as string)
                              : '';
                          }

                          return (
                            <Table.Cell {...column.cellProps} key={key}>
                              <span
                                className={cx(copyValue && 'has-copy-icon')}
                              >
                                {column.render
                                  ? column.render(item)
                                  : item[column.column]}
                                {copyValue && (
                                  <CopyButton
                                    value={copyValue}
                                    size="tiny"
                                    className="hidden-row-action"
                                    tabIndex="-1"
                                  />
                                )}
                              </span>
                            </Table.Cell>
                          );
                        })}
                      </Table.Row>
                    );
                  })}
                </Table.Body>
              </Table>
              <Dimmer active={showLoading} inverted />
              {showLoading && <Loader />}
            </Dimmer.Dimmable>
            {pagination.render(
              normalizedData.pageCount,
              normalizedData.totalItemCount
            )}
          </>
        ) : (
          <Loader inline="centered" active={showLoading} />
        )}
      </Segment>
    </>
  );
}

type FilterButton<TDto> = {
  onClick: () => void;
  runtimeConfig?: RuntimeConfig<TDto>;
};

function FilterButton<TDto>(props: FilterButton<TDto>) {
  return (
    <Button
      type="button"
      className="filter"
      basic
      color="black"
      onClick={props.onClick}
    >
      <FontAwesomeIcon icon={faFilter} /> Filter
      {props.runtimeConfig?.filterBadge ? (
        <Label
          className="filter-badge"
          floating
          circular
          color={props.runtimeConfig?.filterBadgeColor ?? 'red'}
        >
          {props.runtimeConfig?.filterBadge}
        </Label>
      ) : (
        ''
      )}
    </Button>
  );
}

export const getActiveFiltersCount = (obj: any) =>
  Object.values(flattenObject(obj)).filter((x) => Boolean(x)).length;

const AdditionalParams = {};
function useAdditionalParams(newParams: AnyObject = AdditionalParams) {
  const [state, setState] = useState(newParams);
  useEffect(() => {
    if (!isEqual(state, newParams)) {
      setState(newParams);
    }
  }, [newParams, state]);
  return state;
}

export function PagedDataTableConfig<TDto>(
  pagedFetchAction: (params: any) => Promise<Response<AdvancedQueryPage<TDto>>>,
  config: PagedDataTableConfig<TDto>
): PagedDataTableConfig<TDto> {
  return config;
}

export const renderEditButton: React.FC<RenderEditButton> = (props) => {
  const {item, route, descriptor} = props;
  const url = buildPath(route, {
    id: item.id,
  });
  return (
    <Tooltip label={`Edit ${descriptor}`}>
      <Button className="clear" basic icon as={Link} to={url}>
        <FontAwesomeIcon icon={faPencil} />
      </Button>
    </Tooltip>
  );
};

const DEFAULT_RESULT = {
  items: [],
  page: 0,
  pageSize: 0,
  pageCount: 0,
  totalItemCount: 0,
};

function normalizedAdvancedPagedResult<T>(
  value: AdvancedQueryPage<T> | null | undefined
): AdvancedQueryPage<T> {
  return value || DEFAULT_RESULT;
}

const styles = css`
  .table-search {
    input {
      border-width: 2px;
      border-color: #cbcfd1;
    }

    &.left.action {
      input {
        margin-left: -1px;
      }

      .ui.button {
        box-shadow: none !important;
        z-index: 0;
      }
    }
  }

  .filter {
    position: relative;
    margin-left: 1rem;
  }

  .filter-badge {
    z-index: 0 !important;
  }

  .table-container {
    overflow-x: auto;
    margin: 2em 0em;
  }

  .paged-table.ui.table {
    thead th {
      white-space: nowrap;
      padding: 0.528571em 0.78571429em !important;
      border-bottom: 1px solid rgba(34, 36, 38, 0.25);

      &:first-of-type {
        padding-left: 0 !important;
      }

      &.sortable {
        user-select: none;
        cursor: pointer;

        &:hover {
          background-color: #f5f5f5;
        }

        svg {
          margin-left: 0.5rem;
        }
      }
    }
  }

  .has-copy-icon {
    white-space: nowrap;
    position: relative;
  }

  .copy-button {
    position: relative;
    opacity: 0;
    margin-left: 0 !important;
    margin-right: -5px !important;
    background-color: ${transparentize(0.2, themeColors.white1)} !important;
    border: solid 1px ${transparentize(0.2, themeColors.white1)} !important;
    height: 36px;
    width: 36px;
    position: absolute;
    top: 50%;
    right: -38px;
    margin-top: -18px;

    &:hover {
      background-color: ${themeColors.white1} !important;
      border-color: inherit !important;
    }
  }

  td:hover .copy-button {
    opacity: 1;
  }

  .no-results .ui.icon.header .icon {
    line-height: 2.5rem;
    font-size: 2rem;
  }

  .ui.buttons {
    .ui.button.basic {
      box-shadow: 0px 0px 0px 1px #144b70 !important;
      color: #144b70 !important;

      &.active {
        background: #144b70 !important;
        color: #fff !important;
      }

      &:active,
      &:focus,
      &:hover {
        background: ${lighten(0.05, '#144b70')} !important;
        color: #fff !important;
      }
    }
  }
`;

export const PagedDataTableStyle = css`
    .ui.segment {
      border: 0;
      box-shadow: none;
      border-radius: 0.9375rem;
      width: 100%;
    }

    td {
      color: ${MobileaseTheme.colors.grey600};
      font-size: 0.875rem;
    }

    .ui.table.selectable tbody tr:hover {
      background: ${MobileaseTheme.colors.blue50} !important;
    }

    th {
      font-size: 1rem;
      color: ${MobileaseTheme.colors.grey600} !important;
    }

    .ui.pagination.menu {
      background: ${MobileaseTheme.colors.blue50};
      border: 0;
    }

    .ui.menu .item {
      color: ${MobileaseTheme.colors.blue500};
    }

    .ui.pagination.menu .active.item {
      color: white;
      background: ${MobileaseTheme.colors.blue500};
    }

    .header {
      font-size: 0.875rem;
      font-weight: 600;
      color: ${MobileaseTheme.colors.grey600};
    }
    .sub-header {
      display: flex;
      font-size: 0.75rem;
      color: ${MobileaseTheme.colors.grey600};
      justify-content: flex-start;
      align-items: center;
    }

    .circle.icon {
      margin: 0em 0.25rem 0em 0.25rem;
      color: ${MobileaseTheme.colors.grey300};
      font-size: 0.3125rem;
      height: auto;
    }

    .ui.label {
      background: ${MobileaseTheme.colors.purple50};
      color: ${MobileaseTheme.colors.purple500};
    }

    .ui.selection.dropdown {
      background: ${MobileaseTheme.colors.blue50};
      color: ${MobileaseTheme.colors.blue500};
      border: 0;
    }

    .ui.dropdown .menu {
      background: ${MobileaseTheme.colors.blue50};
      color: ${MobileaseTheme.colors.blue500};
      border: 0;

      & > .item {
        border-top: 0;
      }

      & .selected.item {
        color: white;
        background: ${MobileaseTheme.colors.blue500};
      }
    }
  }
`;
