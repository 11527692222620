/* eslint-disable react-perf/jsx-no-new-object-as-prop */

import css from '@emotion/css/macro';
import {DialogContent, DialogOverlay} from '@reach/dialog';
import '@reach/dialog/styles.css';
import React from 'react';

type Props = {
  open: boolean;
  children: React.ReactNode;
  onRequestClose?: () => void;
} & StyleProps;

type StyleProps = {
  scroll: boolean;
  width: number;
};

export const SlideOut = ({
  open,
  children,
  onRequestClose,
  width,
  scroll,
}: Props) => {
  return (
    <DialogOverlay
      isOpen={open}
      className="slide-backdrop"
      onDismiss={onRequestClose}
      css={styles}
    >
      <DialogContent
        className="slide-overlay"
        aria-label="dialog content"
        style={{
          width: `${width}px`,
          overflow: scroll ? 'auto' : 'hidden',
        }}
      >
        {children}
      </DialogContent>
    </DialogOverlay>
  );
};

SlideOut.Header = ({children}) => (
  <div className="slide-header">{children}</div>
);

SlideOut.Content = ({children}) => (
  <div className="slide-content">{children}</div>
);

const styles = css`
  &.slide-backdrop {
    z-index: 100;
  }
  .slide-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    box-shadow: -3px 0px 10px -5px rgba(0, 0, 0, 0.5);
    z-index: 1000;
    border-left: solid 1px #c3c3c3;
    display: flex;
    flex-direction: column;
    border-top-left-radius: 5px;
    margin: 0;
    padding: 0;

    .slide-header {
      display: flex;
      align-items: center;
      padding: 1rem 1.5rem;
      background: #fff;

      .ui.header {
        margin: 0;
        flex: 1 1 0%;
      }
    }

    .slide-content {
      flex: 1 1 0%;
      overflow-y: auto;
      padding: 0 1.5rem;
    }
  }
`;
