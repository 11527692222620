import {useCallback, useState} from 'react';

interface Cache {
  [key: string]: any;
}

export const cache: Cache = {};

export function useCachedState<T>(initialValue: T, persistKey: string) {
  const persistedValue: T = cache[persistKey];
  const [value, setValue] = useState(() => persistedValue || initialValue);

  const handleUpdateValue = useCallback(
    (newValue: T) => {
      cache[persistKey] = newValue;
      setValue(newValue);
    },
    [persistKey]
  );

  return [value, handleUpdateValue] as [T, (newValue: T) => void];
}
