import {css} from '@emotion/core';
import {faPencil, faTrashAlt} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useState} from 'react';
import {useAsyncFn} from 'react-use';
import {Button, Divider, Modal, Segment} from 'semantic-ui-react';
import {QuoteCommentGetDto, QuoteCommentsService} from '../api/generated';
import {AsyncStateContainer} from '../components/async-state-container';
import {DeleteButton} from '../components/confirm-button';
import {DateFormat} from '../components/date';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {fieldConfig, textarea} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {MobileaseTheme} from '../styles/branding';

export const QuoteCommentsModal: React.FC<{
  quoteId: number;
  quoteComments: QuoteCommentGetDto[] | undefined;
  openCommentsModal: boolean;
  setOpenCommentsModal: (boolean) => void;
  isLoading: boolean;
  commentsRetry: () => void;
}> = ({
  quoteId,
  quoteComments,
  openCommentsModal,
  setOpenCommentsModal,
  isLoading,
  commentsRetry,
}) => {
  const notifications = useNotification();

  const [selectedCommentId, setSelectedCommentId] = useState<number>(0);
  const [selectedCommentText, setSelectedCommentText] = useState<string>('');

  const onSubmit = async (values) => {
    const response = await QuoteCommentsService.create({
      body: {quoteId: quoteId, text: values.text},
    });
    if (response.hasErrors) {
      return response;
    }

    commentsRetry();
    setOpenCommentsModal(false);
  };

  const onUpdate = async () => {
    const response = await QuoteCommentsService.update({
      body: {id: selectedCommentId, text: selectedCommentText},
    });
    if (response.hasErrors) {
      notifications.error(response.errors[0].errorMessage);
      return response;
    }

    commentsRetry();
    setSelectedCommentId(0);
  };

  const handleEditClick = (id: number, text: string) => {
    setSelectedCommentId(id);
    setSelectedCommentText(text);
  };

  const handleChange = (e) => {
    setSelectedCommentText(e.target.value);
  };

  const fields = fieldConfig<any>({
    text: textarea({
      fieldLabel: 'Add New Comment',
      inputProps: {
        rows: 2,
      },
    }),
  });

  const FormFields = () => {
    return (
      <>
        <Form.Row>
          <Form.TextArea fieldConfig={fields.text} />
        </Form.Row>
      </>
    );
  };

  const [deleteCommentState, deleteComment] = useAsyncFn(async (id) => {
    const response = await QuoteCommentsService.deleteById({id});
    if (response.hasErrors) {
      notifications.errors(response.errors);
    } else {
      notifications.success('Comment successfully deleted');
      commentsRetry();
    }
  });

  return (
    <Modal
      css={commentStyles}
      open={openCommentsModal}
      onClose={() => setOpenCommentsModal(false)}
    >
      <Modal.Header>Quote Comments</Modal.Header>
      <AsyncStateContainer loading={isLoading}>
        <Modal.Content>
          <div className="modal-table-container">
            {quoteComments && quoteComments.length > 0 ? (
              quoteComments.map((comment) => {
                return comment.id === selectedCommentId ? (
                  <Flex.Col className="comment-container">
                    <Flex.Row justifyContent="space-between" align="center">
                      <Flex.Col>
                        <strong>{comment.createdByUserName}</strong>
                      </Flex.Col>
                      <Flex.Col className="comment-date">
                        <DateFormat date={comment.createdDate} />
                      </Flex.Col>
                    </Flex.Row>
                    <Flex.Row>
                      <textarea
                        className="update-comment-text"
                        onChange={handleChange}
                      >
                        {selectedCommentText}
                      </textarea>
                    </Flex.Row>
                    <Flex.Row justifyContent="flex-end" align="center">
                      <Flex.Col>
                        <Button primary size="small" onClick={onUpdate}>
                          Update
                        </Button>
                      </Flex.Col>
                      <Flex.Col>
                        <Button
                          className="clear"
                          basic
                          size="small"
                          onClick={() => setSelectedCommentId(0)}
                        >
                          Cancel
                        </Button>
                      </Flex.Col>
                    </Flex.Row>
                  </Flex.Col>
                ) : (
                  <Flex.Col className="comment-container">
                    <Flex.Row justifyContent="space-between" align="center">
                      <Flex.Col>
                        <strong>{comment.createdByUserName}</strong>
                      </Flex.Col>
                      <Flex.Col className="comment-date">
                        <DateFormat date={comment.createdDate} />
                      </Flex.Col>
                    </Flex.Row>
                    <Flex.Row>{comment.text}</Flex.Row>
                    {comment.canModify && (
                      <Flex.Row justifyContent="flex-end" align="center">
                        <Flex.Col>
                          <Button
                            className="clear"
                            basic
                            icon
                            size="small"
                            onClick={() =>
                              handleEditClick(comment.id, comment.text)
                            }
                          >
                            <FontAwesomeIcon icon={faPencil} />
                          </Button>
                        </Flex.Col>
                        <Flex.Col>
                          <DeleteButton
                            icon={faTrashAlt}
                            text=""
                            onConfirm={() => deleteComment(comment.id)}
                            loading={deleteCommentState.loading}
                          />
                        </Flex.Col>
                      </Flex.Row>
                    )}
                  </Flex.Col>
                );
              })
            ) : (
              <Segment placeholder textAlign="center" size="big">
                This quote has no comments
              </Segment>
            )}
          </div>
          <Divider />
          <Form.Container basic>
            <Form
              onSubmit={onSubmit}
              render={() => {
                return (
                  <>
                    <FormFields />
                    <Divider />
                    <Button type="submit" primary content="Submit" />
                    <Button
                      onClick={() => {
                        setOpenCommentsModal(false);
                        setSelectedCommentId(0);
                      }}
                      secondary
                      content="Cancel"
                    />
                  </>
                );
              }}
            />
          </Form.Container>
        </Modal.Content>
      </AsyncStateContainer>
    </Modal>
  );
};

const commentStyles = css`
  .modal-table-container {
    overflow: hidden;
    overflow-y: scroll;
    height: 30vh;
  }

  .comment-container {
    border-style: solid;
    border-width: 2px;
    border-color: ${MobileaseTheme.colors.blue50};
    border-radius: 1rem;
    padding: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .comment-date {
    font-size: 0.75rem;
  }

  .update-comment-text {
    width: 100%;
    height: 5rem;
    border-color: ${MobileaseTheme.colors.blue500};
    margin: 0.5rem 0 0.5rem 0;
    padding: 0.5rem;
  }
`;
