import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {RequestTemplateListing} from './request-template-listing';
import {RequestTemplateEdit} from './request-template-update';

export const RequestTemplateRoutes = () => {
  return (
    <Switch>
      <Route path={routes.requestTemplates.index} exact>
        <RequestTemplateListing />
      </Route>
      <Route path={routes.requestTemplates.detail}>
        <RequestTemplateEdit />
      </Route>
    </Switch>
  );
};
