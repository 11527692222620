import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  faFilePdf,
  faUpload,
  faTimesCircle,
  faRedo,
} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import moment from 'moment';
import {
  GetCurrentInvoiceGenerationBatchResponse,
  InvoicesService,
} from '../api/generated';
import {DropdownOption} from '../api/generated/utils';
import {BasicPage} from '../basic-page';
import {DateFormat} from '../components/date';
import {Tooltip} from '../components/tooltip';
import {Form} from '../forms';
import {Button} from '../forms/button';
import {dropdown, fieldConfig} from '../forms/schema-utils';
import {
  AdditionalParams,
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useProduce} from '../hooks/use-produce';
import {useHistory} from 'react-router-dom';
import {routes} from '../routes';
import {useAsyncFn} from 'react-use';
import {useNotification} from '../hooks/use-notifications';
import {currencyFormatter} from '../assets/asset-utils';
import {ConfirmButton} from '../components/confirm-button';
import {AdvancedPagedRequest} from '../types';

type FilterState = AdditionalParams<typeof InvoicesService.getAllMonthly>;
type ComponentState = {
  filter: FilterState;
  tableState?: Parameters<typeof InvoicesService.getAllMonthly>[0];
};

export const MonthlyInvoiceListing = () => {
  const [tableRefresh, setTableRefresh] = useState(+new Date());
  const [batchStatus, setBatchStatus] =
    useState<GetCurrentInvoiceGenerationBatchResponse | null>(null);
  const [filterState, setFilterState] = useProduce<ComponentState>({
    filter: {
      searchMonth: dropdownOptions[0].value,
    },
  });

  const isCurrentMonth = useMemo(() => {
    return filterState.filter.searchMonth === dropdownOptions[0].value;
  }, [filterState.filter.searchMonth]);

  const onSubmit = (values) => {
    setFilterState((x) => (x.filter.searchMonth = values.billingCycle));
  };

  const fetchTable = useCallback(
    (x: AdvancedPagedRequest<any>) => InvoicesService.getAllMonthly({...x}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [tableRefresh]
  );

  const [generateAllInvoicesState, generateAllInvoices] = useAsyncFn(
    async () => {
      await InvoicesService.generateAll();
      await fetchBatchStatus();
    }
  );

  const fetchBatchStatus = async () => {
    const response = await InvoicesService.getBatchStatus();
    if (!response.hasErrors) {
      setBatchStatus(response.data);
    }
  };

  useEffect(() => {
    if (batchStatus === null) {
      setTimeout(fetchBatchStatus);
    } else if (batchStatus.isGenerating) {
      setTimeout(fetchBatchStatus, 10000);
    }
  }, [batchStatus]);

  const tableConfig = useMemo(() => {
    let config = {
      ...TableConfig(
        filterState.filter.searchMonth,
        isCurrentMonth,
        setTableRefresh
      ),
    };
    return config;
  }, [filterState.filter.searchMonth, isCurrentMonth, setTableRefresh]);

  const dataTable = usePagedDataTable(fetchTable, tableConfig, {
    additionalParams: {
      ...filterState.filter,
    },
    actions: (
      <>
        {isCurrentMonth &&
          (batchStatus?.isGenerating ? (
            <p>
              Generating Invoices... ({batchStatus.numComplete} /{' '}
              {batchStatus.total} complete)
            </p>
          ) : (
            <ConfirmButton
              className=""
              basic={false}
              secondary
              text="Generate Invoices"
              onConfirm={generateAllInvoices}
              loading={generateAllInvoicesState.loading}
              loadingText="Queuing..."
            ></ConfirmButton>
          ))}
      </>
    ),
  });

  return (
    <>
      <BasicPage title="">
        <TableFilters
          onSubmit={onSubmit}
          billingCycle={filterState.filter.searchMonth}
        />
        {dataTable}
      </BasicPage>
    </>
  );
};

const getDropdownOptions = () => {
  const startMonth = moment('2021-12-01');
  const currentMonth = moment().add(1, 'M').startOf('M');
  const months = currentMonth.diff(startMonth, 'M');
  const options = [] as DropdownOption[];
  options.push({
    text: currentMonth.format('MMMM YYYY'),
    value: currentMonth.format(),
  });
  for (let i = 0; i < months; i++) {
    const monthToAdd = currentMonth.add(-1, 'M');
    options.push({
      text: monthToAdd.format('MMMM YYYY'),
      value: monthToAdd.format(),
    });
  }
  return options;
};

const dropdownOptions = getDropdownOptions();

const fields = fieldConfig<any>({
  billingCycle: dropdown({
    fieldLabel: 'Billing Cycle',
    inputProps: {
      options: dropdownOptions,
      placeholder: 'Select a Billing Cycle',
      selection: true,
    },
  }),
});

const TableFilters = ({onSubmit, billingCycle}) => {
  return (
    <>
      <Form.Container>
        <Form
          initialValues={{billingCycle}}
          onSubmit={(values) => {
            onSubmit(values);
          }}
          render={() => (
            <>
              <Form.Row proportions={[1, 3]}>
                <Form.Dropdown fieldConfig={fields.billingCycle} />
              </Form.Row>
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  View Billing Cycle
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </>
  );
};

const TableConfig = (date, isCurrentMonth, setTableRefresh) =>
  PagedDataTableConfig(InvoicesService.getAllMonthly, {
    columns: [
      {
        header: 'Customer #',
        column: 'customerId',
        sortable: 'customerId',
      },
      {
        header: 'Customer Name',
        render: (item) => (
          <>
            {`${item.nameFirst} ${item.nameBusiness} ${item.nameSuffix}`.trim()}
          </>
        ),
      },
      {
        header: 'Invoice #',
        column: 'invoiceNumber',
      },
      {
        header: 'Invoice Date',
        render: (item) =>
          item.invoiceDate ? (
            <>
              <DateFormat date={item.invoiceDate} />
            </>
          ) : (
            <></>
          ),
      },
      {
        header: 'Due Date',
        render: (item) =>
          item.dueDate ? (
            <>
              <DateFormat date={item.dueDate} />
            </>
          ) : (
            <></>
          ),
      },
      {
        header: 'Amount',
        render: (item) => <>{item.amount && currencyFormatter(item.amount)}</>,
      },
      {
        header: '',
        render: (item) =>
          item.invoiceId ? (
            <ViewInvoiceButton id={item.invoiceId} />
          ) : (
            <UploadInvoiceButton customerId={item.customerId} date={date} />
          ),
        cellProps: {
          collapsing: true,
        },
      },
      {
        header: '',
        render: (item) =>
          item.invoiceId ? (
            <DeleteInvoiceButton
              id={item.invoiceId}
              setTableRefresh={setTableRefresh}
            />
          ) : item.shouldGenerateInvoice ? (
            <>
              {isCurrentMonth && (
                <GenerateInvoiceButton
                  customerId={item.customerId}
                  setTableRefresh={setTableRefresh}
                />
              )}
            </>
          ) : (
            <>{isCurrentMonth && <DisabledGenerateInvoiceButton />}</>
          ),
        cellProps: {
          collapsing: true,
        },
      },
    ],
    defaultSort: {
      column: 'customerId',
      direction: 'ASC',
    },
    searchFieldNames: ['customerId', 'nameFirst', 'nameBusiness'],
    initialPageSize: 10,
  });

export const ViewInvoiceButton = ({id}) => {
  const notifications = useNotification();

  const [getLinkState, sendGetLink] = useAsyncFn(async () => {
    const response = await InvoicesService.getInvoiceLink({
      id,
    });
    if (response.data) {
      window.open(response.data.toString(), '_blank');
    } else {
      notifications.error(response.errors);
    }
  });

  return (
    <>
      <Tooltip label="View Invoice">
        <Button
          className="clear"
          basic
          icon
          type="button"
          loading={getLinkState.loading}
          onClick={sendGetLink}
        >
          <FontAwesomeIcon icon={faFilePdf} />
        </Button>
      </Tooltip>
    </>
  );
};

const UploadInvoiceButton = ({customerId, date}) => {
  const history = useHistory();
  return (
    <Tooltip label="Upload Invoice">
      <Button
        className="clear"
        basic
        icon
        type="button"
        onClick={() => {
          history.push({
            pathname: routes.invoices.upload,
            state: {
              customerId: customerId,
              billingCycleDate: date,
              isSupplemental: false,
              returnRoute: routes.invoices.monthly,
            },
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
      </Button>
    </Tooltip>
  );
};

const GenerateInvoiceButton = ({customerId, setTableRefresh}) => {
  const notifications = useNotification();

  const [sendGenerateState, sendGenerate] = useAsyncFn(async () => {
    const response = await InvoicesService.generate({
      customerId,
    });

    if (response.hasErrors) {
      notifications.errors(response.errors);
    } else {
      notifications.success('Invoice Generated.');
      setTableRefresh(+new Date());
    }
  });

  return (
    <ConfirmButton
      onConfirm={sendGenerate}
      loading={sendGenerateState.loading}
      loadingText=""
      icon={faRedo}
      tooltip="Generate Invoice"
    ></ConfirmButton>
  );
};

const DisabledGenerateInvoiceButton = () => {
  return (
    <Tooltip label="Not available for this customer">
      <Button className="clear" basic icon type="button" disabled>
        <FontAwesomeIcon icon={faRedo} />
      </Button>
    </Tooltip>
  );
};

const confirmProps = {negative: true, primary: false};

const DeleteInvoiceButton = ({id, setTableRefresh}) => {
  const notifications = useNotification();

  const [deleteInvoiceState, deleteInvoice] = useAsyncFn(async () => {
    const response = await InvoicesService.delete({id});
    if (response.hasErrors) {
      notifications.error('Unable to delete invoice');
    } else {
      notifications.success('Invoice successfully deleted');
      setTableRefresh(+new Date());
    }
  });

  return (
    <ConfirmButton
      onConfirm={deleteInvoice}
      loading={deleteInvoiceState.loading}
      loadingText=""
      icon={faTimesCircle}
      tooltip="Delete Invoice"
      confirmProps={confirmProps}
    ></ConfirmButton>
  );
};
