import css from '@emotion/css/macro';
import {cx} from 'emotion';
import React from 'react';
import {Container} from 'semantic-ui-react';
import {PageTitle} from './components/page-title';
import {RequestButtonProps, SecondaryNavigation} from './secondary-navigation';
import {Media} from './styles/breakpoints';

export type TitlePart = {
  title: string;
  url?: string;
};

type Props = {
  title: string | TitlePart[];
  className?: string;
  showSecondaryNavigation?: boolean;
  navigationRequestButtons?: RequestButtonProps[];
};

export const BasicPage: React.FC<Props> = React.forwardRef(
  (
    {
      title,
      children,
      className,
      showSecondaryNavigation,
      navigationRequestButtons,
    },
    ref: any
  ) => {
    return (
      <div>
        {showSecondaryNavigation ? (
          <SecondaryNavigation
            navigationRequestButtons={navigationRequestButtons}
          />
        ) : null}
        <Container
          className={cx(className, 'basic-page')}
          ref={ref}
          css={styles}
          fluid
        >
          <div className="content">
            <PageTitle title={title} />
            {children}
          </div>
        </Container>
      </div>
    );
  }
);

const styles = css`
  &.ui.container {
    max-width: 1380px !important;

    ${Media('MobileMax')} {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  > .content {
    padding: 25px;

    ${Media('MobileMax')} {
      padding: 1rem 1rem 0;
    }
  }
`;
