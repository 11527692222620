import _ from 'lodash';
import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {routes} from '../routes/index';
import {
  FinancialListing,
  FinancialListingTabTypes,
  FinancialTypeRoutesMap,
} from './financial-listing';

export const FinancialRoutes = () => {
  const location = useLocation();
  const financialsRouteMapInverted = _.invert(FinancialTypeRoutesMap);
  return (
    <Switch>
      <Route path={routes.financials.index}>
        <FinancialListing
          activeTab={
            (financialsRouteMapInverted[
              location.pathname
            ] as FinancialListingTabTypes) ?? FinancialListingTabTypes.INVOICES
          }
        />
      </Route>
    </Switch>
  );
};
