import React, {useMemo} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncFn} from 'react-use';
import {
  CreateRoutePatternRequest,
  RoutePatternsService,
} from '../api/generated';
import {BasicPage} from '../basic-page';
import {DeleteButton} from '../components/confirm-button';
import {Form} from '../forms';
import {fieldConfig, getDefaults, input} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

const fields = fieldConfig<CreateRoutePatternRequest>({
  description: input({
    fieldLabel: 'Description',
  }),
  pattern: input({
    fieldLabel: 'Pattern',
  }),
});

const blankRoutePattern = getDefaults(fields);

const breadcrumbs = [
  {title: 'Route Patterns', url: routes.admin.routePatterns.index},
  {title: 'Create'},
];

export const RoutePatternCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const onSubmit = async (value) => {
    const response = await RoutePatternsService.create({body: value});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Route Pattern Created');
    history.push(routes.admin.routePatterns.index);
  };

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          initialValues={blankRoutePattern}
          onSubmit={onSubmit}
          render={() => {
            return (
              <>
                <FormFields />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Create
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.admin.routePatterns.index}
                  >
                    Cancel
                  </Form.Button>
                </div>
              </>
            );
          }}
        />
      </Form.Container>
    </BasicPage>
  );
};

export const RoutePatternUpdate = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const notifications = useNotification();

  const fetchRoutePattern = useAsync(async () => {
    const {data} = await RoutePatternsService.getById({
      id,
    });
    return data;
  }, [id]);

  const routePattern = fetchRoutePattern.value || undefined;
  const routePatternDescription = routePattern?.description ?? '';

  const breadcrumbs = useMemo(
    () => [
      {title: 'Route Patterns', url: routes.admin.routePatterns.index},
      {title: routePatternDescription},
    ],
    [routePatternDescription]
  );

  const onSubmit = async (value) => {
    const response = await RoutePatternsService.update({id, body: value});

    if (response.hasErrors) {
      return response;
    }

    notifications.success('Route Pattern Updated');
    history.push(routes.admin.routePatterns.index);
  };

  const [deleteRoutePatternState, deleteRoutePattern] = useAsyncFn(async () => {
    const response = await RoutePatternsService.deleteById({id});
    if (response.hasErrors) {
      notifications.error('Unable to delete route pattern');
    } else {
      notifications.success('Route pattern successfully deleted');
      history.push(routes.admin.routePatterns.index);
    }
  });

  return (
    <BasicPage title={breadcrumbs}>
      <Form.Container>
        <Form
          initialValues={fetchRoutePattern.value ?? undefined}
          onSubmit={onSubmit}
          render={() => {
            return (
              <>
                <FormFields />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Save
                  </Form.Button>
                  <Form.Button
                    secondary
                    as={Link}
                    to={routes.admin.routePatterns.index}
                  >
                    Cancel
                  </Form.Button>
                  <DeleteButton
                    onConfirm={deleteRoutePattern}
                    loading={deleteRoutePatternState.loading}
                  />
                </div>
              </>
            );
          }}
        />
      </Form.Container>
    </BasicPage>
  );
};

const FormFields = () => {
  return (
    <>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.description} />
      </Form.Row>
      <Form.Row proportions={[1, 1]}>
        <Form.Input fieldConfig={fields.pattern} />
      </Form.Row>
    </>
  );
};
