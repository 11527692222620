import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Modal} from 'semantic-ui-react';
import {QuotesService} from '../api/generated';
import {Flex} from '../components/flex';
import {routes} from '../routes';
import {buildPath} from '../routes/utils';
import {notifications} from '../utils/notification-service';

export const CloneQuoteButton: React.FC<{quoteId: number}> = ({quoteId}) => {
  const [cloningQuote, setCloningQuote] = useState<boolean>(false);
  const history = useHistory();
  const cloneQuote = async () => {
    const response = await QuotesService.cloneQuote({body: {quoteId}});
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    notifications.success('Quote cloned successfully');

    response.data &&
      history.push(
        buildPath(routes.quotes.detail, {
          id: response.data.newQuoteId,
          stage: '',
        })
      );
  };

  return (
    <>
      <Button
        onClick={() => {
          setCloningQuote(true);
        }}
      >
        Clone Quote
      </Button>
      <Modal
        onClose={() => {
          setCloningQuote(false);
        }}
        open={cloningQuote}
      >
        <Modal.Header>Are you sure you want to clone this quote?</Modal.Header>
        <Modal.Content>
          This will create a new quote with the same asset and term information.
        </Modal.Content>
        <Modal.Actions>
          <Flex.Row>
            <Button primary onClick={cloneQuote}>
              Clone Quote
            </Button>
            <Button
              secondary
              onClick={() => {
                setCloningQuote(false);
              }}
            >
              Cancel
            </Button>
          </Flex.Row>
        </Modal.Actions>
      </Modal>
    </>
  );
};
