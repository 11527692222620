import React from 'react';
import {Route, Switch} from 'react-router-dom';
import {routes} from '../routes/index';
import {LogGroupDetail} from './log-group-detail';
import {LogsListing} from './logs-listing';

export const LogRoutes = () => {
  return (
    <Switch>
      <Route path={routes.admin.logs.index} exact>
        <LogsListing />
      </Route>
      <Route path={routes.admin.logs.groupedDetail}>
        <LogGroupDetail />
      </Route>
    </Switch>
  );
};
