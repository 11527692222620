import css from '@emotion/css';
import {faTools, faTruckPickup} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {useHistory} from 'react-router';
import {useAsync} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {DashboardService, LeaseCompleteCustomerService} from '../api/generated';
import {
  AssetFilters,
  AssetMaintenanceFilters,
  AssetStatuses,
  RequestTemplateKeys,
} from '../api/generated/enums';
import {AssetFilterState} from '../assets/assets-listing';
import {useRequireAuthorization} from '../auth/require-authorization';
import {AsyncStateContainer} from '../components/async-state-container';
import {Flex} from '../components/flex';
import {useCustomerContext} from '../customer-context';
import {useRequestTemplateModal} from '../request-templates/request-template-context';
import {routes} from '../routes';
import {SecondaryNavigation} from '../secondary-navigation';
import {AttentionCard, AttentionCardType} from './attention-card';
import {FleetExpensesChart} from './charts/fleet-expenses-chart';
import {FleetOverviewChart} from './charts/fleet-overview-chart';
import {CustomerDetailsCard} from './customer-details-card';
import {DashboardPanel} from './dashboard-panel';
import {DashboardPlaceholder} from './dashboard-placeholder';

export const CustomerDashboard = () => {
  const [customerContext] = useCustomerContext();
  const {currentCustomerId} = customerContext;

  const canViewAssets = useRequireAuthorization({permission: 'Assets: View'});
  const history = useHistory<AssetFilterState>();
  const {openRequestTemplateModal} = useRequestTemplateModal();
  const fetchCustomerDetails = useAsync(async () => {
    return await LeaseCompleteCustomerService.getDashboardById({
      id: currentCustomerId,
    });
  }, [currentCustomerId]);
  const fetchDashboardMetrics = useAsync(async () => {
    return await DashboardService.getStats({customerId: currentCustomerId});
  }, [currentCustomerId]);

  const customer = fetchCustomerDetails?.value?.data;
  const dashboardDto = fetchDashboardMetrics?.value?.data;
  const expired = dashboardDto?.expiredRegistrations;
  const upcomingExpirations = dashboardDto?.upcomingRegistrationExpirations;

  const requestButtons = [
    {
      label: 'New Order',
      icon: faTruckPickup,
      templateKey: RequestTemplateKeys.NewOrder,
    },
  ];

  return (
    <>
      <SecondaryNavigation navigationRequestButtons={requestButtons} />
      <AsyncStateContainer loading={fetchDashboardMetrics.loading}>
        {currentCustomerId && dashboardDto ? (
          <div css={dashboardStyles}>
            <Flex.Row fill>
              <Flex.Col className="min-width-25">
                <DashboardPanel headerText="Account">
                  <Flex.Row fill className="width-100">
                    {customer && (
                      <CustomerDetailsCard
                        customer={customer}
                        reviewDate={dashboardDto.reviewDate}
                      />
                    )}
                  </Flex.Row>
                </DashboardPanel>
              </Flex.Col>
              <Flex.Col fill>
                <Flex.Row>
                  <Flex.Col fill>
                    <DashboardPanel headerText="Leases">
                      <Flex.Row>
                        <AttentionCard
                          label="Upcoming Lease Expirations"
                          value={dashboardDto.upcomingPastDueLeases}
                          type={AttentionCardType.Warning}
                          onClick={() => {
                            if (canViewAssets) {
                              history.push({
                                pathname: routes.assets.index,
                                state: {
                                  assetLeaseExpirationStatus:
                                    AssetFilters.Expiring,
                                  assetStatus: AssetStatuses.Leased,
                                },
                              });
                            }
                          }}
                        />
                      </Flex.Row>
                      <Flex.Row>
                        <AttentionCard
                          label="Past Due Leases"
                          value={dashboardDto.pastDueLeases}
                          type={AttentionCardType.Danger}
                          onClick={() => {
                            if (canViewAssets) {
                              history.push({
                                pathname: routes.assets.index,
                                state: {
                                  assetLeaseExpirationStatus:
                                    AssetFilters.Expired,
                                  assetStatus: AssetStatuses.Leased,
                                },
                              });
                            }
                          }}
                        />
                      </Flex.Row>
                    </DashboardPanel>
                  </Flex.Col>
                  <Flex.Col fill>
                    <DashboardPanel headerText="Registration">
                      <Flex.Row>
                        <AttentionCard
                          label="Upcoming Expirations"
                          value={upcomingExpirations}
                          type={AttentionCardType.Warning}
                          onClick={() => {
                            if (canViewAssets) {
                              history.push({
                                pathname: routes.assets.index,
                                state: {
                                  assetRegistrationStatus:
                                    AssetFilters.Expiring,
                                  assetStatus: AssetStatuses.Leased,
                                },
                              });
                            }
                          }}
                        />
                      </Flex.Row>
                      <Flex.Row>
                        <AttentionCard
                          label="Expired"
                          value={expired}
                          type={AttentionCardType.Danger}
                          onClick={() => {
                            if (canViewAssets) {
                              history.push({
                                pathname: routes.assets.index,
                                state: {
                                  assetRegistrationStatus: AssetFilters.Expired,
                                  assetStatus: AssetStatuses.Leased,
                                },
                              });
                            }
                          }}
                        />
                      </Flex.Row>
                    </DashboardPanel>
                  </Flex.Col>
                  <Flex.Col fill>
                    <DashboardPanel headerText="Maintenance">
                      {dashboardDto.assetsUnderMaintenancePlans > 0 ? (
                        <>
                          <Flex.Row>
                            <AttentionCard
                              label="Vehicles With Maintenance"
                              value={dashboardDto.assetsUnderMaintenancePlans}
                              type={AttentionCardType.Information}
                              onClick={() => {
                                history.push({
                                  pathname: routes.assets.index,
                                  state: {
                                    maintenancePlan:
                                      AssetMaintenanceFilters[
                                        'Under Maintenance'
                                      ],
                                    assetStatus: AssetStatuses.Leased,
                                  },
                                });
                              }}
                            />
                          </Flex.Row>
                          <Flex.Row>
                            <AttentionCard
                              label="Vehicles Without Maintenance"
                              value={
                                dashboardDto.assetsNotUnderMaintenancePlans
                              }
                              type={AttentionCardType.Information}
                              onClick={() => {
                                history.push({
                                  pathname: routes.assets.index,
                                  state: {
                                    maintenancePlan:
                                      AssetMaintenanceFilters[
                                        'Not Under Maintenance'
                                      ],
                                    assetStatus: AssetStatuses.Leased,
                                  },
                                });
                              }}
                            />
                          </Flex.Row>
                        </>
                      ) : (
                        <Flex.Row>
                          <AttentionCard
                            label="Enroll in Maintenance Program"
                            value={
                              <FontAwesomeIcon icon={faTools} size={'xs'} />
                            }
                            type={AttentionCardType.CallToAction}
                            onClick={() => {
                              openRequestTemplateModal(
                                RequestTemplateKeys.EnrollInMaintenance
                              );
                            }}
                          />
                        </Flex.Row>
                      )}
                    </DashboardPanel>
                  </Flex.Col>
                </Flex.Row>
                <Divider hidden />
                <Flex.Row justifyContent="space-around">
                  <Flex.Col fill>
                    <DashboardPanel headerText="Fleet Overview">
                      <FleetOverviewChart
                        fetchFleetOverView={() =>
                          DashboardService.getFleetOverview({
                            customerId: currentCustomerId,
                          })
                        }
                        onChartClick={(assetStatusSelected: string) => {
                          history.push({
                            pathname: routes.assets.index,
                            state: {assetStatus: assetStatusSelected},
                          });
                        }}
                      />
                    </DashboardPanel>
                  </Flex.Col>
                  <Flex.Col fill>
                    <DashboardPanel headerText="Fleet Expenses">
                      <FleetExpensesChart />
                    </DashboardPanel>
                  </Flex.Col>
                </Flex.Row>
              </Flex.Col>
            </Flex.Row>
          </div>
        ) : (
          <DashboardPlaceholder text="Please Select a Customer to View" />
        )}
      </AsyncStateContainer>
    </>
  );
};

const dashboardStyles = css`
  padding: 0 1rem;

  .width-100 {
    width: 100%;
  }

  .width-75 {
    width: 75%;
  }

  .min-width-25 {
    min-width: 25%;
  }
`;
