import {FormContainer} from '../../forms/container';
import {QuoteStageProps} from '../quote-create-update';
import React, {useEffect, useMemo, useState} from 'react';
import {Button, Divider, Header, Modal} from 'semantic-ui-react';
import {Form} from '../../forms';
import {
  CreateNewQuoteTermsTemplateFromQuoteRequest,
  QuoteGetDto,
  QuotesService,
  QuoteTermsTemplatesService,
  SaveQuoteAdditionalValuesRequest,
} from '../../api/generated';
import {notifications} from '../../utils/notification-service';
import {
  dropdown,
  fieldConfig,
  input,
  RawFieldConfig,
} from '../../forms/schema-utils';
import {Flex} from '../../components/flex';
import {QuoteStageFormActions} from './quote-stage-form-actions';
import {ConfirmButton} from '../../components/confirm-button';
import {css} from '@emotion/core';
import {useAsync, useAsyncRetry} from 'react-use';
import {CloneQuoteButton} from '../clone-quote';

export const QuoteFinalDocuments: React.FC<QuoteStageProps> = ({
  quote,
  refetchQuote,
}) => {
  const [sendingToLeaseComplete, setSendingToLeaseComplete] = useState(false);

  const handleSendToLeaseComplete = async () => {
    setSendingToLeaseComplete(true);
    const response = await QuotesService.sendQuoteToLeaseComplete({
      body: {quoteId: quote.id},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      setSendingToLeaseComplete(false);
      return;
    }
    setSendingToLeaseComplete(false);
    refetchQuote();
  };

  return (
    <>
      <FormContainer>
        {quote.leaseCompleteQuoteId ? (
          <>
            <Header>Book In LeaseComplete</Header>
            <p>
              This quote has been submitted to LeaseComplete and is ready for
              booking.
            </p>
            <p>
              LeaseComplete Quote ID:{' '}
              <strong>{quote.leaseCompleteQuoteId}</strong>
            </p>
          </>
        ) : (
          <>
            <Header>Additional Information</Header>
            <p>
              Provide a VIN and additional customer info (if applicable) before
              submitting this quote to LeaseComplete.
            </p>
            <AddAdditionalInformationButton quote={quote} />
          </>
        )}
        <Header>Create a Template</Header>
        <p>
          A template can be created from the approved terms and used to create
          future quotes quickly.
        </p>
        <CreateTermsTemplateFromQuoteButton quoteId={quote.id} />
        <Header>Create a New Quote</Header>
        <p>
          This quote can be cloned, and a new quote will be created with the
          same asset and term information.
        </p>
        <p>
          You can then modify the new quote as needed before submitting for
          approval.
        </p>
        <CloneQuoteButton quoteId={quote.id} />
        <Divider />
        <QuoteStageFormActions
          quoteId={quote.id}
          action={
            <>
              {!quote.leaseCompleteQuoteId && (
                <ConfirmButton
                  onConfirm={handleSendToLeaseComplete}
                  css={css``}
                  text="Send To LeaseComplete"
                  type="button"
                  primary
                  loading={sendingToLeaseComplete}
                  basic={false}
                />
              )}
            </>
          }
        />
      </FormContainer>
    </>
  );
};

const CreateTermsTemplateFromQuoteButton: React.FC<{quoteId: number}> = ({
  quoteId,
}) => {
  type FormFields = Pick<CreateNewQuoteTermsTemplateFromQuoteRequest, 'name'>;
  const fields = useMemo(
    () =>
      fieldConfig<FormFields>({
        name: input({
          fieldLabel: 'Name',
          required: true,
        }),
      }),
    []
  );

  const [creatingNewTemplate, setCreatingNewTemplate] = useState(false);
  const handleSubmit = async (values: FormFields) => {
    const response = await QuoteTermsTemplatesService.createTemplateFromQuote({
      body: {name: values.name, quoteId},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return response;
    }
    notifications.success('Template created');
    setCreatingNewTemplate(false);
  };
  return (
    <>
      <Button
        onClick={() => {
          setCreatingNewTemplate(true);
        }}
      >
        Create Terms Template From Quote
      </Button>
      <Modal
        open={creatingNewTemplate}
        onClose={() => {
          setCreatingNewTemplate(false);
        }}
      >
        <Modal.Header>Create a New Template</Modal.Header>
        <Modal.Content>
          <Form
            onSubmit={handleSubmit}
            render={() => {
              return (
                <>
                  <Form.Input fieldConfig={fields.name} />
                  <Flex.Row>
                    <Form.Button primary type="submit">
                      Create Template
                    </Form.Button>
                    <Form.Button
                      secondary
                      type="button"
                      onClick={() => {
                        setCreatingNewTemplate(false);
                      }}
                    >
                      Cancel
                    </Form.Button>
                  </Flex.Row>
                </>
              );
            }}
          />
        </Modal.Content>
      </Modal>
    </>
  );
};

const AddAdditionalInformationButton: React.FC<{quote: QuoteGetDto}> = ({
  quote,
}) => {
  const [addingAdditionalInformation, setAddingAdditionalInformation] =
    useState(false);
  const initialValues = useAsyncRetry(async () => {
    const response = await QuotesService.getInitialAdditionalValues({
      quoteId: quote.id,
    });
    return response.data;
  });

  const stateOptions = useAsync(async () => {
    const response = await QuotesService.getStateOptions();
    return response.data;
  });

  const fields = usefields({stateOptions: stateOptions.value});
  useEffect(() => {
    initialValues.retry();
  }, [addingAdditionalInformation]);
  return (
    <>
      <Button
        content="Provide Additional Information"
        onClick={() => {
          setAddingAdditionalInformation(true);
        }}
      />

      <Modal
        open={addingAdditionalInformation}
        onClose={() => setAddingAdditionalInformation(false)}
        onOpen={() => {
          initialValues.retry();
        }}
      >
        <Modal.Header>Provide Additional Information</Modal.Header>
        <Modal.Content>
          <Form.Container>
            <Form
              initialValues={initialValues.value ?? {}}
              onSubmit={async (
                values: SaveQuoteAdditionalValuesRequestFormFields
              ) => {
                const response = await QuotesService.saveAdditionalValues({
                  body: {...values, quoteId: quote.id},
                });
                if (response.hasErrors) {
                  notifications.errors(response.errors);
                  return;
                }
                notifications.success('Quote updated.');
                setAddingAdditionalInformation(false);
              }}
              render={() => (
                <>
                  {initialValues.value && (
                    <FormFields
                      fields={fields}
                      showCustomerInfo={quote.customerId === null}
                    />
                  )}
                  <Divider />
                  <Flex.Row>
                    <Button type="submit" content="Submit" primary />
                    <Button
                      content="Cancel"
                      secondary
                      onClick={() => setAddingAdditionalInformation(false)}
                    />
                  </Flex.Row>
                </>
              )}
            />
          </Form.Container>
        </Modal.Content>
      </Modal>
    </>
  );
};

type SaveQuoteAdditionalValuesRequestFormFields = Omit<
  SaveQuoteAdditionalValuesRequest,
  'quoteId'
>;

const FormFields: React.FC<{
  fields: RawFieldConfig<SaveQuoteAdditionalValuesRequestFormFields>;
  showCustomerInfo: boolean;
}> = ({fields, showCustomerInfo}) => {
  return (
    <>
      <Form.Row>
        <Form.Input fieldConfig={fields.vin} />
        <Form.Input fieldConfig={fields.newAssetId} />
      </Form.Row>
      {showCustomerInfo && (
        <>
          <Form.Row proportions={[1, 1, 1]}>
            <Form.Input fieldConfig={fields.customerFirstName} />
            <Form.Input fieldConfig={fields.customerLastNameBusinessName} />
            <Form.Input fieldConfig={fields.customerSuffix} />
          </Form.Row>
          <Form.Row>
            <Form.Input fieldConfig={fields.customerContactName} />
          </Form.Row>
          <Form.Row proportions={[1, 1]}>
            <Form.Input fieldConfig={fields.customerAddress1} />
            <Form.Input fieldConfig={fields.customerAddress2} />
          </Form.Row>
          <Form.Row proportions={[1, 1, 1]}>
            <Form.Input fieldConfig={fields.customerCity} />
            <Form.Dropdown fieldConfig={fields.customerStateId} />
            <Form.Input fieldConfig={fields.customerPostalCode} />
          </Form.Row>
          <Form.Row proportions={[1, 1]}>
            <Form.Input fieldConfig={fields.customerPhoneNumber} />
            <Form.Input fieldConfig={fields.customerAltPhoneNumber} />
          </Form.Row>
        </>
      )}
    </>
  );
};

const usefields: ({
  stateOptions,
}) => RawFieldConfig<SaveQuoteAdditionalValuesRequestFormFields> = ({
  stateOptions,
}) =>
  fieldConfig<SaveQuoteAdditionalValuesRequestFormFields>({
    vin: input({fieldLabel: 'VIN/Serial Number'}),
    newAssetId: input({fieldLabel: 'New Asset Id'}),
    customerFirstName: input({fieldLabel: 'Customer First Name'}),
    customerLastNameBusinessName: input({
      fieldLabel: 'Cust Last Name/ Business Name',
    }),
    customerContactName: input({fieldLabel: 'Contact Name'}),
    customerSuffix: input({fieldLabel: 'Suffix'}),
    customerAddress1: input({fieldLabel: 'Address 1'}),
    customerAddress2: input({fieldLabel: 'Address 2'}),
    customerCity: input({fieldLabel: 'City'}),
    customerStateId: dropdown({
      fieldLabel: 'State',
      inputProps: {
        options: stateOptions,
        selection: true,
        search: true,
      },
    }),
    customerPostalCode: input({fieldLabel: 'Postal Code'}),
    customerPhoneNumber: input({fieldLabel: 'Phone'}),
    customerAltPhoneNumber: input({fieldLabel: 'Alt Phone'}),
  });
