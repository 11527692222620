import {css} from '@emotion/core';
import {faCopy} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react';
import {useFormState} from 'react-final-form';
import {Route, Switch, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync} from 'react-use';
import {
  Button,
  Card,
  Divider,
  DropdownItemProps,
  Modal,
  Segment,
  Statistic,
} from 'semantic-ui-react';
import {
  QuoteAssetGetDto,
  QuoteAssetsService,
  QuotesService,
} from '../../api/generated';
import {
  UseTypes,
  QuoteAssetStatuses,
  TermsPercentageOrDollar,
  AssetTypes,
  QuoteStages,
} from '../../api/generated/enums';
import {getEnumDropdownOptions} from '../../api/generated/utils';
import {currencyFormatter} from '../../assets/asset-utils';
import {DeleteButton} from '../../components/confirm-button';
import {Flex} from '../../components/flex';
import {Form} from '../../forms';
import {
  dropdown,
  fieldConfig,
  input,
  RawFieldConfig,
  currency,
  checkbox,
} from '../../forms/schema-utils';
import {useNotification} from '../../hooks/use-notifications';
import {routes} from '../../routes';
import {buildPath} from '../../routes/utils';
import {notifications} from '../../utils/notification-service';
import {QuoteStageProps} from '../quote-create-update';
import {getUrlForQuoteStage} from '../quote-steps';
import {QuoteStageFormActions} from './quote-stage-form-actions';

const MAX_NUMBER_OF_ASSETS = 3;

export const LocateAsset: React.FC<
  QuoteStageProps & {
    quoteAssets: QuoteAssetGetDto[];
    refetchQuoteAssets: () => void;
  }
> = ({
  quoteAssets,
  refetchQuoteAssets,
  refetchQuote,
  quote,
  readOnly = false,
}) => {
  const [viewedAsset, setViewedAsset] = useState<QuoteAssetGetDto | null>(null);
  const history = useHistory();
  const match = useRouteMatch<{
    id: string | undefined;
    assetId: string | undefined;
  }>({path: routes.quotes.asset});
  useEffect(() => {
    refetchQuoteAssets();
  }, [viewedAsset]);

  return (
    <>
      <Switch>
        <Route path={routes.quotes.newAsset} exact>
          <QuoteNewAssetForm
            onCloseForm={() => {
              history.push(
                buildPath(routes.quotes.detail, {
                  id: quote.id,
                  stage: getUrlForQuoteStage(QuoteStages.LocateAsset),
                })
              );
            }}
            quoteId={quote.id}
            refetchQuote={refetchQuote}
            refetchQuoteAssets={refetchQuoteAssets}
          />
        </Route>
        <Route path={routes.quotes.asset} exact>
          <QuoteAssetForm
            quoteAsset={viewedAsset}
            quoteAssetId={parseInt(match?.params.assetId as string)}
            refetchQuote={refetchQuote}
            refetchQuoteAssets={refetchQuoteAssets}
            quoteId={quote.id}
            onCloseForm={() => {
              history.push(
                buildPath(routes.quotes.detail, {
                  id: quote.id,
                  stage: 'assets',
                })
              );
            }}
            readOnly={readOnly}
          />
        </Route>
        <Route path={routes.quotes.detail} exact>
          <QuoteAssetsListing
            quoteAssets={quoteAssets}
            onNewAssetClicked={() => {
              history.push(buildPath(routes.quotes.newAsset, {id: quote.id}));
            }}
            onAssetClicked={(assetClicked) => {
              setViewedAsset(assetClicked);
              history.push(
                buildPath(routes.quotes.asset, {
                  id: quote.id,
                  assetId: assetClicked.id,
                })
              );
            }}
            readOnly={readOnly}
          />
        </Route>
      </Switch>
    </>
  );
};

export const QuoteAssetsListing: React.FC<{
  quoteAssets: QuoteAssetGetDto[];
  onNewAssetClicked: () => void;
  onAssetClicked: (assetClicked: QuoteAssetGetDto) => void;
  readOnly: boolean;
}> = ({quoteAssets, onNewAssetClicked, onAssetClicked, readOnly}) => {
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  return (
    <>
      <Segment>
        <Card.Group centered>
          {quoteAssets.map((asset) => {
            return (
              <>
                <QuoteAssetListingCard
                  clonable={
                    quoteAssets.length < MAX_NUMBER_OF_ASSETS && !readOnly
                  }
                  asset={asset}
                  onAssetClicked={(assetClicked) =>
                    onAssetClicked(assetClicked)
                  }
                />
              </>
            );
          })}
          {quoteAssets?.length < MAX_NUMBER_OF_ASSETS && !readOnly && (
            <Card
              onClick={() => {
                onNewAssetClicked();
              }}
            >
              <Card.Content textAlign="center">
                <Statistic size="large">
                  <Statistic.Value>+</Statistic.Value>
                  <Statistic.Label>Add New Asset</Statistic.Label>
                </Statistic>
              </Card.Content>
            </Card>
          )}
        </Card.Group>
        <Divider />
        <QuoteStageFormActions quoteId={id} />
      </Segment>
    </>
  );
};

export const QuoteAssetListingCard: React.FC<{
  asset: QuoteAssetGetDto;
  onAssetClicked: (assetClicked: QuoteAssetGetDto) => void;
  clonable: boolean;
}> = ({asset, onAssetClicked, clonable}) => {
  return (
    <>
      <Card
        onClick={() => {
          onAssetClicked(asset);
        }}
        color={
          asset.assetStatus === QuoteAssetStatuses.Located ? 'green' : 'yellow'
        }
        key={asset.id}
        css={css`
          .content {
            min-height: 13rem;
          }
          .meta {
            color: rgba(0, 0, 0, 0.6) !important;
          }
        `}
      >
        <Card.Content>
          <Card.Header as="h3">{asset.description}</Card.Header>
          <Card.Meta>
            <strong>Status:</strong> {asset.assetStatus}
          </Card.Meta>
          {asset.assetStatus === QuoteAssetStatuses.Located && (
            <Card.Meta>
              <strong>Cost: </strong>{' '}
              {currencyFormatter(asset.companyCost / 100) ?? 'N/A'}
            </Card.Meta>
          )}
        </Card.Content>
        <Card.Content extra>
          <Flex.Row justifyContent="space-between" align="center">
            <>
              {asset.termsConfigured} Term
              {asset.termsConfigured !== 1 && 's'} Configured
            </>
            {clonable && (
              <CloneQuoteAssetButton
                quoteAssetId={asset.id}
                onAssetCloned={(newAsset) => {
                  onAssetClicked(newAsset);
                }}
              />
            )}
          </Flex.Row>
        </Card.Content>
      </Card>
    </>
  );
};

export const QuoteAssetForm: React.FC<{
  quoteAsset: QuoteAssetGetDto | null;
  quoteAssetId: number;
  refetchQuoteAssets: () => void;
  refetchQuote: () => void;
  onCloseForm: () => void;
  quoteId: number;
  readOnly?: boolean;
}> = ({
  refetchQuoteAssets,
  quoteAsset,
  refetchQuote,
  onCloseForm,
  readOnly = false,
  quoteId,
  quoteAssetId,
}) => {
  const notifications = useNotification();
  const history = useHistory();
  const dealerOptions = useAsync(async () => {
    const response = await QuotesService.getDealerOptions();
    return response.data?.map<DropdownItemProps>((optionItem) => {
      return {value: optionItem.value, text: optionItem.text};
    });
  });

  const fetchQuoteAsset = useAsync(async () => {
    if (quoteAsset) {
      return quoteAsset;
    }
    const response = await QuoteAssetsService.getById({id: quoteAssetId});
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    return response.data;
  });

  const deleteQuoteAsset = async () => {
    if (fetchQuoteAsset.value) {
      const response = await QuoteAssetsService.deleteById({
        id: fetchQuoteAsset.value.id,
      });
      if (response.hasErrors) {
        notifications.errors(response.errors);
        return;
      }
      notifications.success('Quote Asset deleted');
      refetchQuoteAssets();
      refetchQuote();
      onCloseForm();
    }
  };

  const fields = useFields(dealerOptions.value ?? [], readOnly);

  return (
    <Form.Container>
      {fetchQuoteAsset.value && (
        <Form
          css={css`
            .ui.disabled.input,
            .ui.input:not(.disabled) input[disabled] {
              opacity: 1 !important;
            }
          `}
          onSubmit={async (values) => {
            const response = await QuoteAssetsService.update({
              body: {...values},
            });
            if (response.hasErrors) {
              return response;
            }
            notifications.success('Asset updated.');

            refetchQuote();
            refetchQuoteAssets();
            history.push(
              buildPath(routes.quotes.detail, {
                id: quoteId,
                stage: getUrlForQuoteStage(
                  response.data?.assetStatus === QuoteAssetStatuses.Located
                    ? QuoteStages.ConfigureTerms
                    : QuoteStages.LocateAsset
                ),
              })
            );
          }}
          initialValues={fetchQuoteAsset.value}
          render={() => (
            <>
              {fields && <FormFields fields={fields} />}
              <>
                <Divider />
                <Flex.Row justifyContent="space-between">
                  <div>
                    {!readOnly && (
                      <Form.Button type="submit" primary>
                        Save
                      </Form.Button>
                    )}
                    <Form.Button type="button" onClick={onCloseForm}>
                      Cancel
                    </Form.Button>
                  </div>
                  {!readOnly && (
                    <DeleteButton
                      onConfirm={deleteQuoteAsset}
                      loading={false}
                      text="Delete Quote Asset"
                    />
                  )}
                </Flex.Row>
              </>
            </>
          )}
        />
      )}
    </Form.Container>
  );
};

export const QuoteNewAssetForm: React.FC<{
  refetchQuoteAssets: () => void;
  onCloseForm: () => void;
  refetchQuote: () => void;
  quoteId: number;
}> = ({refetchQuoteAssets, quoteId, refetchQuote, onCloseForm}) => {
  const notifications = useNotification();
  const history = useHistory();
  const dealerOptions = useAsync(async () => {
    const response = await QuotesService.getDealerOptions();
    return response.data?.map<DropdownItemProps>((optionItem) => {
      return {value: optionItem.value, text: optionItem.text};
    });
  });

  const fields = useFields(dealerOptions.value ?? [], false);

  return (
    <Form.Container>
      <Form
        onSubmit={async (values) => {
          const response = await QuoteAssetsService.create({
            body: {...values, quoteId},
          });
          if (response.hasErrors) {
            if (response.errors.some((x) => !x.propertyName)) {
              notifications.errors(
                response.errors.filter((x) => !x.propertyName)
              );
            }
            return response;
          }
          notifications.success('Asset created.');

          refetchQuoteAssets();
          refetchQuote();
          history.push(
            buildPath(routes.quotes.detail, {
              id: quoteId,
              stage: getUrlForQuoteStage(QuoteStages.ConfigureTerms),
            })
          );
        }}
        initialValues={{} as Omit<QuoteAssetGetDto, 'id'>}
        render={() => (
          <>
            {fields && <FormFields fields={fields} />}
            <Divider />
            <Form.Row>
              <Form.Button type="submit" primary>
                Create
              </Form.Button>
              <Form.Button
                type="button"
                onClick={() => {
                  onCloseForm();
                }}
              >
                Cancel
              </Form.Button>
            </Form.Row>
          </>
        )}
      />
    </Form.Container>
  );
};

const FormFields: React.FC<{
  fields: RawFieldConfig<
    Omit<QuoteAssetGetDto, 'quoteId' | 'id' | 'description' | 'termsConfigured'>
  >;
}> = ({fields}) => {
  const {values} = useFormState<QuoteAssetGetDto>();
  return (
    <>
      <Form.Section title="Asset Information">
        <Form.Row proportions={[2, 2]}>
          <Form.Dropdown fieldConfig={fields.dealerId} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1, 1]}>
          <Form.Dropdown fieldConfig={fields.assetType} />
          <Form.Checkbox fieldConfig={fields.isForklift} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1, 1]}>
          <Form.Dropdown fieldConfig={fields.useType} />
          <Form.Input fieldConfig={fields.year} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1, 1]}>
          <Form.Input fieldConfig={fields.make} />
          <Form.Input fieldConfig={fields.model} />
          <Form.Input fieldConfig={fields.trimLevel} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1, 1]}>
          <Form.Input fieldConfig={fields.upfit} />
          <Form.Input fieldConfig={fields.requestedTerm} />
        </Form.Row>
        <Form.Row proportions={[2, 2]}>
          <Form.Input fieldConfig={fields.garagingAddress} />
        </Form.Row>
        <Form.Row proportions={[2, 2]}>
          <Form.Input fieldConfig={fields.garagingRegion} />
        </Form.Row>
        <Form.Row proportions={[1, 3]}>
          <Form.Dropdown fieldConfig={fields.assetStatus} />
        </Form.Row>
        {values.assetStatus === QuoteAssetStatuses.Located && (
          <div className="located-asset-section">
            <Form.Row proportions={[1, 3]}>
              <Form.InputCurrency fieldConfig={fields.companyCost} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 2]}>
              <Form.Input fieldConfig={fields.residual} />
              <Form.Dropdown fieldConfig={fields.residualType} />
            </Form.Row>
            <Form.Row proportions={[1, 1, 1, 1]}>
              <Form.Input fieldConfig={fields.currentMileage} />
              <Form.Input fieldConfig={fields.estimatedAnnualMileage} />
              <Form.InputCurrency fieldConfig={fields.excessMileageCharge} />
            </Form.Row>
            <Form.Row proportions={[2, 2]}>
              <Form.Input fieldConfig={fields.vin} />
            </Form.Row>
          </div>
        )}
      </Form.Section>
    </>
  );
};

const CloneQuoteAssetButton: React.FC<{
  quoteAssetId: number;
  onAssetCloned: (newlyCreatedAsset: QuoteAssetGetDto) => void;
}> = ({quoteAssetId, onAssetCloned}) => {
  const [cloningAsset, setCloningAsset] = useState(false);
  const handleAssetClone = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.stopPropagation();
    const response = await QuoteAssetsService.cloneQuoteAsset({
      body: {quoteAssetId},
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    notifications.success('Asset successfully cloned.');
    onAssetCloned(response.data as QuoteAssetGetDto);
  };
  return (
    <>
      <Button
        secondary
        onClick={(e) => {
          e.stopPropagation();
          setCloningAsset(true);
        }}
      >
        <FontAwesomeIcon icon={faCopy} />
      </Button>
      <Modal open={cloningAsset} onClose={() => setCloningAsset(false)}>
        <Modal.Header>Clone this Asset</Modal.Header>
        <Modal.Content>
          This will clone this asset's information and terms into a new asset.
          Would you like to proceed?
        </Modal.Content>
        <Modal.Actions>
          <Button primary onClick={handleAssetClone}>
            Yes, Clone This Asset and Terms
          </Button>
          <Button
            secondary
            onClick={(e) => {
              e.stopPropagation();
              setCloningAsset(false);
            }}
          >
            No
          </Button>
        </Modal.Actions>
      </Modal>
    </>
  );
};

const useFields = (options: DropdownItemProps[], readOnly: boolean) => {
  return fieldConfig<
    Omit<QuoteAssetGetDto, 'quoteId' | 'id' | 'description' | 'termsConfigured'>
  >({
    dealerId: dropdown({
      fieldLabel: 'Authorized Dealer',
      inputProps: {
        selection: true,
        search: true,
        options: options,
        lazyLoad: true,
        clearable: true,
        disabled: readOnly,
      },
    }),
    assetType: dropdown({
      fieldLabel: 'Asset Type',
      required: true,
      inputProps: {
        placeholder: 'Select Asset Type...',
        selection: true,
        options: getEnumDropdownOptions(AssetTypes),
        search: true,
        disabled: readOnly,
      },
    }),
    useType: dropdown({
      fieldLabel: 'Use Type',
      required: true,
      inputProps: {
        placeholder: 'Select Use Type...',
        selection: true,
        options: getEnumDropdownOptions(UseTypes),
        search: true,
        disabled: readOnly,
      },
    }),
    year: input({
      fieldLabel: 'Year',
      required: true,
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    make: input({
      fieldLabel: 'Make',
      inputProps: {
        disabled: readOnly,
      },
    }),
    model: input({
      fieldLabel: 'Model / Body Style',
      inputProps: {
        disabled: readOnly,
      },
    }),
    trimLevel: input({
      fieldLabel: 'Trim Level / Drivetrain ',
      inputProps: {
        disabled: readOnly,
      },
    }),
    upfit: input({
      fieldLabel: 'Upfit',
      inputProps: {
        disabled: readOnly,
      },
    }),
    requestedTerm: input({
      fieldLabel: 'Requested Term',
      inputProps: {
        disabled: readOnly,
      },
    }),
    currentMileage: input({
      fieldLabel: 'Current Mileage',
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    estimatedAnnualMileage: input({
      fieldLabel: 'Estimated Annual Mileage',
      required: true,
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    excessMileageCharge: input({
      fieldLabel: 'Excess Miles Charge',
      required: true,
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    garagingAddress: input({
      fieldLabel: 'Garaging Address',
      inputProps: {
        disabled: readOnly,
      },
    }),
    garagingRegion: input({
      fieldLabel: 'City, State, Zip',
      inputProps: {
        disabled: readOnly,
      },
    }),
    isForklift: checkbox({
      fieldLabel: 'This is a Forklift',
      inputProps: {
        disabled: readOnly,
        toggle: true,
      },
    }),
    assetStatus: dropdown({
      fieldLabel: 'Status',
      required: true,
      inputProps: {
        options: getEnumDropdownOptions(QuoteAssetStatuses),
        placeholder: 'Select Asset Status...',
        selection: true,
        disabled: readOnly,
      },
    }),
    companyCost: currency({
      fieldLabel: 'Company Cost',
      required: true,
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    residual: input({
      fieldLabel: 'Residual',
      required: true,
      inputProps: {
        type: 'number',
        disabled: readOnly,
      },
    }),
    residualType: dropdown({
      fieldLabel: 'Type',
      required: true,
      inputProps: {
        selection: true,
        search: true,
        clearable: true,
        placeholder: 'Select Residual Type...',
        options: getEnumDropdownOptions(TermsPercentageOrDollar),
        disabled: readOnly,
      },
    }),
    vin: input({
      fieldLabel: 'VIN',
      inputProps: {
        disabled: readOnly,
      },
    }),
  });
};
