import moment from 'moment';
import React, {useState} from 'react';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {useAsync} from 'react-use';
import {Divider} from 'semantic-ui-react';
import {InvoicesService} from '../api/generated';
import {getOptionDtoDropdownOptions} from '../api/generated/utils';
import {BasicPage} from '../basic-page';
import {FileDropzone} from '../components/dropzone';
import {Form} from '../forms';
import {fieldConfig, input, datepicker, dropdown} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';

export const InvoiceCreate = () => {
  const notifications = useNotification();
  const history = useHistory();
  const location = useLocation();
  const customerId = location.state?.customerId;
  const billingCycleDate = location.state?.billingCycleDate;
  const isSupplemental = location.state?.isSupplemental;
  const returnRoute = location.state?.returnRoute;

  const [file, setFile] = useState<File[]>([]);

  const fetchOptions = useAsync(async () => {
    const response = await InvoicesService.getOptions({customerId: customerId});
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const customerOptions = fetchOptions.value;

  const onSubmit = async (values) => {
    const response = await InvoicesService.upload({
      ...values,
      isSupplemental: isSupplemental,
      file: file[0],
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return response;
    }

    notifications.success('Invoice Created');
    history.push(returnRoute || routes.invoices.index);
  };

  const fields = fieldConfig<any>({
    customerId: dropdown({
      fieldLabel: 'Customer',
      inputProps: {
        options: getOptionDtoDropdownOptions(customerOptions ?? []),
        selection: true,
        search: true,
        defaultValue:
          customerOptions && customerOptions.length === 1
            ? customerOptions[0].value
            : undefined,
      },
    }),
    invoiceNumber: input({
      fieldLabel: 'Invoice #',
    }),
    amount: input({
      fieldLabel: 'Amount',
      inputProps: {
        icon: 'dollar',
        iconPosition: 'left',
        type: 'number',
      },
    }),
    invoiceDate: datepicker({
      fieldLabel: 'Invoice Date',
      inputProps: {
        initialVisibleMonth: () => {
          if (billingCycleDate) {
            return moment(billingCycleDate).subtract(1, 'M');
          } else {
            return moment();
          }
        },
      },
    }),
    dueDate: datepicker({
      fieldLabel: 'Due Date',
      inputProps: {
        initialVisibleMonth: () => {
          return moment(billingCycleDate);
        },
        isOutsideRange: (day) => {
          if (
            billingCycleDate &&
            (day < moment(billingCycleDate) ||
              day > moment(billingCycleDate).add(1, 'M'))
          ) {
            return true;
          }
          return false;
        },
      },
    }),
  });

  const FormFields = () => {
    return (
      <>
        <Form.Row proportions={[1, 2]}>
          <Form.Dropdown fieldConfig={fields.customerId} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1]}>
          <Form.Input fieldConfig={fields.invoiceNumber} />
          <Form.Input fieldConfig={fields.amount} />
        </Form.Row>
        <Form.Row proportions={[1, 1, 1]}>
          <Form.DatePicker fieldConfig={fields.invoiceDate} />
          <Form.DatePicker fieldConfig={fields.dueDate} />
        </Form.Row>
      </>
    );
  };

  return (
    <BasicPage title="Create Invoice">
      <Form.Container>
        <Form
          onSubmit={onSubmit}
          render={() => (
            <>
              <FormFields />
              <Divider hidden />
              <FileDropzone
                header="Upload Invoice"
                files={file}
                onFilesChanged={setFile}
                fileUploadedMessage="Invoice selected"
                maxFiles={1}
                accept=".pdf"
              />
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Create Invoice
                </Form.Button>
                <Form.Button
                  secondary
                  as={Link}
                  to={returnRoute || routes.invoices.index}
                >
                  Cancel
                </Form.Button>
              </div>
            </>
          )}
        />
      </Form.Container>
    </BasicPage>
  );
};
