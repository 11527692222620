import {css} from '@emotion/core';
import _ from 'lodash';
import React, {useState} from 'react';
import {useAsync} from 'react-use';
import {
  Button,
  Modal,
  ModalContent,
  ModalHeader,
  ModalProps,
} from 'semantic-ui-react';
import {
  ExcelExportSettingDto,
  ExcelExportService,
  PdfExportService,
} from '../api/generated';
import {ExportTypes} from '../api/generated/enums';
import {Flex} from '../components/flex';
import {useCustomerContext} from '../customer-context';
import {Form} from '../forms';
import {notifications} from '../utils/notification-service';

export const ExportButton: React.FC<{
  title: string;
  exportUrl: string;
  exportType: ExportTypes;
  customerId?: string;
}> = ({title, exportUrl, exportType, customerId}) => {
  const [exporting, setExporting] = useState(false);
  const [{currentCustomerId}] = useCustomerContext();
  return (
    <div>
      <Button
        onClick={async () => {
          setExporting(true);
        }}
        className="export-button"
      >
        {title}
      </Button>
      {exporting && (
        <ExportModal
          open
          onClose={() => setExporting(false)}
          customerId={customerId ?? currentCustomerId}
          exportUrl={exportUrl}
          exportType={exportType}
        />
      )}
    </div>
  );
};

const ExportModal: React.FC<
  ModalProps & {
    customerId?: string;
    onClose: () => void;
    exportUrl: string;
    exportType: ExportTypes;
  }
> = ({customerId, onClose, exportUrl, exportType, ...modalProps}) => {
  const [exportColumns, setExportColumns] = useState<
    ExcelExportSettingDto[] | null
  >(null);
  useAsync(async () => {
    const response = await ExcelExportService.getExportSettingsForCustomer({
      customerId: customerId,
      exportItemType: exportType,
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    setExportColumns(response.data);
  }, [customerId, exportType]);
  return (
    <Modal {...modalProps} onClose={onClose}>
      <ModalHeader>Select Columns</ModalHeader>
      <ModalContent>
        {exportColumns && (
          <Form
            onSubmit={async (values) => {
              const columns = Object.entries(values)
                .filter((val) => {
                  return val[1];
                })
                .map((val) => {
                  return parseInt(val[0].replace('col_', ''));
                });
              const saveColumnsResponse =
                await ExcelExportService.saveCustomerExportColumns({
                  body: {columns, customerId: customerId ?? ''},
                });
              if (saveColumnsResponse.hasErrors) {
                notifications.errors(saveColumnsResponse.errors);
                return;
              }

              window.open(exportUrl, '_blank');
              onClose();
            }}
            // eslint-disable-next-line react-perf/jsx-no-new-object-as-prop
            mutators={{
              selectAll: (args, state, utils) => {
                Object.keys(state.fields).forEach((key) => {
                  utils.changeValue(state, key, () => true);
                });
              },
              deselectAll: (args, state, utils) => {
                Object.keys(state.fields).forEach((key) => {
                  utils.changeValue(state, key, () => false);
                });
              },
            }}
            initialValues={initialValuesFromExportSettings(exportColumns)}
            render={(props) => {
              return (
                <>
                  <Flex.Row
                    css={css`
                      margin-bottom: 1rem;
                    `}
                  >
                    <Button
                      type="button"
                      onClick={props.form.mutators.selectAll}
                    >
                      Select All
                    </Button>
                    <Button
                      type="button"
                      onClick={props.form.mutators.deselectAll}
                    >
                      Deselect All
                    </Button>
                  </Flex.Row>
                  <Flex.Row
                    fill
                    css={css`
                      margin-bottom: 1rem;
                    `}
                  >
                    {_.chunk(exportColumns, exportColumns.length / 3).map(
                      (col_group) => (
                        <Flex.Col fill>
                          {col_group.map((col) => (
                            <Form.Checkbox
                              fieldName={'col_' + col.columnId}
                              value={col.columnId}
                              fieldLabel={col.heading}
                            />
                          ))}
                        </Flex.Col>
                      )
                    )}
                  </Flex.Row>
                  <Button type="submit" content="Export" />
                </>
              );
            }}
          />
        )}
      </ModalContent>
    </Modal>
  );
};

const initialValuesFromExportSettings: (
  exportSettings: ExcelExportSettingDto[]
) => {
  [key: string]: boolean;
} = (exportSettings: ExcelExportSettingDto[]) => {
  const noneSelected = exportSettings.every((setting) => !setting.selected);
  const initialValues = {};
  exportSettings.forEach(
    (s) => (initialValues['col_' + s.columnId] = noneSelected || s.selected)
  );
  return initialValues;
};
