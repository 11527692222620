import React, {useMemo} from 'react';
import {LeaseCompleteCustomerService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {routes} from '../routes';

export const CustomersListing = () => {
  const tableConfig = useMemo(
    () =>
      PagedDataTableConfig(LeaseCompleteCustomerService.getAll, {
        columns: [
          {
            header: '',
            render: item =>
              renderEditButton({
                item,
                descriptor: 'Customer',
                route: routes.customers.detail,
              }),
            cellProps: {
              collapsing: true,
            },
          },
          {header: 'Customer #', column: 'id', sortable: 'id'},
          {
            header: 'Business Name',
            render: item => (
              <>
                {`${item.nameFirst} ${item.nameBusiness} ${item.nameSuffix}`.trim()}
              </>
            ),
          },
          {
            header: 'Business Type',
            column: 'type',
            sortable: 'type',
          },
          {
            header: 'Account Executive',
            column: 'officerName',
          },
          {
            header: 'Customer Service Rep.',
            column: 'relationOfficerName',
          },
        ],
        searchFieldNames: [
          'id',
          'nameFirst',
          'nameBusiness',
          'officerName',
          'relationOfficerName',
        ],
        defaultSort: {column: 'id', direction: 'ASC'},
      }),
    []
  );
  const dataTable = usePagedDataTable(
    LeaseCompleteCustomerService.getAll,
    tableConfig
  );

  return (
    <>
      <BasicPage title="Customers">{dataTable}</BasicPage>
    </>
  );
};
