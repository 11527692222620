import React from 'react';
import css from '@emotion/css/macro';
import {Checkbox, Header} from 'semantic-ui-react';
import {LogSummariesService, LogSummaryGroupListItem} from '../api/generated';
import {BasicPage} from '../basic-page';
import {
  AdditionalParams,
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {ellipsis} from 'polished';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHorizontalRule} from '@fortawesome/pro-regular-svg-icons';
import {buildPath} from '../routes/utils';
import {routes} from '../routes';
import {Link} from 'react-router-dom';
import {Chip} from '../components/chip';
import {getPooledMoment} from '../utils/get-pooled-moment';
import {useProduce} from '../hooks/use-produce';

type FetchParams = AdditionalParams<typeof LogSummariesService.getAll>;

type LogsListingState = {
  showOnlyImportant: boolean;
  showOnlyUnresolved: boolean;
};

const DEFAULT_STATE: LogsListingState = {
  showOnlyImportant: true,
  showOnlyUnresolved: true,
};

export const LogsListing = () => {
  const [state, setState] = useProduce<LogsListingState>(DEFAULT_STATE);

  const additionalParams: FetchParams = {
    onlyImportant: state.showOnlyImportant,
    onlyUnresolved: state.showOnlyUnresolved,
  };

  const pagedDataTable = usePagedDataTable(
    LogSummariesService.getAll,
    TableConfig,
    {
      renderSearch: (searchHandler, DefaultComponent) => (
        <>
          {DefaultComponent}
          <Checkbox
            toggle
            checked={state.showOnlyImportant}
            onChange={() => {
              setState(
                (draft) => (draft.showOnlyImportant = !state.showOnlyImportant)
              );
            }}
            label="Show Only Important"
            className="filter-toggle"
          />
          <Checkbox
            toggle
            checked={state.showOnlyUnresolved}
            onChange={() => {
              setState(
                (draft) =>
                  (draft.showOnlyUnresolved = !state.showOnlyUnresolved)
              );
            }}
            label="Show Only Unresolved"
            className="filter-toggle"
          />
        </>
      ),
      additionalParams,
    }
  );
  return (
    <BasicPage title="Logs" css={styles}>
      {pagedDataTable}
    </BasicPage>
  );
};

export const getStatusColor = (status: string) => {
  const statusCode = parseInt(status);

  if (statusCode >= 500) {
    return 'red';
  }
  if (statusCode >= 400) {
    return 'orange';
  }
  return 'blue';
};

const renderPattern = (log: LogSummaryGroupListItem) => {
  var params = {
    status: log.status,
    matchedPattern: log.matchedPattern,
    errorMessages: log.errorMessages,
  };

  var base64Params = btoa(JSON.stringify(params));
  var url = buildPath(routes.admin.logs.groupedDetail, {
    parameters: base64Params,
  });

  return (
    <Link to={url} className="pattern">
      {log.matchedPattern}
    </Link>
  );
};

const TableConfig = PagedDataTableConfig(LogSummariesService.getAll, {
  columns: [
    {
      header: 'Status Code',
      render: (log) => (
        <>
          <span className="row-header">
            <Header color={getStatusColor(log.status)}>{log.status}</Header>
            {renderPattern(log)}
          </span>
          <div className="error-messages">
            {log.errorMessages || (
              <FontAwesomeIcon icon={faHorizontalRule} color="gray" />
            )}
          </div>
        </>
      ),
      sortable: 'status',
    },
    {
      header: 'Occurrences',
      render: (log) => <Header size="small">{log.occurrences}</Header>,
      sortable: 'occurrences',
    },
    {
      header: 'Status',
      render: (log) =>
        log.hasUnhandledOccurrences ? (
          <Chip level="error" text="Unresolved" />
        ) : (
          <Chip level="success" text="Resolved" />
        ),
    },
    {
      header: 'Last Occurrence | Age',
      render: (log) => (
        <>
          {getPooledMoment(log.lastOccurrenceTimestamp).fromNow()} {' | '}
          {getPooledMoment(log.firstOccurrenceTimestamp).fromNow(true)} old
        </>
      ),
      sortable: 'lastOccurrenceTimestamp',
    },
  ],
  defaultSort: {
    column: 'status',
    direction: 'DESC',
  },
  searchFieldNames: [
    'status',
    'matchedPattern',
    'occurrences',
    'errorMessages',
  ],
});

const styles = css`
  .filter-toggle {
    margin-left: 2rem;
  }
  .row-header {
    display: flex;
  }
  .pattern {
    font-size: 14px;
    color: #1e175c;
    margin-left: 14px;
    margin-top: -2px;
  }
  .error-messages {
    ${ellipsis(800)}
  }
`;
