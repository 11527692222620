import React from 'react';
import {Checkbox, StrictCheckboxProps} from 'semantic-ui-react';

export type CheckboxAdapterProps = StrictCheckboxProps;

export const CheckboxAdapter = ({input, meta, ...rest}) => {
  const {value, ...inputValues} = input;
  return (
    <Checkbox
      {...inputValues}
      {...rest}
      checked={!!value}
      type="checkbox"
      onChange={(event, {checked}) => input.onChange(checked)}
    />
  );
};
