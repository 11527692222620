import {faPlus, faUnlock, faEnvelope} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import arrayMutators from 'final-form-arrays';
import React, {useCallback} from 'react';
import {Link} from 'react-router-dom';
import {useAsyncFn} from 'react-use';
import {Button, Label} from 'semantic-ui-react';
import {UsersService} from '../api/generated';
import {AllPermissions, Roles} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {ConfirmButton} from '../components/confirm-button';
import {Json} from '../components/json';
import {Form} from '../forms';
import {
  checkbox,
  dropdown,
  fieldConfig,
  getDefaults,
  input,
} from '../forms/schema-utils';
import {useProduce} from '../hooks/use-produce';
import {routes} from '../routes';
import {notifications} from '../utils/notification-service';
import {
  PagedDataTableConfig,
  usePagedDataTable,
  renderEditButton,
  AdditionalParams,
  getActiveFiltersCount,
} from '../hooks/use-paged-data-table';
import {useCustomerContext} from '../customer-context';
import {faDownload} from '@fortawesome/pro-solid-svg-icons';
import {buildApiUrl} from '../api/generated/utils';
import {RequireAuthorization} from '../auth/require-authorization';

type FilterState = AdditionalParams<typeof UsersService.getAll>;
type ComponentState = {
  filter: FilterState;
  tableState?: Parameters<typeof UsersService.getAll>[0];
};

export const UserListing = () => {
  const [{currentCustomerId}] = useCustomerContext();
  const [state, setState] = useProduce<ComponentState>({
    filter: {
      claims: [],
      roles: [],
      includeDeleted: false,
    },
  });

  const exportUrl = buildApiUrl('/api/users/export');

  const additionalParams = {...state.filter, customerId: currentCustomerId};
  const filtersInSlideOut = additionalParams.claims;

  const pagedDataTable = usePagedDataTable(UsersService.getAll, TableConfig, {
    additionalParams: additionalParams,
    onStateChange: useCallback(
      (tableState) => {
        setState((draft) => {
          draft.tableState = tableState;
        });
      },
      [setState]
    ),
    renderFilter: (dismis) => (
      <TableFilters
        initialValues={state.filter}
        onApply={(filters) => {
          setState((draft) => {
            draft.filter.claims = filters.claims;
            draft.filter.roles = filters.roles;
            draft.filter.includeDeleted = filters.includeDeleted;
          });

          dismis();
        }}
      />
    ),
    filterBadge: getActiveFiltersCount(filtersInSlideOut),
    actions: (
      <>
        <Button
          as={Link}
          onClick={() => {
            window.open(exportUrl);
          }}
          secondary
        >
          <FontAwesomeIcon icon={faDownload} /> Export Users
        </Button>
        <Button as={Link} to={routes.users.create} primary>
          <FontAwesomeIcon icon={faPlus} /> New User
        </Button>
      </>
    ),
  });

  return (
    <BasicPage title="Users" showSecondaryNavigation>
      {pagedDataTable}
      <Json data={state.tableState} />
    </BasicPage>
  );
};

const fields = fieldConfig<any>({
  claims: input({
    fieldLabel: 'Claims',
  }),
  roles: dropdown({
    fieldLabel: 'Roles',
    inputProps: {
      enum: Roles,
      selection: true,
      clearable: true,
      multiple: true,
    },
  }),
  includeDeleted: checkbox({
    fieldLabel: 'Include deleted',
    inputProps: {
      toggle: true,
    },
  }),
});

const mutators = {
  ...arrayMutators,
};

const TableFilters = ({initialValues, onApply}) => {
  return (
    <Form
      initialValues={initialValues || getDefaults(fields)}
      mutators={mutators}
      onSubmit={(values) => {
        onApply(values);
      }}
      render={() => (
        <>
          <Form.Dropdown fieldConfig={fields.roles} />
          <Form.MultiSelectCheckbox
            fieldName={fields.claims.fieldName}
            fieldLabel={fields.claims.fieldLabel}
            options={AllPermissions.map((x) => x)}
          />
          <RequireAuthorization role={'Mobilease Admin'}>
            <Form.Checkbox fieldConfig={fields.includeDeleted} />
          </RequireAuthorization>
          <div className="form-actions">
            <Form.Button type="submit" primary>
              Apply Filter
            </Form.Button>
          </div>
        </>
      )}
    />
  );
};

const ResetPasswordButton = ({id}) => {
  const [sendResetState, sendReset] = useAsyncFn(async () => {
    const response = await UsersService.sendPasswordReset({
      id,
    });

    if (response.hasErrors) {
      notifications.error('Failed to send password reset link');
    } else {
      notifications.success('Password reset link sent!');
    }
  });

  return (
    <ConfirmButton
      onConfirm={sendReset}
      loading={sendResetState.loading}
      icon={faUnlock}
      loadingText="Sending..."
      tooltip="Send Password Reset"
    ></ConfirmButton>
  );
};

const ResendActivationButton = ({id}) => {
  const [resendActivationState, resendActivation] = useAsyncFn(async () => {
    const response = await UsersService.resendActivationEmail({
      id,
    });

    if (response.hasErrors) {
      notifications.error(
        'Failed to send activation link. Please refresh the page.'
      );
    } else {
      notifications.success('Activation link sent!');
    }
  });

  return (
    <ConfirmButton
      onConfirm={resendActivation}
      loading={resendActivationState.loading}
      icon={faEnvelope}
      loadingText="Sending..."
      tooltip="Resend Activation Link"
    ></ConfirmButton>
  );
};

const TableConfig = PagedDataTableConfig(UsersService.getAll, {
  rowProps: (item) => ({
    negative: item.isDeleted,
  }),
  columns: [
    {
      header: '',
      render: (item) => (
        <>
          {renderEditButton({
            item,
            descriptor: 'User Account',
            route: routes.users.detail,
          })}
          {!item.isDeleted && (
            <>
              {item.emailConfirmed ? (
                <ResetPasswordButton id={item.id} />
              ) : (
                <ResendActivationButton id={item.id} />
              )}
            </>
          )}
        </>
      ),
      cellProps: {
        collapsing: true,
      },
    },
    {
      header: 'Email Address',
      render: (item) => (
        <>{item.emailAddress + (item.isDeleted ? ' (deleted)' : '')}</>
      ),
      sortable: 'emailAddress',
    },
    {
      header: 'Name',
      render: (item) => `${item.firstName} ${item.lastName}`,
      sortable: 'firstName',
    },
    {
      header: 'Role',
      column: 'role',
      sortable: 'role',
    },
    {
      header: '2FA',
      sortable: 'phoneNumberConfirmed',
      render: (item) =>
        item.phoneNumberConfirmed ? (
          <Label content="2FA Enabled" color="blue" />
        ) : undefined,
    },
  ],
  defaultSort: {
    column: 'id',
    direction: 'ASC',
  },
  searchFieldNames: ['firstName', 'lastName', 'emailAddress'],
});
