import qs from 'qs';
import {useMemo} from 'react';
import {useLocation} from 'react-router-dom';

export function useQueryParams<T = {}>() {
  const location = useLocation();
  const queryParams = useMemo(
    () => qs.parse((location.search || '').replace('?', '')),
    [location.search]
  );
  return queryParams as unknown as T;
}
