import * as signalR from '@microsoft/signalr';
import {once} from 'lodash';
import {useEffect} from 'react';
import {SignalRConfig, SignalRTopics} from '../api/generated/enums';
import {userManager} from '../auth';
import {Env} from '../config/env-vars';
import {notify} from '../hooks/use-subscription';
import {logger} from '../utils/logger';

const log = logger('SignalR');

const connection = new signalR.HubConnectionBuilder()
  .withUrl(`${Env.apiBaseUrl}${SignalRConfig['/signalr-hub']}`, {
    accessTokenFactory: async () => {
      return (await userManager.getUser())?.access_token || '';
    },
  })
  .withAutomaticReconnect()
  .build();

const connect = once(() => {
  connection.start().then(() => {
    notify('signalr-state-changed', connection.state);
  });
});

export const useSignalR = (
  topic: SignalRTopics,
  callback: (...args: any[]) => void
) => {
  connect();

  useEffect(() => {
    connection.on(topic, callback);

    return () => {
      connection?.off(topic, callback);
    };
  }, [callback, topic]);
};

connection.onreconnected(() => {
  log.info('onreconnected', connection.state);
  notify('signalr-state-changed', connection.state);
});

connection.onreconnecting(() => {
  log.info('onreconnecting', connection.state);
  notify('signalr-state-changed', connection.state);
});

connection.onclose(() => {
  log.info('onclose', connection.state);
  notify('signalr-state-changed', connection.state);

  setTimeout(() => {
    if (connection.state === signalR.HubConnectionState.Disconnected) {
      connection.start();
    }
  }, 10000);
});
