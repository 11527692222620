import React, {useState} from 'react';
import {Button, Divider, Modal} from 'semantic-ui-react';
import {Link, useHistory} from 'react-router-dom';
import {routes} from '../routes';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {Form} from '../forms/form';
import {
  QuoteSetupFormFields,
  useFieldsForQuoteSetup,
} from './stages/quote-information';
import {useAsync} from 'react-use';
import {QuotesService, UpdateQuoteSetupRequest} from '../api/generated';
import {Flex} from '../components/flex';
import {notifications} from '../utils/notification-service';
import {buildPath} from '../routes/utils';

type CreateQuoteDto = Omit<UpdateQuoteSetupRequest, 'id'> & {
  customerName: string | null;
};
export const NewQuoteButton: React.FC = () => {
  const history = useHistory();
  const [creatingNewQuote, setCreatingNewQuote] = useState(false);

  const fetchOptions = useAsync(async () => {
    const response = await QuotesService.getSetupOptions();
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fields = useFieldsForQuoteSetup({
    customers: options?.customers,
    readOnly: false,
  });

  const submitNewQuote = async (values: CreateQuoteDto) => {
    const valuesToSubmit = {...values};
    if (
      options?.customers.find((o) => o.value === values.customerId) ===
      undefined
    ) {
      valuesToSubmit.customerName = values.customerId;
      valuesToSubmit.customerId = '';
    } else {
      valuesToSubmit.customerName = '';
    }

    const response = await QuotesService.setup({
      body: {...valuesToSubmit},
    });
    if (response.hasErrors) {
      return response;
    }
    if (response.data) {
      notifications.success('Quote created.');
      history.push(buildPath(routes.quotes.detail, {id: response.data?.id}));
    }
  };

  return (
    <>
      <Button
        as={Link}
        onClick={() => setCreatingNewQuote(true)}
        basic
        color="black"
        floated="right"
      >
        <FontAwesomeIcon icon={faPlus} /> New Quote
      </Button>
      {creatingNewQuote && (
        <Modal onClose={() => setCreatingNewQuote(false)} open>
          <Modal.Header>Create a New Quote</Modal.Header>
          <Modal.Content>
            <Form
              onSubmit={submitNewQuote}
              render={() => (
                <>
                  {fields && (
                    <>
                      <QuoteSetupFormFields fields={fields} />
                      <Divider />
                      <Flex.Row>
                        <Button primary>Submit</Button>
                        <Button
                          secondary
                          onClick={() => setCreatingNewQuote(false)}
                        >
                          Cancel
                        </Button>
                      </Flex.Row>
                    </>
                  )}
                </>
              )}
            ></Form>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
};
