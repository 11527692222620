import React from 'react';
import {Route} from 'react-router-dom';
import {SettingDetails} from './setting-details';
import {SettingListing} from './setting-listing';
import {routes} from '../routes/index';

export const SettingRoutes = () => {
  return (
    <>
      <Route path={routes.settings.index} exact>
        <SettingListing />
      </Route>
      <Route path={routes.settings.detail}>
        <SettingDetails />
      </Route>
    </>
  );
};
