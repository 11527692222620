import {faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React from 'react';
import {Link} from 'react-router-dom';
import {BasicPage} from '../basic-page';
import {Button} from '../forms/button';
import {routes} from '../routes';
import {
  PagedDataTableConfig,
  renderEditButton,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {RoutePatternsService} from '../api/generated';

const ListActions = {
  actions: (
    <>
      <Button as={Link} to={routes.admin.routePatterns.create} primary>
        <FontAwesomeIcon icon={faPlus} /> New Route Pattern
      </Button>
    </>
  ),
};

export const RoutePatternListing = () => {
  const pagedDataTable = usePagedDataTable(
    RoutePatternsService.getAll,
    TableConfig,
    ListActions
  );
  return <BasicPage title="Route Patterns">{pagedDataTable}</BasicPage>;
};

const TableConfig = PagedDataTableConfig(RoutePatternsService.getAll, {
  columns: [
    {
      header: '',
      render: (item) =>
        renderEditButton({
          item,
          descriptor: 'route pattern',
          route: routes.admin.routePatterns.detail,
        }),
      cellProps: {
        width: '1',
      },
    },
    {
      header: 'Description',
      column: 'description',
      sortable: 'description',
    },
    {
      header: 'Pattern',
      column: 'pattern',
      sortable: 'pattern',
    },
  ],
  defaultSort: {
    column: 'description',
    direction: 'ASC',
  },
  searchFieldNames: ['description', 'pattern'],
});
