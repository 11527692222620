import {includes, some} from 'lodash';
import React from 'react';
import {useUser} from './use-auth';
import {AllPermissions, Roles} from '../api/generated/enums';

type Permissions = typeof AllPermissions[number];
type PermissionParam = Permissions | Permissions[];
type RoleParam = Roles | Roles[];

type RequireAuthorizationProps =
  | {
      permission: PermissionParam;
      role?: RoleParam;
    }
  | {
      permission?: PermissionParam;
      role: RoleParam;
    }
  | {
      permission: PermissionParam;
      role: RoleParam;
    };

export const useRequireAuthorization = ({
  permission,
  role,
}: RequireAuthorizationProps) => {
  const user = useUser();

  const permissions = Array.isArray(permission) ? permission : [permission];
  const roles = Array.isArray(role) ? role : [role];

  const hasPermission = some(permissions, (x) => includes(user.permissions, x));
  const hasRole = roles.includes(user.role);
  return hasPermission || hasRole;
};

export const RequireAuthorization: React.FC<RequireAuthorizationProps> = ({
  children,
  ...rest
}) => {
  const isAuthorized = useRequireAuthorization({...rest});
  return <>{isAuthorized && children}</>;
};
