import {css} from '@emotion/core';
import React, {useState} from 'react';
import {Modal, Button} from 'semantic-ui-react';
import {UpdateAssetRequest, LeaseCompleteAssetsService} from '../api/generated';
import {useAssetContext} from '../assets/asset-context';
import {RequireAuthorization} from '../auth/require-authorization';
import {Flex} from '../components/flex';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {MobileaseTheme} from '../styles/branding';

export const NO_VALUE_PLACEHOLDER = 'N/A';

type LineItemProps = {
  leftText: string | React.ReactNode;
  rightLineDetail?: string | React.ReactNode;
};

export const AssetDetailRow = ({title, value}) => {
  return (
    <Flex.Row justifyContent="space-between" css={assetDetailRowStyles}>
      <Flex.Col className="row-title">{title}</Flex.Col>
      <Flex.Col className="row-value">{value || NO_VALUE_PLACEHOLDER}</Flex.Col>
    </Flex.Row>
  );
};

export const EditableAssetDetailRow: React.FC<{
  title: string;
  value: any;
  onAssetUpdated: () => void;
  fieldName: keyof UpdateAssetRequest;
}> = ({title, value, onAssetUpdated, fieldName}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <Flex.Row justifyContent="space-between" css={assetDetailRowStyles}>
        <Flex.Col className="row-title">{title}</Flex.Col>
        <Flex.Col className="row-value" justifyContent="space-between">
          <Flex.Row>
            {value || NO_VALUE_PLACEHOLDER}
            <RequireAuthorization permission="Assets: Manage">
              <Button
                css={buttonStyles}
                icon="pencil"
                onClick={() => setEditModalOpen(true)}
              />
            </RequireAuthorization>
          </Flex.Row>
        </Flex.Col>
      </Flex.Row>
      <EditAssetPropertyModal
        fieldName={fieldName}
        fieldLabel={title}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        initialPropertyValue={value}
        onAssetUpdated={onAssetUpdated}
      />
    </>
  );
};

export const LineItem: React.FC<{
  onClick?: React.MouseEventHandler<HTMLDivElement> | undefined;
}> = ({children, onClick}) => {
  return (
    <div className="line-item-row" onClick={onClick}>
      {children}
    </div>
  );
};

export const LineItemTwoColumnContent: React.FC<LineItemProps> = ({
  leftText,
  rightLineDetail,
}) => (
  <Flex.Row justifyContent="space-between" className="line-item-row">
    <div className="text-left">{leftText}</div>
    <div className="text-right">{rightLineDetail || NO_VALUE_PLACEHOLDER}</div>
  </Flex.Row>
);

export const EditableLineItem: React.FC<{
  leftText: string;
  onAssetUpdated: () => void;
  initialValue: any;
  fieldName: keyof UpdateAssetRequest;
  last?: boolean;
}> = ({leftText, onAssetUpdated, initialValue, fieldName}) => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <LineItem>
        <LineItemTwoColumnContent
          leftText={leftText}
          rightLineDetail={
            <>
              {initialValue || NO_VALUE_PLACEHOLDER}
              <RequireAuthorization permission="Assets: Manage">
                <Button
                  css={buttonStyles}
                  icon="pencil"
                  onClick={() => setEditModalOpen(true)}
                />
              </RequireAuthorization>
            </>
          }
        />
      </LineItem>
      <EditAssetPropertyModal
        fieldName={fieldName}
        fieldLabel={leftText}
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        initialPropertyValue={initialValue}
        onAssetUpdated={onAssetUpdated}
      />
    </>
  );
};

const EditAssetPropertyModal: React.FC<{
  open: boolean;
  onClose: () => void;
  initialPropertyValue: string;
  fieldName: string;
  fieldLabel: string;
  onAssetUpdated: () => void;
}> = ({
  open,
  onClose,
  fieldName,
  fieldLabel,
  initialPropertyValue,
  onAssetUpdated,
}) => {
  const notifications = useNotification();
  const {asset} = useAssetContext();
  return (
    <Modal size="small" open={open} onClose={onClose}>
      <Modal.Header>Edit {fieldLabel}</Modal.Header>
      <Modal.Content>
        <Form
          initialValues={{
            [fieldName]: initialPropertyValue,
          }}
          onSubmit={async (values: UpdateAssetRequest) => {
            const response = await LeaseCompleteAssetsService.update({
              body: {...values, assetId: asset?.assetId ?? ''},
            });
            if (response.hasErrors) {
              notifications.errors(response.errors);
              return;
            }
            onAssetUpdated();
            notifications.success('Asset updated.');
          }}
          render={() => {
            return (
              <>
                <Form.Row>
                  <Form.Input fieldLabel={fieldLabel} fieldName={fieldName} />
                </Form.Row>
                <Form.Row>
                  <Form.Button type="submit" primary>
                    Submit
                  </Form.Button>
                  <Form.Button type="button" onClick={onClose}>
                    Cancel
                  </Form.Button>
                </Form.Row>
              </>
            );
          }}
        />
      </Modal.Content>
    </Modal>
  );
};

export type LineItemListProps = {boldHeaders?: boolean; borderLast?: boolean};

export const LineItemList: React.FC<LineItemListProps> = ({
  children,
  ...styleProps
}) => {
  return <div css={lineItemStyles(styleProps)}>{children}</div>;
};

const buttonStyles = css`
  &.ui.button {
    color: ${MobileaseTheme.colors.purple500};
    background: #ffffff;
    margin-right: 0em;
    padding-right: 0em;
    font-size: 14px;
  }

  &.ui.icon.button {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

const assetDetailRowStyles = css`
  .row-title {
    font-weight: bold;
  }

  .row-value {
    text-align: right;
  }
`;

const lineItemStyles = ({boldHeaders, borderLast}: LineItemListProps) => css`
  width: 100%;

  .line-item-row {
    padding: 0.25rem 0rem;
    border-bottom: 1px solid lightgrey;

    &:last-child {
      border-bottom: ${borderLast ? '1px solid lightgrey' : 'none'};
    }
  }

  .text-left {
    font-size: 14px;
    font-weight: ${boldHeaders ? '600' : '400'};
    margin-right: 1rem;
  }

  .text-right {
    font-size: 14px;
    text-align: right;
  }
`;
