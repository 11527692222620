import React, {useEffect, useMemo, useState} from 'react';
import {Link, useHistory, useRouteMatch} from 'react-router-dom';
import {useAsync, useAsyncRetry} from 'react-use';
import {Button, Divider, Header, Icon, Label, Segment} from 'semantic-ui-react';

import {
  QuoteAssetGetDto,
  QuoteAssetsService,
  QuoteCommentsService,
  QuoteGetDto,
  QuotesService,
  UpdateQuoteSetupRequest,
} from '../api/generated';
import {QuoteStages} from '../api/generated/enums';
import {BasicPage} from '../basic-page';
import {Form} from '../forms';
import {useNotification} from '../hooks/use-notifications';
import {routes} from '../routes';
import {LocateAsset} from './stages/quote-locate-asset';
import {QuoteStageInfo, QuoteSteps} from './quote-steps';
import {
  QuoteInformation,
  QuoteSetupFormFields,
  useFieldsForQuoteSetup,
} from './stages/quote-information';
import {Flex} from '../components/flex';
import {css} from '@emotion/core';
import {dateFormatter} from '../assets/asset-utils';
import {QuoteConfigureTerms} from './stages/quote-configure-terms';
import {AsyncStateContainer} from '../components/async-state-container';
import {buildPath} from '../routes/utils';
import {QuoteCommentsModal} from './quote-comments-modal';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArchive, faUserEdit} from '@fortawesome/pro-regular-svg-icons';
import {ChangeAssigneeModal} from './change-assignee-modal';
import Currency from '../components/currency';
import {QuoteFinalDocuments} from './stages/quote-final-documents';
import {Tooltip} from '../components/tooltip';
import {QuoteReview} from './stages/quote-review';
import {notifications} from '../utils/notification-service';

export type UpdateQuoteSetupRequestWithOptionalCustomerInfo = Omit<
  UpdateQuoteSetupRequest,
  'customerId' | 'customerName'
> & {
  customerId: string | null;
  customerName: string | null;
};

export const QuoteCreate = () => {
  const history = useHistory();
  const notifications = useNotification();

  const fetchOptions = useAsync(async () => {
    const response = await QuotesService.getSetupOptions();
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const options = fetchOptions.value || undefined;

  const fields = useFieldsForQuoteSetup({
    customers: options?.customers,
    readOnly: false,
  });

  const onSubmit = async (
    values: UpdateQuoteSetupRequestWithOptionalCustomerInfo
  ) => {
    const valuesToSubmit = {...values};
    if (
      options?.customers.find((o) => o.value === values.customerId) ===
      undefined
    ) {
      valuesToSubmit.customerName = values.customerId;
      valuesToSubmit.customerId = null;
    } else {
      valuesToSubmit.customerName = null;
    }
    const response = await QuotesService.setup({
      body: valuesToSubmit as UpdateQuoteSetupRequest,
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return response;
    }

    notifications.success('Quote Created');
    history.push(
      buildPath(routes.quotes.newAsset, {id: response.data?.id ?? 0})
    );
  };

  return (
    <BasicPage title="New Quote">
      <QuoteSteps quoteStage={QuoteStages.Details} />
      <AsyncStateContainer {...fetchOptions}>
        <Form.Container>
          <Form
            onSubmit={onSubmit}
            render={() => (
              <>
                {fields && <QuoteSetupFormFields fields={fields} />}
                <Divider />
                <div className="form-actions">
                  <Form.Button type="submit" primary>
                    Finish Setup
                  </Form.Button>
                  <Form.Button secondary as={Link} to={routes.quotes.index}>
                    Cancel
                  </Form.Button>
                </div>
              </>
            )}
          />
        </Form.Container>
      </AsyncStateContainer>
    </BasicPage>
  );
};

enum StatusTypes {
  WATCH = 'WATCH',
  HOLD = 'HOLD',
}

const getColor = {
  [StatusTypes.WATCH]: 'orange',
  [StatusTypes.HOLD]: 'red',
};

export const QuoteDetails = () => {
  const match = useRouteMatch<{id: string; stage: string}>();
  const id = Number(match.params.id);
  const history = useHistory();
  const [activeStage, setActiveStage] = useState<QuoteStages | null>(
    QuoteStageInfo.find((x) => x.url === match.params.stage)?.quoteStage ??
      QuoteStages.Details
  );

  const fetchQuote = useAsyncRetry(async () => {
    const response = await QuotesService.getById({id: id});
    if (response.hasErrors) {
      return null;
    }
    if (activeStage === null && response.data) {
      history.push(
        buildPath(routes.quotes.detail, {
          id: id,
          stage: response.data.quoteStage,
        })
      );
    }
    return response.data;
  }, [id]);

  const fetchQuoteAssets = useAsyncRetry(async () => {
    const response = await QuoteAssetsService.getByQuoteId({
      id: id,
      includeUnLocatedQuoteAssets: true,
    });
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    return response.data;
  }, [id]);

  const quote = fetchQuote.value || undefined;
  const quoteAssets = fetchQuoteAssets.value || undefined;

  useEffect(() => {
    var requestedStageIndex = QuoteStageInfo.findIndex(
      (x) => x.url === match.params.stage
    );
    var actualQuoteStageIndex = QuoteStageInfo.findIndex(
      (x) => x.quoteStage === quote?.quoteStage
    );
    if (requestedStageIndex > -1) {
      if (requestedStageIndex > actualQuoteStageIndex) {
        setActiveStage(QuoteStageInfo[actualQuoteStageIndex]?.quoteStage);
      } else {
        setActiveStage(QuoteStageInfo[requestedStageIndex]?.quoteStage);
      }
    } else {
      setActiveStage((quote?.quoteStage as QuoteStages) ?? QuoteStages.Details);
    }
  }, [match.params.stage, quote]);

  const breadcrumbs = useMemo(
    () => [
      {title: 'Quotes', url: routes.quotes.index},
      {title: `Quote ${quote?.id}`},
    ],
    [quote]
  );

  return (
    <AsyncStateContainer {...fetchQuote}>
      <BasicPage title={breadcrumbs} css={styles}>
        <QuoteDetailsHeader quote={quote} />
        {activeStage && (
          <QuoteSteps
            quoteStage={
              quote ? QuoteStages[quote.quoteStage] : QuoteStages.Details
            }
          />
        )}
        {getQuoteFormForStage(
          activeStage as QuoteStages,
          quote,
          quoteAssets,
          fetchQuote.retry,
          fetchQuoteAssets.retry
        )}
      </BasicPage>
    </AsyncStateContainer>
  );
};

const getQuoteFormForStage = (
  activeStage: QuoteStages,
  quote: QuoteGetDto | undefined,
  quoteAssets: QuoteAssetGetDto[] | undefined,
  refetchQuote: () => void,
  refetchAssets: () => void
) => {
  if (quote) {
    switch (activeStage) {
      case QuoteStages.Details:
        return (
          <QuoteInformation
            quote={{
              ...quote,
              customerId: quote.customerId
                ? quote.customerId
                : quote.customerName,
            }}
            refetchQuote={refetchQuote}
            readOnly={
              quote.quoteStage === QuoteStages.InReview ||
              quote.quoteStage === QuoteStages.Completed
            }
          />
        );
      case QuoteStages.LocateAsset:
        return (
          <LocateAsset
            quoteAssets={quoteAssets ?? []}
            refetchQuoteAssets={refetchAssets}
            refetchQuote={refetchQuote}
            quote={quote}
            readOnly={
              quote.quoteStage === QuoteStages.InReview ||
              quote.quoteStage === QuoteStages.Completed
            }
          />
        );
      case QuoteStages.ConfigureTerms:
        return (
          <QuoteConfigureTerms
            quote={quote}
            refetchQuote={refetchQuote}
            readOnly={
              quote.quoteStage === QuoteStages.InReview ||
              quote.quoteStage === QuoteStages.Completed
            }
          />
        );
      case QuoteStages.InReview:
        return (
          <QuoteReview
            quote={quote}
            refetchQuote={refetchQuote}
            readOnly={quote.quoteStage === QuoteStages.Completed}
          />
        );
      case QuoteStages.Completed:
        return (
          <QuoteFinalDocuments quote={quote} refetchQuote={refetchQuote} />
        );
    }
  }
};

export type QuoteStageProps = {
  quote: QuoteGetDto;
  refetchQuote: () => void;
  readOnly?: boolean;
};

export const QuoteDetailsHeader: React.FC<{
  quote: QuoteGetDto | undefined;
}> = ({quote}) => {
  const [openCommentsModal, setOpenCommentsModal] = useState<boolean>(false);
  const [openAssigneeModal, setOpenAssigneeModal] = useState<boolean>(false);
  const [facilityStatusColor, setFacilityStatusColor] = useState<
    'green' | 'orange' | 'red'
  >('green');
  const [creditColor, setCreditColor] = useState<'green' | 'orange' | 'red'>(
    'green'
  );
  const fetchQuoteComments = useAsyncRetry(async () => {
    const response = await QuoteCommentsService.getAllForQuote({
      id: quote?.id ?? 0,
    });
    if (response.hasErrors) {
      return null;
    }
    return response.data;
  });

  const quoteComments = fetchQuoteComments.value || undefined;
  useEffect(() => {
    if (quote) {
      setFacilityStatusColor(getColor[quote.facilityStatus] ?? 'green');
      setCreditColor(getColor[quote.facilityStatus] ?? 'green');

      if (quote.availableCredit <= 0) {
        setCreditColor('red');
      }
    }
  }, [quote]);
  return (
    <>
      {quote && (
        <>
          {' '}
          <Segment>
            <Flex.Row justifyContent="space-between">
              <Flex.Col fill>
                <Flex.Row fill justifyContent="space-between" align="center">
                  <Flex.Col>
                    <Header
                      css={css`
                        margin: 0 !important;
                      `}
                    >
                      {quote.customerName}{' '}
                      {quote.customerId && <>({quote.customerId})</>}
                      {quote.isArchived && (
                        <Tooltip label="Archived">
                          <FontAwesomeIcon
                            icon={faArchive}
                            color="orange"
                            size="lg"
                          />
                        </Tooltip>
                      )}
                    </Header>
                  </Flex.Col>
                  <Flex.Col
                    css={css`
                      margin-bottom: 0.5rem;
                    `}
                  >
                    <Flex.Row>
                      <Tooltip label="Change Assignee">
                        <Button
                          icon
                          type="button"
                          secondary
                          onClick={() => setOpenAssigneeModal(true)}
                        >
                          <FontAwesomeIcon icon={faUserEdit} />
                        </Button>
                      </Tooltip>
                      <Tooltip label="View Quote Comments">
                        <Button
                          icon
                          type="button"
                          className="filter"
                          secondary
                          onClick={() => setOpenCommentsModal(true)}
                        >
                          <Icon name="comments outline" />
                          {quoteComments && quoteComments.length > 0 && (
                            <Label
                              className="filter-badge"
                              floating
                              circular
                              color="red"
                            >
                              {quoteComments.length}
                            </Label>
                          )}
                        </Button>
                      </Tooltip>
                    </Flex.Row>
                  </Flex.Col>
                </Flex.Row>
                <Flex.Row align="center">
                  <Flex.Col flex={2}>
                    <span>
                      <strong>Facility Status:</strong>{' '}
                      <strong
                        css={css`
                          color: ${facilityStatusColor};
                        `}
                      >
                        {quote.facilityStatus ?? 'N/A'}
                      </strong>
                    </span>
                    <span
                      css={css`
                        display: inherit;
                      `}
                    >
                      <strong>Available Credit:</strong>
                      <strong
                        css={css`
                          color: ${creditColor};
                          margin-left: 0.25rem;
                        `}
                      >
                        <Currency amount={quote.availableCredit} />
                      </strong>
                      <strong
                        css={css`
                          margin: 0 0.25rem 0 0.25rem;
                        `}
                      >
                        of
                      </strong>
                      <strong>
                        <Currency amount={quote.totalCredit} />
                      </strong>
                    </span>
                  </Flex.Col>
                  <Flex.Col flex={2}>
                    <span>
                      <strong>Assignee:</strong> {quote.assignedUserName}
                    </span>
                    <span>
                      <strong>Status:</strong> {quote.quoteStatus}
                    </span>
                  </Flex.Col>
                  <Flex.Col>
                    <span>
                      <strong>Created: </strong>
                      {quote.createdDate && dateFormatter(quote.createdDate)}
                    </span>
                    <span>
                      <strong>Modified: </strong>
                      {quote.modifiedDate && dateFormatter(quote.modifiedDate)}
                    </span>
                  </Flex.Col>
                </Flex.Row>
              </Flex.Col>
            </Flex.Row>
          </Segment>
          <QuoteCommentsModal
            quoteId={quote.id}
            quoteComments={quoteComments}
            openCommentsModal={openCommentsModal}
            setOpenCommentsModal={setOpenCommentsModal}
            isLoading={fetchQuoteComments.loading}
            commentsRetry={fetchQuoteComments.retry}
          />
          <ChangeAssigneeModal
            openAssigneeModal={openAssigneeModal}
            setOpenAssigneeModal={setOpenAssigneeModal}
            quoteId={quote.id}
            assignedUserId={quote.assignedUserId}
          />
        </>
      )}
    </>
  );
};

const styles = css`
  .margin-left {
    margin-left: 1rem;
  }

  .margin-right {
    margin-right: 1rem;
  }

  .filter {
    position: relative;
    margin-left: 0.5rem;
  }

  .filter-badge {
    z-index: 0 !important;
  }

  .credit-amount {
    font-size: 1.2rem;
  }
`;
