import {useMemo} from 'react';
import {BasicPage, TitlePart} from '../basic-page';
import {Form} from '../forms';
import React from 'react';
import {routes} from '../routes';
import {
  QuoteTermFields,
  useQuoteTermFormProps,
} from '../quotes/stages/quote-configure-terms';
import {dropdown, fieldConfig, input} from '../forms/schema-utils';
import {useAsync} from 'react-use';
import {
  CreateQuoteTermsTemplateRequest,
  QuoteTermsTemplatesService,
  UpdateQuoteTermsTemplateRequest,
} from '../api/generated';
import {Button, Divider} from 'semantic-ui-react';
import {Flex} from '../components/flex';
import {useHistory, useRouteMatch} from 'react-router';
import {Link} from 'react-router-dom';
import {notifications} from '../utils/notification-service';
import {DeleteButton} from '../components/confirm-button';
import {FindSalesTaxFormComponent} from '../quotes/sales-tax-modal';

type NonTermFields = Pick<
  UpdateQuoteTermsTemplateRequest,
  'customerId' | 'name'
>;

const useFields = (customerOptions) => {
  return fieldConfig<NonTermFields>({
    name: input({
      fieldLabel: 'Template Name',
      required: true,
      inputProps: {autoFocus: true},
    }),
    customerId: dropdown({
      fieldLabel: 'Customer',
      inputProps: {
        selectOnNavigation: false,
        selection: true,
        search: true,
        clearable: true,
        options: customerOptions,
      },
    }),
  });
};

export const QuoteTermTemplateEdit: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{id: string}>();
  const id = Number(match.params.id);
  const templateFetch = useAsync(async () => {
    const response = await QuoteTermsTemplatesService.getTemplateById({
      id: id,
    });
    return response.data;
  });
  const template = templateFetch.value;
  const breadcrumbs: TitlePart[] = useMemo(() => {
    return [
      {title: 'Quote Term Templates', url: routes.quoteTermTemplates.index},
      {title: template?.name ?? ''},
    ];
  }, [template]);

  const customerOptions = useAsync(async () => {
    const response =
      await QuoteTermsTemplatesService.getQuoteTermsTemplateOptions();
    return response.data?.customers;
  }, []);

  const {fields: termFields, termsFormMutators} = useQuoteTermFormProps(false);

  const deleteQuoteTermTemplate = async () => {
    const response = await QuoteTermsTemplatesService.deleteTemplate({id});
    if (response.hasErrors) {
      notifications.errors(response.errors);
      return;
    }
    history.push(routes.quoteTermTemplates.index);
    notifications.success('Template deleted.');
  };

  const fields = useFields(customerOptions.value);

  return (
    <>
      <BasicPage title={breadcrumbs}>
        <Form.Container>
          {fields && template && (
            <Form
              onSubmit={async (values: UpdateQuoteTermsTemplateRequest) => {
                const response =
                  await QuoteTermsTemplatesService.updateTemplate({
                    body: {...values, id},
                  });
                if (response.hasErrors) {
                  notifications.errors(response.errors);
                  return response;
                }
                notifications.success('Template updated');
                history.push(routes.quoteTermTemplates.index);
              }}
              mutators={termsFormMutators}
              initialValues={{...template}}
              render={() => (
                <>
                  <QuoteTermsTemplateFields fields={fields} />
                  <Flex.Row justifyContent="flex-end">
                    <FindSalesTaxFormComponent />
                  </Flex.Row>
                  <QuoteTermFields autoFocus={false} fields={termFields} />
                  <Divider />
                  <Flex.Row justifyContent="space-between">
                    <div>
                      <Button content="Save" primary />
                      <Button
                        content="Cancel"
                        as={Link}
                        secondary
                        to={routes.quoteTermTemplates.index}
                      />
                    </div>
                    <DeleteButton
                      onConfirm={deleteQuoteTermTemplate}
                      text="Delete"
                      loading={false}
                    />
                  </Flex.Row>
                </>
              )}
            />
          )}
        </Form.Container>
      </BasicPage>
    </>
  );
};

export const QuoteTermTemplateCreate: React.FC = () => {
  const history = useHistory();
  const breadcrumbs: TitlePart[] = useMemo(() => {
    return [{title: 'Create'}];
  }, []);

  const customerOptions = useAsync(async () => {
    const response =
      await QuoteTermsTemplatesService.getQuoteTermsTemplateOptions();
    return response.data?.customers;
  }, []);

  const fields = useFields(customerOptions.value);
  const {fields: termFields, termsFormMutators} = useQuoteTermFormProps(false);

  return (
    <>
      <BasicPage title={breadcrumbs}>
        <Form.Container>
          {fields && (
            <Form
              mutators={termsFormMutators}
              onSubmit={async (values: CreateQuoteTermsTemplateRequest) => {
                const response =
                  await QuoteTermsTemplatesService.createTemplate({
                    body: {...values},
                  });
                if (response.hasErrors) {
                  notifications.errors(response.errors);
                  return response;
                }
                notifications.success('Template created');
                history.push(routes.quoteTermTemplates.index);
              }}
              render={() => (
                <>
                  <QuoteTermsTemplateFields fields={fields} />
                  <Flex.Row justifyContent="flex-end">
                    <FindSalesTaxFormComponent />
                  </Flex.Row>
                  <QuoteTermFields fields={termFields} />
                  <Divider />
                  <Flex.Row>
                    <div>
                      <Button content="Create" primary />
                      <Button
                        content="Cancel"
                        as={Link}
                        secondary
                        to={routes.quoteTermTemplates.index}
                      />
                    </div>
                  </Flex.Row>
                </>
              )}
            />
          )}
        </Form.Container>
      </BasicPage>
    </>
  );
};

const QuoteTermsTemplateFields = ({fields}) => (
  <>
    <Form.Input fieldConfig={fields.name} />
    <Form.Dropdown fieldConfig={fields.customerId} />
  </>
);
