import React from 'react';
import {Route} from 'react-router';
import {Switch} from 'react-router-dom';
import {routes} from '../routes';
import {
  QuoteTermTemplateCreate,
  QuoteTermTemplateEdit,
} from './quote-term-template-create-update';
import {QuoteTermTemplatesListing} from './quote-term-templates-listing';

export const QuoteTermTemplatesRoutes = () => (
  <Switch>
    <Route path={routes.quoteTermTemplates.index} exact>
      <QuoteTermTemplatesListing />
    </Route>
    <Route path={routes.quoteTermTemplates.create} exact>
      <QuoteTermTemplateCreate />
    </Route>
    <Route path={routes.quoteTermTemplates.detail} exact>
      <QuoteTermTemplateEdit />
    </Route>
  </Switch>
);
