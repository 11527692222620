import _ from 'lodash';
import React from 'react';
import {Route, Switch, useLocation} from 'react-router-dom';
import {routes} from '../routes/index';
import {
  InvoiceListing,
  InvoiceListingTabTypes,
  InvoiceTypeRoutesMap,
} from './invoice-listing';
import {InvoiceCreate} from './invoice-upload';

export const InvoiceRoutes = () => {
  const location = useLocation();
  const invoiceRouteMapInverted = _.invert(InvoiceTypeRoutesMap);
  return (
    <Switch>
      <Route path={routes.invoices.upload} exact>
        <InvoiceCreate />
      </Route>
      <Route path={routes.invoices.index}>
        <InvoiceListing
          activeTab={
            (invoiceRouteMapInverted[
              location.pathname
            ] as InvoiceListingTabTypes) ?? InvoiceListingTabTypes.MONTHLY
          }
        />
      </Route>
    </Switch>
  );
};
