import React from 'react';
import {useFormState} from 'react-final-form';
import {Json} from '../components/json';

export const DebugFormState = ({full}: {full?: boolean}) => {
  const state = useFormState();
  const output = full ? state : state.values;

  return <Json data={output} />;
};
