import css from '@emotion/css/macro';
import {MobileaseTheme} from '../styles/branding';

export const BlueFilterFieldStyles = css`
  .angle.down.icon {
    color: ${MobileaseTheme.colors.blue400};
    cursor: pointer;
    position: absolute;
    width: auto;
    height: auto;
    line-height: 1.21428571em;
    top: 0.73333333em;
    right: 0.53333333em;
    z-index: 3;
    margin: -0.53333333em;
    padding: 0.51666667em 0.91666667em;
    opacity: 0.8;
    transition: opacity 0.1s ease;
  }

  .visible.menu.transition {
    background: ${MobileaseTheme.colors.blue50};
    border: 0;
  }

  .ui.selection.dropdown {
    background: ${MobileaseTheme.colors.blue50};
    border: 0;
    width: 100%;
    .menu > .item {
      border-top: 0;
    }
  }

  .ui.input input {
    background: ${MobileaseTheme.colors.blue50};
    border: 0;
  }

  .ui.input input:focus {
    background: ${MobileaseTheme.colors.blue50};
  }

  .SingleDatePicker {
    .DateInput {
      .DateInput_input {
        background-color: ${MobileaseTheme.colors.blue50};
        border-color: transparent;

        &::placeholder {
          color: ${MobileaseTheme.colors.grey500};
        }
      }
      .DateInput_input__focused:focus {
        background-color: ${MobileaseTheme.colors.blue50};
        border-color: transparent;
      }
    }
  }

  .ui.button.submit-button {
    width: 100%;
    margin-top: 1rem;
    background-color: ${MobileaseTheme.colors.blue500};
    color: white;
    &::hover {
      background-color: ${MobileaseTheme.colors.blue200};
    }
  }
`;
