import React, {useEffect, useState} from 'react';
import {useForm} from 'react-final-form';
import {useLocation} from 'react-router';
import {Button, Divider, Modal} from 'semantic-ui-react';
import {
  RequestTemplateDetailDto,
  RequestTemplateService,
} from '../api/generated';
import {FileDropzone} from '../components/dropzone';
import {HtmlTextInput} from '../components/html-input';
import {useCustomerContext} from '../customer-context';
import {Form} from '../forms';
import {fieldConfig, input, textarea} from '../forms/schema-utils';
import {useNotification} from '../hooks/use-notifications';

export const RequestTemplateModal: React.FC<{
  open: boolean;
  requestTemplate: RequestTemplateDetailDto | null;
  associatedAssetId: string | undefined;
  onClose: () => void;
}> = ({open, onClose, requestTemplate, associatedAssetId}) => {
  const location = useLocation();
  const notifications = useNotification();
  const [{currentCustomerId}] = useCustomerContext();
  const [files, setFiles] = useState<File[]>([]);

  const onSubmit = async (values) => {
    const response = await RequestTemplateService.sendRequestEmail({
      customerId: currentCustomerId,
      subject: values.subject,
      body: values.body,
      requestUrl: location.pathname,
      assetId: associatedAssetId,
      key: requestTemplate?.key ?? '',
      attachments: files as [],
    });
    if (response.hasErrors) {
      notifications.error(response.errors[0].errorMessage);
      return response;
    }
    onClose();
    notifications.success('Request Sent');
  };

  useEffect(() => {
    setFiles([]);
  }, [open]);

  return (
    <Modal open={open} size="large" onClose={onClose}>
      <Modal.Header>
        {requestTemplate ? `${requestTemplate.name} Request` : ''}
      </Modal.Header>
      <Modal.Content>
        <Form
          initialValues={requestTemplate ?? undefined}
          onSubmit={onSubmit}
          render={() => (
            <>
              <FormFields files={files} setFiles={setFiles} />
              <Divider />
              <div className="form-actions">
                <Form.Button type="submit" primary>
                  Send Request
                </Form.Button>
                <Button onClick={() => onClose()}>Cancel</Button>
              </div>
            </>
          )}
        />
      </Modal.Content>
    </Modal>
  );
};

const FormFields = ({files, setFiles}) => {
  const {change} = useForm();

  return (
    <>
      <Form.Row>
        <Form.Input fieldConfig={fields.intendedRecipients} />
      </Form.Row>
      <Form.Row>
        <Form.Input fieldConfig={fields.subject} />
      </Form.Row>
      <Form.Row>
        <HtmlTextInput
          fieldConfig={fields.body}
          onChange={(content: string) => {
            change('body', content);
          }}
        />
      </Form.Row>
      <Form.Row>
        <FileDropzone
          files={files}
          onFilesChanged={setFiles}
          maxFiles={0}
          fileUploadedMessage={'Files selected'}
          accept={[
            '.pdf',
            '.csv',
            '.xlsx',
            '.xls',
            '.jpg',
            '.jpeg',
            '.png',
            '.txt',
          ]}
        />
      </Form.Row>
    </>
  );
};

const fields = fieldConfig<SendRequestEmailRequest>({
  subject: input({
    fieldLabel: 'Subject',
  }),
  body: textarea({
    fieldLabel: 'Body',
  }),
  intendedRecipients: input({
    fieldLabel: 'To',
    inputProps: {
      readOnly: true,
    },
  }),
});

type SendRequestEmailRequest = {
  subject: string;
  body: string;
  intendedRecipients: string;
};
