import React from 'react';
import {faFilePdf, faPlus} from '@fortawesome/pro-regular-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {InvoicesService} from '../api/generated';
import {BasicPage} from '../basic-page';
import {DateFormat} from '../components/date';
import {Tooltip} from '../components/tooltip';
import {Button} from '../forms/button';
import {
  PagedDataTableConfig,
  usePagedDataTable,
} from '../hooks/use-paged-data-table';
import {useAsyncFn} from 'react-use';
import {useNotification} from '../hooks/use-notifications';
import {currencyFormatter} from '../assets/asset-utils';
import {routes} from '../routes';
import {useHistory} from 'react-router-dom';

export const SupplementalInvoiceListing = () => {
  const history = useHistory();

  const dataTable = usePagedDataTable(
    InvoicesService.getAllSupplemental,
    TableConfig,
    {
      actions: (
        <Button
          type="button"
          primary
          onClick={() => {
            history.push({
              pathname: routes.invoices.upload,
              state: {
                isSupplemental: true,
                returnRoute: routes.invoices.supplemental,
              },
            });
          }}
        >
          <FontAwesomeIcon icon={faPlus} /> New Invoice
        </Button>
      ),
    }
  );

  return (
    <>
      <BasicPage title="">{dataTable}</BasicPage>
    </>
  );
};

const TableConfig = PagedDataTableConfig(InvoicesService.getAllSupplemental, {
  columns: [
    {
      header: 'Customer #',
      column: 'customerId',
      sortable: 'customerId',
    },
    {
      header: 'Customer Name',
      column: 'customerName',
    },
    {
      header: 'Invoice #',
      column: 'invoiceNumber',
      sortable: 'invoiceNumber',
    },
    {
      header: 'Invoice Date',
      render: (item) =>
        item.invoiceDate ? (
          <>
            <DateFormat date={item.invoiceDate} />
          </>
        ) : (
          <></>
        ),
      sortable: 'invoiceDate',
    },
    {
      header: 'Due Date',
      render: (item) =>
        item.dueDate ? (
          <>
            <DateFormat date={item.dueDate} />
          </>
        ) : (
          <></>
        ),
      sortable: 'dueDate',
    },
    {
      header: 'Amount',
      render: (item) => <>{item.amount && currencyFormatter(item.amount)}</>,
      sortable: 'amount',
    },
    {
      header: '',
      render: (item) => <ViewInvoiceButton id={item.id} />,
      cellProps: {
        collapsing: true,
      },
    },
  ],
  defaultSort: {
    column: 'customerId',
    direction: 'ASC',
  },
  searchFieldNames: ['customerId', 'invoiceNumber'],
  initialPageSize: 10,
});

export const ViewInvoiceButton = ({id}) => {
  const notifications = useNotification();

  const [getLinkState, sendGetLink] = useAsyncFn(async () => {
    const response = await InvoicesService.getInvoiceLink({
      id,
    });
    if (response.data) {
      window.open(response.data.toString(), '_blank');
    } else {
      notifications.error(response.errors);
    }
  });

  return (
    <>
      <Tooltip label="View Invoice">
        <Button
          className="clear"
          basic
          icon
          type="button"
          loading={getLinkState.loading}
          onClick={sendGetLink}
        >
          <FontAwesomeIcon icon={faFilePdf} />
        </Button>
      </Tooltip>
    </>
  );
};
