import React from 'react';
import {CurrencyCodes} from '../api/generated/enums';

type Currency = {
  amount: number;
  locale?: 'en-us';
  currency?: CurrencyCodes;
};

const Currency: React.FC<Currency> = (props) => <>{formatCurrency(props)}</>;

export const formatCurrency = ({
  amount,
  locale = 'en-us',
  currency = 'USD',
}: Currency) => {
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
  });
  return (
    <div className="currency">
      {formatter.formatToParts(amount).map(({type, value}, index) => {
        switch (type) {
          case 'currency':
            return (
              <span className="symbol" key={index}>
                {value}
              </span>
            );
          case 'minusSign':
            return '(';
          default:
            return <React.Fragment key={index}>{value}</React.Fragment>;
        }
      })}
      {amount >= 0 ? '' : ')'}
    </div>
  );
};

export default Currency;
