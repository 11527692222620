import moment from 'moment';

export const percentageDateCalculator = (startDate: Date, endDate: Date) => {
  const mTodayDate = moment();
  const mEndDate = moment(endDate);
  const mStartDate = moment(startDate);
  const daysFromStartToToday = mTodayDate.diff(mStartDate, 'days');
  const daysFromStartToEnd = mEndDate.diff(mStartDate, 'days');
  return Math.floor((100 * daysFromStartToToday) / daysFromStartToEnd);
};

export const getMonthsFromStartToToday = (startDate: Date) => {
  const todaysDate = moment();
  const mStartDate = moment(startDate);
  return todaysDate.diff(mStartDate, 'M');
};

export const getAllFormattedDates = (startDate: Date, endDate: Date) => {
  const today = moment().format('MM/DD/YYYY');
  const formattedStartDate = dateFormatter(startDate);
  const formattedEndDate = endOfMonth(endDate);

  return {today, formattedStartDate, formattedEndDate};
};

export const endOfMonth = (date: Date) => {
  return moment
    .utc(date)
    .add('M', 1)
    .set('date', 1)
    .subtract('d', 1)
    .format('MM/DD/YYYY');
};

export const dateFormatter = (date: Date) => {
  return moment.utc(date).format('MM/DD/YYYY');
};

export const numberFormatter = (number: number) => {
  return Intl.NumberFormat().format(number);
};

export const currencyFormatter = (number: number) => {
  return new Intl.NumberFormat('en-us', {
    style: 'currency',
    currency: 'USD',
  }).format(number);
};
