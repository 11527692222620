import css from '@emotion/css';
import {IconDefinition} from '@fortawesome/fontawesome-common-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RequestTemplateKeys} from '../api/generated/enums';
import {Flex} from '../components/flex';
import {Button} from '../forms/button';
import {MobileaseTheme} from '../styles/branding';

export const RequestButton: React.FC<
  Button & {
    icon: IconDefinition;
    templateKey: RequestTemplateKeys;
  }
> = ({children, icon, label, ...restOfProps}) => {
  return (
    <Button css={style} {...restOfProps}>
      <Flex.Row justifyContent="space-between" align="center">
        <Flex.Col className="icon-wrapper">
          <FontAwesomeIcon className="icon fas" icon={icon} />
        </Flex.Col>
        <Flex.Col className="label">{label}</Flex.Col>
      </Flex.Row>
    </Button>
  );
};

const style = css`
  &.ui.button {
    background-color: ${MobileaseTheme.colors.blue50};
    padding: 0.5rem 1rem;
    transition: background-color 1ms ease-ease-in-out;

    &:hover {
      background-color: ${MobileaseTheme.colors.blue500};

      .label {
        color: ${MobileaseTheme.colors.white};
      }
    }
  }

  .icon-wrapper {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    margin-right: 0.75rem;
    background-color: ${MobileaseTheme.colors.blue200};

    & .icon {
      font-size: 0.8rem;
      color: ${MobileaseTheme.colors.white};
    }
  }

  .label {
    color: ${MobileaseTheme.colors.blue600};
    font-size: 0.75rem;
    font-weight: 600;
  }
`;
