import {css} from '@emotion/core';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useMemo, useState} from 'react';
import {Modal, ModalHeader, ModalContent} from 'semantic-ui-react';
import {
  AssetUsageDetailDto,
  AssetUsageService,
  CreateAssetUsageRequest,
} from '../../api/generated';
import {Tooltip} from '../../components/tooltip';
import {Form} from '../../forms';
import {
  fieldConfig,
  input,
  datepicker,
  textarea,
} from '../../forms/schema-utils';
import {notifications} from '../../utils/notification-service';
import React from 'react';
import {RequireAuthorization} from '../../auth/require-authorization';
import {MobileaseTheme} from '../../styles/branding';
import {AssetTypes} from '../../api/generated/enums';

export const AssetUsageHistoryCreateButton: React.FC<{
  assetId: string;
  assetType: AssetTypes;
  onUsageHistoryUpdated: () => void;
}> = ({assetId, onUsageHistoryUpdated, assetType}) => {
  const [addingUsageReading, setAddingUsageReading] = useState(false);
  return (
    <>
      <RequireAuthorization permission="Assets: Manage">
        <Tooltip label="Add New Entry">
          <FontAwesomeIcon
            icon={faPlus}
            css={css`
              cursor: pointer;
              margin-left: 0.5rem;
              color: ${MobileaseTheme.colors.purple500};
            `}
            onClick={() => setAddingUsageReading(true)}
          />
        </Tooltip>
        <AddUsageReadingModal
          open={addingUsageReading}
          onClose={() => {
            setAddingUsageReading(false);
          }}
          assetId={assetId}
          assetType={assetType}
          onUsageEntryAdded={onUsageHistoryUpdated}
        />
      </RequireAuthorization>
    </>
  );
};

const AddUsageReadingModal: React.FC<{
  open: boolean;
  onClose: () => void;
  onUsageEntryAdded: () => void;
  assetType: AssetTypes;
  assetId: string;
}> = ({open, onClose, assetId, onUsageEntryAdded, assetType}) => {
  const formFields = useMemo(() => {
    return fieldConfig<Omit<CreateAssetUsageRequest, 'assetId'>>({
      usageUnits: input({
        fieldLabel: assetType === AssetTypes.Equipment ? 'Hours' : 'Mileage',
        inputProps: {type: 'number'},
      }),
      readingDate: datepicker({
        fieldLabel: 'Reading Date',
        required: true,
        inputProps: {showClearDate: false},
      }),
      notes: textarea({
        fieldLabel: 'Notes',
        inputProps: {required: false},
      }),
    });
  }, [assetType]);

  return (
    <Modal open={open} onClose={onClose}>
      <ModalHeader>
        Add {assetType === AssetTypes.Equipment ? 'Usage' : 'Mileage'} Reading
      </ModalHeader>
      <ModalContent>
        <Form
          initialValues={{readingDate: new Date(), assetId}}
          onSubmit={async (values: AssetUsageDetailDto) => {
            const response = await AssetUsageService.createAssetUsage({
              body: {
                ...values,
              },
            });
            if (response.hasErrors) {
              notifications.errors(response.errors);
              return;
            }
            notifications.success('Reading added.');
            onUsageEntryAdded();
            onClose();
          }}
          render={() => (
            <>
              <Form.Input fieldConfig={formFields.usageUnits} />{' '}
              <Form.DatePicker fieldConfig={formFields.readingDate} />{' '}
              <Form.TextArea fieldConfig={formFields.notes} />{' '}
              <Form.Button type="submit" primary>
                Submit
              </Form.Button>
              <Form.Button type="button" secondary onClick={onClose}>
                Cancel
              </Form.Button>
            </>
          )}
        ></Form>
      </ModalContent>
    </Modal>
  );
};
