import {css} from '@emotion/core';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Tab} from 'semantic-ui-react';
import {routes} from '../routes';
import {SecondaryNavigation} from '../secondary-navigation';
import {MobileaseTheme} from '../styles/branding';
import {ChargesListing} from './charges-listing';
import {InvoiceListing} from './invoice-listing';

export enum FinancialListingTabTypes {
  INVOICES = 'INVOICES',
  CHARGES = 'CHARGES',
}

export const FinancialTypeRoutesMap: {[T in FinancialListingTabTypes]: string} =
  {
    [FinancialListingTabTypes.CHARGES]: routes.financials.charges,
    [FinancialListingTabTypes.INVOICES]: routes.financials.invoices,
  };

const panes = [
  {
    menuItem: 'Invoices',
    render: () => (
      <Tab.Pane as="string">
        <InvoiceListing />
      </Tab.Pane>
    ),
    tabType: FinancialListingTabTypes.INVOICES,
  },
  {
    menuItem: 'Charges',
    render: () => (
      <Tab.Pane as="string">
        <ChargesListing />
      </Tab.Pane>
    ),
    tabType: FinancialListingTabTypes.CHARGES,
  },
];

export const FinancialListing: React.FC<{
  activeTab: FinancialListingTabTypes;
}> = ({activeTab}) => {
  const history = useHistory();
  return (
    <div css={styles}>
      <SecondaryNavigation />
      <Tab
        menu={{secondary: true, pointing: true}}
        panes={panes}
        activeIndex={panes.findIndex((x) => x.tabType === activeTab)}
        onTabChange={(event, data) => {
          history.push(
            FinancialTypeRoutesMap[panes[data.activeIndex as number].tabType]
          );
        }}
      />
    </div>
  );
};

export const styles = css`
  .ui.secondary.menu .item,
  .ui.secondary.menu .item.active {
    font-size: 1.25rem;
  }

  .ui.secondary.menu .item.active {
    font-weight: bold;
  }

  .ui.secondary.pointing.menu {
    padding: 0rem 2rem;
  }

  .ui.menu.secondary.pointing .active.item {
    border-color: ${MobileaseTheme.colors.blue500};
  }
`;
