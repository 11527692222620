import css from '@emotion/css/macro';
import styled from '@emotion/styled/macro';
import {SkipNavContent, SkipNavLink} from '@reach/skip-nav';
import {cx} from 'emotion';
import React, {useCallback, useEffect, useState} from 'react';
import {ToastProvider} from 'react-toast-notifications';
import {Modal} from 'semantic-ui-react';
import {analtyics} from './analytics';
import {setUser} from './analytics/sentry';
import {userManager} from './auth';
import {useUser} from './auth/use-auth';
import {NonProductionWarning} from './components/non-production-warning';
import {Env} from './config/env-vars';
import {CustomerContextProvider} from './customer-context';
import {useSubscription} from './hooks/use-subscription';
import {PrimaryNavigation} from './primary-navigation';
import {RequestTemplateContextProvider} from './request-templates/request-template-context';
import {LocationNotifier} from './routes/location-notifier';
import {Routes} from './routes/routes';
import {useSignalR} from './signalr/use-signalr';
import {themeColors} from './styles';
import {MobileaseTheme} from './styles/branding';
import {notifications, NotificationService} from './utils/notification-service';
import {TwoFactorWarning} from './components/two-factor-warning';

export const App = () => {
  const user = useUser();
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    analtyics.identify(`${user.id}`);
    setUser({id: `${user.id}`, email: user.email});
  }, [user.email, user.id]);

  useEffect(() => {
    userManager.clearStaleState();
  }, []);

  useSubscription('session-expired', () => {
    setSessionExpired(true);
    userManager.signoutRedirect();
  });

  useSignalR(
    'TestSignal',
    useCallback(() => {
      notifications.info('TestSignal');
    }, [])
  );

  const onLocationChange = useCallback(
    (location) => analtyics.trackPage(location),
    []
  );

  return (
    <ToastProvider components={ToastComponents}>
      <CustomerContextProvider>
        <RequestTemplateContextProvider>
          <NotificationService />
          <LocationNotifier onChange={onLocationChange} />
          <SkipNavLink>Main Content</SkipNavLink>
          <div
            className={cx('root-container', `${Env.name}-environment`)}
            css={styles}
          >
            <div className="main-content">
              <NonProductionWarning></NonProductionWarning>
              <TwoFactorWarning />
              <PrimaryNavigation user={user} />
              <div className="content">
                <SkipNavContent></SkipNavContent>
                <Routes />
              </div>
            </div>
          </div>
          <Modal open={sessionExpired}>
            <Modal.Header>Session Expired</Modal.Header>
          </Modal>
        </RequestTemplateContextProvider>
      </CustomerContextProvider>
    </ToastProvider>
  );
};

const styles = css`
  &.root-container {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    overflow: hidden;
    background-color: ${themeColors.white2};
  }

  .main-content {
    flex: 1 1 0%;
    overflow-x: auto;
    display: flex;
    position: relative;
    background: #fff;
    border: solid 1px #b4c5ce;
    box-shadow: rgb(87, 99, 105) -1px 2px 4px -3px;
    border-top: none;
    flex-direction: column;

    > .content {
      flex: 1 1 0%;
      overflow-y: auto;
      background-color: ${MobileaseTheme.colors.grey50};
      position: relative;
    }
  }

  &.production-environment .main-content {
    border-top: solid 4px ${themeColors.black1};
  }

  .segment {
    border-radius: 1rem;
    box-shadow: none;
    border: none;
  }
`;

export const ToastContainer = styled.div`
  position: fixed;
  top: 70px;
  right: 8px;
  max-height: 100%;
  z-index: 10001;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 8px;
`;

const ToastComponents = {ToastContainer};
